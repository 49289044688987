let config;
const { ENVIRONMENT } = require('./env.json');

try {
  // console.log(`Getting config for ${ENVIRONMENT}`);
  config = require(`./${ENVIRONMENT}.json`);
} catch (err) {
  console.log('No env found. Falling back to dev environment', err);
  config = require('./dev.json');
}
module.exports = config;
