export const addScriptTag = ({ src }) => {
  const script = document.createElement('script');
  script.src = src;
  script.type = 'text/javascript';
  script.async = true;
  document.body.appendChild(script);
};

export const addLinkTag = ({ href }) => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  link.async = true;
  document.body.appendChild(link);
};
