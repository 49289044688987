import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {URLS} from '../../../lib/api';
import {get} from '../../../lib/restClient';
import TablePager from '../../../components/TablePager';
import {Input} from '../../../components/Inputs';
import useDataFilter from '../../../hooks/useDataFilter';
import {simpleDateFormatting} from '../../../lib/dates';
import {useHistory, useParams} from 'react-router-dom/cjs/react-router-dom.min';
import RightAlignedButton from '../../../components/RightAlignedButton';
import DatePicker from '../../../components/DatePicker';
import SortArrow from '../../../components/SortArrow';
import {Checkbox} from '../../../components/Checkbox';
import Loader from "../../../components/Loader";

const initialValues = {};

const StockDetail = () => {
    const {productId} = useParams();
    const history = useHistory();
    const [showFilters, setShowFilters] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const {
        filtersValues,
        setFilterValue,
        submitFilters,
        sort,
        setSortField,
    } = useDataFilter({
        initialFilters: initialValues,
        initialSort: {field: '', order: 'asc'},
        onRefetch: () => refetch(),
    });
    const {isLoading, error, data, refetch, isFetching} = useQuery('adminStocksDetail', async () => {
        const detailResponse = await get(URLS.PRODUCT.DETAIL({productId}));
        const getStockResponse = await get(URLS.PRODUCT.GET_STOCK({
            productId,
            page: currentPage,
            sort,
            filters: {
                ...filtersValues,
                registrationNumber: encodeURIComponent(filtersValues?.registrationNumber) || null,
            },
        }));
        const partnersResponse = await get(URLS.ADMIN.LIST_PARTNERS({}));

        return {
            product: detailResponse?.data,
            stocks: getStockResponse?.data,
            stocksPageCount: getStockResponse.headers['x-page-count'],
            stocksTotalCount: getStockResponse.headers['x-total-count'],
            partners: partnersResponse?.data,
        };
    }, {refetchOnWindowFocus: false});
    useEffect(() => {
        setTimeout(() => refetch(), 600);
        return () => {
        };
    }, [sort, currentPage, refetch]);

    if (error) {
        return <span>Error</span>;
    }
    const showLoader = isLoading || isFetching;
    return (
        <main  className="main-cms">
            <form action="#" method="post">
                <div className="box main-header-cms">
                    <div className="main-header-cms-top">
                        <div className="main-header-cms-title">
                            <span className="header-cms-icon icon-stock"></span>
                            <h1 className="header-cms-title">
                                Stock de productos <span
                                className="cms-title-detail big-size">{data?.product?.name}</span>
                                {!showLoader &&
                                    <span className="cms-title-detail">{data?.stocksTotalCount} licencias</span>}
                            </h1>
                        </div>
                        <div className="main-header-cms-actions">
                            <div className="header-cms-actions-buttons">
                                <button type="button" id="btnFilters" name="btnFilters" className="btn-cms-actions"
                                        onClick={() => setShowFilters(!showFilters)}>
                                    Filtros
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {showLoader &&
                    <Loader boxed/>
                }
                {!showLoader && <div className="box">
                    <div className={`cms-filters ${showFilters ? 'show' : false}`}>
                        <h2 className="cms-filters-title">Filtros</h2>
                        <button type="button" id="btnCloseFilters" name="btnCloseFilters" className="btn-close-filters"
                                onClick={() => setShowFilters(!showFilters)}>
                            <span className="icon-close-modal"></span>
                        </button>

                        <div className="form-item-row form-row-special">
                            <div className="form-item filter-size1">
                                <label className="form-label">ID Registration Number</label>
                                <div className="form-text-container">
                                    <Input type="text" id="txtId" name="txtId" className="form-text"
                                           onChange={(e) => setFilterValue('registrationNumber', e.target.value)}/>
                                </div>
                            </div>

                            <div className="form-item item-special-size2">
                                <label className="form-label">Fecha de alta</label>
                                <div className="form-text-container-two">
                                    <DatePicker
                                        placeholder="Desde"
                                        onChange={(date) => setFilterValue('createAt|from', date)}/>
                                    <DatePicker
                                        placeholder="Hasta"
                                        onChange={(date) => setFilterValue('createAt|to', date)}/>
                                </div>
                            </div>

                            <div className="form-item item-special-size2">
                                <label className="form-label">Localization</label>
                                <div className="form-text-container multiselect-container">
                                    <div className="form-text-container">
                                        <select id="cmbLocalization" name="cmbLocalization" className="form-select"
                                                onChange={(e) => setFilterValue('localization', e.target.value)}>
                                            <option value="">Todos</option>
                                            <option value="numen">Numen</option>
                                            <option value="partner">Partner</option>
                                        </select>
                                        <span className="icon-arrow select-arrow"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-item-row form-row-special">
                            <div className="form-item item-special-size1">
                                <label className="form-label">Partners</label>

                                <div className="form-text-container">
                                    <select id="cmbPartner" name="cmbPartner" className="form-select"
                                            onChange={(e) => setFilterValue('partners', e.target.value)}>
                                        <option value="">Todos</option>
                                        {data?.partners?.map((partner) => (
                                            <option key={partner.id}
                                                    value={partner.id}>{partner.organizationName}</option>
                                        ))}
                                    </select>
                                    <span className="icon-arrow select-arrow"></span>
                                </div>
                            </div>
                            <div className="form-item item-special-size2">
                                <label className="form-label">Versión</label>

                                <div className="form-text-container">
                                    <select id="cmbVersion" name="cmbVersion" className="form-select"
                                            onChange={(e) => setFilterValue('version', e.target.value)}>
                                        <option value={""}>Todos</option>
                                        {data?.product?.versions.map(version => (
                                            <option key={version?.id} value={version?.id}>{version?.version}</option>
                                        ))}
                                    </select>
                                    <span className="icon-arrow select-arrow"></span>
                                </div>
                            </div>
                        </div>
                        <div className="form-item-row form-row-special">

                            <div className="form-item filter-size6 align-bottom">

                                <Checkbox
                                    id="chTerms"
                                    checked={filtersValues.onlyAssigned}
                                    onClick={() => {
                                        setFilterValue('onlyAssigned', !filtersValues?.onlyAssigned);
                                    }}
                                />
                                <label for="chShow">Mostrar sólo los asignados a cliente</label>


                            </div>


                            <RightAlignedButton
                                onClick={() => {
                                    setShowFilters(false);
                                    setCurrentPage(0);
                                    submitFilters();
                                }}>
                                Filtrar
                            </RightAlignedButton>
                        </div>

                    </div>
                </div>}

                {!showLoader && <div className="box">
                    <div className="responsive-table full-table">
                        <table className="table-type2">
                            <thead>
                            <tr>
                                <th onClick={() => setSortField({field: 'registrationNumber'})}>
                                    REGISTRATION NUMBER
                                    <SortArrow sort={sort} field="registrationNumber"/>
                                </th>
                                <th onClick={() => setSortField({field: 'version'})}>
                                    Versión
                                    <SortArrow sort={sort} field="version"/>
                                </th>
                                <th>Localization</th>
                                <th>fecha de alta
                                </th>
                                <th>Pedido</th>
                                <th onClick={() => setSortField({field: 'partner'})}>
                                    Partner
                                    <SortArrow sort={sort} field="partner"/>
                                </th>
                                <th>Cliente</th>
                            </tr>
                            </thead>

                            <tbody>
                            {data?.stocks?.map(stock => (
                                <tr key={stock?.id}>
                                    <td>{stock?.registrationNumber}</td>
                                    <td>{stock?.version}</td>
                                    <td>{stock?.localization}</td>
                                    <td>{simpleDateFormatting(new Date(stock?.createAt))}</td>
                                    <td><a href="#"
                                           onClick={() => history.push(`/admin/orders/${stock?.order}`)}>{stock?.order}</a>
                                    </td>
                                    <td>{stock?.partner}</td>
                                    <td>{stock?.customer}</td>
                                </tr>
                            ))}

                            </tbody>
                        </table>
                    </div>
                </div>}

                {!showLoader && <TablePager
                    pages={data?.stocksPageCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    refetch={refetch}
                />
                }

            </form>
        </main>
    );
};

export default StockDetail;