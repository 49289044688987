import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';

import useDataFilter from '../../../../../hooks/useDataFilter';
import { URLS } from '../../../../../lib/api';
import { nonStandardDateFormatting } from '../../../../../lib/dates';
import { get } from '../../../../../lib/restClient';
import TablePager from '../../../../../components/TablePager';
import { getUserId } from '../../../../../lib/auth';
import SortArrow from '../../../../../components/SortArrow';
import Loader from '../../../../../components/Loader';

const initialValues = {
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
};


const TAG_COLOR = {
  'aceptado': {
    color: '#4EC1C2',
    borderColor: '#4EC1C2',
  },
  'validado': {
    color: '#4EC1C2',
    borderColor: '#4EC1C2',
  },
  'pagado': {
    color: '#CD6632',
    borderColor: '#CD6632',
  },
  'reservado': {
    color: '#E2CB26',
    borderColor: '#E2CB26',
  },
  'cancelado': {
    color: '#A21E28',
    borderColor: '#A21E28',
  },
};

export default () => {
  const history = useHistory();
  const {
    currentPage,
    setCurrentPage,
    filtersValues,
    sort,
    setSortField,
  } = useDataFilter({
    initialFilters: initialValues,
    initialSort: { field: 'createdAt', order: 'asc' },
    onRefetch: () => refetch(),
  });
  const [sortedQuery, setSelectedSortedQuery] = useState(null);
  const { isLoading, error, data, refetch, isFetching } = useQuery('partnersOrders', async (...params) => {
    const response = await get(URLS.PARTNER.LIST_ORDERS({
      page: currentPage,
      sort: sortedQuery || sort,
      filters: filtersValues,
      partnerId: getUserId(),
    }));
    return {
      list: response.data,
      pageCount: response.headers['x-page-count'],
      totalCount: response.headers['x-total-count'],
    };
  }, { refetchOnWindowFocus: false });
  useEffect(() => {
    setSelectedSortedQuery(sort);
    setTimeout(() => refetch(), 1200);
    return () => { };
  }, [sort, currentPage, refetch]);
  if (error) {
    return <span>Error</span>;
  }

  const showLoader = isLoading || isFetching;
  if (showLoader) {
    return <Loader />;
  }

  return (
    <>
      <div className="box">
        <div className="responsive-table full-table">
          <table className="table-type2">
            <thead>
              <tr>
                <th onClick={() => setSortField({ field: 'id' })}>
                  Pedido
                  <SortArrow sort={sort} field="id" />
                </th>
                <th onClick={() => setSortField({ field: 'createdAt' })}>
                  Fecha
                  <SortArrow sort={sort} field="createdAt" />
                </th>
                <th onClick={() => setSortField({ field: 'state' })}>
                  Estado
                  <SortArrow sort={sort} field="state" />
                </th>
                <th onClick={() => setSortField({ field: 'total_amount' })}>
                  Precio
                  <SortArrow sort={sort} field="total_amount" />
                </th>
                <th onClick={() => setSortField({ field: 'country' })}>
                  País
                  <SortArrow sort={sort} field="country" />
                </th>
                <th className="centered">Acciones</th>
              </tr>
            </thead>

            <tbody>
              {data?.list?.map(order => (
                <tr>
                  <td>{order?.id}</td>
                  <td>{nonStandardDateFormatting(order?.createdAt?.date)}</td>
                  <td>
                    <span
                      className="status-tag reserved"
                      style={TAG_COLOR[order?.state?.caption.toLowerCase()]}
                    >
                      {order?.state?.caption}
                    </span>
                  </td>
                  <td>{order?.total_amount} {order?.currency?.name}</td>
                  <td>{order?.country?.name}</td>
                  <td className="centered">
                    <button type="button" id="btnShowItem1" name="btnShowItem1" className="btn-icon-border">
                      <span className="icon-show" onClick={() => history.push(`/partners/orders/${order?.id}`)}></span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {!showLoader && <TablePager
        pages={data.pageCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        refetch={refetch} />
      }
    </>

  );
};
