import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';

import { Input } from '../../components/Inputs';
import { Checkbox } from '../../components/Checkbox';
import { Button } from '../../components/Buttons';
import { Image } from '../../components/Image';
import FormError from '../../components/FormError';
import TextModal from '../../components/TextModal';
import TermsAndConditionsModal from '../../components/TermsAndConditionsModal';
import PoliciesModal from '../../components/PoliciesModal';
import { URLS } from '../../lib/api';
import { get } from '../../lib/restClient';
import { extractErrorMessageFromResponse } from '../../lib/utils';

const PartnerRegisterSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
  lastName: Yup.string()
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
  email: Yup.string()
    .email('Introduce correo electrónico válido')
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
  phone: Yup.string()
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
  country: Yup.object().nullable(),
  organizationName: Yup.string()
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
  address: Yup.string()
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
  address2: Yup.string()
    .optional()
    .nullable(),
  region: Yup.string()
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
  city: Yup.string()
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
  zip: Yup.string()
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
  website: Yup.string()
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
});

const PartnerRegister = () => {
  const [TermsAndConditionsModalIsOpen, setTermsAndConditionsModalIsOpen] = useState(false);
  const [policiesModalIsOpen, setPoliciesModalIsOpen] = useState(false);
  const [isRegisterFinished, setIsRegisterFinished] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [areTermsAndConditionsChecked, setTermsAndConditionsChecked] = useState(null);
  const { isLoading: areCountriesLoading, data: countriesData } = useQuery('countries', async () => {
    const response = await get(URLS.COUNTRIES.LIST());
    return response.data;
  });
  const { mutate: register } = useMutation(
    registerBody => axios.post(URLS.PARTNER.REGISTER(), registerBody),
    {
      onSuccess: () => {
        setIsRegisterFinished(true);
      },
      onError: (error) => {
        if (extractErrorMessageFromResponse(error)) {
          setErrorMessage(extractErrorMessageFromResponse(error));
        } else {
          setErrorMessage(error.response.data.message);
        }
      }
    }
  );
  return (
    <>
      <div className="access-form-bl access-register">
        <Link to="/" className="back-link icon-arrow">Login</Link>
        <Image icon="logo.svg" alt="Numentech" className="access-logo" />
        <h1 className="title">Registro de Partner</h1>
        <p className="form-desc">Todos los campos son obligatorios salvo Dirección 2 que es opcional.</p>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            locales: ['ESP'],
            country: 'España',
            organizationName: '',
            address: '',
            region: '',
            city: '',
            zip: '',
            website: '',
          }}
          validationSchema={PartnerRegisterSchema}
          onSubmit={(values) => {
            if (!values.country.iso_code) {
              values.country = countriesData.filter(c => c.name === values.country)[0];
            }
            if (areTermsAndConditionsChecked) {
              register(values);
            } else {
              setErrorMessage('Debes marcar los términos y condiciones');
            }
          }}
          render={({
            values,
            errors,
            handleSubmit,
            setFieldValue
          }) => (
            <form action="#" method="post">
              <fieldset className="access-fieldset">
                <div className="form-item-row">
                  <div className="form-item">
                    <label className="form-label">Nombre</label>
                    <Input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="form-text"
                      value={values.firstName}
                      onChange={(e) => setFieldValue('firstName', e.target.value)}
                    />
                    {errors.firstName && <FormError>{errors.firstName}</FormError>}
                  </div>

                  <div className="form-item">
                    <label className="form-label">Apellidos</label>
                    <Input
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="form-text"
                      value={values.lastName}
                      onChange={(e) => setFieldValue('lastName', e.target.value)}
                    />
                    {errors.lastName && <FormError>{errors.lastName}</FormError>}
                  </div>
                </div>
                <div className="form-item">
                  <label className="form-label">Email</label>
                  <Input
                    type="text"
                    id="email"
                    name="email"
                    className="form-text"
                    value={values.email}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                  />
                  {errors.email && <FormError>{errors.email}</FormError>}
                </div>

                <div className="form-item-row">
                  <div className="form-item">
                    <label className="form-label">Nombre de la organización</label>
                    <Input
                      type="text"
                      id="organizationName"
                      name="organizationName"
                      className="form-text"
                      value={values.organizationName}
                      onChange={(e) => setFieldValue('organizationName', e.target.value)}
                    />
                    {errors.organizationName && <FormError>{errors.organizationName}</FormError>}
                  </div>
                  <div className="form-item">
                    <label className="form-label">Teléfono</label>
                    <Input
                      type="text"
                      id="phone"
                      name="phone"
                      className="form-text"
                      value={values.phone}
                      onChange={(e) => setFieldValue('phone', e.target.value)}
                    />
                    {errors.phone && <FormError>{errors.phone}</FormError>}
                  </div>
                </div>

                <div className="form-item-row">
                  <div className="form-item">
                    <label className="form-label">Dirección</label>
                    <Input
                      type="text"
                      id="address"
                      name="address"
                      className="form-text"
                      value={values.address}
                      onChange={(e) => setFieldValue('address', e.target.value)}
                    />
                    {errors.address && <FormError>{errors.address}</FormError>}
                  </div>
                  <div className="form-item">
                    <label className="form-label">Dirección 2</label>
                    <Input
                      type="text"
                      id="address2"
                      name="address2"
                      className="form-text"
                      value={values.address2}
                      onChange={(e) => setFieldValue('address2', e.target.value)}
                    />
                    {errors.address2 && <FormError>{errors.address2}</FormError>}
                  </div>
                </div>

                <div className="form-item-row">
                  <div className="form-item">
                    <label className="form-label">Pais</label>
                    <div className={`form-text-container ${errors.country && 'form-error'}`}>
                      {!areCountriesLoading &&
                        <select
                          id="cmbCountry"
                          name="cmbCountry"
                          className="form-select"
                          defaultValue="España"
                          onChange={(e) => setFieldValue('country', e.target.value)}>
                          {countriesData.map(country => (
                            <option value={country.name}>{country.name}</option>
                          ))}
                        </select>
                      }
                      <span className="icon-arrow select-arrow"></span>
                    </div>
                    {errors.country && <FormError>{errors.country}</FormError>}
                  </div>
                  <div className="form-item">
                    <label className="form-label">Región</label>
                    <Input
                      type="text"
                      id="region"
                      name="region"
                      className="form-text"
                      value={values.region}
                      onChange={(e) => setFieldValue('region', e.target.value)}
                    />
                    {errors.region && <FormError>{errors.region}</FormError>}
                  </div>
                </div>

                <div className="form-item-row">
                  <div className="form-item">
                    <label className="form-label">Ciudad</label>
                    <Input
                      type="text"
                      id="city"
                      name="city"
                      className="form-text"
                      value={values.city}
                      onChange={(e) => setFieldValue('city', e.target.value)}
                    />
                    {errors.city && <FormError>{errors.city}</FormError>}
                  </div>
                  <div className="form-item">
                    <label className="form-label">Código postal</label>
                    <Input
                      type="text"
                      id="zip"
                      name="zip"
                      className="form-text"
                      value={values.zip}
                      onChange={(e) => setFieldValue('zip', e.target.value)}
                    />
                    {errors.zip && <FormError>{errors.zip}</FormError>}
                  </div>
                </div>

                <div className="form-item">
                  <label className="form-label">Página web de la organización</label>
                  <Input
                    type="text"
                    id="website"
                    name="website"
                    className="form-text"
                    value={values.website}
                    onChange={(e) => setFieldValue('website', e.target.value)}
                  />
                  {errors.website && <FormError>{errors.website}</FormError>}
                </div>

                <div className="form-item-checkbox">
                  <Checkbox
                    id="chTerms"
                    checked={areTermsAndConditionsChecked}
                    onClick={() => {
                      if (areTermsAndConditionsChecked) {
                        setErrorMessage('Debes marcar los términos y condiciones');
                      } else {
                        setErrorMessage('');
                      }
                      setTermsAndConditionsChecked(!areTermsAndConditionsChecked)
                    }}
                  />
                  <label htmlFor="chTerms">He leído y acepto las condiciones de los
                    <a style={{ fontWeight: 800 }} onClick={() => setTermsAndConditionsModalIsOpen(true)}> términos y condiciones</a>
                    {' '}y la <a style={{ fontWeight: 800 }} onClick={() => setPoliciesModalIsOpen(true)}>política de privacidad</a></label>
                </div>
                {errorMessage && <FormError>{errorMessage}</FormError>}

                <Button
                  type="submit"
                  id="btnRegister"
                  name="btnRegister"
                  onClick={handleSubmit}>
                  Registrarme
                  </Button>

              </fieldset>
            </form>
          )} />
        <TextModal
          isOpen={isRegisterFinished}
          text="Gracias por tu solicitud. Nos podremos en contacto con usted."
          onAccept={() => window.location.href = '/'}
        />
      </div>

      <div className="access-right-col">
        <Image icon="bg-register.jpg" alt="" />
      </div>

      <TermsAndConditionsModal isOpen={TermsAndConditionsModalIsOpen} setIsOpen={setTermsAndConditionsModalIsOpen} />
      <PoliciesModal isOpen={policiesModalIsOpen} setIsOpen={setPoliciesModalIsOpen} />
    </>
  );
};

export default PartnerRegister;