import React, { useState } from 'react';

import TermsAndConditionsModal from '../../../../components/TermsAndConditionsModal';
import PoliciesModal from '../../../../components/PoliciesModal';
import CookiesModal from '../../../../components/CookiesModal';

const Header = () => {
  const [termsAndConditionsModalIsOpen, setTermsAndConditionsModalIsOpen] = useState(false);
  const [policiesModalIsOpen, setPoliciesModalIsOpen] = useState(false);
  const [cookiesModalIsOpen, setCookiesModalIsOpen] = useState(false);
  return (
    <>
      <footer className="main-footer">
        <div className="row">
          <div className="footer-container">
            <div className="footer-top">
              <a className="footer-logo" href="//numentech.es" target="_blank" rel="noreferrer">
                <img src="../assets/img/logo-footer.svg" alt="Numentech" />
              </a>
              <span className="footer-slogan">Solutions that keep you safe</span>
            </div>
            <div className="footer-bottom">
              <ul className="terms-list">
                <li><a href="#" title="Más info: Términos y condiciones" onClick={() => setTermsAndConditionsModalIsOpen(true)}>Términos y condiciones</a></li>
                <li><a href="#" title="Más info: Política de privacidad" onClick={() => setPoliciesModalIsOpen(true)}>Política de privacidad</a></li>
                <li><a href="#" title="Más info: Política de Cookies" onClick={() => setCookiesModalIsOpen(true)}>Política de Cookies</a></li>
                <li>© Numentech 2020</li>
              </ul>
            </div>
          </div>
        </div>
        <TermsAndConditionsModal isOpen={termsAndConditionsModalIsOpen} setIsOpen={setTermsAndConditionsModalIsOpen} />
        <PoliciesModal isOpen={policiesModalIsOpen} setIsOpen={setPoliciesModalIsOpen} />
        <CookiesModal isOpen={cookiesModalIsOpen} setIsOpen={setCookiesModalIsOpen} />
      </footer>
    </>
  );
};



export default Header;
