import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import EmptyMessage from '../../../../components/EmptyMessage';
import InfoModal from '../../../../components/InfoModal';
import { get } from '../../../../lib/restClient';
import { URLS } from '../../../../lib/api';
import { nonStandardDateFormatting } from '../../../../lib/dates';
import { downloadFile } from '../../../../lib/downloads';
import Loader from '../../../../components/Loader';

export default () => {
  const { partnerId } = useParams();
  const [showParnterAgreementModal, setShowParnterAgreementModal] = useState(false);
  const [partnerAgreementDescription, setPartnerAgreementDescription] = useState('');
  const { isLoading: areDocumentsLoading, data: documentsData, error } = useQuery('userDocuments', async () => {
    const response = await get(URLS.DOCUMENTS.LIST_DOCUMENTS_BY_USER({ userId: partnerId }));
    return response.data;
  });
  if (error) {
    return <span>error</span>;
  }
  if (areDocumentsLoading) {
    return <Loader />;
  }
  const partnersAgreement = documentsData.filter(d => d.document.type.id === 'partner_agreement');
  const productSolutionsActivation = documentsData.filter(d => d.document.type.id === 'product_solution_activation');
  return (
    <div className="box form-box">
      <h2 className="title-type6">Documentación</h2>
      <div className="responsive-table">
        <table className="table-type1">
          <thead>
            <tr>
              <th className="fixed-width">Acuerdos de partnership</th>
              <th>Fecha firma</th>
              <th className="centered">Descarga archivo</th>
            </tr>
          </thead>
          <tbody>
            {partnersAgreement.map(d => (
              <tr>
                <th>
                  <span className="icon-doc">{d.document.title}</span>
                </th>

                <td>
                  {d?.signedAt?.date ?
                    nonStandardDateFormatting(d?.signedAt?.date) :
                    'Sin firmar'
                  }
                </td>

                <td className="centered">
                  <button type="button" id="btnDownloadItem1" name="btnDownloadItem1" className="btn-icon-border"
                    onClick={() => {
                      if (!d.document.content_url) {
                        setPartnerAgreementDescription(d?.document?.description);
                        return setShowParnterAgreementModal(true);
                      }
                      downloadFile({
                        url: URLS.PRODUCT.DOCUMENT_DOWNLOAD({ documentId: d.document.id }),
                        name: document.title,
                      })
                    }}>
                    {d.document.content_url && <span className="icon-download"></span>}
                    {!d.document.content_url && <span className="icon-show"></span>}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {partnersAgreement.length === 0 &&
          <EmptyMessage>No se ha firmado contrato con Numentech.</EmptyMessage>}
      </div>

      <div className="responsive-table">
        <table className="table-type1">
          <thead>
            <tr>
              <th className="fixed-width">Autorizaciones de distribución</th>
              <th>Fecha firma</th>
              <th className="centered">Descarga Archivo</th>
            </tr>
          </thead>
          <tbody>
            {productSolutionsActivation.map(d => (
              <tr>
                <th>
                  <span className="icon-doc">{d.document.title}</span>
                </th>

                <td>
                  {d?.signedAt?.date ?
                    nonStandardDateFormatting(d?.signedAt?.date) :
                    'Sin firmar'
                  }
                </td>

                <td className="centered">
                  <button type="button" id="btnDownloadItem1" name="btnDownloadItem1" className="btn-icon-border"
                    onClick={() => {
                      if (!d.document.content_url) {
                        setPartnerAgreementDescription(d?.document?.description);
                        return setShowParnterAgreementModal(true);
                      }
                      downloadFile({
                        url: URLS.PRODUCT.DOCUMENT_DOWNLOAD({ documentId: d.document.id }),
                        name: document.title,
                      })
                    }}>
                    {d.document.content_url && <span className="icon-download"></span>}
                    {!d.document.content_url && <span className="icon-show"></span>}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {productSolutionsActivation.length === 0 &&
          <EmptyMessage>No se ha firmado ningún acuerdo de distribución de productos Numentech.</EmptyMessage>}
      </div>

      <InfoModal
        isOpen={showParnterAgreementModal}
        setIsOpen={setShowParnterAgreementModal}
        title="Document"
        subtitle={partnerAgreementDescription}
      />

    </div>
  );
}
