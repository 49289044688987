import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Info from './components/Info';
import Versions from './components/Versions';
import Dependencies from './components/Dependencies';
import { get } from '../../../lib/restClient';
import { URLS } from '../../../lib/api';
import { useQuery } from 'react-query';

export default ({ isCreating }) => {
  const history = useHistory();
  const { productId } = useParams();
  const { data, isLoading } = useQuery('productDetailForThis', async () => {
    if (!isCreating) {
      const response = await get(URLS.PRODUCT.DETAIL({ productId }));
      return { product: response.data };
    }
    return;
  });
  const infoRef = useRef();
  const versionRef = useRef();
  const dependencyRef = useRef();
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <div role="main" className="main-cms">
      <form action="#" method="post">
        <div className="box main-header-cms">
          <div className="main-header-cms-top">
            <div className="main-header-cms-title">
              <span className="header-cms-icon icon-products"></span>
              <h1 className="header-cms-title">
                {isCreating ? 'Crear' : 'Editar'} producto {!isCreating && !isLoading && ` - ${data?.product?.name}`}
              </h1>
            </div>

            <div className="main-header-cms-actions">
              <div className="header-cms-actions-absolute">
                <button type="button" id="btnCancel" name="btnCancel" className="btn-type4 small"
                  onClick={() => history?.goBack()}>
                  Volver
                </button>
                {selectedTab === 0 &&
                  <button type="button" id="btnSave" name="btnSave" className="btn-type5"
                    onClick={() => {
                      if (selectedTab === 0) {
                        infoRef.current.update();
                      } else if (selectedTab === 2) {
                        dependencyRef.current.update();
                      }
                    }}>
                    Guardar
                </button>
                }
              </div>

            </div>
          </div>

          <nav className="cms-tabs">
            <ul className="tabs-container">
              <li
                className={`item-tab ${selectedTab === 0 ? 'active' : ''}`}
                onClick={() => setSelectedTab(0)}>
                Datos básicos
              </li>
              {!isCreating &&
                <li
                  className={`item-tab ${selectedTab === 1 ? 'active' : ''}`}
                  onClick={() => setSelectedTab(1)}>
                  Versión
              </li>
              }
              {!isCreating &&
                <li
                  className={`item-tab ${selectedTab === 2 ? 'active' : ''}`}
                  onClick={() => setSelectedTab(2)}>
                  Dependencia
              </li>
              }
            </ul>
          </nav>

        </div>

        {selectedTab === 0 &&
          <Info
            ref={infoRef}
            key={1}
            isCreating={isCreating} />
        }
        {!isCreating && selectedTab === 1 &&
          <Versions
            ref={versionRef}
            key={2} />
        }
        {!isCreating && selectedTab === 2 &&
          <Dependencies
            ref={dependencyRef}
            key={3} />
        }

      </form>
    </div>
  );
}

