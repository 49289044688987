import React, { useState } from 'react';
import moment from 'moment';
import ModalVideo from 'react-modal-video';
import { useMutation, queryCache } from 'react-query';

import {
  isProductAboutToExpire,
  isProductExpired,
  isProductInactive,
  isProductActive,
  isProductRenewable,
  canActivateTrialInMyProducts,
  showProductTrialTag,
} from '../../../../../lib/models/products';
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import InfoModal from '../../../../../components/InfoModal';
import { post } from '../../../../../lib/restClient';
import { URLS } from '../../../../../lib/api';
import SVG from '../../../../../components/Svg';
import { simpleDateFormatting, isFutureDate } from '../../../../../lib/dates';
import { getVideoId, getVideoProvider } from '../../../../../lib/videos';
import { downloadFile } from '../../../../../lib/downloads';

import SlideToggle from '../../../../../components/SlideToggle';

export default ({ product, showAddKey }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [video, setVideo] = useState({ provider: 'youtube', videoId: '2MpUj-Aua48' });
  const [isRenewModalShown, setRenewModalIsShown] = useState(false);
  const [showFinishedRenew, setShowFinishedRenew] = useState(false);
  const [videosIndex, setVideosIndex] = useState(0);
  const [videoModalShown, setVideoModalShown] = useState(false);
  const { mutate: renewProduct } = useMutation(
    ({ productId }) => post(URLS.PRODUCT.ORDER_RENEW({ productId })),
    {
      onSuccess: () => {
        setRenewModalIsShown(false);
        setShowFinishedRenew(true);
        const { refetch } = queryCache.getQuery('clientProducts');
        refetch();
      },
      onError: (error) => {
        console.log('ERROR', error);
      }
    }
  );

  const DATE_FORMAT = 'MM/DD/YYYY';
  let formattedEndDate = moment().format(DATE_FORMAT);
  if (product.end_date) {
    formattedEndDate = moment(product.end_date.date).format(DATE_FORMAT);

  }
  const activationDate = new Date(product?.activation_date?.date.split(' ')[0]);

  const linksPagerIndicators = [];
  const links = product?.product_version?.documentation?.links || [];
  for (let i = 0; i < links.length; i++) {
    linksPagerIndicators.push(<li className={videosIndex === i && 'active'}>{i}</li>);
  }
  const isPerpetual = product?.product_version?.product?.perpetual;
  return (
    <>
      <SlideToggle
        collapsed={!isOpen}>
        {({ toggle, setCollapsibleElement }) => (
          <div className="accordion-item">
            <div className={`accordion-header ${isOpen ? 'active' : ''}`} onClick={(e) => {
              setIsOpen(!isOpen);
              toggle(e);
            }}>
              <SVG
                svg={product?.product_version?.product?.solution?.svgIcon}
                alt={product?.product_version?.product?.name} />

              <div className="accordion-header-info">
                <h4 className="accordion-header-title">{product.product_version.product.name}</h4>
                {!isPerpetual &&
                  <span className="accordion-header-desc">(
                    {product?.product_version?.product?.duration} meses
                    {isFutureDate(activationDate) && `. Esta licencia se activará el ${simpleDateFormatting(activationDate)}`}
                  )
                </span>
                }
              </div>

              {!isPerpetual && isProductAboutToExpire(product) && <span className="product-tag next-expire">Va a caducar</span>}
              {isProductExpired(product) && <span className="product-tag next-expire">Caducado</span>}
              {isProductInactive(product) && <span className="product-tag inactive">Sin activar</span>}
              {isProductActive(product) && <span className="product-tag active">Activado</span>}
              {showProductTrialTag(product) && <span className="trial-tag">Trial version</span>}

              {isProductRenewable(product) &&
                <button
                  type="button"
                  id="btnRenew"
                  name="btnRenew"
                  className="btn-type3"
                  onClick={() => {
                    setRenewModalIsShown(true);
                  }}
                >
                  Renovar
              </button>
              }
              {canActivateTrialInMyProducts(product) &&
                <button
                  type="button"
                  id="btnRenew"
                  name="btnRenew"
                  className="btn-type3"
                  onClick={() => {
                    toggle();
                    showAddKey();
                  }}>
                  Activar Trial
                </button>
              }
            </div>

            <div className="accordion-content slide-toggle__box" ref={setCollapsibleElement}>
              <div className="accordion-content-data slide-toggle__inner">
                <div className="accordion-data-item accordion-data-item-full">
                  <span className="accordion-content-title">Descripción</span>
                  <span className="accordion-content-desc">{product.product_version.product.description}</span>
                </div>
              </div>

              <div className="accordion-content-data">
                <div className="accordion-data-item">
                  <span className="accordion-content-title">Partner</span>
                  <span className="accordion-content-desc">{product.partner.organizationName}</span>
                </div>
                <div className="accordion-data-item">
                  <span className="accordion-content-title">Public key</span>
                  <span className="accordion-content-desc">{product.registrationNumber}</span>
                </div>
                {!isPerpetual &&
                  <div className="accordion-data-item">
                    <span className="accordion-content-title">Fecha renovación</span>
                    <span className="accordion-content-desc">{formattedEndDate}</span>
                  </div>
                }

              </div>

              <div className="accordion-content-documentation">
                <span className="accordion-content-title">Documentación</span>
                <ul className="documentation-list">
                  {product?.product_version?.documentation?.documents.length === 0 &&
                    <span className="accordion-content-desc">
                      No hay documentación asociada a este producto.
                    </span>
                  }
                  {product?.product_version?.documentation?.documents?.map(document => (
                    <li>
                      <a
                        onClick={() => downloadFile({
                          url: URLS.PRODUCT.DOCUMENT_DOWNLOAD({ documentId: document.id }),
                          name: document.title,
                        })}
                        className="icon-doc doc-link" download>
                        {document.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="accordion-content-resources">
                <span className="accordion-content-title">Recursos de asistencia</span>

                {product?.product_version?.documentation?.links.length === 0 &&
                  <span className="accordion-content-desc">
                    No hay recursos de asistencia asociados a este producto.
                  </span>
                }
                {product?.product_version?.documentation?.links.length > 0 &&
                  <div className="carousel-container">
                    {videosIndex !== 0 &&
                      <button
                        onClick={() => setVideosIndex(videosIndex - 1)}
                        type="button"
                        id="btnPrev"
                        name="btnPrev"
                        className="btn-carousel btn-carousel-prev" />
                    }
                    {videosIndex !== (links.length - 1) &&
                      <button
                        onClick={() => setVideosIndex(videosIndex + 1)}
                        type="button"
                        id="btnNext"
                        name="btnNext"
                        className="btn-carousel btn-carousel-next" />
                    }

                    <ul className="carousel-pager">
                      {linksPagerIndicators}
                    </ul>

                    <div className="carousel-item-product">
                      {product?.product_version?.documentation?.links &&
                        <>
                          <div className="carousel-image-product-container">
                            <img src="../assets/img/dummy/dummy-product.png" alt="" />
                            <button
                              onClick={() => {
                                const selectedLink = links[videosIndex];
                                const provider = getVideoProvider({ url: selectedLink.content_url });
                                const videoId = getVideoId({ url: selectedLink.content_url });
                                setVideo({
                                  provider,
                                  videoId,
                                });
                                setVideoModalShown(true)
                              }}
                              type="button"
                              id="btnPlay"
                              name="btnPlay"
                              className="btn-player">
                              <span className="icon-play"></span>
                            </button>
                          </div>

                          {product?.product_version?.documentation?.links.length &&
                            <div className="carousel-product-content">
                              <span className="carousel-product-title">{product?.product_version?.documentation?.links[videosIndex].title}</span>
                              <p className="carousel-product-desc">{product?.product_version?.documentation?.links[videosIndex].description}</p>
                            </div>
                          }
                        </>
                      }
                    </div>
                  </div>
                }

              </div>
            </div>
            <ModalVideo
              channel={video.provider}
              isOpen={videoModalShown}
              videoId={video.videoId}
              onClose={() => setVideoModalShown(false)} />
          </div>
        )}
      </SlideToggle>
      <ConfirmationModal
        isOpen={isRenewModalShown}
        setIsOpen={setRenewModalIsShown}
        title="Renovar producto"
        subtitle="Un partner se pondrá en contacto contigo para la adquisición de este producto, ¿Estás de acuerdo?"
        onAccept={() => renewProduct({ productId: product.id })}
      />
      <InfoModal
        isOpen={showFinishedRenew}
        setIsOpen={setShowFinishedRenew}
        title="Renovación"
        subtitle="Gracias por tu solicitud. Pronto un Partner de Numen se pondrá en contacto contigo"
      />
    </>
  );
}
