import React, { useRef } from 'react';
import ReactSelect from 'react-select';
import FormError from '../FormError';
import { URLS } from '../../lib/api';
import { get } from '../../lib/restClient';
import { useQuery } from 'react-query';
import Loader from '../../components/Loader';

const selectContainerStyles = {
  menu: () => ({
    backgroundColor: 'rgba(243, 246, 248, .5)',
  }),
  valueContainer: () => ({
    backgroundColor: 'rgba(243, 246, 248, .5)',
    height: 43,
    paddingLeft: 10,
    marginTop: 14,
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'red',
    color: 'red',
  }),
  dropdownIndicator: () => ({
    color: '#A21C22',
    marginRight: '10px',
    marginTop: '3px',
  })
};


// width: 100%;
// height: 2.8125rem;
// display: flex;
// justify-content: space-between;
// border: 1px solid #D7D7D7;
// border-radius: 5px;
// background-color: rgba(243, 246, 248, .5);
// overflow: hidden;
// position: relative;

const selectContainerStyles2 = {
  control: () => ({
    alignItems: 'center',
    backgroundColor: 'rgba(243, 246, 248, .5)',
    border: '1px solid #D7D7D7',
    borderRadius: '5px',
    display: 'flex',
    flexWrap: 'wrap',
    height: '2.8125rem',
    justifyContent: 'space-between',
    position: 'relative'
  }),
  menu: () => ({
    backgroundColor: 'rgba(243, 246, 248, 1)',
    maxHeight: 200,
    overflowY: 'scroll',
  }),
  menuList: () => ({
    backgroundColor: 'rgba(243, 246, 248, 1)',
    maxHeight: 200,
    overflowY: 'scroll',
  }),
  valueContainer: () => ({
    backgroundColor: 'rgba(243, 246, 248, .5)',
    height: 10,
    paddingLeft: 10,
    marginBottom: 20,
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'red',
    color: 'red',
  }),
  dropdownIndicator: () => ({
    color: '#A21C22',
    marginRight: '10px',
    marginTop: '3px',
  })
};

export default ({
  onChange,
  touched,
  errorMessage,
  formFactor = 'big',
}) => {
  const ref = useRef()
  const { isLoading, error, data } = useQuery('partnerSearchForFiltering', async () => {
    const response = await get(URLS.ADMIN.LIST_PARTNERS({}));
    const partners = response?.data;
    return {
      partners,
    };
  });
  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    console.log('PARTNERS SELECT', error);
    return;
  }
  return (
    <>
      <div className="form-col">
        <ReactSelect
          ref={ref}
          styles={formFactor === 'small' ? selectContainerStyles2 : selectContainerStyles}
          placeholder="Selecciona partner"
          onChange={(e) => {
            const selectedPartner = data.partners.find(partner => {
              return partner.id === e.value
            });
            onChange(selectedPartner);
          }}
          options={data.partners.map(partner => ({
            value: partner?.id,
            label: partner?.organizationName,
          }))} />
        {
          touched && errorMessage &&
          <FormError>{errorMessage}</FormError>
        }
      </div>
    </>
  );
};
