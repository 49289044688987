import React, { useState } from 'react';

import { get } from '../../../lib/restClient';
import Header from '../components/Header';
import Footer from '../components/Footer';

import Product from './components/Product';
import AddKeyModal from './components/AddKeyModal';
import { FILTERS } from './constants';
import { useQuery } from 'react-query';
import { URLS } from '../../../lib/api';
import {
  isProductActive,
  isProductInactive,
  isProductExpired,
  isProductAboutToExpire,
} from '../../../lib/models/products';
import { getUserId } from '../../../lib/auth';
import Loader from '../../../components/Loader';

const MyProducts = () => {
  const [selectedFilter, setSelectedFilter] = useState(FILTERS.ACTIVE);
  const [addKeyIsOpen, setAddKeyIsOpen] = useState(false);

  const { isLoading, error, data = [], refetch } = useQuery('clientProducts', async () => {
    const response = await get(URLS.CLIENT.MY_PRODUCTS({ userId: getUserId() }));
    return response.data;
  });

  const filteredData = {
    [FILTERS.ALL]: data,
    [FILTERS.ACTIVE]: data.filter((product) => isProductActive(product) || isProductAboutToExpire(product)),
    [FILTERS.INACTIVE]: data.filter(isProductInactive),
    [FILTERS.EXPIRED]: data.filter(isProductExpired),
    [FILTERS.ABOUT_TO_EXPIRE]: data.filter(isProductAboutToExpire),
  }
  return (
    <>
      <div role="main" className="main-clients">
        <Header />
        <div className="main-clients-content">
          <div className="row">
            <h2 className="title-type4">Mis Productos</h2>
            <p className="content-desc">¡Realiza todas tus gestiones online! Edita tus datos y consulta las notificaciones. Haz seguimiento de tus productos y las documentaciones asociadas. Contacta con el servicio asistencia y realiza pedidos directamente desde el catálogo online de Numen.</p>

            <div className="main-clients-content-header">
              <h3 className="title-type5">Productos adquiridos</h3>
              <button type="button" id="btnAddKey" name="btnAddKey" className="btn-type3 medium" onClick={() => setAddKeyIsOpen(true)}>
                Añade la Key de tu nuevo producto
                    </button>
            </div>

            <div className="clients-filters-container">
              <button
                type="button"
                id="btnActive"
                name="btnActive"
                onClick={() => setSelectedFilter(FILTERS.ACTIVE)}
                className={`btn-type2 ${selectedFilter === FILTERS.ACTIVE ? 'active' : ''}`}>
                Activos ({filteredData.ACTIVE.length})
              </button>
              <button
                type="button"
                id="btnInactive"
                name="btnInactive"
                onClick={() => setSelectedFilter(FILTERS.INACTIVE)}
                className={`btn-type2 ${selectedFilter === FILTERS.INACTIVE ? 'active' : ''}`}>
                Sin activar ({filteredData.INACTIVE.length})
              </button>
              <button
                type="button"
                id="btnNextExpire"
                name="btnNextExpire"
                onClick={() => setSelectedFilter(FILTERS.ABOUT_TO_EXPIRE)}
                className={`btn-type2 ${selectedFilter === FILTERS.ABOUT_TO_EXPIRE ? 'active' : ''}`}>
                Caducan pronto ({filteredData.ABOUT_TO_EXPIRE.length})
              </button>
              <button
                type="button"
                id="btnExpired"
                name="btnExpired"
                onClick={() => setSelectedFilter(FILTERS.EXPIRED)}
                className={`btn-type2 ${selectedFilter === FILTERS.EXPIRED ? 'active' : ''}`}>
                Caducados ({filteredData.EXPIRED.length})
              </button>
              <button
                type="button"
                id="btnAll"
                name="btnAll"
                onClick={() => setSelectedFilter(FILTERS.ALL)}
                className={`btn-type2 ${selectedFilter === FILTERS.ALL ? 'active' : ''}`}>
                Todos ({filteredData.ALL.length})
              </button>
            </div>
            {data &&
              filteredData[selectedFilter].map(product =>
                <Product key={product.id} product={product} showAddKey={() => setAddKeyIsOpen(true)} />
              )
            }
            {isLoading && <Loader />}
            {error && <Loader />}
          </div>
        </div>
        <AddKeyModal
          isOpen={addKeyIsOpen}
          setIsOpen={setAddKeyIsOpen}
          onFinish={() => refetch()} />
      </div>
      <Footer />
    </>
  );
};

export default MyProducts;