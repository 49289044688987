import React, {useState} from 'react';
import {get} from '../../../lib/restClient';
import useDataFilter from '../../../hooks/useDataFilter';
import {URLS} from '../../../lib/api';
import {nonStandardDateFormatting} from '../../../lib/dates';
import TablePager from '../../../components/TablePager';
import {useHistory} from 'react-router-dom';
import {useQuery} from 'react-query';
import SortArrow from '../../../components/SortArrow';
import DatePicker from '../../../components/DatePicker';
import RightAlignedButton from '../../../components/RightAlignedButton';
import {downloadFile} from '../../../lib/downloads';
import PartnerSearcher from '../../../components/PartnerSearcher';
import Loader from '../../../components/Loader';

const initialValues = {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
};

const TAG_COLOR = {
    'aceptado': {
        color: '#4EC1C2',
        borderColor: '#4EC1C2',
    },
    'validado': {
        color: '#4EC1C2',
        borderColor: '#4EC1C2',
    },
    'pagado': {
        color: '#CD6632',
        borderColor: '#CD6632',
    },
    'reservado': {
        color: '#E2CB26',
        borderColor: '#E2CB26',
    },
    'cancelado': {
        color: '#A21E28',
        borderColor: '#A21E28',
    },
};

const Orders = () => {
    const history = useHistory();
    const [showFilters, setShowFilters] = useState(false);
    const {
        currentPage,
        setCurrentPage,
        setFilterValue,
        setSortField,
        filtersValues,
        submitFilters,
        sort,
    } = useDataFilter({
        initialFilters: initialValues,
        initialSort: {field: '', order: ''},
        onRefetch: () => refetch(),
    });

    const {isLoading, error, data, refetch, isFetching} = useQuery('adminOrders', async () => {
        const response = await get(URLS.ADMIN.LIST_ORDERS({
            page: currentPage,
            sort,
            filters: filtersValues,
        }));
        const partnersResponse = await get(URLS.ADMIN.LIST_PARTNERS({
            sort: {field: 'organizationName', order: 'asc'},
            filters: {},
        }));
        const countriesResponse = await get(URLS.COUNTRIES.LIST());
        return {
            list: response.data,
            pageCount: response.headers['x-page-count'],
            totalCount: response.headers['x-total-count'],
            partners: partnersResponse.data,
            countries: countriesResponse.data,
        };
    }, {refetchOnWindowFocus: false});
    if (error) {
        return <span>Error</span>;
    }
    const showLoader = isLoading || isFetching;
    return (
        <main>
      <span>
        <form action="#" method="post">
          <div className="box main-header-cms">
            <div className="main-header-cms-top">
              <div className="main-header-cms-title">
                <span className="header-cms-icon icon-orders"></span>
                <h1 className="header-cms-title">
                  Pedidos <span>Relación de los pedidos de Numentech</span>
                  <span className="cms-title-detail">{data?.totalCount} pedidos</span>
                </h1>
              </div>
              <div className="main-header-cms-actions">
                <div className="header-cms-actions-buttons">
                  <button type="button" id="btnFilters" name="btnFilters" className="btn-cms-actions"
                          onClick={() => setShowFilters(true)}>
                    Filtros
                </button>
                  <button type="button" id="btnDownload" name="btnDownload" className="btn-cms-actions"
                          onClick={() => downloadFile({
                              url: URLS.ADMIN.DOWNLOAD_ORDERS_CSV(),
                              name: 'Orders.csv',
                              contentType: 'application/csv',
                          })}>
                    Descargar CSV <span className="icon-download-filters"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <div className={`cms-filters ${showFilters ? 'show' : ''}`}>
              <h2 className="cms-filters-title">Filtros</h2>
              <button type="button" id="btnCloseFilters" name="btnCloseFilters" className="btn-close-filters"
                      onClick={() => setShowFilters(false)}>
                <span className="icon-close-modal"></span>
              </button>

              <div className="form-item-row form-row-special">
                <div className="form-item item-special-size1">
                  <label className="form-label">Partners</label>
                  <PartnerSearcher
                      formFactor="small"
                      onChange={(value) => setFilterValue('partners', value.id)}
                  />
                </div>

                <div className="form-item item-special-size2">
                  <label className="form-label">Fecha de pedido</label>
                  <div className="form-text-container-two">
                    <DatePicker
                        placeholder="Desde"
                        onChange={(date) => setFilterValue('createdAt|from', date)}/>
                    <DatePicker
                        placeholder="Hasta"
                        onChange={(date) => setFilterValue('createdAt|to', date)}/>
                  </div>
                </div>
              </div>

              <div className="form-item-row form-row-special">
                <div className="form-item item-special-size1">
                  <label className="form-label">Estado del pedido</label>

                  <div className="form-text-container">
                    <select id="cmbStatus" name="cmbStatus" className="form-select"
                            onChange={(e) => setFilterValue('state', e.target.value || undefined)}>
                      <option value="">Todos</option>
                      <option value="reserved">Reservado</option>
                      <option value="accepted">Aceptado</option>
                      <option value="canceled">Cancelado</option>
                      <option value="paid">Pagado</option>
                    </select>
                    <span className="icon-arrow select-arrow"></span>
                  </div>
                </div>

                <div className="form-item item-special-size1">
                  <label className="form-label">País</label>
                  <div className="form-text-container">
                    <select id="cmbStatus" name="cmbStatus" className="form-select"
                            onChange={(e) => setFilterValue('country', e.target.value || undefined)}>
                      <option value="">Cualquiera</option>
                        {data?.countries?.map((country) => (
                            <option key={country.iso_code} value={country.iso_code}>{country.name}</option>
                        ))}
                    </select>
                    <span className="icon-arrow select-arrow"></span>
                  </div>
                </div>
              </div>
              <RightAlignedButton
                  onClick={() => {
                      submitFilters();
                      setShowFilters(false);
                      setCurrentPage(0);
                  }}>
                Filtrar
						</RightAlignedButton>
            </div>

              {showLoader &&
                  <Loader boxed/>
              }
              {!showLoader &&
                  <div className="responsive-table full-table">
                      <table className="table-type2">
                          <thead>
                          <tr>
                              <th onClick={() => setSortField({field: 'partner'})}>
                                  Nombre partner
                                  <SortArrow sort={sort} field="partner"/>
                              </th>
                              <th>
                                  Fecha
                              </th>
                              <th onClick={() => setSortField({field: 'state'})}>
                                  Estado
                                  <SortArrow sort={sort} field="state"/>
                              </th>
                              <th onClick={() => setSortField({field: 'amount'})}>
                                  Precio
                                  <SortArrow sort={sort} field="amount"/>
                              </th>
                              <th onClick={() => setSortField({field: 'country'})}>
                                  País
                                  <SortArrow sort={sort} field="country"/>
                              </th>
                              <th className="centered">Acciones</th>
                          </tr>
                          </thead>

                          <tbody>
                          {data?.list?.map(order => (
                              <tr key={order.id}>
                                  <td>{order?.partner?.organizationName}</td>
                                  <td>{nonStandardDateFormatting(order?.createdAt?.date)}</td>
                                  <td>
                          <span
                              className="status-tag reserved"
                              style={TAG_COLOR[order?.state?.caption.toLowerCase()]}
                          >
                            {order?.state?.caption}
                          </span>
                                  </td>
                                  <td>{order?.total_amount} {order?.currency?.name}</td>
                                  <td>{order?.country?.name}</td>
                                  <td className="centered">
                                      <button type="button" id="btnShowItem1" name="btnShowItem1"
                                              className="btn-icon-border">
                                          <span className="icon-show"
                                                onClick={() => history.push(`/admin/orders/${order?.id}`)}></span>
                                      </button>
                                  </td>
                              </tr>
                          ))}
                          </tbody>
                      </table>
                  </div>
              }
          </div>
            {!showLoader && <TablePager
                pages={data.pageCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                refetch={refetch}/>
            }

        </form>
      </span>
        </main>
    );
};

export default Orders;