import React, { useEffect, useState } from 'react';
import { downloadFile } from '../../../lib/downloads';
import { URLS } from '../../../lib/api';
import { useQuery } from 'react-query';
import { get } from '../../../lib/restClient';
import Loader from '../../../components/Loader';
import TablePager from '../../../components/TablePager';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { nonStandardDateFormatting } from '../../../lib/dates';
import { Input } from '../../../components/Inputs';
import RightAlignedButton from '../../../components/RightAlignedButton';
import useDataFilter from '../../../hooks/useDataFilter';
import SortArrow from '../../../components/SortArrow';

const initialValues = {
  name: null,
  type: null,
};

const Documents = () => {
  const history = useHistory();
  const {
    currentPage,
    setCurrentPage,
    filtersValues,
    setFilterValue,
    submitFilters,
    sort,
    setSortField,
    resetFilters,
  } = useDataFilter({
    initialFilters: initialValues,
    initialSort: { field: 'title', order: 'asc' },
    onRefetch: () => refetch(),
  });
  const [showFilters, setShowFilters] = useState(false);
  const { isLoading, error, data, refetch, isFetching } = useQuery('adminDocuments', async () => {
    const response = await get(URLS.ADMIN.LIST_DOCUMENTS({
      page: currentPage,
      sort,
      filters: filtersValues,
    }));
    resetFilters();
    return {
      list: response.data,
      pageCount: response.headers['x-page-count'],
      totalCount: response.headers['x-total-count'],
    };
  }, { refetchOnWindowFocus: false });
  const { isLoading: isLoadingDocumentTypes, data: documentTypes } = useQuery('documentTypes', async () => {
    const response = await get(URLS.DOCUMENTS.LIST_DOCUMENT_TYPES());
    return response.data;
  }, { refetchOnWindowFocus: false });
  useEffect(() => {
    setTimeout(() => refetch(), 600);
    return () => { };
  }, [sort, currentPage, refetch]);
  if (error) {
    return <span>Error</span>;
  }
  const showLoader = isLoading || isFetching || isLoadingDocumentTypes;
  return (
    <>
      <form action="#" method="post">
        <div className="box main-header-cms">
          <div className="main-header-cms-top">
            <div className="main-header-cms-title">
              <span className="header-cms-icon icon-documents"></span>
              <h1 className="header-cms-title">
                Documentación
                <span>Relación de la documentación de Numentech</span>
                {!showLoader && <span className="cms-title-detail">{data.totalCount} documentos</span>}
              </h1>
            </div>

            <div className="main-header-cms-actions">

              <div className="header-cms-actions-absolute">
                <button type="button" id="btnAdd" name="btnAdd" className="btn-type5"
                  onClick={() => history.push('/admin/documents/add')}>
                  Añadir documento
                </button>
              </div>

              <div className="header-cms-actions-buttons">
                <button type="button" id="btnFilters" name="btnFilters" className="btn-cms-actions"
                  onClick={() => {
                    setShowFilters(!showFilters);
                  }}>
                  Filtros
                </button>
                <button type="button" id="btnDownload" name="btnDownload" className="btn-cms-actions"
                  onClick={() => downloadFile({
                    url: URLS.ADMIN.DOWNLOAD_DOCUMENTS_CSV(),
                    name: 'Documentos.csv',
                    contentType: 'application/csv',
                  })}>
                  Descargar CSV <span className="icon-download-filters"></span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {showLoader &&
          <Loader boxed />
        }
        {!showLoader &&
          <div className="box">
            {!showLoader &&
              <div className={`cms-filters ${showFilters ? 'show' : false}`}>
                <h2 className="cms-filters-title">Filtros</h2>
                <button type="button" id="btnCloseFilters" name="btnCloseFilters" className="btn-close-filters"
                  onClick={() => setShowFilters(!showFilters)}>
                  <span className="icon-close-modal"></span>
                </button>

                <div className="form-item-row form-row-special">
                  <div className="form-item filter-size2">
                    <label className="form-label">Nombre del documento</label>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      className="form-text"
                      value={filtersValues?.name}
                      onChange={(e) => setFilterValue('title', e.target.value)} />
                  </div>

                  <div className="form-item item-special-size2">
                    <label className="form-label">Categoría</label>

                    <div className="form-text-container">
                      <select id="cmbType" name="cmbType" className="form-select"
                        onChange={(e) => setFilterValue('type', e.target.value === 'Todos' ? null : e.target.value)}>
                        <option>Todos</option>
                        {documentTypes.map(type => (
                          <option key={type.id} value={type.id}>{type.caption}</option>
                        ))}
                      </select>
                      <span className="icon-arrow select-arrow"></span>
                    </div>
                  </div>
                  <RightAlignedButton
                    onClick={() => {
                      submitFilters();
                      setShowFilters(false);
                      setCurrentPage(0);
                    }}>
                    Filtrar
						    </RightAlignedButton>
                </div>
              </div>
            }

            <div className="responsive-table full-table">
              <table className="table-type2">
                <thead>
                  <tr>
                    <th onClick={() => setSortField({ field: 'title' })}>
                      Nombre documento
                    <SortArrow sort={sort} field="title" />
                    </th>
                    <th onClick={() => setSortField({ field: 'type' })}>
                      Tipo
                    <SortArrow sort={sort} field="type" />
                    </th>
                    <th>
                      Fecha últ. edición
                  </th>
                    <th>
                      Estado
                  </th>
                    <th className="centered">Acciones</th>
                  </tr>
                </thead>

                <tbody>
                  {showLoader && <Loader />}
                  {!showLoader && data.list.map(document => (
                    <tr key={document.id}>
                      <td>{document?.title}</td>
                      <td>{document?.type?.caption}</td>
                      <td>{nonStandardDateFormatting(document?.updated_at.date)}</td>
                      <td>{document?.active ? 'Activo' : 'Inactivo'}</td>
                      <td className="centered">
                        <button
                          type="button"
                          id="btnEditItem1"
                          name="btnEditItem1"
                          className="btn-icon-border"
                          onClick={() => history.push(`/admin/documents/edit/${document.id}`)}>
                          <span className="icon-edit"></span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        }

        {!showLoader && <TablePager
          pages={data.pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          refetch={refetch} />}
      </form>
    </>
  );
};

export default Documents;