import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './statics/main.css';

import { SCRIPTS_DOMAIN } from './config';
import Home from './screens/Home';
import RequestTrial from './screens/RequestTrial';
import Dashboard from './screens/Dashboard';
import RememberPassword from './screens/RememberPassword';
import ClientRegister from './screens/ClientRegister';
import PartnerRegister from './screens/PartnerRegister';
import ClientProducts from './screens/Clients/Products';
import ClientMyProducts from './screens/Clients/MyProducts';
import ClientMyAccount from './screens/Clients/MyAccount';
import PartnerMyAccount from './screens/Partners/MyAccount';
import PartnerOrders from './screens/Partners/Orders';
import PartnerClients from './screens/Partners/Clients';
import PartnerProducts from './screens/Partners/Products';
import PartnerOrderDetail from './screens/Partners/OrderDetail';
import AdminAssigments from './screens/Admin/Assignments';
import AdminClients from './screens/Admin/Clients';
import AdminDocuments from './screens/Admin/Documents';
import AdminOrders from './screens/Admin/Orders';
import AdminPartners from './screens/Admin/Partners';
import AdminPricesAndDiscounts from './screens/Admin/PricesAndDiscounts';
import AdminPricesAndDiscountsDetail from './screens/Admin/PricesAndDiscountsDetail';
import AdminProducts from './screens/Admin/Products';
import AdminProviders from './screens/Admin/Providers';
import AdminStock from './screens/Admin/Stock';
import AdminStockDetail from './screens/Admin/StockDetail';
import AdminAddDocument from './screens/Admin/AddDocument';
import AdminProductDetail from './screens/Admin/ProductDetail';
import LoaderContext from './contexts/LoaderContext';
import Notifications from './screens/Clients/Notifications';
import Settings from './screens/Clients/Settings';

import {
  isLoggedIn,
  getUserType,
  setUserId,
} from './lib/auth';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { USER_TYPE } from './lib/constants';

import PartnerLayout from './components/PartnerLayout';
import AdminLayout from './components/AdminLayout';
import ResetPassword from './screens/ResetPassword/view';
import { URLS } from './lib/api';
import { get } from './lib/restClient';
import { useQuery } from 'react-query';
import ClientDetail from './screens/ClientDetail/view';
import Loader from './components/Loader';
import PartnerDetail from './screens/PartnerDetail/view';
import { addLinkTag, addScriptTag } from './lib/html-tags';

moment.locale('es');

const CommonContainer = ({ children }) => {
  const { isLoading, data } = useQuery('userMe', async () => {
    const response = await get(URLS.AUTH.ME());
    return response.data;
  });
  if (isLoading) {
    return <Loader />;
  }
  if (data) {
    setUserId(data.id);
  }
  return (
    <>
      {children}
    </>
  );
}

export default function App() {
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  function toggleIsRequestLoading() {
    setIsRequestLoading(isRequestLoading => !isRequestLoading);
  }
  const userType = getUserType();
  useEffect(() => {
    if (userType === USER_TYPE.PARTNER) {
      addLinkTag({
        href: `${SCRIPTS_DOMAIN}/im_livechat/external_lib.css`,
      });
      addScriptTag({
        src: `${SCRIPTS_DOMAIN}/im_livechat/external_lib.js`,
      });
      addScriptTag({
        src: `${SCRIPTS_DOMAIN}/im_livechat/loader/5`,
      });
    }
  }, [userType]);


  if (window.location.href.indexOf('request-trial') !== -1) {
    return (
      <LoaderContext.Provider value={{ isRequestLoading, toggleIsRequestLoading }}>
        <div role="main" className="main-access">
          <Router>
            <Switch>
              <Route path="/request-trial" exact>
                <RequestTrial />
              </Route>
            </Switch>
          </Router>
        </div>
      </LoaderContext.Provider>
    )
  }
  if (getUserType() === USER_TYPE.CUSTOMER) {
    return (
      <LoaderContext.Provider value={{ isRequestLoading, toggleIsRequestLoading }}>
        <CommonContainer>
          <Router>
            <Switch>
              <Route path="/request-trial" exact>
                <RequestTrial />
              </Route>
              <Route path="/clients/products">
                <ClientProducts />
              </Route>
              <Route path="/clients/my-products">
                <ClientMyProducts />
              </Route>
              <Route path="/clients/my-account">
                <ClientMyAccount />
              </Route>
              <Route path="/clients/notifications">
                <Notifications />
              </Route>
              <Route path="/clients/settings">
                <Settings />
              </Route>
              <Redirect to="/clients/products" />
            </Switch>
          </Router>
        </CommonContainer>
      </LoaderContext.Provider>
    );
  }
  if (getUserType() === USER_TYPE.PARTNER) {
    return (
      <LoaderContext.Provider value={{ isRequestLoading, toggleIsRequestLoading }}>
        <CommonContainer>
          <Router>
            <PartnerLayout>
              <Route path="/request-trial" exact>
                <RequestTrial />
              </Route>
              <Route path="/partners/my-account" exact>
                <PartnerMyAccount />
              </Route>
              <Route path="/partners/products" exact>
                <PartnerProducts />
              </Route>
              <Route path="/partners/orders" exact>
                <PartnerOrders />
              </Route>
              <Route path={`/partners/orders/:orderId`} exact>
                <PartnerOrderDetail />
              </Route>
              <Route path="/partners/clients/:clientId" exact>
                <ClientDetail />
              </Route>
              <Route path="/partners/clients" exact>
                <PartnerClients />
              </Route>
            </PartnerLayout>
          </Router>
        </CommonContainer>
      </LoaderContext.Provider>
    );
  }
  if (getUserType() === USER_TYPE.ADMIN) {
    return (
      <LoaderContext.Provider value={{ isRequestLoading, toggleIsRequestLoading }}>
        <CommonContainer>
          <Router>
            <AdminLayout>
              <Route path="/request-trial" exact>
                <RequestTrial />
              </Route>
              <Route path="/admin/providers" exact>
                <AdminProviders />
              </Route>
              <Route path="/admin/partners/:partnerId" exact>
                <PartnerDetail />
              </Route>
              <Route path="/admin/partners" exact>
                <AdminPartners />
              </Route>
              <Route path="/admin/clients/:clientId" exact>
                <ClientDetail />
              </Route>
              <Route path="/admin/clients" exact>
                <AdminClients />
              </Route>
              <Route path="/admin/documents/add" exact>
                <AdminAddDocument />
              </Route>
              <Route path="/admin/documents/edit/:documentId" exact>
                <AdminAddDocument edit />
              </Route>
              <Route path="/admin/products/add" exact>
                <AdminProductDetail isCreating={true} />
              </Route>
              <Route path="/admin/products/:productId/edit">
                <AdminProductDetail edit />
              </Route>
              <Route path="/admin/documents" exact>
                <AdminDocuments />
              </Route>
              <Route path="/admin/stocks" exact>
                <AdminStock />
              </Route>
              <Route path="/admin/stocks/:productId" exact>
                <AdminStockDetail />
              </Route>
              <Route path="/admin/prices-and-discounts/:productId/versions/:versionId" exact>
                <AdminPricesAndDiscountsDetail />
              </Route>
              <Route path="/admin/prices-and-discounts" exact>
                <AdminPricesAndDiscounts />
              </Route>
              <Route path="/admin/products" exact>
                <AdminProducts />
              </Route>
              <Route path="/admin/orders" exact>
                <AdminOrders />
              </Route>
              <Route path={`/admin/orders/:orderId`} exact>
                <PartnerOrderDetail />
              </Route>
              <Route path="/admin/assignments" exact>
                <AdminAssigments />
              </Route>
              <Route path="/" exact>
                <AdminOrders />
              </Route>
            </AdminLayout>
          </Router>
        </CommonContainer>
      </LoaderContext.Provider>
    );
  }
  return (
    <LoaderContext.Provider value={{ isRequestLoading, toggleIsRequestLoading }}>
      <div role="main" className="main-access">
        <Router>
          <Switch>
            <Route path="/request-trial" exact>
              <RequestTrial />
            </Route>
            <Route path="/partner-register" exact>
              <PartnerRegister />
            </Route>
            <Route path="/client-register" exact>
              <ClientRegister />
            </Route>
            <Route path="/remember-password" exact>
              <RememberPassword />
            </Route>
            <Route path="/reset-password/:id/:token" exact>
              <ResetPassword />
            </Route>
            <Route path="/" component={() => {
              if (isLoggedIn()) {
                return <Dashboard />
              }
              return <Home />;
            }} />
          </Switch>
        </Router>
      </div>
    </LoaderContext.Provider>
  );
}
