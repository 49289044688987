import React, {useState} from 'react';
import Modal from 'react-modal';
import {customStyles} from '../../lib/modals';
import {Input} from '../Inputs';
import {useMutation} from 'react-query';
import {uploadMedia} from '../../lib/restClient';
import {URLS} from '../../lib/api';
import {FormError} from '../FormError/styles';
import {extractErrorMessageFromResponse} from '../../lib/utils';
import Loader from '../Loader';
import {isEmpty} from "underscore";

Modal.setAppElement('#root')

export default ({isOpen, setIsOpen, mode = 'ADD',success}) => {
    const [uploadedMessage, setUploadedMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [plainError, setPlainError] = useState(null);
    const {mutate: postStock} = useMutation(
        ({file}) => uploadMedia(
            mode === 'ADD' ?
                URLS.PRODUCT.IMPORT_STOCKS() :
                URLS.PRODUCT.EDIT_STOCKS(),
            file
        ),
        {
            onSuccess: (response) => {
                setIsLoading(false);
                success();
                setUploadedMessage(response?.data);
            },
            onError: (error) => {
                setIsLoading(false);
                console.log('ERROR', error);
                setPlainError(extractErrorMessageFromResponse(error))
            }
        }
    );
    return (
        <Modal
            isOpen={isOpen}
            style={customStyles}
        >
            <div className="modal-container">
                <div className="modal-content cms-modal">
                    <form action="#" method="post">
                        {!uploadedMessage &&
                            <>
                                <h2 className="modal-title-type1">{mode === 'ADD' ? 'Añadir ' : 'Editar '} stock</h2>
                                {!isLoading &&
                                    <div><p className="cms-modal-desc">Seleccione un archivo .csv para cargar los
                                        productos</p>
                                        <div style={{'margin-bottom': 50, fontSize: 14}}><span>El documento debe tener la siguiente structura</span>
                                            <div className="responsive-table full-table">
                                                <table className="table-type2">
                                                    <thead>
                                                    <tr>
                                                        <th style={{fontSize: 10, border: "1px solid", padding: 5}}>id
                                                            version
                                                        </th>
                                                        <th style={{fontSize: 10, border: "1px solid", padding: 5}}>id
                                                            provider
                                                        </th>
                                                        <th style={{
                                                            fontSize: 10,
                                                            border: "1px solid",
                                                            padding: 5
                                                        }}>Localization
                                                        </th>
                                                        <th style={{fontSize: 10, border: "1px solid", padding: 5}}>id
                                                            register number
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td style={{ padding: 10,border: "1px solid"}}>b6c.....200c</td>
                                                        <td style={{ padding:10,border: "1px solid"}}>b6c.....200c</td>
                                                        <td style={{ padding:10,border: "1px solid"}}>nu...</td>
                                                        <td style={{ padding:10,border: "1px solid"}}>GX4k...+t2</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                }
                                {isLoading &&
                                    <p className="cms-modal-desc">Cargando...</p>
                                }

                                {!isLoading &&

                                    <div className="form-item">
                                        <label className="form-label">Buscar</label>
                                        <Input type="file" id="txtSearch" name="txtSearch" className="form-text"
                                               onChange={(e) => {
                                                   setPlainError(null);
                                                   setIsLoading(true);
                                                   if (!isEmpty((e.target.files))) {
                                                       setFileName(e.target.files[0].name);
                                                       postStock({file: e.target.files[0]});
                                                   } else {
                                                       setFileName(null);
                                                       postStock(null);
                                                   }
                                               }}/>
                                    </div>

                                }
                            </>
                        }

                        {isLoading &&
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%'
                            }}>
                                <Loader/>
                            </div>
                        }

                        {uploadedMessage &&
                            <>
                                <h2 className="modal-title-type1">Archivo añadido</h2>
                                <p className="cms-modal-desc">Se han
                                    importado {uploadedMessage?.row - uploadedMessage?.errors} registros
                                    de {uploadedMessage?.row}</p>

                                <div className="form-item-row">
                                    <div className="form-item">
                                        <label className="form-label">Archivo CSV añadido</label>
                                        <div className="form-text-container">
                                            <input type="text" id="txtAdded" name="txtAdded" disabled
                                                   className="form-text"
                                                   value={fileName}/>
                                        </div>
                                    </div>
                                </div>
                                {uploadedMessage?.messages?.length > 0 &&
                                    <>
                                        <p className="modal-error-text">Los siguientes registros no se han
                                            importado:</p>
                                        <ul className="modal-error-list">
                                            {uploadedMessage?.messages.map((message) => (
                                                <li key={message?.line}>
                                                    <strong>Línea {message?.line}</strong> {message?.message}
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                }

                            </>
                        }

                        {plainError && <FormError>{plainError}</FormError>}

                        <div className="cms-modal-actions">
                            <button type="button" id="btnCancelModal" name="btnCancelModal" className="btn-type4 small"
                                    onClick={() => setIsOpen()}>
                                {uploadedMessage ? 'Aceptar' : 'Cancelar'}
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </Modal>
    );
}
