import React, { forwardRef } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Loader from '../../../../../components/Loader';
import { URLS } from '../../../../../lib/api';
import { get } from '../../../../../lib/restClient';
import VersionFormItem from './components/VersionFormItem';

export default forwardRef((props, ref) => {
  const { productId } = useParams();
  const { error, isLoading, data } = useQuery('productsVersion', async () => {
    const response = await get(URLS.ADMIN.LIST_PRODUCT_VERSIONS_BY_ID({ productId }));
    const productDocumentsResponse = await get(URLS.ADMIN.LIST_DOCUMENTS({
      page: 0,
      filters: { type: 'product' },
      sort: { field: 'updatedAt', order: 'asc' },
    }));
    const productActivationDocumentsResponse = await get(URLS.ADMIN.LIST_DOCUMENTS({
      page: 0,
      filters: { type: 'product_activation' },
      sort: { field: 'updatedAt', order: 'asc' },
    }));
    const documents = {
      product: productDocumentsResponse.data,
      product_activation: productActivationDocumentsResponse.data,
    };
    return {
      versions: response.data,
      documents,
    };
  });
  if (error) {
    return <span>Error</span>;
  }
  if (isLoading) {
    return <Loader />;
  }
  const actualVersion = data.versions[0];
  const otherVersions = data.versions.slice(1);
  const documents = data.documents;
  return (
    <>
      <VersionFormItem
        productId={productId}
        documentTypes={documents}
        createMode />
      {actualVersion &&
        <VersionFormItem
          key={actualVersion?.id}
          isLatest
          documentTypes={documents}
          productId={productId}
          versionId={actualVersion?.id} />
      }
      {otherVersions.map(version => (
        <VersionFormItem
          key={version?.id}
          documentTypes={documents}
          productId={productId}
          versionId={version?.id} />
      ))}
    </>
  );
});
