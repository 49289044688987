import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {URLS} from '../../../lib/api';
import {get} from '../../../lib/restClient';
import TablePager from '../../../components/TablePager';
import {Input} from '../../../components/Inputs';
import StatusLabel from '../../../components/StatusLabel';
import Loader from '../../../components/Loader';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {nonStandardDateFormatting} from '../../../lib/dates';
import {downloadFile} from '../../../lib/downloads';
import RightAlignedButton from '../../../components/RightAlignedButton';
import DatePicker from '../../../components/DatePicker';
import useDataFilter from '../../../hooks/useDataFilter';
import SortArrow from '../../../components/SortArrow';

const initialValues = {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
};

const Partners = () => {
    const history = useHistory();
    const {
        currentPage,
        setCurrentPage,
        filtersValues,
        setFilterValue,
        submitFilters,
        sort,
        setSortField,
    } = useDataFilter({
        initialFilters: initialValues,
        initialSort: {field: 'createdAt', order: 'desc'},
        onRefetch: () => refetch(),
    });
    const [showFilters, setShowFilters] = useState(false);
    const {isLoading, error, data, refetch, isFetching} = useQuery('adminPartners', async () => {
        const response = await get(URLS.ADMIN.LIST_PARTNERS({
            page: currentPage,
            sort,
            filters: filtersValues,
        }));
        return {
            list: response.data,
            pageCount: response.headers['x-page-count'],
            totalCount: response.headers['x-total-count'],
        };
    }, {refetchOnWindowFocus: false});
    const {isLoading: areStatusesLoading, data: statusesData} = useQuery('partnerStatuses', async () => {
        const partnerStatusResponse = await get(URLS.PARTNER.PARTNER_STATUS());
        return partnerStatusResponse.data;
    }, {refetchOnWindowFocus: false});
    if (error) {
        return <span>Error</span>;
    }
    const showLoader = isLoading || areStatusesLoading || isFetching;
    return (
        <main>
      <span>
        <form action="#" method="post">
          <div className="box main-header-cms">
            <div className="main-header-cms-top">
              <div className="main-header-cms-title">
                <span className="header-cms-icon icon-partners"></span>
                <h1 className="header-cms-title">
                  Partners <span>Relación de los partners de Numentech</span>
                    {!showLoader && <span className="cms-title-detail">{data.totalCount} partners</span>}
                </h1>
              </div>

              <div className="main-header-cms-actions">
                <div className="header-cms-actions-buttons">
                  <button type="button" id="btnFilters" name="btnFilters" className="btn-cms-actions"
                          onClick={() => setShowFilters(!showFilters)}>
                    Filtros
                  </button>
                  <button type="button" id="btnDownload" name="btnDownload" className="btn-cms-actions"
                          onClick={() => downloadFile({
                              url: URLS.ADMIN.DOWNLOAD_PARTNERS_CSV(),
                              name: 'Partners.csv',
                              contentType: 'application/csv',
                          })}>
                    Descargar CSV <span className="icon-download-filters"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            {!showLoader &&
                <div className={`cms-filters ${showFilters ? 'show' : false}`}>
                    <h2 className="cms-filters-title">Filtros</h2>
                    <button type="button" id="btnCloseFilters" name="btnCloseFilters" className="btn-close-filters"
                            onClick={() => setShowFilters(!showFilters)}>
                        <span className="icon-close-modal"></span>
                    </button>
                    <div className="form-item-row form-row-special">
                        <div className="form-item item-special-size2">
                            <label className="form-label">Nombre</label>
                            <Input
                                type="text"
                                id="organizationName"
                                name="organizationName"
                                onChange={(e) => setFilterValue('organizationName', e.target.value)}
                                value={filtersValues.organizationName}/>
                        </div>
                        <div className="form-item item-special-size2">
                            <label className="form-label">Email</label>
                            <Input
                                type="text"
                                id="email"
                                name="email"
                                onChange={(e) => setFilterValue('email', e.target.value)}
                                value={filtersValues.email}/>
                        </div>
                        <div className="form-item item-special-size2">
                            <label className="form-label">Fecha de alta</label>

                            <div className="form-text-container-two">
                                <DatePicker
                                    placeholder="Desde"
                                    onChange={(date) => setFilterValue('createdAt|from', date)}/>
                                <DatePicker
                                    placeholder="Hasta"
                                    onChange={(date) => setFilterValue('createdAt|to', date)}/>
                            </div>
                        </div>

                        <div className="form-item item-special-size2">
                            <label className="form-label">Fecha de expiración</label>

                            <div className="form-text-container-two">
                                <DatePicker
                                    placeholder="Desde"
                                    onChange={(date) => setFilterValue('expiredAt|from', date)}/>
                                <DatePicker
                                    placeholder="Hasta"
                                    onChange={(date) => setFilterValue('expiredAt|to', date)}/>
                            </div>
                        </div>

                    </div>

                    <div className="form-item-row form-row-special">
                        <div className="form-item item-special-size2">
                            <label className="form-label">Estado</label>

                            <div className="form-text-container">
                                <select id="cmbStatus" name="cmbStatus" className="form-select"
                                        onChange={(e) => setFilterValue('status', e.target.value)}>
                                    <option value="">Todos</option>
                                    {statusesData.map((status) => (
                                        <option key={status.id} value={status.id}>{status.name}</option>
                                    ))}
                                </select>
                                <span className="icon-arrow select-arrow"></span>
                            </div>
                        </div>
                    </div>

                    <RightAlignedButton
                        onClick={() => {
                            setShowFilters(false);
                            setCurrentPage(0);
                            submitFilters();
                        }}>
                        Filtrar
                    </RightAlignedButton>
                </div>
            }

              {showLoader &&
                  <Loader boxed/>
              }
              {!showLoader &&
                  <div className="responsive-table full-table">
                      <table className="table-type2">
                          <thead>
                          <tr>
                              <th onClick={() => setSortField({field: 'organizationName'})}>
                                  Nombre
                                  <SortArrow sort={sort} field="organizationName"/>
                              </th>
                              <th onClick={() => setSortField({field: 'email'})}>
                                  Email
                                  <SortArrow sort={sort} field="email"/>
                              </th>
                              <th onClick={() => setSortField({field: 'createdAt'})}>
                                  fecha de alta
                                  <SortArrow sort={sort} field="createdAt"/>
                              </th>
                              <th>
                                  Expiración
                              </th>
                              <th>
                                  Estado
                              </th>
                              <th className="centered">Acciones</th>
                          </tr>
                          </thead>

                          <tbody>
                          {showLoader && <Loader/>}
                          {!showLoader && data.list.map(partner => (
                              <tr key={partner?.id}>
                                  <td>{partner?.organizationName}</td>
                                  <td>{partner?.email}</td>
                                  <td>{nonStandardDateFormatting(partner.createdAt.date)}</td>
                                  <td>{partner.partnerAgreement?.expiresAt ?
                                      nonStandardDateFormatting(partner.partnerAgreement?.expiresAt?.date) : ''
                                  }</td>
                                  <td><StatusLabel status={partner.status.id}/></td>
                                  <td className="centered">
                                      <button
                                          type="button"
                                          id="btnEditItem1"
                                          name="btnEditItem1"
                                          className="btn-icon-border"
                                          onClick={() => history.push(`/admin/partners/${partner.id}`)}>
                                          <span className="icon-edit"></span>
                                      </button>
                                  </td>
                              </tr>
                          ))}
                          </tbody>
                      </table>
                  </div>
              }
          </div>

            {!showLoader && <TablePager
                pages={data.pageCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                refetch={refetch}/>
            }

        </form>
      </span>
        </main>
    );
};

export default Partners;