import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { logout } from '../../../../lib/auth';
import { URLS } from '../../../../lib/api';
import { get } from '../../../../lib/restClient';
import Loader from '../../../../components/Loader';

const Header = () => {
  const { isLoading, error, data } = useQuery('notifications_header', async () => {
    const response = await get(URLS.CLIENT.NOTIFICATIONS());
    return {
      notifications: response.data,
    };
  });
  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <span>Error</span>;
  }
  const unreadNotifications = data?.notifications?.filter(notification => !notification?.open).length;
  const myProductsClassName =
    `item-tab ${window.location.href.indexOf('/my-products') === -1 ? '' : 'active'}`;
  const productsClassName =
    `item-tab ${window.location.href.indexOf('/products') === -1 ? '' : 'active'}`;
  const myAccountClassName =
    `item-tab ${window.location.href.indexOf('/my-account') === -1 ? '' : 'active'}`;
  const notificationsClassName =
    `item-tab ${window.location.href.indexOf('/notifications') === -1 ? '' : 'active'}`;
  return (
    <>
      <header className="header-clients">
        <div className="row">
          <img src="../assets/img/logo-internal.svg" alt="Numentech" className="internal-logo" />

          <button
            type="button"
            id="btnLogout"
            name="btnLogout"
            className="btn-logout"
            onClick={() => logout()}>
            Salir
                <span className="icon-logout"></span>
          </button>
        </div>
      </header>
      <div className="main-clients-header">
        <div className="row">
          <h1 className="title-type3">Área de Cliente</h1>
        </div>
      </div>
      <nav className="nav-tabs">
        <div className="row">
          <ul className="tabs-container">
            <Link to="/clients/my-products"><li className={myProductsClassName}>Mis Productos</li></Link>
            <Link to="/clients/products"><li className={productsClassName}>Catálogo Numen</li></Link>
            <Link to="/clients/notifications">
              <li className={notificationsClassName} style={{ display: 'flex' }}>
                Notificaciones
              {unreadNotifications > 0 &&
                <div style={{
                  backgroundColor: 'red',
                  width: 20,
                  height: 20,
                  color: 'white',
                  fontSize: 13,
                  borderRadius: 10,
                  fontWeight: 800,
                  marginLeft: 10,
                }}>
                  <span>{unreadNotifications}</span>
                </div>}
              </li>
            </Link>
            <Link to="/clients/my-account"><li className={myAccountClassName}>Mi Cuenta</li></Link>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
