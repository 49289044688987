import React from 'react';
import Modal from 'react-modal';
import * as Yup from 'yup';
import { customStyles } from '../../lib/modals';
import { addProduct } from '../../lib/order-basket';
import { useFormik } from 'formik';
import { formatPrice } from '../../lib/prices';

import SVG from '../Svg';

Modal.setAppElement('#root');

const ValidationSchema = Yup.object().shape({
  quantity: Yup.number()
    .positive(),
});

export default ({ isOpen, setIsOpen, product, productVersion, currencyIsoCode, setShowAddedModal }) => {
  let nextVolumeDiscount;
  let selectedVolumeDiscount;
  let discountAmount;
  let discountExtra;
  let type = 'VOLUME';
  let totalDiscountInPercentage = 0;
  const formik = useFormik({
    initialValues: {
      quantity: 1,
      product: productVersion,
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      addProduct({
        ...values,
        originalUnitPrice: productVersion?.price?.unitPrice,
        discountedUnitPrice: unitPrice,
        discountType: type,
        discountAmount,
        discountExtra,
        currencyIsoCode,
      });
      setShowAddedModal(true);
      setIsOpen(false);
    },
  });
  
  if (productVersion?.price?.volumeDiscounts?.length > 0) {
    for (let i = 0; i < productVersion?.price?.volumeDiscounts.length; i++) {
      const volDiscount = productVersion?.price?.volumeDiscounts[i];
      const volCategory = volDiscount?.volumeCategory;
      if (
        volCategory.quantityFrom > formik?.values?.quantity &&
        (nextVolumeDiscount?.volumeCategory?.quantityFrom > volCategory.quantityFrom || !nextVolumeDiscount)
      ) {
        type = 'VOLUME';
        nextVolumeDiscount = volDiscount
      }
      if (
        volCategory.quantityFrom < formik?.values?.quantity &&
        formik?.values?.quantity <= volCategory.quantityTo) {
        selectedVolumeDiscount = volDiscount;
        type = 'VOLUME';
        console.log({ discountByVolumePercentage: volDiscount?.discount })
      }
      if (formik?.values?.quantity > volCategory.quantityFrom) {
        selectedVolumeDiscount = volDiscount;
        type = 'VOLUME';
        totalDiscountInPercentage += volDiscount?.discount;
        console.log({ discountByVolumePercentage: volDiscount?.discount });
      }
    }
  }
  let unitPrice = selectedVolumeDiscount ?
    productVersion?.price?.unitPrice - (productVersion?.price?.unitPrice * selectedVolumeDiscount?.discount) / 100 :
    productVersion?.price?.unitPrice;
  if (productVersion?.price?.planDiscount) {
    unitPrice =
      productVersion?.price?.unitPrice
      - (productVersion?.price?.unitPrice * productVersion?.price?.planDiscount?.discount)
      / 100;
    discountAmount = productVersion?.price?.planDiscount?.discount;
    discountExtra = productVersion?.price?.planDiscount?.partnerPlan?.name;
    console.log({ discountByPlanPercentage: discountAmount })
    totalDiscountInPercentage += discountAmount;
    type = 'PLAN';
  }
  if (productVersion?.price?.typeDiscount) {
    unitPrice =
      productVersion?.price?.unitPrice
      - (productVersion?.price?.unitPrice * productVersion?.price?.typeDiscount?.discount)
      / 100;
    discountAmount = productVersion?.price?.typeDiscount?.discount;
    console.log({ discountByTypePercentage: discountAmount });
    totalDiscountInPercentage += discountAmount;
    type = 'TYPE';
  }

  console.log({ totalDiscountInPercentage })

  unitPrice = totalDiscountInPercentage !== 0 ?
    productVersion?.price?.unitPrice - (productVersion?.price?.unitPrice * totalDiscountInPercentage) / 100 :
    productVersion?.price?.unitPrice;
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}>
      <div className="modal-container">
        <div className="modal-content cms-modal">
          <form action="#" method="post">
            <h2 className="modal-title-type1">Hacer pedido</h2>
            <p className="cms-modal-desc">Solicita la cantidad de productos que necesites</p>

            <div className="cms-modal-item">
              <SVG
                svg={product?.solution?.svgIcon}
                alt={product?.solution?.name} />
              <h3 className="cms-modal-item-title">
                {productVersion?.productName}
                <span>{productVersion?.solutionName}</span>
              </h3>
            </div>

            <div className="cms-modal-version-info">
              <span className="cms-modal-version-txt">Versión {productVersion?.version}</span>

              <div className="form-item-plus-minus">
                <button type="button" id="btnMinus" name="btnMinus" className="btn-plus-minus"
                  onClick={() => {
                    if (formik?.values?.quantity !== 1) {
                      formik.setFieldValue(
                        'quantity',
                        formik?.values?.quantity - 1,
                      );
                    }
                  }}>
                  <span className="icon-minus"></span>
                </button>

                <input
                  type="text"
                  id="txtPlusMinus3"
                  name="txtPlusMinus3"
                  className="form-text"
                  value={parseInt(formik?.values?.quantity)}
                  onChange={(e) => {
                    addProduct({
                      product: productVersion,
                      quantity: parseInt(e.target.value),
                    });
                    formik.setFieldValue('quantity', parseInt(e.target.value) || 0)
                  }}
                />

                <button type="button" id="btnPlus" name="btnPlus" className="btn-plus-minus"
                  onClick={() => {
                    formik.setFieldValue(
                      'quantity',
                      formik?.values?.quantity + 1,
                    )
                  }}>
                  <span className="icon-plus"></span>
                </button>
              </div>

              <span className="cms-modal-version-price">
                {formatPrice({
                  number: unitPrice,
                  currencyIsoCode,
                })}
              </span>
            </div>

            {productVersion?.price?.planDiscount &&
              <p className="cms-modal-legend">
                Por ser partner {productVersion?.price?.planDiscount?.partnerPlan?.name} el precio baja un
              {` ${productVersion?.price?.planDiscount?.discount}%.`}
              </p>
            }

            {productVersion?.price?.typeDiscount &&
              <p className="cms-modal-legend">
                Por ser partner {productVersion?.price?.typeDiscount?.partnerType?.name} el precio baja un
                {` ${productVersion?.price?.typeDiscount?.discount}%.`}
              </p>
            }
            {productVersion?.price?.volumeDiscounts?.length > 0 &&
              <h5 style={{ marginTop: 20, marginBottom: 10}}>DESCUENTOS POR CANTIDAD</h5>
            }
            {productVersion?.price?.volumeDiscounts
              .sort((a, b) =>
                a?.volumeCategory?.quantityFrom - b?.volumeCategory?.quantityFrom
              )
              .map((volumeDiscount) => (
                <p className="cms-modal-legend">
                  Por cantidades superiores a {volumeDiscount?.volumeCategory?.quantityFrom} unidades el precio baja un {volumeDiscount?.discount}% adicional.
                </p>
              ))}



            <div className="cms-modal-total-price">
              <span className="cms-modal-total-label">Precio total</span>
              <span className="cms-modal-total-info">
                {formatPrice({
                  number: formik?.values?.quantity * unitPrice,
                  currencyIsoCode,
                })}
              </span>
            </div>

            <div className="cms-modal-actions">
              <button type="button" id="btnCancel" name="btnCancel" className="btn-type4 small"
                onClick={() => setIsOpen(false)}>
                Cancelar
              </button>

              <button type="button" id="btnAdd" name="btnAdd" className="btn-type5 big"
                onClick={() => {
                  formik.handleSubmit();
                }}>
                Añadir pedido
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
