import React, {useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Header from '../components/Header';
import Footer from '../components/Footer';
import InfoModal from '../../../components/InfoModal';
import {useMutation, useQuery} from 'react-query';
import {get, post, put} from '../../../lib/restClient';
import {URLS} from '../../../lib/api';
import ChangePasswordModal from '../../../components/ChangePasswordModal';
import FormError from '../../../components/FormError';
import Loader from '../../../components/Loader';
import {getRequireChangePassword, REQUIRE_CHANGE_PASSWORD} from '../../../lib/auth';
import useLocalStorage from '../../../hooks/localStorage';

const UpdateSchema = Yup.object().shape({
    email: Yup.string()
        .email('Introduce correo electrónico válido')
        .required('Requerido'),
    firstName: Yup.string()
        .required('Requerido'),
    lastName: Yup.string()
        .required('Requerido'),
    country: Yup.string()
        .required('Requerido'),
    phone: Yup.string()
        .required('Requerido'),
});

const MyAccount = () => {
    const [showUpdatedModal, setShowUpdatedModal] = useState(false);
    const [showRequiredChange, setShowRequiredChange] = useLocalStorage(REQUIRE_CHANGE_PASSWORD, getRequireChangePassword());
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    console.log({showChangePasswordModal});
    const [passwordError, setPasswordError] = useState(false);
    const {isLoading, error, data, refetch} = useQuery('customerInfo', async () => {
        const response = await get(URLS.AUTH.ME());
        return response.data;
    });
    const {isLoading: areCountriesLoading, data: countriesData} = useQuery('countries', async () => {
        const response = await get(URLS.COUNTRIES.LIST());
        return response.data;
    });
    const {mutate: update} = useMutation(
        async (values) => {
            values.type = values?.type?.id || values?.type;
            const response = await put(URLS.CLIENT.UPDATE_INFO({userId: data.id}), values);
            if (response.data.requireChangePassword) {
                setShowChangePasswordModal(true);
            }
            return response;
        },
        {
            onSuccess: async () => refetch(),
            onError: (error) => {
                console.log('ERROR', error);
            }
        }
    );
    const {mutate: changePassword} = useMutation(
        ({oldPassword, password}) => {
            return post(URLS.AUTH.CHANGE_PASSWORD(), {old_password: oldPassword, password});
        },
        {
            onSuccess: async () => {
                refetch();
                setShowUpdatedModal(true);
                setShowEditPassword(false);
                setPassword('');
                setPassword2('');
            },
            onError: (error) => {
                setPasswordError(error.response.data.errors.password[0]);
                setShowUpdatedModal(false);
                console.log('ERROR', error);
            }
        }
    );

    const [showEditFirstName, setShowEditFirstName] = useState(false);
    const [showEditLastName, setShowEditLastName] = useState(false);
    const [showEditCountry, setShowEditCountry] = useState(false);
    const [showEditPhone, setShowEditPhone] = useState(false);
    const [showEditPassword, setShowEditPassword] = useState(false);

    const [showEditFirstNameError, setShowEditFirstNameError] = useState(false);
    const [showEditLastNameError, setShowEditLastNameError] = useState(false);
    const [showEditCountryError, setShowEditCountryError] = useState(false);
    const [showEditPhoneError, setShowEditPhoneError] = useState(false);
    const [showEditPasswordError, setShowEditPasswordError] = useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const cancelButtonHandler = ({setShowError, setShowModal}) => () => {
        setShowError(false)
        setShowModal(false)
        setPasswordError(null)
    };

    const acceptButtonHandler = ({
                                     values,
                                     selectedValue,
                                     setShowError,
                                     setShowModal,
                                     onValidate,
                                     onUpdate,
                                 }) => () => {
        if (!selectedValue) {
            setShowError(true);
        } else {
            values.country = countriesData.filter(c => c.name === values.country)[0];
            if (onValidate && !onValidate()) {
                return;
            }
            if (onUpdate) {
                onUpdate();
            } else {
                update(values);
                setShowModal(false);
            }
            setShowError(false);
        }
    }

    const EditActionButtons = ({
                                   values,
                                   selectedValue,
                                   setShowError,
                                   setShowModal,
                                   onValidate,
                                   onUpdate
                               }) => (
        <div className="edit-item-actions">
            <button
                type="button"

                className="btn-type4"
                onClick={cancelButtonHandler({
                    setSelectedValue: selectedValue,
                    setShowError,
                    setShowModal,
                })}>
                Cancelar
            </button>
            <button
                type="button"

                className="btn-type3 small"
                onClick={acceptButtonHandler({
                    values,
                    selectedValue,
                    setShowError,
                    setShowModal,
                    onValidate,
                    onUpdate,
                })}>
                Aceptar
            </button>
        </div>
    );

    if (isLoading || areCountriesLoading) {
        return <Loader/>;
    }
    if (error) {
        return <span>Error</span>;
    }

    return (
        <>
            <div role="main" className="main-clients">
                <Header/>
                <div className="main-clients-content">
                    <div className="row">
                        <div className="account-container-bl">
                            <div className="account-left-bl">
                                <h2 className="title-type4">Configuración de la cuenta</h2>
                                <p className="content-desc">Consulta y gestiona los datos vinculados a tu perfil. Si
                                    tienes cualquier consulta o sugerencia, contacta con support@numentech.es</p>
                            </div>

                            <div className="account-right-bl">
                                <Formik
                                    initialValues={{
                                        ...data,
                                        country: data.country.name,
                                    }}
                                    validationSchema={UpdateSchema}
                                    onSubmit={(values) => {
                                        values.country = countriesData.filter(c => c.name === values.country)[0];
                                        values.type = values?.type?.id || values?.type;
                                        update(values);
                                    }}
                                    render={({
                                                 values,
                                                 errors,
                                                 handleSubmit,
                                                 setFieldValue
                                             }) => (
                                        <form action="#" method="post">
                                            <fieldset className="account-fieldset">
                                                <div className="form-item-row">
                                                    <div className="form-item-col">
                                                        <div className="form-item form-item-edit">
                                                            <button type="button" id="btnEditItem1" name="btnEditItem1"
                                                                    className="btn-edit"
                                                                    onClick={() => setShowEditFirstName(true)}>
                                                                <span className="icon-edit"></span>
                                                            </button>
                                                            <div
                                                                className={`edit-bl${showEditFirstName ? ' show' : ''}`}>
                                                                <span className="icon-edit"></span>
                                                                <div className="edit-item">
                                                                    <label className="item-data-label">Nombre</label>
                                                                    <span className="item-data-desc-small">Introduzca nuevo nombre:</span>

                                                                    <div className="form-text-container form-error">
                                                                        <input type="text" id="txtFirst" name="txtFirst"
                                                                               className="form-text"
                                                                               value={values.firstName}
                                                                               onChange={(e) => setFieldValue('firstName', e.target.value)}/>
                                                                    </div>
                                                                    {showEditFirstNameError && <span
                                                                        className="error-text icon-error">Requerido</span>}

                                                                    <EditActionButtons
                                                                        values={values}
                                                                        selectedValue={values.firstName}
                                                                        setShowError={setShowEditFirstNameError}
                                                                        setShowModal={setShowEditFirstName}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="form-item-data">
                                                                <span className="item-data-label">Nombre</span>
                                                                <div className="item-data-desc">
                                                                    {data.firstName}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-item form-item-edit">
                                                            <button type="button" id="btnEditItem2" name="btnEditItem2"
                                                                    className="btn-edit"
                                                                    onClick={() => setShowEditLastName(true)}>
                                                                <span className="icon-edit"></span>
                                                            </button>

                                                            <div
                                                                className={`edit-bl${showEditLastName ? ' show' : ''}`}>
                                                                <span className="icon-edit"></span>
                                                                <div className="edit-item">
                                                                    <label className="item-data-label">Apellidos</label>
                                                                    <span className="item-data-desc-small">Introduzca nuevo apellido:</span>

                                                                    <div className="form-text-container">
                                                                        <input type="text" id="txtLast" name="txtLast"
                                                                               className="form-text"
                                                                               value={values.lastName}
                                                                               onChange={(e) => setFieldValue('lastName', e.target.value)}/>
                                                                    </div>
                                                                    {showEditLastNameError && <span
                                                                        className="error-text icon-error">Requerido</span>}

                                                                    <EditActionButtons
                                                                        values={values}
                                                                        selectedValue={values.lastName}
                                                                        setShowError={setShowEditLastNameError}
                                                                        setShowModal={setShowEditLastName}
                                                                    />

                                                                </div>
                                                            </div>

                                                            <div className="form-item-data">
                                                                <span className="item-data-label">Apellidos</span>
                                                                <div className="item-data-desc">
                                                                    {data.lastName}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="form-item form-item-edit">
                                                            <button type="button" id="btnEditItem3" name="btnEditItem3"
                                                                    className="btn-edit"
                                                                    onClick={() => setShowEditCountry(true)}>
                                                                <span className="icon-edit"></span>
                                                            </button>

                                                            <div className={`edit-bl${showEditCountry ? ' show' : ''}`}>
                                                                <span className="icon-edit"></span>
                                                                <div className="edit-item">
                                                                    <label className="item-data-label">Pais</label>
                                                                    <span className="item-data-desc-small">Seleccione nuevo país:</span>

                                                                    <div className="form-text-container form-error">
                                                                        <select id="cmbCountry" name="cmbCountry"
                                                                                className="form-select"
                                                                                onChange={(e) => setFieldValue('country', e.target.value)}>
                                                                            {countriesData.map(country => (
                                                                                <option
                                                                                    selected={data.country.name === country.name}>{country.name}</option>
                                                                            ))}
                                                                        </select>
                                                                        <span
                                                                            className="icon-arrow select-arrow"></span>
                                                                    </div>

                                                                    {showEditCountryError && <span
                                                                        className="error-text icon-error">Requerido</span>}

                                                                    <EditActionButtons
                                                                        values={values}
                                                                        selectedValue={values.country}
                                                                        setShowError={setShowEditCountryError}
                                                                        setShowModal={setShowEditCountry}
                                                                    />

                                                                </div>
                                                            </div>

                                                            <div className="form-item-data">
                                                                <span className="item-data-label">País</span>
                                                                <div className="item-data-desc">
                                                                    {data.country.name}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="form-item form-item-edit">
                                                            <button type="button" id="btnEditItem4" name="btnEditItem4"
                                                                    className="btn-edit"
                                                                    onClick={() => setShowEditPhone(true)}>
                                                                <span className="icon-edit"></span>
                                                            </button>

                                                            <div className={`edit-bl${showEditPhone ? ' show' : ''}`}>
                                                                <span className="icon-edit"></span>
                                                                <div className="edit-item">
                                                                    <label className="item-data-label">Teléfono</label>
                                                                    <span className="item-data-desc-small">Introduzca nuevo teléfono:</span>

                                                                    <div className="form-text-container">
                                                                        <input type="text" id="txtPhone" name="txtPhone"
                                                                               className="form-text"
                                                                               value={values.phone}
                                                                               onChange={(e) => setFieldValue('phone', e.target.value)}/>
                                                                    </div>
                                                                    {showEditPhoneError && <span
                                                                        className="error-text icon-error">Requerido</span>}

                                                                    <EditActionButtons
                                                                        values={values}
                                                                        selectedValue={values.phone}
                                                                        setShowError={setShowEditPhoneError}
                                                                        setShowModal={setShowEditPhone}
                                                                    />

                                                                </div>
                                                            </div>

                                                            <div className="form-item-data">
                                                                <span className="item-data-label">Teléfono</span>
                                                                <div className="item-data-desc">
                                                                    {data.phone}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="form-item form-item-edit">
                                                            <button type="button" id="btnEditItem5" name="btnEditItem5"
                                                                    className="btn-edit"
                                                                    onClick={() => setShowEditPassword(true)}>
                                                                <span className="icon-edit"></span>
                                                            </button>

                                                            <div
                                                                className={`edit-bl${showEditPassword ? ' show' : ''}`}>
                                                                <span className="icon-edit"></span>
                                                                <div className="edit-item">
                                                                    <label className="item-data-label">Password</label>

                                                                    <div className="edit-item-container">
                                                                        <div className="edit-item-bl">
                                                                            <span className="item-data-desc-small">Contraseña anterior:</span>

                                                                            <div
                                                                                className="form-text-container form-error">
                                                                                <input type="password" id="txtOldPass"
                                                                                       name="txtOldPass"
                                                                                       className="form-text"
                                                                                       value={oldPassword}
                                                                                       onChange={(event) => setOldPassword(event.target.value)}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="edit-item-container">
                                                                        <div className="edit-item-bl">
                                                                            <span className="item-data-desc-small">Nueva contraseña (max. 8 caract.):</span>

                                                                            <div
                                                                                className="form-text-container form-error">
                                                                                <input type="password" id="txtPass"
                                                                                       name="txtPass"
                                                                                       className="form-text"
                                                                                       value={password}
                                                                                       onChange={(event) => setPassword(event.target.value)}/>
                                                                            </div>
                                                                            {showEditPasswordError && <span
                                                                                className="error-text icon-error">Requerido</span>}
                                                                        </div>

                                                                        <div className="edit-item-bl">
                                                                            <span
                                                                                className="item-data-desc-small">Repítala:</span>

                                                                            <div
                                                                                className="form-text-container form-error">
                                                                                <input type="password" id="txtPass2"
                                                                                       name="txtPass2"
                                                                                       className="form-text"
                                                                                       value={password2}
                                                                                       onChange={(event) => setPassword2(event.target.value)}/>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <FormError>{passwordError}</FormError>
                                                                    <EditActionButtons
                                                                        values={values}
                                                                        selectedValue={password}
                                                                        setShowError={setShowEditPasswordError}
                                                                        setShowModal={setShowEditPassword}
                                                                        onValidate={() => {
                                                                            const condition = password === password2;
                                                                            if (!condition) {
                                                                                setPasswordError('Las contraseñas deben coincidir');
                                                                            }
                                                                            return condition;
                                                                        }}
                                                                        onUpdate={() => changePassword({
                                                                            oldPassword,
                                                                            password
                                                                        })}
                                                                    />

                                                                </div>
                                                            </div>

                                                            <div className="form-item-data">
                                                                <span className="item-data-label">Password</span>
                                                                <div className="item-data-desc">
                                                                    **********
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-item-col">
                                                        <div className="form-item">
                                                            <div className="form-item-data">
                                                                <span className="item-data-label">Email</span>
                                                                <div className="item-data-desc">
                                                                    {data.email}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-item">
                                                            <div className="form-item-data">
                                                                <span className="item-data-label">Tipo cliente</span>
                                                                <div className="item-data-desc">
                                                                    {data.type.caption}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="form-item">
                                                            <div className="form-item-data">
                                                                <span
                                                                    className="item-data-label">Partners asociados</span>
                                                                <div className="item-data-desc">
                                                                    {data.partner && data.partner.organizationName}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    )}/>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer/>
            <InfoModal
                isOpen={showUpdatedModal}
                setIsOpen={setShowUpdatedModal}
                title="Datos guardados"
                subtitle="Contraseña cambiada correctamente"
            />
            <ChangePasswordModal
                isOpen={showRequiredChange}
                setIsOpen={setShowRequiredChange}
            />
        </>
    );
};

export default MyAccount;