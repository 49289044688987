import React, {useContext, useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import ModalVideo from 'react-modal-video';

import Header from '../components/Header';
import Footer from '../components/Footer';
import {get, post} from '../../../lib/restClient';
import {URLS} from '../../../lib/api';
import {groupBy} from '../../../lib/utils';
import Svg from '../../../components/Svg';
import TextModal from '../../../components/TextModal';
import ConfirmationModal from '../../../components/ConfirmationModal';
import {getVideoId, getVideoProvider} from '../../../lib/videos';
import LoaderContext from '../../../contexts/LoaderContext';
import Loader from '../../../components/Loader';

const ClientProducts = () => {
    const {toggleIsRequestLoading} = useContext(LoaderContext);
    const [selectedProducts, setSelectedProducts] = useState({});
    const [showSureToAskForTrial, setShowSureToAskForTrial] = useState(false);
    const [askForTrialMessage, setAskForTrialMessage] = useState(null);
    const [showAskedForTrial, setShowAskedForTrial] = useState(false);
    const [video, setVideo] = useState({provider: 'youtube', videoId: '2MpUj-Aua48'});
    const [videoModalShown, setVideoModalShown] = useState(false);

    const {isLoading, data} = useQuery('productsSolution', async () => {
        const response = await get(URLS.PRODUCT.SOLUTIONS());
        return response.data;
    });
    const {mutate: askForTrial} = useMutation(
        ({productId}) => post(URLS.PRODUCT.ASK_FOR_TRIAL({productId})),
        {
            onSuccess: () => {
                toggleIsRequestLoading();
                setShowSureToAskForTrial(false);
                setShowAskedForTrial(true);
            },
            onError: (error) => {
                toggleIsRequestLoading();
                setAskForTrialMessage(error.response.data.message);
            }
        }
    );
    if (isLoading) {
        return <Loader/>;
    }

    const bySegment = groupBy(data, 'segmentCaption');
    const products = {};
    const selectedProductsInitial = {};
    Object.keys(bySegment).forEach(segment => {
        products[segment] = groupBy(data.filter(d => d.segmentCaption === segment), 'typeCaption');
        selectedProductsInitial[segment] = Object.keys(products[segment])[0];
    });
    if (!Object.keys(selectedProducts).length) {
        setSelectedProducts(selectedProductsInitial);
    }
    return (
        <>
            <div role="main" className="main-clients gray-content">
                <Header/>
                <div className="main-clients-content">
                    <div className="row">
                        <h2 className="title-type4">Productos Numen</h2>
                        <p className="content-desc">Descubre, prueba o adquiere los productos de Numen directamente
                            desde tu área cliente.</p>
                        <p className="content-desc">Contacta con un experto de Numen para ayudarte a elegir las
                            soluciones que más se ajusten a tus necesidades a través de sales@numentech.es.</p>
                        {Object.keys(products).map(segment => (
                            <div key={segment}>
                                <div className="main-clients-content-header">
                                    <h3 className="title-type5">{segment} products</h3>
                                </div>

                                <nav className="product-tabs">
                                    <ul className="tabs-container">
                                        {Object.keys(products[segment]).map(type => (
                                            <li
                                                key={type}
                                                onClick={(e) => {
                                                    const newSection = e.target.innerHTML;
                                                    setSelectedProducts({
                                                        ...selectedProducts,
                                                        [segment]: newSection,
                                                    });
                                                }}
                                                className={`item-tab ${selectedProducts[segment] === type ? 'active' : ''}`}>
                                                {type}
                                            </li>
                                        ))}
                                    </ul>
                                </nav>

                                <div className="products-container">
                                    {products[segment][selectedProducts[segment]]?.map(itemProduct => (
                                        <div key={itemProduct.id} className="item-product">
                                            <div className="item-product-img-container">
                                                <Svg svg={itemProduct.svgIcon} alt=""/>
                                            </div>
                                            <h4 className="item-product-title">{itemProduct.name}</h4>
                                            <p className="item-product-desc">{itemProduct.description}</p>
                                            {itemProduct?.demo &&
                                                <button
                                                    type="button"
                                                    id="btnTryItem1"
                                                    name="btnTryItem1"
                                                    className="btn-type3 small"
                                                    onClick={() => {
                                                        const selectedLink = itemProduct?.demo;
                                                        const provider = getVideoProvider({url: selectedLink});
                                                        const videoId = getVideoId({url: selectedLink});
                                                        setVideo({
                                                            provider,
                                                            videoId,
                                                        });
                                                        setVideoModalShown(true);
                                                    }}>
                                                    Demo
                                                </button>
                                            }
                                            {itemProduct?.trialAvailable && itemProduct?.customer?.can_request_trial &&
                                                <button
                                                    type="button"
                                                    id="btnTryItem1"
                                                    name="btnTryItem1"
                                                    className="btn-type3 small"
                                                    onClick={() => {
                                                        setShowSureToAskForTrial({
                                                            show: true,
                                                            id: itemProduct.id,
                                                            name: itemProduct.name
                                                        })
                                                    }}>
                                                    Pruébalo
                                                </button>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <ModalVideo
                channel={video.provider}
                isOpen={videoModalShown}
                videoId={video.videoId}
                onClose={() => setVideoModalShown(false)}/>
            <TextModal
                isOpen={showAskedForTrial}
                setIsOpen={setShowAskedForTrial}
                text="Gracias por tu solicitud. Pronto un Partner de Numen se pondrá en contacto contigo"
            />
            <ConfirmationModal
                isOpen={showSureToAskForTrial.show}
                setIsOpen={setShowSureToAskForTrial}
                title={"Pedir Trial o una Demo del producto: " + showSureToAskForTrial.name}
                errorMessage={askForTrialMessage}
                subtitle="Estamos a punto de enviar tu solicitud a uno de nuestros Partners. ¿Estás seguro?"
                onAccept={() => {
                    toggleIsRequestLoading();
                    askForTrial({productId: showSureToAskForTrial.id})
                }}
            />
            <Footer/>
        </>
    );
};

export default ClientProducts;