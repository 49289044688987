import React, { useState } from 'react';
import { Formik } from 'formik';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import FormError from '../../../components/FormError';
import * as Yup from 'yup';
import { URLS } from '../../../lib/api';
import { nonStandardDateFormatting } from '../../../lib/dates';
import { get, put, post } from '../../../lib/restClient';
import { downloadFile } from '../../../lib/downloads';
import {
  REQUIRE_CHANGE_PASSWORD,
  REQUIRE_PARTNER_SIGN,
  getRequireChangePassword,
  getRequirePartnerSigned,
  getUserId
} from '../../../lib/auth';
import { Input } from '../../../components/Inputs';
import InfoModal from '../../../components/InfoModal';
import EmptyMessage from '../../../components/EmptyMessage';
import ChangePasswordModal from '../../../components/ChangePasswordModal';
import PartnerDocumentsModal from '../../../components/PartnerDocumentsModal';
import useLocalStorage from '../../../hooks/localStorage';
import Loader from '../../../components/Loader';

const UpdateSchema = Yup.object().shape({
  email: Yup.string()
    .email('Introduce correo electrónico válido')
    .required('Requerido'),
  firstName: Yup.string()
    .required('Requerido'),
  lastName: Yup.string()
    .required('Requerido'),
  organizationName: Yup.string()
    .required('Requerido'),
  website: Yup.string()
    .required('Requerido'),
  phone: Yup.string()
    .required('Requerido'),
  address: Yup.object({
    address: Yup.string()
      .required('Requerido'),
    address2: Yup.string()
      .optional()
      .nullable(),
    city: Yup.string()
      .required('Requerido'),
    region: Yup.string()
      .required('Requerido'),
    zip: Yup.string()
      .required('Requerido'),
  }),
});

const AdministrativeUpdateSchema = Yup.object().shape({
  administrativeContact: Yup.object({
    firstName: Yup.string()
      .required('Requerido'),
    lastName: Yup.string()
      .required('Requerido'),
    email: Yup.string()
      .required('Requerido'),
    phone: Yup.string()
      .required('Requerido'),
  }).optional(),
});

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Requerido'),
  newPassword: Yup.string()
    .required('Requerido'),
  repeatedNewPassword: Yup.string()
    .required('Requerido'),
});

const MyAccount = () => {
  const queryClient = useQueryClient();
  let resetCurrentForm = null;
  const [showUpdatedModal, setShowUpdatedModal] = useState(false);
  const [showParnterAgreementModal, setShowParnterAgreementModal] = useState(false);
  const [partnerAgreementDescription, setPartnerAgreementDescription] = useState('');
  const [errorMessageForPassword, setErrorMessageForPassword] = useState('');
  const [showRequiredChange, setShowRequiredChange] = useLocalStorage(REQUIRE_CHANGE_PASSWORD, getRequireChangePassword());
  const [showRequiredPartnerSign, setShowRequiredPartnerSign] = useLocalStorage(REQUIRE_PARTNER_SIGN, getRequirePartnerSigned());

  console.log({ showRequiredChange });
  console.log({ getRequireChangePassword: getRequireChangePassword() });
  const { isLoading: areCountriesLoading, data: countriesData } = useQuery('countries', async () => {
    const response = await get(URLS.COUNTRIES.LIST());
    return response.data;
  });
  const { isLoading: areDocumentsLoading, data: documentsData } = useQuery('userDocuments', async () => {
    const response = await get(URLS.DOCUMENTS.LIST_DOCUMENTS_BY_USER({ userId: getUserId() }));
    const needsToSign = response?.data?.filter(d => !d.signedAt).length !== 0;
    setShowRequiredPartnerSign(needsToSign);
    return response.data;
  });
  const { isLoading, error, data, refetch } = useQuery('partnerInfo', async () => {
    const response = await get(URLS.AUTH.ME());
    return response.data;
  });
  const { mutate: update } = useMutation(
    (values) => {
      return put(URLS.PARTNER.UPDATE_INFO({ userId: data.id }), values);
    },
    {
      onSuccess: () => {
        refetch();
        setShowUpdatedModal(true);
        resetCurrentForm();
      },
      onError: (error) => {
        console.log('-----> ERROR')
      }
    }
  );
  const { mutate: changePassword } = useMutation(
    (values) => {
      return post(URLS.AUTH.CHANGE_PASSWORD(), values);
    },
    {
      onSuccess: () => {
        setShowUpdatedModal(true);
        resetCurrentForm();
      },
      onError: (error) => {
        console.log('ERROR', error);
        setErrorMessageForPassword(error.response.data.errors.password[0]);
      }
    }
  );

  if (isLoading || areCountriesLoading || areDocumentsLoading) {
    return <Loader />;
  }
  if (error) {
    return <span>Error</span>;
  }
  const initialCountry = countriesData.filter(c => c.name === data.address?.country?.name)[0];
  const partnersAgreement = documentsData.filter(d => d.document.type.id === 'partner_agreement');
  const productSolutionsActivation = documentsData.filter(d => d.document.type.id === 'product_solution_activation');
  return (
    <>
      <div role="main" className="main-cms">
        <form action="#" method="post">
          <div className="box main-header-cms">
            <div className="main-header-cms-top">
              <div className="main-header-cms-title">
                <span className="header-cms-icon icon-profile"></span>
                <h1 className="header-cms-title">
                  Mi Perfil
                  <span>Edición de los datos</span>
                </h1>
              </div>
            </div>
          </div>

          <Formik
            initialValues={{
              ...data,
              address: {
                ...data.address,
                country: initialCountry,
              },
            }}
            validationSchema={UpdateSchema}
            onSubmit={(values) => {
              let newValues = values;
              newValues.address.country = countriesData.filter(c => c.name === values?.address?.country)[0];
              update(newValues);
            }}
            render={({
              values,
              errors,
              handleSubmit,
              setFieldValue
            }) => (
              <div className="box form-box">
                <h2 className="title-type6">Datos de Empresa y Persona de Contacto</h2>
                <button type="button" id="btnSaveBlock1" name="btnSaveBlock1" className="btn-type5 btn-absolute"
                  onClick={handleSubmit}>
                  Guardar
                  </button>

                <div className="form-item-row">
                  <div className="form-item">
                    <label className="form-label">Nombre persona de contacto</label>
                    <Input
                      type="text"
                      id="txtFirst"
                      name="txtFirst"
                      value={values.firstName}
                      onChange={(e) => setFieldValue('firstName', e.target.value)}
                      resetId="btnResetFirst"
                      resetName="btnResetFirst" />
                    {errors.firstName && <FormError>{errors.firstName}</FormError>}
                  </div>

                  <div className="form-item">
                    <label className="form-label">Apellidos persona de contacto</label>
                    <Input
                      type="text"
                      id="txtLast"
                      name="txtLast"
                      value={values.lastName}
                      onChange={(e) => setFieldValue('lastName', e.target.value)}
                      resetId="btnResetLast"
                      resetName="btnResetLast" />
                    {errors.lastName && <FormError>{errors.lastName}</FormError>}
                  </div>

                  <div className="form-item">
                    <label className="form-label">Teléfono</label>
                    <Input
                      type="text"
                      id="txtPhone"
                      name="txtPhone"
                      value={values?.phone}
                      onChange={(e) => setFieldValue('phone', e.target.value)}
                      resetId="btnResetPhone"
                      resetName="btnResetPhone" />
                    {errors.phone && <FormError>{errors.phone}</FormError>}
                  </div>
                </div>

                <div className="form-item-row">
                  <div className="form-item">
                    <label className="form-label">Nombre empresa</label>
                    <Input
                      type="text"
                      id="txtOrganization"
                      name="txtOrganization"
                      value={values.organizationName}
                      onChange={(e) => setFieldValue('organizationName', e.target.value)}
                      resetId="btnResetOrganitation"
                      resetName="btnResetOrganitation" />
                    {errors.organizationName && <FormError>{errors.organizationName}</FormError>}
                  </div>

                  <div className="form-item">
                    <label className="form-label">Email</label>
                    <Input
                      type="text"
                      id="txtMail"
                      name="txtMail"
                      value={values.email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      resetId="btnResetMail"
                      blocked
                      resetName="btnResetMail" />
                    {errors.email && <FormError>{errors.email}</FormError>}
                  </div>

                  <div className="form-item">
                    <label className="form-label">Página web</label>
                    <Input
                      type="text"
                      id="txtWeb"
                      name="txtWeb"
                      value={values.website}
                      onChange={(e) => setFieldValue('website', e.target.value)}
                      resetId="btnResetWeb"
                      resetName="btnResetWeb" />
                    {errors.website && <FormError>{errors.website}</FormError>}
                  </div>
                </div>

                <div className="form-item-row">
                  <div className="form-item">
                    <label className="form-label">Dirección</label>
                    <Input
                      type="text"
                      id="txtAddress"
                      name="txtAddress"
                      value={values.address?.address}
                      onChange={(e) => setFieldValue('address.address', e.target.value)}
                      resetId="btnResetAddress"
                      resetName="btnResetAddress" />
                    {errors.address?.address && <FormError>{errors.address?.address}</FormError>}
                  </div>

                  <div className="form-item">
                    <label className="form-label">Dirección 2 (opcional)</label>
                    <Input
                      type="text"
                      id="txtAddress2"
                      name="txtAddress2"
                      value={values.address?.address2}
                      onChange={(e) => setFieldValue('address.address2', e.target.value)}
                      resetId="btnResetAddress2"
                      resetName="btnResetAddress2" />
                    {errors.address?.address2 && <FormError>{errors.address?.address2}</FormError>}
                  </div>
                  <div className="form-item">
                    <label className="form-label">País</label>
                    <div className="form-text-container">
                      {!areCountriesLoading &&
                        <select
                          id="cmbCountry"
                          name="cmbCountry"
                          className="form-select"
                          defaultValue={values?.address?.country?.name}
                          onChange={(e) => setFieldValue('address.country', e.target.value)}>
                          {countriesData.map(country => (
                            <option value={country.name}>{country.name}</option>
                          ))}
                        </select>
                      }
                      <span className="icon-arrow select-arrow"></span>
                    </div>
                    {errors?.address?.country && <FormError>{errors?.address?.country}</FormError>}
                  </div>
                </div>

                <div className="form-item-row">
                  <div className="form-item">
                    <label className="form-label">Región</label>
                    <Input
                      type="text"
                      id="txtRegion"
                      name="txtRegion"
                      value={values.address?.region}
                      onChange={(e) => setFieldValue('address.region', e.target.value)}
                      resetId="btnResetRegion"
                      resetName="btnResetRegion" />
                    {errors?.address?.region && <FormError>{errors?.address?.region}</FormError>}
                  </div>

                  <div className="form-item">
                    <label className="form-label">Ciudad</label>
                    <Input
                      type="text"
                      id="txtCity"
                      name="txtCity"
                      value={values.address?.city}
                      onChange={(e) => setFieldValue('address.city', e.target.value)}
                      resetId="btnResetCity"
                      resetName="btnResetCity" />
                    {errors?.address?.city && <FormError>{errors?.address?.city}</FormError>}
                  </div>

                  <div className="form-item">
                    <label className="form-label">Código postal</label>
                    <Input
                      type="text"
                      id="txtPostalCode"
                      name="txtPostalCode"
                      value={values.address?.zip}
                      onChange={(e) => setFieldValue('address.zip', e.target.value)}
                      resetId="btnResetPostalCode"
                      resetName="btnResetPostalCode" />
                    {errors?.address?.zip && <FormError>{errors?.address?.zip}</FormError>}
                  </div>
                </div>
              </div>

            )} />

          <Formik
            initialValues={{
              administrativeContact: {
                firstName: data.administrativeContact.firstName || '',
                lastName: data.administrativeContact.lastName || '',
                phone: data.administrativeContact.phone || '',
                email: data.administrativeContact.email || '',
              },
            }}
            validationSchema={AdministrativeUpdateSchema}
            onSubmit={(values) => {
              update(values);
            }}
            render={({
              values,
              errors,
              handleSubmit,
              setFieldValue
            }) => (
              <div className="box form-box">
                <h2 className="title-type6">Datos administración</h2>

                <button type="button" id="btnSaveBlock1" name="btnSaveBlock1" className="btn-type5 btn-absolute"
                  onClick={handleSubmit}>
                  Guardar
                  </button>

                <div className="form-item-row">
                  <div className="form-item">
                    <label className="form-label">Nombre</label>
                    <Input
                      type="text"
                      id="txtFirst"
                      name="txtFirst"
                      value={values?.administrativeContact?.firstName}
                      onChange={(e) => setFieldValue('administrativeContact.firstName', e.target.value)}
                      resetId="btnResetFirst"
                      resetName="btnResetFirst" />
                    {errors?.administrativeContact?.firstName && <FormError>{errors?.administrativeContact?.firstName}</FormError>}
                  </div>

                  <div className="form-item">
                    <label className="form-label">Apellidos</label>
                    <Input
                      type="text"
                      id="txtLast"
                      name="txtLast"
                      value={values?.administrativeContact?.lastName}
                      onChange={(e) => setFieldValue('administrativeContact.lastName', e.target.value)}
                      resetId="btnResetLast"
                      resetName="btnResetLast" />
                    {errors?.administrativeContact?.lastName && <FormError>{errors?.administrativeContact?.lastName}</FormError>}
                  </div>

                  <div className="form-item">
                    <label className="form-label">Email</label>
                    <Input
                      type="email"
                      id="txtEmail"
                      name="txtEmail"
                      value={values?.administrativeContact?.email}
                      onChange={(e) => setFieldValue('administrativeContact.email', e.target.value)}
                      resetId="btnResetEmail"
                      resetName="btnResetEmail" />
                    {errors?.administrativeContact?.email && <FormError>{errors?.administrativeContact?.email}</FormError>}
                  </div>
                </div>

                <div className="form-item">
                  <div className="form-item">
                    <label className="form-label">Teléfono</label>
                    <Input
                      type="text"
                      id="txtPhone"
                      name="txtPhone"
                      value={values?.administrativeContact?.phone}
                      onChange={(e) => setFieldValue('administrativeContact.phone', e.target.value)}
                      resetId="btnResetPhone"
                      resetName="btnResetPhone" />
                    {errors?.administrativeContact?.phone && <FormError>{errors?.administrativeContact?.phone}</FormError>}
                  </div>
                </div>
              </div>
            )} />
          <Formik
            initialValues={{
              oldPassword: '',
              newPassword: '',
              repeatedNewPassword: '',
            }}
            validationSchema={ChangePasswordSchema}
            onSubmit={(values, { resetForm }) => {
              resetCurrentForm = resetForm;
              if (values.newPassword !== values.repeatedNewPassword) {
                return setErrorMessageForPassword('La nueva contraseña tiene que coincidir');
              }
              changePassword({
                old_password: values.oldPassword,
                password: values.newPassword,
              });
            }}
            render={({
              values,
              errors,
              touched,
              handleSubmit,
              setFieldValue
            }) => (
              <div className="box form-box">
                <h2 className="title-type6">Contraseña</h2>
                <button
                  type="button"
                  id="btnSaveBlock2"
                  name="btnSaveBlock2"
                  className="btn-type5 btn-absolute"
                  onClick={handleSubmit} >
                  Guardar
                  </button>
                <div className="form-item-row">
                  <div className="form-item">
                    <label className="form-label">Contraseña actual</label>
                    <Input
                      type="password"
                      id="txtPass"
                      name="txtPass"
                      value={values.oldPassword}
                      onChange={(e) => setFieldValue('oldPassword', e.target.value)}
                      resetId="btnShowPass"
                      resetName="btnShowPass" />
                    {errors?.oldPassword && touched?.oldPassword &&
                      <FormError>{errors?.oldPassword}</FormError>}
                  </div>

                  <div className="form-item">
                    <label className="form-label">Nueva contraseña</label>
                    <Input
                      type="password"
                      id="txtPassNew"
                      name="txtPassNew"
                      value={values.newPassword}
                      onChange={(e) => setFieldValue('newPassword', e.target.value)}
                      resetId="btnResetPassNew"
                      resetName="btnResetPassNew" />
                    {errors?.newPassword && touched?.newPassword &&
                      <FormError>{errors?.newPassword}</FormError>}
                  </div>

                  <div className="form-item">
                    <label className="form-label">Repetir contraseña</label>
                    <Input
                      type="password"
                      id="txtPassRepeat"
                      name="txtPassRepeat"
                      value={values.repeatedNewPassword}
                      onChange={(e) => setFieldValue('repeatedNewPassword', e.target.value)}
                      resetId="btnResetPassRepeat"
                      resetName="btnResetPassRepeat" />
                    {errors?.repeatedNewPassword && touched?.repeatedNewPassword &&
                      <FormError>{errors?.repeatedNewPassword}</FormError>}
                  </div>
                </div>
                {errorMessageForPassword &&
                  <FormError>{errorMessageForPassword}</FormError>}
              </div>
            )} />

          <div className="box form-box">
            <h2 className="title-type6">Assistant Service</h2>
            <div className="form-item-row">
              <div className="form-item">
                <label className="form-label">{data?.assistantService?.firstName} {data?.assistantService?.lastName}</label>
                <label className="form-label">{data?.assistantService?.phone}</label>
                <label className="form-label">{data?.assistantService?.email}</label>
              </div>
            </div>
          </div>

          <div className="box form-box">
            <h2 className="title-type6">Documentación</h2>
            <div className="responsive-table">
              <table className="table-type1">
                <thead>
                  <tr>
                    <th>Acuerdos de partnership</th>
                    <th>Fecha firma</th>
                    <th className="centered">Descarga Archivo</th>
                  </tr>
                </thead>
                <tbody>
                  {partnersAgreement.map(d => (
                    <tr>
                      <th>
                        <span className="icon-doc">{d.document.title}</span>
                      </th>

                      <td>
                        {d?.signedAt?.date ?
                          nonStandardDateFormatting(d?.signedAt?.date) :
                          'Sin firmar'
                        }
                      </td>

                      <td className="centered">
                        <button type="button" id="btnDownloadItem1" name="btnDownloadItem1" className="btn-icon-border"
                          onClick={() => {
                            if (!d.document.content_url) {
                              setPartnerAgreementDescription(d?.document?.description);
                              return setShowParnterAgreementModal(true);
                            }
                            downloadFile({
                              url: URLS.PRODUCT.DOCUMENT_DOWNLOAD({ documentId: d.document.id }),
                              name: document.title,
                            })
                          }}>
                          {d.document.content_url && <span className="icon-download"></span>}
                          {!d.document.content_url && <span className="icon-show"></span>}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {partnersAgreement.length === 0 &&
                <EmptyMessage>No se ha firmado contrato con Numentech.</EmptyMessage>}
            </div>

            <div className="responsive-table">
              <table className="table-type1">
                <thead>
                  <tr>
                    <th>Autorizaciones de distribución</th>
                    <th>Fecha firma</th>
                    <th className="centered">Descarga Archivo</th>
                  </tr>
                </thead>
                <tbody>
                  {productSolutionsActivation.map(d => (
                    <tr>
                      <th>
                        <span className="icon-doc">{d.document.title}</span>
                      </th>
                      <td>
                        {d?.signedAt?.date ?
                          nonStandardDateFormatting(d?.signedAt?.date) :
                          'Sin firmar'
                        }
                      </td>

                      <td className="centered">
                        <button type="button" id="btnDownloadItem1" name="btnDownloadItem1" className="btn-icon-border"
                          onClick={() => {
                            if (!d.document.content_url) {
                              setPartnerAgreementDescription(d?.document?.description);
                              return setShowParnterAgreementModal(true);
                            }
                            downloadFile({
                              url: URLS.PRODUCT.DOCUMENT_DOWNLOAD({ documentId: d.document.id }),
                              name: document.title,
                            })
                          }}>
                          {d.document.content_url && <span className="icon-download"></span>}
                          {!d.document.content_url && <span className="icon-show"></span>}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {productSolutionsActivation.length === 0 &&
                <EmptyMessage>No se ha firmado ningún acuerdo de distribución de productos Numentech.</EmptyMessage>}
            </div>

          </div>
        </form>
      </div>
      <InfoModal
        isOpen={showUpdatedModal}
        setIsOpen={setShowUpdatedModal}
        title="Datos guardados"
        subtitle="Los datos se han guardado correctamente"
      />
      <InfoModal
        isOpen={showParnterAgreementModal}
        setIsOpen={setShowParnterAgreementModal}
        title="Document"
        subtitle={partnerAgreementDescription}
      />
      <PartnerDocumentsModal
        isOpen={showRequiredPartnerSign}
        setIsOpen={setShowRequiredPartnerSign}
        onFinish={() => {
          setShowRequiredPartnerSign(false);
          queryClient.refetchQueries(['userDocuments']);
        }}
        documents={
          documentsData
            .filter(d => !d.signedAt)
            .map(d => d.document)
        }
      />
      <ChangePasswordModal
        isOpen={showRequiredChange}
        setIsOpen={setShowRequiredChange}
      />
    </>
  );
};

export default MyAccount;