import React, { useState } from 'react';
import Modal from 'react-modal';
import { useMutation } from 'react-query';

import { customStyles } from '../../../../../lib/modals';
import { post } from '../../../../../lib/restClient';
import { URLS } from '../../../../../lib/api';
import { Input } from '../../../../../components/Inputs';
import KeyDocumentsModal from '../../../../../components/KeyDocumentsModal';
import TextModal from '../../../../../components/TextModal';
import FormError from '../../../../../components/FormError';

Modal.setAppElement('#root')

export default ({ isOpen, setIsOpen, onFinish }) => {
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const [showFinishModal, setShowFinishModal] = useState(false);
  const [availableDocuments, setAvailableDocuments] = useState([]);
  const { mutate: activateKey } = useMutation(
    () => post(URLS.CLIENT.ACTIVATE_KEY(), { registrationNumber }),
    {
      onSuccess: (response) => {
        const { data } = response;
        console.log({ data });
        if (data.requiresSignInContracts) {
          setShowDocumentsModal(true);
          setAvailableDocuments(data.document);
        } else {
          setShowFinishModal(true);
          onFinish();
        }
        closeModal();
      },
      onError: (error) => {
        setErrorMessage(error.response.data.message);
        console.log('ERROR', error);
      }
    }
  );
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-container">
          <div className="modal-content action-modal">
            <header className="modal-header header-icon">
              <span className="icon-key"></span>
            </header>

            <div className="action-modal-content">
              <h2 className="modal-title-type1">Añade la Key de tu nuevo producto</h2>
              <p className="modal-action-desc">Escribe en este campo el número de licencia (key), firmas los documentos asociados y empieza a usar tu producto.</p>
              <form action="#" method="post">
                <fieldset>
                  <div className="form-item">
                    <div className="form-text-container">
                      <Input
                        type="text"
                        id="txtKey"
                        name="txtKey"
                        value={registrationNumber}
                        onChange={(event) => setRegistrationNumber(event.target.value)}
                        resetId="btnResetKey"
                        resetName="btnResetKey"
                      />
                    </div>
                  </div>
                  <FormError>{errorMessage}</FormError>
                  <button type="button" id="btnAccept" name="btnAccept" className="btn-type1" onClick={(e) => {
                    e.preventDefault();
                    activateKey();
                  }}>
                    Aceptar
                        </button>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <KeyDocumentsModal
        isOpen={showDocumentsModal}
        setIsOpen={setShowDocumentsModal}
        registrationNumber={registrationNumber}
        onFinish={() => {
          setRegistrationNumber('');
          setShowFinishModal(true);
          onFinish();
        }}
        documents={availableDocuments} />
      <TextModal
        text="Producto activado correctamente"
        isOpen={showFinishModal}
        setIsOpen={setShowFinishModal}
      />
    </>
  );
}
