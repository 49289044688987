import React from 'react';

export const GreatSelect = ({
  options,
  ...rest
}) => (
  <div className="form-text-container form-error"
    style={{
      borderColor: '#656364',
    }}>
    <select {...rest} className="form-select">
      <option></option>
      {options.map(option => (
        <option
          value={option.id}
          selected={option.selected}>
            {option.label}
        </option>
      ))}
    </select>
    <span className="icon-arrow select-arrow" style={{ color: '#656364'}}></span>
  </div>
);
