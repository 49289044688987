import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import * as Yup from 'yup';
import Loader from '../../../components/Loader';
import { URLS } from '../../../lib/api';
import { get, post, put, uploadMedia } from '../../../lib/restClient';
import { Input } from '../../../components/Inputs';
import FormError from '../../../components/FormError';
import InfoModal from '../../../components/InfoModal';
import { extractErrorMessageFromResponse } from '../../../lib/utils';
import { downloadFile } from '../../../lib/downloads';

const initialValues = {
  title: '',
  active: false,
  documentClass: '',
  description: '',
  type: '',
  content_url: '',
};

const AddSchema = Yup.object().shape({
  title: Yup.string()
    .required('Requerido'),
  type: Yup.string()
    .required('Requerido'),
  active: Yup.bool()
    .required('Requerido'),
  documentClass: Yup.string()
    .when('type', {
      is: 'document',
      then: Yup.string()
        .required('Requerido')
    })
    .when('type', {
      is: 'document.id',
      then: Yup.string()
        .required('Requerido')
    }),
  content_url: Yup
    .string('Introduce una URL válida')
    .url('Introduce una URL válida')
    .nullable(),
  description: Yup.string()
    .required('Requerido'),
});

export default ({ edit }) => {
  const history = useHistory();
  const { documentId } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const saveFormik = useFormik({
    validationSchema: AddSchema,
    initialValues,
    onSubmit: (values) => {
      if (values?.type !== 'product') {
        delete values.documentClass;
        delete values.content_url;
      }
      addDocument(values);
    },
  });

  const onDrop = useCallback(acceptedFiles => {
    setSelectedFiles([acceptedFiles[0]]);
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    maxFiles: 1,
  });
  const { isLoading, error, data: documentTypes } = useQuery('documentTypes', async () => {
    const response = await get(
      URLS.DOCUMENTS.LIST_DOCUMENT_TYPES(),
    );
    return response.data;
  });
  const { isLoading: isLoadingById } = useQuery('documentById', async () => {
    if (!documentId) {
      return;
    }
    const response = await get(
      URLS.DOCUMENTS.DETAIL({ documentId }),
    );
    if (!saveFormik?.value?.content_url) {
      saveFormik.setFieldValue('type', response?.data?.type.id);
      saveFormik.setFieldValue('content_url', response?.data?.content_url);
      saveFormik.setFieldValue('description', response?.data?.description);
      saveFormik.setFieldValue('active', response?.data?.active);
      saveFormik.setFieldValue('title', response?.data?.title);
      saveFormik.setFieldValue('documentClass', response?.data?.documentClass?.id);
      setSelectedFiles([
        { name: response?.data?.file?.filename }
      ]);
    }
    return response.data;
  });
  const { mutate: addDocument } = useMutation(
    (values) => {
      if (edit) {
        if (values?.documentClass === 'document') {
          return put(URLS.DOCUMENTS.UPDATE({ documentId }), values)
            .then(response => {
              const documentId = response.data.id;
              return uploadMedia(URLS.DOCUMENTS.UPLOAD_FILE({ documentId }), acceptedFiles.length > 0 ?
                acceptedFiles[0] :
                selectedFiles[0]
              );
            });
        }
        return put(URLS.DOCUMENTS.UPDATE({ documentId }), values);
      }
      if (values?.documentClass === 'document') {
        return post(URLS.DOCUMENTS.ADD(), values)
          .then(response => {
            const documentId = response.data.id;
            return uploadMedia(URLS.DOCUMENTS.UPLOAD_FILE({ documentId }), acceptedFiles.length > 0 ?
              acceptedFiles[0] :
              selectedFiles[0]
            );
          });
      }
      return post(URLS.DOCUMENTS.ADD(), values);
    },
    {
      onSuccess: async () => {
        if (edit) {
          setShowModal(true);
        } else {
          history.goBack();
        }
      },
      onError: (error) => {
        console.log('ERROR', error);
        setErrorMessage(extractErrorMessageFromResponse(error));
      }
    }
  );
  if (error) {
    return <span>Error</span>;
  }
  if (isLoading || isLoadingById) {
    return <Loader />;
  }
  if (documentTypes && !saveFormik?.values?.type) {
    saveFormik.setFieldValue('type', documentTypes[0].id);
  }
  const calculatedStyle = {
    ...(isHovered ?
      {
        textDecoration: 'underline',
        cursor: 'pointer',
      } :
      {})
  };
  return (
    <>
      <div className="box main-header-cms">
        <div className="main-header-cms-top">
          <div className="main-header-cms-title">
            <span className="header-cms-icon icon-documents"></span>
            <h1 className="header-cms-title">
              {edit ?
                'Editar documento' :
                'Añadir documento'
              }
            </h1>
          </div>

          <div className="main-header-cms-actions">
            <div className="header-cms-actions-absolute">
              <button type="button" id="btnCancel" name="btnCancel" className="btn-type4 small"
                onClick={() => history.goBack()}>
                Cancelar
              </button>
              <button type="button" id="btnSave" name="btnSave" className="btn-type5"
                onClick={saveFormik.handleSubmit}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="box form-box">
        <div className="form-item-row form-row-start">
          <FormError>{errorMessage}</FormError>
          <div className="form-item-col">
            <div className="form-item">
              <label className="form-label">Tipo *</label>
              <div className="form-text-container">
                <select
                  id="cmbType"
                  name="cmbType"
                  onChange={(e) => saveFormik.setFieldValue('type', e.target.value)}
                  className="form-select">
                  {documentTypes.map(dt => (
                    <option
                      selected={dt.id === saveFormik.values.type}
                      value={dt.id}>
                      {dt.caption}
                    </option>
                  ))}
                </select>
                <span className="icon-arrow select-arrow"></span>
              </div>
              {saveFormik?.touched?.type && saveFormik?.errors?.type &&
                <FormError>{saveFormik?.errors?.type}</FormError>
              }
            </div>
          </div>
          <div className="form-item-col">
            <div className="form-item">
              <label className="form-label">Título *</label>
              <Input type="text" id="txtUrl" name="txtUrl" className="form-text"
                onChange={(e) => saveFormik.setFieldValue('title', e.target.value)}
                value={saveFormik?.values?.title}
              />
              {saveFormik?.touched?.title && saveFormik?.errors?.title &&
                <FormError>{saveFormik?.errors?.title}</FormError>
              }
            </div>
          </div>

          <div className="form-item-col">
            <div className="form-item">
              <label className="form-label">Estado *</label>
              <div className="form-text-container">
                <select
                  id="cmbType"
                  name="cmbType"
                  onChange={(e) => {
                    saveFormik.setFieldValue('active', e.target.value === 'true')
                  }}
                  className="form-select">
                  <option
                    selected={saveFormik?.values?.active}
                    value={true}>
                    Activo
                    </option>
                  <option
                    selected={!saveFormik?.values?.active}
                    value={false}>
                    Inactivo
                    </option>
                </select>
                <span className="icon-arrow select-arrow"></span>
              </div>
              {saveFormik?.errors?.active &&
                <FormError>{saveFormik?.errors?.active}</FormError>
              }
            </div>
          </div>
        </div>

        <div className="form-item-row form-row-start">
          {saveFormik?.values?.type === 'product' &&

            <div className="form-item-col">
              <div className="form-item">
                <label className="form-label">Clase</label>

                <div className="form-text-container">
                  <select
                    id="cmbClass"
                    name="cmbClass"
                    className="form-select"
                    onChange={(e) => saveFormik.setFieldValue('documentClass', e.target.value)}>
                    <option></option>
                    <option
                      selected={
                        saveFormik?.values?.documentClass === 'document' ||
                        saveFormik?.values?.documentClass?.id === 'document'
                      }
                      value="document">
                      Documento
                  </option>
                    <option
                      selected={
                        saveFormik?.values?.documentClass === 'video' ||
                        saveFormik?.values?.documentClass?.id === 'video'
                      }
                      value="video">
                      Vídeo
                  </option>
                  </select>
                  <span className="icon-arrow select-arrow"></span>
                </div>
                {saveFormik?.touched?.documentClass && saveFormik?.errors?.documentClass &&
                  <FormError>{saveFormik?.errors?.documentClass}</FormError>
                }
              </div>

              {(saveFormik?.values?.documentClass === 'video'
                || saveFormik?.values?.documentClass?.id === 'video') &&
                <div className="form-item">
                  <label className="form-label">URL Vídeo</label>
                  <Input type="text" id="txtUrl" name="txtUrl" className="form-text"
                    onChange={(e) => saveFormik.setFieldValue('content_url', e.target.value)}
                    value={saveFormik?.values?.content_url}
                  />
                  {saveFormik?.touched?.content_url && saveFormik?.errors?.content_url &&
                    <FormError>{saveFormik?.errors?.content_url}</FormError>
                  }
                </div>
              }

              {saveFormik?.values?.documentClass === 'document' &&
                <div className="form-item">
                  <label className="form-label">Documentación, solo pueden adjuntarse archivos PDF</label>
                  <div className="form-file-container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      {
                        isDragActive ?
                          <span>Deja aquí el archivo para poder subirlo</span> :
                          <label htmlFor="fileDocument">Arrastra y suelta los archivos o súbelos <strong>aquí</strong></label>
                      }
                    </div>
                  </div>
                  {fileRejections.length > 0 &&
                    <FormError>{fileRejections[0]?.file?.name} no es del tipo adecuado</FormError>
                  }
                  <table style={{ marginTop: 30 }}>
                    <tbody>
                      {(fileRejections.length === 0) &&
                        (acceptedFiles.length > 0 ? acceptedFiles : selectedFiles).map(file => (
                          <tr
                            style={calculatedStyle}
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                            onClick={() => {
                              if (!documentId) {
                                return;
                              }
                              downloadFile({
                                url: URLS.DOCUMENTS.DOWNLOAD_DOCUMENT_PDF({ documentId }),
                                name: file?.name,
                              })
                            }}>
                            <th>
                              <span className="icon-doc" style={{ padding: 20 }}></span>
                              <span>{file?.name}</span>
                            </th>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                </div>
              }
            </div>
          }
          <div className="form-item-col size2">
            <div className="form-item">
              <label className="form-label">Descripción *</label>
              <div className="form-text-container form-textarea">
                <textarea id="txtDescription" name="txtDrescription" className="form-text"
                  onChange={(e) => saveFormik.setFieldValue('description', e.target.value)}
                  value={saveFormik?.values?.description} />
              </div>
            </div>

            {saveFormik?.touched?.description && saveFormik?.errors?.description &&
              <FormError>{saveFormik?.errors?.description}</FormError>
            }
          </div>
        </div>
      </div>
      <InfoModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        title="Datos guardados"
        subtitle="Datos guardados correctamente"
      />
    </>
  );
}

