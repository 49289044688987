import React, { useState } from 'react';
import Toggle from 'react-toggle'
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Header from '../components/Header';
import Footer from '../components/Footer';
import { get, post } from '../../../lib/restClient';
import { URLS } from '../../../lib/api';
import InfoModal from '../../../components/InfoModal';
import Loader from '../../../components/Loader';

const Categories = () => {
  const queryClient = useQueryClient()
  const mappedCategorySettings = {};
  const [savedNotifications, setSavedNotifications] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { isLoading, error, data } = useQuery('categories_settings', async () => {
    const meResponse = await get(URLS.AUTH.ME());
    const response = await get(URLS.CLIENT.GET_NOTIFICATIONS_CATEGORIES());
    const importancesResponse = await get(URLS.CLIENT.GET_NOTIFICATIONS_IMPORTANCES());
    if (!selectedCategories.length && meResponse?.data?.notificationsSettings?.length > 0) {
      setSelectedCategories(meResponse?.data?.notificationsSettings?.map(notification => ({
        categoryId: notification?.category,
        importanceId: notification?.importance,
      })));
    }
    return {
      me: meResponse.data,
      categories: response.data,
      importances: importancesResponse.data.map(importance => ({
        id: importance.id,
        label: importance.name,
      })),
    };
  });
  const { mutate: postNotificationSettings } = useMutation(
    () =>
      post(
        URLS.AUTH.NOTIFICATIONS_SETTINGS(),
        {
          notificationsSettings: Object.keys(savedNotifications).map(category => ({
            category,
            importance: savedNotifications[category],
          })),
        },
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['categories']);
        setShowModal(true);
      },
      onError: (error) => {
        console.log('ERROR', error);
      }
    }
  );
  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <span>Error</span>;
  }
  const notificationSettings = data?.me?.notificationsSettings || [];
  notificationSettings.forEach(setting => {
    mappedCategorySettings[setting.category] = setting.importance;
  });
  if (!savedNotifications) {
    setSavedNotifications(mappedCategorySettings);
  }
  return (
    <>
      <div role="main" className="main-clients">
        <Header />
        <div className="row">
          <div className="box">
            <div className="responsive-table full-table">
              <table className="table-type2">
                <thead>
                  <tr>
                    <th>Categoría</th>
                    <th>Recibir por email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{''}</td>
                    <td style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      {data?.importances?.map((importance, index) => (
                        <span
                          key={importance.id}
                          style={{
                            fontWeight: 800,
                            marginRight: 10,
                            marginLeft: index === 0 ? 0 : 85,
                            textTransform: 'uppercase',
                        }}>{importance.label}</span>
                      ))}
                    </td>
                  </tr>
                  {data?.categories?.map(category => (
                    <tr key={category?.id}>
                      <td>{category?.name}</td>
                      <td style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        {
                          savedNotifications && data?.importances.map((importance, index) => (
                            <div key={index}
                              style={{ marginRight: 80 }}>
                              <Toggle
                                defaultChecked={savedNotifications[category.id]?.indexOf(importance.id) >= 0}
                                onChange={() => {
                                  if (savedNotifications[category.id]?.indexOf(importance.id) >= 0) {
                                    savedNotifications[category.id] = savedNotifications[category.id].filter(importanceId => importanceId !== importance.id);
                                  } else {
                                    if (savedNotifications[category.id]) {
                                      savedNotifications[category.id].push(importance.id);
                                    } else {
                                      savedNotifications[category.id] = [importance.id];
                                    }
                                  }
                                  setSavedNotifications(savedNotifications);
                                }} />
                            </div>
                          ))
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="main-header-cms-actions"
          style={{
            float: 'right',
            marginTop: 20,
            marginBottom: 20,
          }}>
          <button type="button" id="btnAdd" name="btnAdd" className="btn-cms-actions"
            onClick={() => {
              postNotificationSettings({ selectedCategories });
            }}>
            Guardar
          </button>
        </div>
      </div>
      <Footer />
      <InfoModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        title="Datos guardados"
        subtitle="Datos guardados correctamente"
      />
    </>
  );
};

export default Categories;