import React, { useState } from 'react';
import OrderModal from '../../../../../components/OrderModal';
import { formatPrice } from '../../../../../lib/prices';
import InfoModal from '../../../../../components/InfoModal';

export default ({ product, currencyIsoCode }) => {
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [showDocuments, setShowDocuments] = useState(false);
  const [showPreviousVersions, setShowPreviousVersions] = useState(false);
  const [showProductDocumentation, setShowProductDocumentation] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showAddedModal, setShowAddedModal] = useState(false);
  const pastVersions = product?.versions?.slice(1);

  let discountAmount;
  let totalDiscountInPercentage = 0;

  let unitPrice = product?.price?.unitPrice;
  if (product?.latestVersion?.price?.planDiscount) {
    unitPrice =
      product?.latestVersion?.price?.unitPrice
      - (product?.latestVersion?.price?.unitPrice * product?.latestVersion?.price?.planDiscount?.discount)
      / 100;
    discountAmount = product?.latestVersion?.price?.planDiscount?.discount;
    totalDiscountInPercentage += discountAmount;
  }
  if (product?.latestVersion?.price?.typeDiscount) {
    unitPrice =
      product?.latestVersion?.price?.unitPrice
      - (product?.latestVersion?.price?.unitPrice * product?.latestVersion?.price?.typeDiscount?.discount)
      / 100;
    discountAmount = product?.latestVersion?.price?.typeDiscount?.discount;
    totalDiscountInPercentage += discountAmount;
  }

  unitPrice = totalDiscountInPercentage !== 0 ?
    product?.latestVersion?.price?.unitPrice - (product?.latestVersion?.price?.unitPrice * totalDiscountInPercentage) / 100 :
    product?.latestVersion?.price?.unitPrice;

  return (
    <>
      <div className="accordion-content-solution">
        <div className="content-solution-item">
          <div className="solution-item-info">
            <h3 className="solution-item-info-title">{product?.name} {product?.trial && <span className="trial-tag">Trial version</span>}</h3>
            <span className="solution-item-info-desc">Versión actual {product?.latestVersion?.version}</span>
          </div>

          <div className="solution-item-action">
            <div className="solution-item-info-price item-pvp">
              {product?.latestVersion?.price?.recommendedPrice &&
              <>
                <span className="solution-item-info-price-label">PVP Recomendado</span>
              {formatPrice({
                number: product?.latestVersion?.price?.recommendedPrice,
                currencyIsoCode,
              })}
              </>
              }
            </div>
            <div className="solution-item-info-price">
              <span className="solution-item-info-price-label">Tu Precio</span>
              {formatPrice({
                number: unitPrice,
                currencyIsoCode,
              })}
            </div>
            <button type="button" id="btnOrderItem1" name="btnOrderItem1" className="btn-type5"
              onClick={() => {
                setSelectedVersion({
                  ...product?.latestVersion,
                  solution: product?.solution,
                });
                setShowOrderModal(true);
              }}>
              Hacer pedido
            </button>
          </div>

        </div>

        {product?.documents.filter(document => document?.document?.type?.id === 'product')?.length > 0 &&
          <>
            <button type="button" id="btnDoc" name="btnDoc" className={`btn-type6 btn-documentation ${showDocuments && 'up'}`}
              onClick={() => setShowDocuments(!showDocuments)}>
              <span className="icon-doc"></span> Documentación del producto <span className="icon-arrow"></span>
            </button>

            <div className={`accordion-content-documentation documentation-type2 ${showDocuments && 'show'}`}>
              <ul className="documentation-list list-type2">
                {product?.documents?.filter(document => document?.document?.type?.id === 'product')
                  .map(document => (
                    <li>
                      <a href="#" target="_blank" className="icon-doc2 doc-link">{document?.title}</a>
                    </li>
                  ))}
              </ul>
            </div>
          </>
        }

        {pastVersions?.length > 0 &&
          <button type="button" id="btnHide" name="btnHide" className={`btn-type6 ${showPreviousVersions && 'up'}`}
            onClick={() => setShowPreviousVersions(!showPreviousVersions)}>
            <span className="icon-version"></span> Versiones anteriores <span className="icon-arrow"></span>
          </button>
        }

        <div className={`solution-item-list ${showPreviousVersions && 'show'}`}>
          {pastVersions.filter(version => version.available)?.map(version => (
            <>
              <div className="content-solution-item">
                <div className="solution-item-info">
                  <span className="solution-item-info-desc">Versión {version?.version}</span>
                </div>

                <div className="solution-item-action">
                  <div className="solution-item-info-price item-pvp">
                    <span className="solution-item-info-price-label">PVP Recomendado</span>
                    {formatPrice({
                      number: product?.latestVersion?.price?.recommendedPrice,
                      currencyIsoCode,
                    })}
                  </div>
                  <div className="solution-item-info-price">
                    <span className="solution-item-info-price-label">Tu Precio</span>
                    {product?.trial &&
                      formatPrice({
                        number: 0,
                        currencyIsoCode,
                      })}
                    {formatPrice({
                      number: version?.price?.unitPrice,
                      currencyIsoCode,
                    })}
                  </div>
                  <button type="button" id="btnOrderItem2" name="btnOrderItem2" className="btn-type5"
                    onClick={() => {
                      setSelectedVersion(version)
                      setShowOrderModal(true);
                    }}>
                    Hacer pedido
                  </button>
                </div>
              </div>
              {version?.documents?.length > 0 &&
                <div className="solution-item-documentation">
                  <button type="button" id="btnDoc3" name="btnDoc3" className={`btn-type6 btn-documentation ${showProductDocumentation && 'up'}`}
                    onClick={() => setShowProductDocumentation(!showProductDocumentation)}>
                    <span className="icon-doc"></span> Documentación del producto <span className="icon-arrow"></span>
                  </button>

                  <div className={`accordion-content-documentation documentation-type2 ${showProductDocumentation && 'show'}`}>
                    <ul className="documentation-list list-type2">
                      {version?.documents?.map(document => (
                        <li>
                          <a href="#" target="_blank" className="icon-doc2 doc-link">{document?.title}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              }
            </>
          ))}
        </div>
      </div>
      {showOrderModal && (
        <OrderModal
          currencyIsoCode={currencyIsoCode}
          productVersion={selectedVersion}
          product={product}
          isOpen={showOrderModal}
          setIsOpen={setShowOrderModal}
          setShowAddedModal={setShowAddedModal} />
      )}
      <InfoModal
        noLogo={true}
        isOpen={showAddedModal}
        setIsOpen={setShowAddedModal}
        title="Añadido al carrito"
        subtitle="Productos añadidos correctamente"
      />
    </>
  );
}
