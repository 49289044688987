import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { URLS } from '../../../../../lib/api';
import Loader from '../../../../../components/Loader';
import { Input } from '../../../../../components/Inputs';
import InfoModal from '../../../../../components/InfoModal';
import FormError from '../../../../../components/FormError';
import CountrySearcher from '../../../../../components/CountrySearcher';
import { get, post, put } from '../../../../../lib/restClient';

const ValidationSchema = Yup.object().shape({
  countries: Yup.array()
    .required('Requerido')
    .min(1, 'Debes seleccionar al menos un país'),
  dependencies: Yup.array(),
  providers: Yup.array()
    .required('Requerido')
    .min(1, 'Debes seleccionar al menos un proveedor'),
  description: Yup.string().required('Requerido'),
  duration: Yup.number().required('Requerido'),
  name: Yup.string().required('Requerido'),
  perpetual: Yup.boolean().required('Requerido'),
  trial: Yup.boolean().required('Requerido'),
  solution: Yup.string().required('Requerido'),
  type: Yup.string().required('Requerido'),
});

const initialValues = {
  countries: [],
  providers: [],
  perpetual: false,
  trial: false,
};

export default forwardRef(({ isCreating }, ref) => {
  const history = useHistory();
  const { productId } = useParams();
  const [alreadyFetched, setAlreadyFetched] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showProviders, setShowProviders] = useState(false);
  const { mutate: updateProduct } = useMutation(
    (values) => {
      if (isCreating) {
        return post(URLS.ADMIN.CREATE_PRODUCT(), values);
      }
      return put(URLS.ADMIN.EDIT_PRODUCT({ productId }), values);
    },
    {
      onSuccess: async () => {
        setShowModal(true);
      },
      onError: (error) => {
        console.log('ERROR', error);
      }
    }
  );
  const formik = useFormik({
    validationSchema: ValidationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateProduct({
        ...values,
        countries: values.countries.map(country => country.iso_code),
      });
    },
  });
  useImperativeHandle(ref, () => ({
    update() {
      formik.handleSubmit();
    }
  }));
  const { isLoading, data, error } = useQuery('productDetail', async () => {
    let response = { data: initialValues };
    if (!isCreating) {
      response = await get(URLS.PRODUCT.DETAIL({ productId }));
    } else {
      setAlreadyFetched(true);
    }
    const providersResponse = await get(URLS.ADMIN.LIST_PROVIDERS());
    const productTypesResponse = await get(URLS.PRODUCT.LIST_PRODUCT_TYPES());
    const productSolutionsResponse = await get(URLS.PRODUCT.SOLUTIONS());
    const statusesResponse = await get(URLS.PARTNER.LIST_STATUS());
    if (!alreadyFetched && !isCreating) {
      formik.setFieldValue('countries', response?.data?.countries);
      formik.setFieldValue('dependencies', response?.data?.dependencies.map(e => e.id));
      formik.setFieldValue('providers', response?.data?.providers.map(e => e.id));
      formik.setFieldValue('description', response?.data?.description);
      formik.setFieldValue('duration', response?.data?.duration);
      formik.setFieldValue('stockAvailable', response?.data?.stockAvailable);
      formik.setFieldValue('name', response?.data?.name);
      formik.setFieldValue('perpetual', response?.data?.perpetual);
      formik.setFieldValue('trial', response?.data?.trial);
      formik.setFieldValue('solution', response?.data?.solution?.id);
      formik.setFieldValue('type', response?.data?.type?.id);
      setAlreadyFetched(true);
    }
    return {
      info: response.data,
      providers: providersResponse?.data,
      productTypes: productTypesResponse?.data,
      productSolutions: productSolutionsResponse?.data,
      statuses: statusesResponse?.data,
    };
  });
  if (error) {
    return <span>Error</span>;
  }
  if (isLoading) {
    return <Loader />;
  }
  const onAcceptCallback = isCreating ?
    () => history.push('/admin/products') :
    null;
  return (
    <div className="box form-box">
      <h2 className="title-type6">Datos básicos</h2>

      <div className="form-item-row">
        <div className="form-item-col">
          {!isCreating &&
            <div className="form-item">
              <label className="form-label">ID Producto</label>
              <Input type="text" id="txtId" name="txtId" className="form-text" blocked value={data?.info?.id} />
              {formik?.errors?.info &&
                <FormError>{formik.errors.info}</FormError>
              }
            </div>
          }

          <div className="form-item">
            <label className="form-label">Nombre del producto *</label>
            <Input type="text" id="txtName" name="txtName" className="form-text"
              onChange={(e) => formik?.setFieldValue('name', e.target.value)}
              value={formik?.values?.name} />
            {formik?.touched?.name && formik?.errors?.name &&
              <FormError>{formik.errors.name}</FormError>
            }
          </div>

          <div className="form-item">
            <label className="form-label">Descripción *</label>
            <div className="form-text-container form-textarea">
              <textarea id="txtDescription" name="txtDrescription" className="form-text"
                onChange={(e) => formik?.setFieldValue('description', e.target.value)}
                value={formik?.values?.description}>
                {formik?.values?.description}
              </textarea>
              {formik?.touched?.description && formik?.errors?.description &&
                <FormError>{formik.errors.description}</FormError>
              }
            </div>
            <span className="text-container-legend">0/500</span>
          </div>
        </div>

        <div className="form-item-col">
          <div className="form-item form-item-two">
            <div className="form-item">
              <label className="form-label">Tipo *</label>
              <div className="form-text-container">
                <select id="cmbType" name="cmbType" className="form-select"
                  value={formik?.values?.type}
                  onChange={(e) => formik?.setFieldValue('type', e.target.value)}>
                  <option></option>
                  {data?.productTypes?.map(productType => (
                    <option key={productType?.id} value={productType?.id}>{productType?.label}</option>
                  ))}
                </select>
                <span className="icon-arrow select-arrow"></span>
              </div>
              {formik?.touched?.type && formik?.errors?.type &&
                <FormError>{formik.errors.type}</FormError>
              }
            </div>
          </div>

          <div className="form-item">
            <label className="form-label">Solución *</label>
            <div className="form-text-container">
              <select id="cmbSolution" name="cmbSolution" className="form-select"
                value={formik?.values?.solution}
                onChange={(e) => formik?.setFieldValue('solution', e.target.value)}>
                <option></option>
                {data?.productSolutions?.map(productSolution => (
                  <option key={productSolution?.id} value={productSolution?.id}>{productSolution?.name}</option>
                ))}
              </select>
              <span className="icon-arrow select-arrow"></span>
            </div>
            {formik?.touched?.solution && formik?.errors?.solution &&
              <FormError>{formik.errors.solution}</FormError>
            }
          </div>

          {!formik?.values?.perpetual &&
            <div className="form-item">
              <label className="form-label">Duración (meses) *</label>
              <Input type="number" id="txtName" name="txtName" className="form-text"
                onChange={(e) => formik?.setFieldValue('duration', parseInt(e.target.value))}
                value={formik?.values?.duration} />
              {formik?.touched?.duration && formik?.errors?.duration &&
                <FormError>{formik.errors.duration}</FormError>
              }
            </div>
          }

          <div className="form-item form-item-two">
            {!formik?.values?.perpetual &&
              <div className="form-item">
                <div className="form-item-checkbox">
                  <input type="checkbox" id="chTrial" name="chTrial" checked={formik?.values?.trial}
                    onChange={() => formik.setFieldValue('trial', !formik?.values?.trial)}
                  />
                  <label htmlFor="chTrial" className="small-label">Trial</label>
                </div>
              </div>
            }

            <div className="form-item">
              <div className="form-item-checkbox">
                <input type="checkbox" id="chLicense" name="chLicense" checked={formik?.values?.perpetual}
                  onChange={() => formik.setFieldValue('perpetual', !formik?.values?.perpetual)}
                />
                <label htmlFor="chLicense" className="small-label">Licencia perpetua</label>
              </div>
            </div>
          </div>

        </div>

        <div className="form-item-col modal-col-size1">
          <div className="form-item">
            <label className="form-label">Países *</label>
            <CountrySearcher
              onChange={(selectedCountries) => {
                formik.setFieldValue('countries', selectedCountries);
              }}
              mustHaveCurrency
              selectedCountries={formik?.values?.countries}
              touched={formik?.touched?.countries}
              errorMessage={formik?.errors?.countries}
            />
          </div>

          <div className="form-item">
            <label className="form-label">Proveedores *</label>

            <div className="form-text-container multiselect-container" onClick={() => {
              setShowProviders(!showProviders);
            }}>
              <input type="text" id="txtProviders" name="txtProviders" className="form-text" readOnly
                value={`${formik?.values?.providers?.length} seleccionados`} />
              <button type="button" id="btnSelectProvider" name="btnSelectProvider" className="btn-multiselect">
                <span className="icon-arrow"></span>
              </button>

              <div className={`multiselect ${showProviders ? 'show' : ''}`}>
                {data?.providers?.map(provider => (
                  <div key={provider.id} className="form-item-checkbox">
                    <input type="checkbox" id="chMulti1" name="chMulti1"
                      onChange={() => {
                        const found = formik?.values?.providers?.indexOf(provider.id) !== -1
                        if (found) {
                          const newProviders = formik?.values?.providers.filter(p => p !== provider.id);
                          formik.setFieldValue('providers', newProviders);
                        } else {
                          const newProviders = [...formik?.values?.providers, provider.id];
                          formik.setFieldValue('providers', newProviders);
                        }
                      }}
                      checked={formik?.values?.providers?.indexOf(provider.id) !== -1} />
                    <label htmlFor="chMulti1">{provider.name}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="search-tags-container">
              {formik?.values?.provider?.map(provider => (
                <div key={provider.id} className="form-item-checkbox">
                  <span className="search-tag icon-close-modal">{provider?.name}</span>
                </div>
              ))}
            </div>
          </div>
          {formik?.touched?.providers && formik?.errors?.providers &&
            <FormError>{formik.errors.providers}</FormError>
          }
        </div>
      </div>
      <InfoModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        title={isCreating ?
          'Producto creado' :
          'Datos guardados'
        }
        subtitle={isCreating ?
          'Creado correctamente' :
          'Datos guardados correctamente'
        }
        onAccept={onAcceptCallback}
      />
    </div>
  );
});
