export const ORDER_BASKET = 'numentech::order-basket';

export const getOrderBasket = () => {
  const stringifiedBasket = window.localStorage.getItem(ORDER_BASKET)
  if (!stringifiedBasket) {
    setOrderBasket([]);
    return [];
  }
  return JSON.parse(stringifiedBasket);
};

export const setOrderBasket = (orders) => {
  window.localStorage.setItem(ORDER_BASKET, JSON.stringify(orders));
};

export const addProduct = ({
  product,
  quantity,
  currencyIsoCode,
  originalUnitPrice,
  discountedUnitPrice,
  discountType,
  discountAmount,
  discountExtra,
}) => {
  const basket = getOrderBasket();
  const foundProduct = basket.find(p => p.id === product.id);
  if (!foundProduct) {
    basket.push({
      ...product,
      quantity,
      currencyIsoCode,
      originalUnitPrice,
      discountedUnitPrice,
      discountType,
      discountAmount,
      discountExtra,
    });
  } else {
    foundProduct.quantity = quantity;
    foundProduct.originalUnitPrice = originalUnitPrice;
    foundProduct.discountedUnitPrice = discountedUnitPrice;
    foundProduct.discountType = discountType;
    foundProduct.discountAmount = discountAmount;
    foundProduct.discountExtra = discountExtra;
  }
  setOrderBasket(basket);
  return basket;
};

export const removeProduct = ({ productId }) => {
  const basket = getOrderBasket();
  const filteredBasket = basket.filter(item => item?.id !== productId);
  setOrderBasket(filteredBasket);
  return filteredBasket;
};

export const basketIsEmpty = () => getOrderBasket().length === 0;
