import React from 'react';

export default ({ children }) => (
  <span className="accordion-content-desc"
    style={{
      display: 'block',
      marginTop: 50,
      marginBottom: 50,
      width: '100%',
      textAlign: 'center',
    }}>
    {children}
  </span>
);
