import React, { useState } from 'react';
import Modal from 'react-modal';
import { useMutation } from 'react-query';

import { customStyles } from '../../lib/modals';
import { post } from '../../lib/restClient';
import { URLS } from '../../lib/api';
import { Input } from '../Inputs';
import FormError from '../FormError';

Modal.setAppElement('#root')

export default ({ isOpen, setIsOpen }) => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);
  const { mutate: changePassword } = useMutation(
    () => post(URLS.AUTH.CHANGE_PASSWORD(), { password }),
    {
      onSuccess: () => {
        closeModal();
      },
      onError: (error) => {
        setMessage(error.response.data.errors.password[0]);
        console.log('ERROR', error);
      }
    }
  );
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-container">
        <div className="modal-content action-modal">
          <header className="modal-header header-icon">
            <h2 className="header-icon-title">Cambiar contraseña</h2>
          </header>
          <div className="action-modal-content">
            <p><strong>Rellene los siguientes campos:</strong></p>
            <p>Para tu seguridad, recomendamos que cambies tu contraseña de acceso.</p>
            <form>
              <fieldset>
                <div className="form-item">
                  <label className="form-label">Nueva contraseña</label>
                  <Input
                    type="password"
                    id="txtKey"
                    name="txtKey"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        changePassword();
                      }
                    }}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    resetId="btnResetKey"
                    resetName="btnResetKey"
                  />
                </div>
                <FormError>{message}</FormError>
                <button type="button" id="btnAccept" name="btnAccept" className="btn-type1" onClick={(e) => {
                  e.preventDefault();
                  changePassword();
                }}>
                  Cambiar contraseña
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}
