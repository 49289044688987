import DOMPurify from 'dompurify';

export const useDocumentPurifier = () => {
  const ALLOWED_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ol', 'ul', 'li', 'p', 'a', 'span', 'b', 'i', 'strong',]

  const purifyDocument = (content) => {
    return DOMPurify.sanitize(content, { ALLOWED_TAGS })
  }

  return purifyDocument
}