import React from 'react';

const Assignment = () => {
  return (
    <>
      <form action="#" method="post">


        <div className="box main-header-cms">
          <div className="main-header-cms-top">
            <div className="main-header-cms-title">
              <span className="header-cms-icon icon-assignments"></span>
              <h1 className="header-cms-title">
                Cesiones
                <span>Relación de las cesiones de Numentech</span>
                <span className="cms-title-detail">250 cesiones</span>
              </h1>
            </div>

            <div className="main-header-cms-actions">


              <div className="header-cms-actions-buttons">
                <button type="button" id="btnFilters" name="btnFilters" className="btn-cms-actions"
                  onclick="this.classList.add('active');document.getElementById('filters').classList.add('show');">

                  Filtros
                </button>
                <button type="button" id="btnDownload" name="btnDownload" className="btn-cms-actions">
                  Descargar CSV <span className="icon-download-filters"></span>
                </button>
              </div>
            </div>
          </div>
        </div>




        <div className="box">


          <div className="cms-filters" id="filters">
            <h2 className="cms-filters-title">Filtros</h2>
            <button type="button" id="btnCloseFilters" name="btnCloseFilters" className="btn-close-filters"
              onclick="document.getElementById('btnFilters').classList.remove('active');document.getElementById('filters').classList.remove('show');">
              <span className="icon-close-modal"></span>
            </button>

            <div className="form-item-row form-row-special">
              <div className="form-item filter-size2">
                <label className="form-label">ID Cesión</label>

                <div className="form-text-container">
                  <input type="text" id="txtId" name="txtId" className="form-text" />
                  <button type="button" id="btnResetId" name="btnResetId" className="btn-reset">
                    <span className="icon-close"></span>
                  </button>
                </div>
              </div>

              <div className="form-item item-special-size2">
                <label className="form-label">Fecha de alta</label>

                <div className="form-text-container-two">

                  <div className="form-text-container">
                    <input type="text" id="txtFrom" name="txtFrom" className="form-text" readonly placeholder="Desde" />
                    <button type="button" id="btnCalendarFrom" name="btnCalendarFrom" className="btn-calendar">
                      <span className="icon-calendar"></span>
                    </button>
                  </div>

                  <div className="form-text-container">
                    <input type="text" id="txtTo" name="txtTo" className="form-text" readonly placeholder="Hasta" />
                    <button type="button" id="btnCalendarTo" name="btnCalendarTo" className="btn-calendar">
                      <span className="icon-calendar"></span>
                    </button>
                  </div>

                </div>
              </div>

              <div className="form-item item-special-size2">
                <label className="form-label">Estado</label>

                <div className="form-text-container">
                  <select id="cmbStatus" name="cmbStatus" className="form-select">
                    <option>Todos</option>
                  </select>
                  <span className="icon-arrow select-arrow"></span>
                </div>
              </div>

            </div>



            <div className="form-item-row form-row-special">




              <div className="form-item item-special-size1">
                <label className="form-label">Partners</label>

                <div className="form-text-container">
                  <select id="cmbPartner" name="cmbPartner" className="form-select">
                    <option>Seleccionar partners</option>
                    <option>Partner 1</option>
                  </select>
                  <span className="icon-arrow select-arrow"></span>
                </div>
              </div>



              <div className="form-item filter-size2">
                <label className="form-label">ID Pedido</label>

                <div className="form-text-container">
                  <input type="text" id="txtIdOrder" name="txtIdOrder" className="form-text" />
                  <button type="button" id="btnResetIdOrder" name="btnResetIdOrder" className="btn-reset">
                    <span className="icon-close"></span>
                  </button>
                </div>

              </div>





            </div>



          </div>




          <div className="responsive-table full-table">
            <table className="table-type2">
              <thead>
                <tr>
                  <th>Id cesión
                    <button type="button" id="btnOrderItem1" name="btnOrderItem1" className="btn-order up">
                      <span className="icon-arrow2"></span>
                    </button>
                  </th>
                  <th>fecha de alta
                    <button type="button" id="btnOrderItem2" name="btnOrderItem2" className="btn-order">
                      <span className="icon-arrow2"></span>
                    </button>
                  </th>
                  <th>Estado
                    <button type="button" id="btnOrderItem3" name="btnOrderItem3" className="btn-order">
                      <span className="icon-arrow2"></span>
                    </button>
                  </th>
                  <th>Nombre partner
                    <button type="button" id="btnOrderItem4" name="btnOrderItem4" className="btn-order">
                      <span className="icon-arrow2"></span>
                    </button>
                  </th>
                  <th>Id pedido
                    <button type="button" id="btnOrderItem5" name="btnOrderItem5" className="btn-order">
                      <span className="icon-arrow2"></span>
                    </button>
                  </th>
                  <th className="centered">Acciones</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1212321</td>
                  <td>21/06/20</td>
                  <td>
                    <span className="status-tag future">Venta futura</span>
                  </td>
                  <td>MediaMarkt</td>
                  <td><a href="#">00152487</a></td>
                  <td className="centered">
                    <button type="button" id="btnShowItem1" name="btnShowItem1" className="btn-icon-border">
                      <span className="icon-edit"></span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>1212321</td>
                  <td>21/06/20</td>
                  <td>
                    <span className="status-tag reserved">Reservado</span>
                  </td>
                  <td>Worten</td>
                  <td><a href="#">00152487</a></td>
                  <td className="centered">
                    <button type="button" id="btnShowItem2" name="btnShowItem2" className="btn-icon-border">
                      <span className="icon-edit"></span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>1212321</td>
                  <td>21/06/20</td>
                  <td>
                    <span className="status-tag sold-out">Vendido</span>
                  </td>
                  <td>Fnac</td>
                  <td><a href="#">00152487</a></td>
                  <td className="centered">
                    <button type="button" id="btnShowItem3" name="btnShowItem3" className="btn-icon-border">
                      <span className="icon-edit"></span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>1212321</td>
                  <td>21/06/20</td>
                  <td>
                    <span className="status-tag test">Vers. prueba</span>
                  </td>
                  <td>Fnac</td>
                  <td><a href="#">00152487</a></td>
                  <td className="centered">
                    <button type="button" id="btnShowItem4" name="btnShowItem4" className="btn-icon-border">
                      <span className="icon-edit"></span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>1212321</td>
                  <td>21/06/20</td>
                  <td>
                    <span className="status-tag free">Vers. gratis</span>
                  </td>
                  <td>Fnac</td>
                  <td><a href="#">00152487</a></td>
                  <td className="centered">
                    <button type="button" id="btnShowItem5" name="btnShowItem5" className="btn-icon-border">
                      <span className="icon-edit"></span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>1212321</td>
                  <td>21/06/20</td>
                  <td>
                    <span className="status-tag sold-out">Vendido</span>
                  </td>
                  <td>Fnac</td>
                  <td><a href="#">00152487</a></td>
                  <td className="centered">
                    <button type="button" id="btnShowItem6" name="btnShowItem6" className="btn-icon-border">
                      <span className="icon-edit"></span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>1212321</td>
                  <td>21/06/20</td>
                  <td>
                    <span className="status-tag sold-out">Vendido</span>
                  </td>
                  <td>Fnac</td>
                  <td><a href="#">00152487</a></td>
                  <td className="centered">
                    <button type="button" id="btnShowItem7" name="btnShowItem7" className="btn-icon-border">
                      <span className="icon-edit"></span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>1212321</td>
                  <td>21/06/20</td>
                  <td>
                    <span className="status-tag sold-out">Vendido</span>
                  </td>
                  <td>Fnac</td>
                  <td><a href="#">00152487</a></td>
                  <td className="centered">
                    <button type="button" id="btnShowItem8" name="btnShowItem8" className="btn-icon-border">
                      <span className="icon-edit"></span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>1212321</td>
                  <td>21/06/20</td>
                  <td>
                    <span className="status-tag sold-out">Vendido</span>
                  </td>
                  <td>Fnac</td>
                  <td><a href="#">00152487</a></td>
                  <td className="centered">
                    <button type="button" id="btnShowItem9" name="btnShowItem9" className="btn-icon-border">
                      <span className="icon-edit"></span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>1212321</td>
                  <td>21/06/20</td>
                  <td>
                    <span className="status-tag sold-out">Vendido</span>
                  </td>
                  <td>Fnac</td>
                  <td><a href="#">00152487</a></td>
                  <td className="centered">
                    <button type="button" id="btnShowItem10" name="btnShowItem10" className="btn-icon-border">
                      <span className="icon-edit"></span>
                    </button>
                  </td>
                </tr>


              </tbody>
            </table>
          </div>


        </div>



        <div className="pager">
          <ul>
            <li className="pager-prev">Anterior</li>
            <li className="pager-active">1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li>6</li>
            <li className="pager-next">Siguiente</li>
          </ul>
        </div>


      </form>
    </>
  );
};

export default Assignment;