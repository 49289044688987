import React from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../lib/modals';

Modal.setAppElement('#root')

export default ({ isOpen, setIsOpen }) => {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="modal-container">

        <div className="modal-content terms-modal">

          <button type="button" id="btnCloseModal" name="btnCloseModal" className="btn-close-modal"
            onClick={closeModal}>
            <span className="icon-close-modal"></span>
          </button>

          <h2 className="modal-title-type1">Política de Cookies</h2>

          <div className="modal-content-scroll">
            <h1>Política de cookies</h1>

            <p><em>www.numentech.es</em> utiliza una tecnología denominada “cookies” para recabar información acerca del uso de nuestro sitio web.</p>

            <p><strong>¿Qué son las cookies?</strong></p>

            <p>Una cookie es un archivo que se descarga en su equipo terminal con la finalidad de almacenar datos que podrán ser actualizados y recuperados por la entidad responsable de su instalación.</p>

            <p><strong>Consentimiento</strong></p>

            <p>Le informamos de que podemos utilizar cookies en su equipo a condición de que Usted haya dado su consentimiento, salvo en los supuestos en los que las cookies sean necesarias para la navegación por nuestro sitio web. En caso de que Usted preste su consentimiento, podremos utilizar cookies que nos permitirán tener más información acerca de sus preferencias y personalizar nuestro sitio web de conformidad con sus intereses individuales.</p>

            <p><strong>¿Para qué se utilizan las cookies en este sitio web?</strong></p>

            <p>Utilizamos cookies propias y de terceros para mejorar nuestros servicios, personalizar nuestro sitio web, facilitar la navegación de nuestros usuarios, proporcionarle una mejor experiencia en el uso del sitio web, identificar problemas para mejorar el mismo, hacer mediciones y estadísticas de uso y mostrarle publicidad relacionada con sus preferencias mediante el análisis del uso del sitio web.</p>

            <p><strong>Retirar el consentimiento</strong></p>

            <p>El usuario podrá retirar en cualquier momento su consentimiento relacionado con la Política de Cookies, y podrá eliminar las cookies almacenadas en su equipo a través de los ajustes y configuraciones de su navegador de Internet, indicados anteriormente.</p>

            <p><strong>Cambios en la Política de Cookies</strong></p>

            <p>La presente Política de Cookies se podrá modificar cuando así lo exija la legislación vigente en cada momento o cuando hubiera alguna variación en el tipo de cookies utilizadas en el sitio web. Por ello, le recomendamos revisar esta política cada vez que acceda a nuestro sitio web con el objetivo de estar adecuadamente informado sobre cómo y para qué usamos las cookies.</p>

            <p><strong>Contacto</strong><br />
              En caso de duda, comentario o sugerencia sobre la Política de Cookies, el usuario podrá dirigirse a la siguiente dirección: dpo@numentech.es</p>

            <p><strong>¿Qué tipo de cookies utiliza esta web? </strong></p>

            <p>Según la finalidad perseguida:</p>

            <p><em>Cookies Técnicas o Funcionales: </em>Son aquéllas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción, utilizar elementos de seguridad durante la navegación.</p>

            <p><em>Cookies de Análisis o de Performance:</em> Son aquéllas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios del sitio web al que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad del sitio web, plataforma o aplicación y para la elaboración de perfiles de navegación de los usuarios de dicho sitio web, plataforma o aplicación, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios.</p>

            <p><em>Cookies de Tracking o Marketing:</em> Son aquéllas que permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en la página web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web.</p>

            <p>En función de su duración las cookies pueden ser:</p>

            <p><em>De sesión:</em> Son un tipo de cookies diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Estas cookies no quedan almacenada en el ordenador del usuario cuando caduca la sesión o este cierra el navegador.</p>

            <p><em>Persistentes:</em> Son un tipo de cookies en el que los datos siguen almacenados en el ordenador del usuario y pueden ser accedidos y tratados cuando el usuario abandona la página web y cuando se vuelva a conectar a ella. Estas cookies pueden ser borradas en cualquier momento por el Usuario.</p>

            <p>En función de la entidad que las gestione:</p>

            <p><em>Cookies propias:</em> Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el titular de la página web y desde la que se presta el servicio solicitado por el usuario.<br /><em>Cookies de tercero: </em>Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el titular de la página web desde la que se presta el servicio solicitado por el usuario, sino por otra entidad que trata los datos obtenidos través de las cookies. Asimismo, en el caso de que las cookies sean instaladas desde un equipo o dominio gestionado por el propio titular del sitio web pero la información que se recoja mediante éstas sea gestionada por un tercero, también serán consideradas como cookies de terceros.</p>

            <p>El Sitio Web recoge información del usuario mediante la instalación de las siguientes cookies en el disco duro del visitante. Nuestro sitio web se escanea con nuestra herramienta de escaneo de cookies con regularidad para mantener una lista lo más precisa posible.</p>

            <p>A continuación, encontrará una lista detallada de las cookies que empleamos en nuestro Sitio web:</p>

            <div className="responsive-table">
              <table className="table-type1">
                <thead>
                  <tr>
                    <th>Propiedad</th>
                    <th>Tipo</th>
                    <th>Nombre</th>
                    <th>Finalidad</th>
                    <th>Duración</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      DRUPAL.COM
                                </td>
                    <td>
                      TÉCNICA
                                </td>
                    <td>
                      <strong>cookieconsent_ dismissed</strong>
                    </td>
                    <td>
                      Cookie usada para recordar la aceptación de la política de cookies por parte del usuario.
                                </td>
                    <td>
                      1 AÑO
                                </td>
                  </tr>
                  <tr>
                    <td>
                      VIMEO.COM
                                </td>
                    <td>
                      TÉCNICA/ VIDEO
                                </td>
                    <td>
                      <strong>PLAYER</strong>
                    </td>
                    <td>
                      Sirve para recordar la modalidad de visualización de video del usuario.
                                </td>
                    <td>
                      1 AÑO
                                </td>
                  </tr>
                  <tr>
                    <td>
                      VIMEO.COM
                                </td>
                    <td>
                      TÉCNICA/ VIDEO
                                </td>
                    <td>
                      <strong>VUID</strong>
                    </td>
                    <td>
                      Sirve para asignar, de forma anónima, un identificativo individual al usuario que está visualizando el video.
                                </td>
                    <td>
                      1 AÑO
                                </td>
                  </tr>
                  <tr>
                    <td>
                      GOOGLE.COM
                                </td>
                    <td>
                      TÉCNICA
                                </td>
                    <td>
                      <strong>SIDCC</strong>
                    </td>
                    <td>
                      Contienen registros cifrados y firmados de forma digital del momento de inicio de sesión más reciente y del ID
                      de cuenta de Google de un usuario. La combinación de estas cookies permite bloquear muchos tipos de ataques, como
                      intentos de robo del contenido de los formularios que rellenas en páginas web.
                                </td>
                    <td>
                      1 AÑO
                                </td>
                  </tr>
                  <tr>
                    <td>
                      GOOGLE.COM
                                </td>
                    <td>
                      ANÁLISIS
                                </td>
                    <td>
                      <strong>OGPC</strong>
                    </td>
                    <td>
                      Sirve para proveer servicios y extraer información anónima sobre la navegación.
                                </td>
                    <td>
                      2 MESES
                                </td>
                  </tr>
                  <tr>
                    <td>
                      GOOGLE.COM
                                </td>
                    <td>
                      ANÁLISIS
                                </td>
                    <td>
                      <strong>_ga</strong>
                    </td>
                    <td>
                      Se utiliza para diferenciar a los usuarios.
                                </td>
                    <td>
                      2 AÑOS
                                </td>
                  </tr>
                  <tr>
                    <td>
                      GOOGLE.COM
                                </td>
                    <td>
                      ANÁLISIS
                                </td>
                    <td>
                      <strong>SEARCH_SAMESITE</strong>
                    </td>
                    <td>
                      Sirve para mitigar el riesgo de fuga de información de origen cruzado. También proporciona cierta protección
                      contra los ataques de falsificación de solicitudes entre sitios.
                                </td>
                    <td>
                      SESIÓN
                                </td>
                  </tr>
                  <tr>
                    <td>
                      GOOGLE.COM
                                </td>
                    <td>
                      TRACKING
                                </td>
                    <td>
                      <strong>_Secure-3PSID</strong>,<br />
                      <strong>_Secure-3PAPISID</strong>
                    </td>
                    <td>
                      Estas cookies se utilizan para manejar perfiles basados en el interés del usuario y así poder mostrarles
                      anuncios personalizados.
                                </td>
                    <td>
                      2 AÑOS
                                </td>
                  </tr>
                  <tr>
                    <td>
                      GOOGLE.COM
                                </td>
                    <td>
                      TRACKING
                                </td>
                    <td>
                      <strong>_Secure-3PSIDCC</strong>
                    </td>
                    <td>
                      Esta cookie se utiliza para manejar perfiles basados en el interés del usuario y así poder mostrarles
                      anuncios personalizados.
                                </td>
                    <td>
                      1 AÑO
                                </td>
                  </tr>
                  <tr>
                    <td>
                      GOOGLE.COM
                                </td>
                    <td>
                      TRACKING
                                </td>
                    <td>
                      <strong>ANID</strong>
                    </td>
                    <td>
                      Esta cookie se utiliza para manejar perfiles basados en el interés del usuario y así poder mostrarles
                      anuncios personalizados.
                                </td>
                    <td>
                      6 MESES
                                </td>
                  </tr>
                  <tr>
                    <td>
                      GOOGLE.COM
                                </td>
                    <td>
                      TRACKING
                                </td>
                    <td>
                      <strong>HSID</strong>,<br />
                      <strong>APISID</strong>,<br />
                      <strong>SID</strong>,<br />
                      <strong>SSID</strong>,<br />
                      <strong>SAPISID</strong><br />
                    </td>
                    <td>
                      Se almacenan en el equipo del usuario para que permanezca conectado a su cuenta de Google cuando vuelva a
                      visitar el servicio. Mientras esté autenticado y utilice plugins de otros sitios web, Google utiliza estas
                      cookies para mejorar su experiencia de usuario. Algunas de las cookies también se utilizan para mejorar
                      su experiencia de navegación.
                                </td>
                    <td>
                      2 AÑOS
                                </td>
                  </tr>
                  <tr>
                    <td>
                      GOOGLE.COM
                                </td>
                    <td>
                      TRACKING
                                </td>
                    <td>
                      <strong>1P_JAR</strong>
                    </td>
                    <td>
                      Cookie que transfiere datos a Google para hacer la publicidad más atractiva.
                                </td>
                    <td>
                      15 DÍAS
                                </td>
                  </tr>
                  <tr>
                    <td>
                      GOOGLE.COM
                                </td>
                    <td>
                      TRACKING
                                </td>
                    <td>
                      <strong>NID</strong>
                    </td>
                    <td>
                      Contiene un ID único que Google utiliza para recordar tus preferencias y otra información,
                      como tu idioma preferido.
                                </td>
                    <td>
                      6 MESES
                                </td>
                  </tr>
                  <tr>
                    <td>
                      GOOGLE.COM
                                </td>
                    <td>
                      TRACKING
                                </td>
                    <td>
                      <strong>CONSENT</strong>
                    </td>
                    <td>
                      Se utiliza para manejar perfiles basados en el interés del usuario y así poder mostrarles anuncios
                      personalizados.
                                </td>
                    <td>
                      18 MESES
                                </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
