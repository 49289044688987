import React, { useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useMutation } from 'react-query';

import { Input } from '../../components/Inputs';
import { Button, IconButton } from '../../components/Buttons';
import { Image } from '../../components/Image';
import FormError from '../../components/FormError';
import { URLS } from '../../lib/api';
import { setAuthToken, setUserType, setRequireChangePassword } from '../../lib/auth';
import { extractErrorMessageFromResponse } from '../../lib/utils';


const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Introduce correo electrónico válido')
    .required('Requerido'),
  password: Yup.string()
    .required('Requerido'),
});

const Home = () => {
  const [message, setMessage] = useState('');
  const { mutate: login } = useMutation(
    loginBody => {
      setMessage('');
      return axios.post(URLS.AUTH.LOGIN(), loginBody);
    },
    {
      onSuccess: (response) => {
        const { data: { token, type, requireChangePassword, firstLogin } } = response;

        console.log({ data: response.data });
        console.log(requireChangePassword);
        console.log(firstLogin);
        setAuthToken(token);
        setUserType(type);
        if (requireChangePassword) {
          setRequireChangePassword(true);
        }
        if (type === 'CUSTOMER') {
          if (requireChangePassword) {
            window.location.href = '/clients/my-account';
            return;
          }
          window.location.href = '/clients/my-products';
        } else if (type === 'PARTNER') {
          if (requireChangePassword) {
            window.location.href = '/partners/my-account';
            return;
          }
          window.location.href = '/partners/products';
        } else {
          window.location.href = '/';
        }
      },
      onError: (error) => {
        console.log('ERROR', error);
        console.log('ERROR');
        const { response: { data: { message } } } = error;
        if (message === 'exception.userNotValidated') {
          setMessage('Email aún no valido, pendiente de confirmar por Numentech.');
        } else if (message.indexOf('incorrecta') !== -1) {
          setMessage(message);
        } else if (extractErrorMessageFromResponse(error)) {
          setMessage(extractErrorMessageFromResponse(error));
        } else {
          setMessage('El email y/o contraseña introducido es incorrecto');
        }
      }
    }
  );
  return (
    <>
      <div className="access-left-col">
        <div className="access-form-bl">
          <Image icon="logo.svg" alt="Numentech" className="access-logo" />
          <h1 className="title">Acceso a tu Area Privada</h1>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={LoginSchema}
            onSubmit={(values) => {
              login(values);
            }}
            render={({
              values,
              errors,
              handleSubmit,
              setFieldValue
            }) => {
              return (
                <form action="#" method="post">
                  <fieldset className="access-fieldset">
                    <div className="form-item">
                      <label className="form-label">Email</label>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                        resetId="btnResetMail"
                        resetName="btnResetMail"
                      />
                      {errors.email && <FormError>{errors.email}</FormError>}
                    </div>

                    <div className="form-item">
                      <label className="form-label">Contraseña</label>
                      <Input
                        type="password"
                        id="password"
                        name="password"
                        value={values.password}
                        onChange={(e) => setFieldValue('password', e.target.value)}
                        resetId="btnResetPass"
                        resetName="btnResetPass"
                      />
                      {errors.password && <FormError>{errors.password}</FormError>}
                    </div>

                    {message && <FormError>{message}</FormError>}

                    <Link to="/remember-password" className="access-link">He olvidado mi contraseña</Link>

                    <Button type="submit" id="btnAccept" name="btnAccept" onClick={handleSubmit}>
                      Iniciar sesión
            </Button>

                  </fieldset>
                </form>
              );
            }} />
        </div>
        <div className="access-register-bl">
          <h2 className="title-type2">¿No estás registrado? ¡Únete ahora!</h2>
          <div className="access-btn-container">
            <Link to="/client-register">
              <IconButton type="button" id="btnClient" name="btnClient" icon="ic-client">
                Como Cliente
            </IconButton>
            </Link>
            <Link to="/partner-register">
              <IconButton type="button" id="btnPartner" name="btnPartner" icon="ic-partner">
                Como Partner
            </IconButton>
            </Link>
          </div>
        </div>
      </div>
      <div className="access-right-col">
        <Image icon="loginnumen.jpg" />
      </div>
    </>
  );
};

export default Home;