import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { logout } from '../../lib/auth';
import { useQuery } from 'react-query';
import { get } from '../../lib/restClient';
import { URLS } from '../../lib/api';

export default ({ children }) => {
  const { data = {} } = useQuery('userMe', async () => {
    const response = await get(URLS.AUTH.ME());
    return response.data;
  });
  const history = useHistory();
  const [path, setPath] = useState(window.location.href);
  const isMyAccount = path.indexOf('/partners/my-account') !== -1;
  const isProducts = path.indexOf('/partners/products') !== -1;
  const isClients = path.indexOf('/partners/clients') !== -1;
  const isOrders = path.indexOf('/partners/orders') !== -1;
  return (
    <div>
      <div className="cms-container">
        <nav className="cms-menu">
          <button type="button" id="btnMenu" name="btnMenu" className="btn-menu"
            onClick={() => document.body.classList.toggle('small-menu')}>
            <span className="icon-menu"></span>
          </button>
          <ul className="cms-menu-list">
            <li className={`icon-profile ${isMyAccount && 'active'}`} onClick={() => {
              history.push('/partners/my-account')
              setPath(window.location.href);
            }}>
              <span>Perfil</span>
            </li>
            <li className={`icon-orders ${isOrders && 'active'}`} onClick={() => {
              history.push('/partners/orders')
              setPath(window.location.href);
            }}>
              <span>Pedidos</span>
            </li>
            <li className={`icon-products ${isProducts && 'active'}`} onClick={() => {
              history.push('/partners/products')
              setPath(window.location.href);
            }}>
              <span>Productos</span>
            </li>
            <li className={`icon-clients ${isClients && 'active'}`} onClick={() => {
              history.push('/partners/clients')
              setPath(window.location.href);
            }}>
              <span>Clientes</span>
            </li>
          </ul>
        </nav>
        <div className="cms-content">
          <header className="header-cms">
            <div className="info-logout">
              <span className="cms-header-user">{data.firstName} {data.lastName}</span>
              <button type="button" id="btnLogout" name="btnLogout" className="btn-logout-cms" onClick={() => logout()}>
                <span className="icon-logout-cms"></span>
              </button>
            </div>
          </header>

          {children}
        </div>
      </div>
    </div >
  );
};
