import React, { useState } from 'react';

import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { Input } from '../../components/Inputs';
import { Button } from '../../components/Buttons';
import { Image } from '../../components/Image';
import FormError from '../../components/FormError';
import TextModal from '../../components/TextModal';
import { URLS } from '../../lib/api';
import { extractErrorMessageFromResponse } from '../../lib/utils';

const RememberSchema = Yup.object().shape({
  password: Yup.string()
    .required('Requerido'),
});

const ResetPassword = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { id, token } = useParams();

  const { mutate: resetPass } = useMutation(
    ({ password }) => axios.put(URLS.AUTH.PASSWORD_RESET({ id }), { token, password }),
    {
      onSuccess: () => {
        setModalIsOpen(true);
      },
      onError: (error) => {
        if (extractErrorMessageFromResponse(error)) {
          setErrorMessage(extractErrorMessageFromResponse(error));
          return;
        }
        setErrorMessage(error.response.data.errors.password[0]);
        console.log('ERROR', error);
      }
    }
  )
  return (
    <>
      <div className="access-form-bl access-pass">
        <Link to="/"><Image icon="logo.svg" alt="Numentech" className="access-logo" /></Link>
        <h1 className="title">Recuperar contraseña</h1>
        <p className="form-desc">Puedes cambiar tu contraseña por motivos de seguridad o recuperarla si la olvidas</p>
        <Formik
          initialValues={{
            password: '',
          }}
          validationSchema={RememberSchema}
          onSubmit={(values) => {
            resetPass(values);
          }}
          render={({
            values,
            errors,
            handleSubmit,
            setFieldValue
          }) => (
              <form action="#" method="post">
                <fieldset className="access-fieldset">
                  <div className="form-item">
                    <label className="form-label">Nueva contraseña</label>
                    <Input
                      type="password"
                      id="password"
                      name="password"
                      value={values.password}
                      onChange={(e) => setFieldValue('password', e.target.value)}
                    />
                    {errors.email && <FormError>{errors.email}</FormError>}
                  </div>

                  {errors.password && <FormError>{errors.password}</FormError>}
                  {errorMessage && <FormError>{errorMessage}</FormError>}

                  <Button type="submit" id="btnChangePass" name="btnChangePass" onClick={handleSubmit}>
                    Cambiar contraseña
                  </Button>
                </fieldset>
              </form>
            )}
        />
      </div>
      <div className="access-right-col">
        <Image icon="loginnumen.jpg" />
      </div>
      <TextModal
        isOpen={modalIsOpen}
        text="Contraseña cambiada."
        onAccept={() => window.location.href = '/'}
      />
    </>
  );
};

export default ResetPassword;