import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import ReactSelect from 'react-select';
import * as Yup from 'yup';
import Loader from '../../../../components/Loader';
import {get, put} from '../../../../lib/restClient';
import {URLS} from '../../../../lib/api';
import {Input} from '../../../../components/Inputs';
import InfoModal from '../../../../components/InfoModal';
import FormError from '../../../../components/FormError';
import {useFormik} from 'formik';
import {useParams} from 'react-router-dom';
import {groupBy} from '../../../../lib/utils';


const initialValues = {
    plan: '',
    type: '',
    averageRevenue: '',
    assistantService: false,
    assistantServiceProvider: '',
    status: '',
    motivation: '',
    marketsOfReference: [],
};

const selectContainerStyles = {
    menu: () => ({
        backgroundColor: 'rgba(243, 246, 248, .5)',
    }),
    valueContainer: () => ({
        backgroundColor: 'rgba(243, 246, 248, .5)',
        height: 43,
        paddingLeft: 10,
    }),
    indicatorSeparator: () => ({
        backgroundColor: 'red',
        color: 'red',
    }),
    dropdownIndicator: () => ({
        color: '#A21C22',
        marginRight: '10px',
        marginTop: '3px',
    })
};

const ValidationSchema = Yup.object().shape({
    type: Yup.string()
        .required('Requerido'),
    plan: Yup.string(),
    averageRevenue: Yup.string().required('Requerido'),
    assistantService: Yup.boolean().required('Requerido'),
    assistantServiceProvider: Yup.string().optional(),
    status: Yup.string(),
    motivation: Yup.string().required('Requerido'),
    marketsOfReference: Yup.array()
        .required('Requerido'),
});

export default forwardRef((props, ref) => {
        const [alreadyFetched, setAlreadyFetched] = useState(false);
        const {partnerId} = useParams();
        const [showModal, setShowModal] = useState(false);
        const {mutate: setCategorization} = useMutation(
            (values) => {
                return put(URLS.PARTNER.SET_CATEGORIZE({partnerId}), values);
            },
            {
                onSuccess: async () => {
                    setShowModal(true);
                },
                onError: (error) => {
                    console.log('ERROR', error);
                }
            }
        );
        const formik = useFormik({
            validationSchema: ValidationSchema,
            initialValues,
            enableReinitialize: true,
            onSubmit: (values) => setCategorization(values),
        });
        const {isLoading, error, data} = useQuery('getStaticDataForClasification', async () => {
                    const revenueTypesResponse = await get(URLS.PARTNER.REVENUE_TYPES());
                    const partnerPlansResponse = await get(URLS.PARTNER.PARTNER_PLANS());
                    const partnerTypesResponse = await get(URLS.PARTNER.PARTNER_TYPES());
                    const partnerStatusResponse = await get(URLS.PARTNER.PARTNER_STATUS());
                    const countriesListResponse = await get(URLS.COUNTRIES.LIST());
                    const partnerResponse = await get(URLS.PARTNER.GET_CATEGORIZE({partnerId}));
                    const assistantServiceProvidersResponse = await get(URLS.ADMIN.LIST_PARTNERS({
                        sort: {
                            field: 'organizationName',
                            order: 'asc',
                        },
                        filters: {
                            status: 'approved',
                            assistantService: 'true',
                        }
                    }));

                    if (!alreadyFetched) {
                        formik.setFieldValue('plan', partnerResponse?.data?.plan?.id);
                        formik.setFieldValue('assistantService', partnerResponse?.data?.assistantService);
                        formik.setFieldValue('assistantServiceProvider', partnerResponse?.data?.assistantServiceProvider?.id);
                        formik.setFieldValue('status', partnerResponse?.data?.status.id);
                        if (partnerResponse?.data?.type?.id) {
                            formik.setFieldValue('type', partnerResponse?.data?.type?.id);
                        }
                        if (partnerResponse?.data?.averageRevenue?.id) {
                            formik.setFieldValue('averageRevenue', partnerResponse?.data?.averageRevenue?.id);
                        }
                        if (partnerResponse?.data?.motivation) {
                            formik.setFieldValue('motivation', partnerResponse?.data?.motivation);
                        }
                        formik.setFieldValue('marketsOfReference', partnerResponse?.data?.marketsOfReference.map(e => e.iso_code));
                        setAlreadyFetched(true);
                    }
                    let grouped = groupBy(countriesListResponse.data, 'continent');
                    const countries = [];
                    Object.keys(grouped).forEach(continent => {
                        countries.push({
                            label: continent,
                            options: grouped[continent].map(country => ({
                                value: country.iso_code,
                                label: country.name,
                            })),
                        })
                    });
                    return {
                        revenueTypes: revenueTypesResponse.data,
                        partnerPlans: partnerPlansResponse.data,
                        partnerTypes: partnerTypesResponse.data,
                        partnerStatus: partnerStatusResponse.data,
                        countries,
                        rawCountries: countriesListResponse.data,
                        partner: partnerResponse.data,
                        assistantServiceProviders: assistantServiceProvidersResponse.data,
                    };
                }
            )
        ;
        useImperativeHandle(ref, () => ({
            update() {
                formik.handleSubmit();
            }
        }));

        if (error) {
            return <span>error</span>
        }
        if (isLoading) {
            return <Loader/>;
        }

        return (
            <div className="box form-box">
                <h2 className="title-type6">Calificación y análisis</h2>

                <div className="form-item-row">
                    <div className="form-item-col">
                        <div className="form-item form-item-two">
                            <div className="form-item">
                                <label className="form-label">Tipo *</label>
                                <div className="form-text-container">
                                    <select id="cmbType" name="cmbType" className="form-select"
                                            onChange={(e) => formik.setFieldValue('type', e.target.value)}>
                                        <option value={-1}></option>
                                        {data.partnerTypes?.map(pt => (
                                            <option key={pt.id}
                                                    selected={
                                                        pt.id === data?.partner?.type ||
                                                        pt.id === data?.partner?.type?.id
                                                    }
                                                    value={pt.id}>{pt.name}</option>
                                        ))}
                                    </select>
                                    <span className="icon-arrow select-arrow"></span>
                                </div>
                                {formik?.touched?.type && formik?.errors?.type &&
                                    <FormError>{formik?.errors?.type}</FormError>
                                }
                            </div>

                            <div className="form-item">
                                <label className="form-label">Status</label>

                                <div className="form-text-container">
                                    <select id="cmbStatus" name="cmbStatus" className="form-select"
                                            onChange={(e) => formik.setFieldValue('plan', e.target.value)}>
                                        <option value={-1}></option>
                                        {data.partnerPlans?.map(plan => (
                                            <option key={plan.id}
                                                    selected={
                                                        plan.id === data?.partner?.plan ||
                                                        plan.id === data?.partner?.plan?.id
                                                    }
                                                    value={plan.id}>{plan.name}</option>
                                        ))}
                                    </select>
                                    <span className="icon-arrow select-arrow"></span>
                                </div>
                            </div>
                            {formik?.touched?.plan && formik?.errors?.plan &&
                                <FormError>{formik?.errors?.plan}</FormError>
                            }
                        </div>

                        <div className="form-item">
                            <label className="form-label">Promedio de ingresos *</label>

                            <div className="form-text-container">
                                <select id="cmbIncome" name="cmbIncome" className="form-select"
                                        onChange={(e) => formik.setFieldValue('averageRevenue', e.target.value)}>
                                    <option value={-1}></option>
                                    {data.revenueTypes?.map(revenueType => (
                                        <option key={revenueType.id}
                                                selected={
                                                    revenueType.id === data?.partner?.averageRevenue ||
                                                    revenueType.id === data?.partner?.averageRevenue?.id
                                                }
                                                value={revenueType.id}>
                                            {revenueType.name}
                                        </option>
                                    ))}
                                </select>
                                <span className="icon-arrow select-arrow"></span>
                            </div>
                        </div>
                        {formik?.touched?.assistantService && formik?.errors?.averageRevenue &&
                            <FormError>{formik?.errors?.averageRevenue}</FormError>
                        }

                        <div className="form-item">
                            <div className="form-item-checkbox">
                                <input type="checkbox" id="chPartner" name="chPartner"
                                       checked={formik?.values?.assistantService}
                                       onChange={() => {
                                           if (!formik?.values?.assistantService) {
                                               formik.setFieldValue('assistantServiceProvider', '');
                                           }
                                           formik.setFieldValue('assistantService', !(formik?.values?.assistantService))
                                       }}/>
                                <label htmlFor="chPartner" className="small-label">Partner con servicio de
                                    asistencia</label>
                            </div>
                        </div>

                        {!formik?.values?.assistantService &&
                            <div className="form-item">
                                <label className="form-label">Servicio de asistencia</label>

                                <div className="form-text-container">
                                    <select id="cmbServices" name="cmbServices" className="form-select"
                                            onChange={(e) => formik.setFieldValue('assistantServiceProvider', e.target.value)}>
                                        <option value={-1}></option>
                                        {data.assistantServiceProviders?.map(assistantServiceProvider => (
                                            <option key={assistantServiceProvider.id}
                                                selected={assistantServiceProvider.id === data?.partner?.assistantServiceProvider?.id}
                                                value={assistantServiceProvider.id}>
                                                {assistantServiceProvider.organizationName}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="icon-arrow select-arrow"></span>
                                </div>
                                {formik?.touched?.assistantService && formik?.errors?.assistantService &&
                                    <FormError>{formik?.errors?.assistantService}</FormError>
                                }
                            </div>
                        }
                    </div>

                    <div className="form-item-col">
                        <div className="form-item">
                            <label className="form-label">Estado de la candidatura *</label>

                            <div className="form-text-container">
                                <select id="cmbStatus2" name="cmbStatus2" className="form-select"
                                        placeholder='Seleccione Estado'
                                        onChange={(e) => formik.setFieldValue('status', e.target.value)}>
                                    <option disabled value={-1}> Seleccione Estado</option>
                                    {data.partnerStatus?.map(status => (
                                        <option key={status.id}
                                            selected={
                                                status.id === data?.partner?.status ||
                                                status.id === data?.partner?.status?.id
                                            }
                                            value={status.id}>{status.name}</option>
                                    ))}
                                </select>
                                <span className="icon-arrow select-arrow"></span>
                            </div>
                            {formik?.touched?.status && formik?.errors?.status &&
                                <FormError>{formik?.errors?.status}</FormError>
                            }
                        </div>

                        <div className="form-item">
                            <label className="form-label">Motivo *</label>
                            <Input type="text" id="txtReason" name="txtReason" className="form-text"
                                   value={formik?.values?.motivation}
                                   onChange={(e) => formik.setFieldValue('motivation', e.target.value)}/>
                            {formik?.touched?.motivation && formik?.errors?.motivation &&
                                <FormError>{formik?.errors?.motivation}</FormError>
                            }
                        </div>
                    </div>

                    <div className="form-item-col">
                        <div className="form-item">
                            <label className="form-label">Mercados de referencia *</label>

                            <ReactSelect
                                styles={selectContainerStyles}
                                placeholder="Selecciona paises"
                                onChange={(e) => {
                                    if (formik?.values?.marketsOfReference?.indexOf(e.value) === -1) {
                                        formik.setFieldValue(
                                            'marketsOfReference',
                                            [...formik?.values?.marketsOfReference, e.value],
                                        );
                                    }
                                }}
                                options={data?.countries}/>

                            {formik?.touched?.marketsOfReference && formik?.errors?.marketsOfReference &&
                                <FormError>{formik?.errors?.marketsOfReference}</FormError>
                            }
                        </div>

                        <div className="search-tags-container">
                            {formik?.values?.marketsOfReference?.map(countryIsoCode => (
                                <span key={countryIsoCode.name} className="search-tag icon-close-modal"
                                      onClick={() => {
                                          formik.setFieldValue(
                                              'marketsOfReference',
                                              formik?.values?.marketsOfReference.filter((e) => e !== countryIsoCode),
                                          );
                                      }}>
                {data?.rawCountries.find(e => e.iso_code === countryIsoCode).name}
              </span>
                            ))}
                        </div>

                    </div>
                </div>
                <InfoModal
                    isOpen={showModal}
                    setIsOpen={setShowModal}
                    title="Datos guardados"
                    subtitle="Datos guardados correctamente"
                />
            </div>
        );
    }
)
;
