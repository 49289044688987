import { addProduct, getOrderBasket, ORDER_BASKET, removeProduct } from '../lib/order-basket';
import useLocalStorage from './localStorage';

function useOrderBasket() {
  const [orderBasket, setOrderBasket] = useLocalStorage(
    ORDER_BASKET,
    getOrderBasket(),
  );

  function addProductToBasket({ product, quantity }) {
    const newBasket = addProduct({ product, quantity });
    setOrderBasket(newBasket);
  }

  function addOneMoreProductToBasket({ product }) {
    const newBasket = addProduct({ product, quantity: product?.quantity + 1 });
    setOrderBasket(newBasket);
  }

  function removeOneProductFromBasket({ product }) {
    const newBasket = addProduct({ product, quantity: product?.quantity - 1 });
    setOrderBasket(newBasket);
  }

  function removeProductFromBasket({ productId }) {
    const newBasket = removeProduct({ productId });
    setOrderBasket(newBasket);
  }

  function clearBasket() {
    setOrderBasket([]);
  }

  return {
    orderBasket,
    clearBasket,
    addProductToBasket,
    addOneMoreProductToBasket,
    removeOneProductFromBasket,
    removeProductFromBasket,
  };
}

export default useOrderBasket;
