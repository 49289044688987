import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../lib/modals';

Modal.setAppElement('#root')

export default ({ isOpen, setIsOpen, stepable, fullHeight = false }) => {
  const [step] = useState(0);
  function closeModal() {
    setIsOpen(false);
  }

  let modalContainerStyle = {};
  let modalContentStyle = {};
  if (fullHeight) {
    modalContainerStyle = {
      maxHeight: '100vh',
      borderRadius: 0,
    };
    modalContentStyle = {
      maxHeight: '100vh',
    };
  }
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="modal-container" style={modalContainerStyle}>

        <div className="modal-content terms-modal" style={modalContentStyle}>

          <button type="button" id="btnCloseModal" name="btnCloseModal" className="btn-close-modal"
            onClick={closeModal}>
            <span className="icon-close-modal"></span>
          </button>

          <h2 className="modal-title-type1">Términos y condiciones {stepable && <span>({step + 1} de 3)</span>}</h2>

          <div className="modal-content-scroll">
            Nombre de la Compañía: NUMEN TECHNOLOGIES, SLU<br />
Domicilio Social: Avenida Diagonal 401, 2o, 08008 BARCELONA (ESPAÑA)<br />
Correo electrónico: info@numentech.es<br />
CIF: B65405466<br />
Inscrita en el Registro Mercantil de Barcelona, en el Tomo 42249, folio 28, Hoja B402597, inscripción 1.<br />

            <h2>Aceptación de estos Términos y condición de Usuario:</h2>
            <p>El acceso y utilización de la intranet www.numentech.es (en adelante, la “Intranet”), titularidad de NUMEN, atribuyen la condición de Usuario de la página e implican la aceptación del presente AVISO LEGAL. Se entenderá por Usuario a la persona que acceda, navegue, utilice o participe en los servicios y actividades, gratuitas u onerosas, desarrolladas en la Intranet.</p>

            <h2>Licencia Limitada:</h2>
            <p>Usted puede acceder y ver el contenido de la Intranet desde su equipo, tableta o móvil o desde cualquier otro aparato y, a menos de que se indique de otra manera en estos Términos y Condiciones o en la Intranet, podrá sacar copias o impresiones individuales del contenido del Sitio Web para su uso personal, interno únicamente.</p>
            <p>El uso de la Intranet y de los servicios que se ofrecen en o a través de la Intranet, son sólo para su uso personal, no comercial.</p>
            <p>El presente Aviso Legal tiene como objeto establecer las Condiciones Generales que regulan el acceso y uso general de la citada página web por parte de todos los usuarios, por lo que el acceso y uso de ésta implica necesariamente, el sometimiento y aceptación de las Condiciones Generales citadas incluidas en este Aviso Legal.</p>

            <h2>Utilización de la Intranet y Propiedad Intelectual:</h2>
            <p>Corresponde a NUMEN la Propiedad Intelectual e Industrial de todos los elementos que forman la Intranet que sean objeto de estos términos, entendiendo por tales en especial los textos, gráficos, dibujos, diseños, códigos informáticos, software, fotografías, música, vídeos, sonidos, marcas comerciales, logotipos, bases de datos, imágenes, expresiones e informaciones y demás contenido protegible.</p>
            <p>El uso o la copia, parcial o total, con fines comerciales (incluida la reproducción, distribución, transformación y comunicación pública) no autorizados de los contenidos mencionados en el párrafo anterior quedan prohibidos, salvo que se cuente con la previa autorización expresa y por escrito de NUMEN.</p>
            <p>El acceso, navegación y uso de la Intranet es responsabilidad del Usuario, por lo que el Usuario se compromete a observar diligente y fielmente cualquier instrucción adicional impartida por NUMEN o por personal autorizado de NUMEN relativa al uso del SITIO WEB y de sus contenidos.</p>
            <p>Así mismo, se insta al Usuario a que comunique a NUMEN cualquier hecho del que tenga conocimiento y suponga una conducta contraria a derecho o vulnere derechos de cualquier tercero, a través de las herramientas de contacto y cualesquiera otras herramientas comunicativas disponibles en el SITIO WEB.</p>
            <p>La infracción de los derechos de Propiedad Intelectual e Industrial sobre el contenido de la Intranet, titularidad de NUMEN, por parte del Usuario o la utilización no autorizada de dichos elementos, tendrá como consecuencia la aplicación de las responsabilidades establecidas en la legislación vigente que resulte de aplicación.</p>

            <h2>Tratamiento de datos de carácter personal:</h2>
            <p>En lo que respecta a los deberes de información y consentimiento previo para la recopilación de datos de carácter personal por parte de NUMEN a través de los diversos formularios de contacto disponibles en el la Intranet, se recomienda al Usuario que visite la Política de Privacidad, donde encontrará información detallada en este sentido.</p>

            <h2>Materiales Presentados:</h2>
            <p>A menos que se solicite específicamente, no pedimos ni deseamos recibir ninguna información confidencial, secreta o patrimonial, ni otro material de usted a través de la Intranet, por correo electrónico o de cualquier otra manera.</p>

            <p>Cualquier "Materiales Presentados", información, trabajos creativos, demostración, ideas, sugerencias, conceptos, métodos, sistemas, diseños, planes, técnicas u otros materiales que nos haya mandado o presentado (incluyendo, por ejemplo y sin limitación, aquello que usted presenta o envía a nuestros grupos de chateo, tablas de mensajes y/o a nuestros ‘blogs’, o que nos manda vía correo electrónico) se considerará como no confidencial o secreto y que puede ser usado por nosotros de cualquier manera consistente con la Política de Privacidad de la Intranet.</p>

            <p>Al presentarnos o mandarnos Materiales usted: (i) representa y garantiza que los Materiales Presentados son originales suyos, que ninguna otra persona tiene ningún derecho sobre ellos, y que cualquier "derecho moral" sobre los Materiales Presentados ha sido renunciado y (ii) usted nos concede, a nosotros y a nuestros afiliados, derecho y licencia libres de regalías, sin restricciones, mundiales, perpetuos, irrevocables, no exclusivos y totalmente transferibles, que pueden ser cedidos y sub-licenciados, para usar, copiar, reproducir, modificar, adaptar, publicar, traducir, crear trabajos derivados de, distribuir, ejecutar, exponer e incorporar en otros trabajos cualquiera de los Materiales Presentados (todos o en parte) en cualquier forma, medio o tecnología no conocida o por desarrollar, incluyendo propósitos promocionales y/o comerciales. No podemos ser responsables de conservar ningún Material Presentado proporcionado por usted y podemos borrar o destruir dicho Material Presentado en cualquier momento.</p>

            <h2>Exención de responsabilidad y enlaces de terceros:</h2>
            <p>NUMEN no garantiza la licitud, fiabilidad, utilidad, veracidad, inexistencia de virus o componentes dañinos, o exactitud de los servicios o de la información que difunda a través de la Intranet. No obstante, NUMEN declara que ha adoptado todas las medidas necesarias, dentro de sus posibilidades y del estado de la tecnología, para garantizar el funcionamiento correcto y adecuado de la Intranet.</p>

            <p>En la Intranet es probable que el Usuario pueda encontrar enlaces a otras páginas web mediante diferentes botones, links, banners, etc., los cuales son gestionados por terceros. NUMEN no se hace responsable del contenido ni de ningún otro aspecto de los sitios webs a que tales links puedan enlazar. El establecimiento de cualquier tipo de enlace por parte de la Intranet a otro sitio web ajeno no implica que exista necesariamente algún tipo de relación empresarial, colaboración o dependencia entre NUMEN y el responsable del sitio web ajeno.</p>

            <h2>Acceso a la Intranet:</h2>
            <p>NUMEN informa que no se responsabiliza de la prestación de servicios y suministros de terceros, incluidos el transporte a través de redes de telecomunicaciones, la fiabilidad, calidad, continuidad y funcionamiento no le corresponde, y por lo que el acceso puede ser suspendido, cancelado o resultar inaccesible por motivos técnicos y especialmente en caso de fuerza mayor.</p>
            <p>Además, NUMEN se reserva el derecho a interrumpir en caso de que lo estime necesario el acceso a su página Intranet en cualquier momento y sin previo aviso, ya sea por motivos de seguridad, de control, de mantenimiento, por errores de suministro eléctrico o por cualquier otra causa. Si la interrupción tuviera carácter definitivo, se comunicará tal circunstancia a los usuarios, que podrán sufrir la pérdida, en su caso, de la información almacenada en los diferentes servicios.</p>
            <p>En consecuencia, en ningún caso NUMEN se responsabiliza de las consecuencias de eventuales interrupciones, ya que no puede garantizar la fiabilidad, la disponibilidad ni la continuidad de su página web.</p>
            <p>Por otra parte, el acceso a determinados servicios es exclusivo para nuestros clientes o personal laboral, para los que son requisitos el registro previo y la aceptación, antes de iniciarse la prestación, de las condiciones particulares, las que sustituirán, completarán y/o modificarán las establecidas en el presente Aviso Legal.</p>

            <h2>Modificación de las presentes condiciones generales:</h2>
            <p>NUMEN se reserva la facultad de modificar y/o adaptar sin aviso previo la Intranet o el presente AVISO LEGAL en cualquier momento. Por ello, se recomienda al Usuario que lea las presentes condiciones en cada una de las ocasiones en que se proponga utilizar la Intranet, ya que tanto éste como aquéllas pueden haber sufrido modificaciones.</p>
            <p>Si el Usuario no está de acuerdo con alguna de las condiciones contenidas en este aviso, deberá dejar de utilizar la Intranet inmediatamente.</p>
            <p>La nueva redacción de las condiciones será publicada en la misma Intranet. La vigencia temporal de estas condiciones generales coincide, por lo tanto, con el tiempo de su exposición, hasta que sean modificadas total o parcialmente, momento en el cual pasarán a tener vigencia las condiciones generales modificadas.</p>

            <h2>Legislación aplicable:</h2>
            <p>Estos términos y condiciones de uso se regirán e interpretarán de conformidad con la legislación española.</p>

            <h2>Fuero:</h2>
            <p>Para cualquier discrepancia, reclamación o litigio en relación con estos términos y condiciones de uso someten la misma expresamente a la jurisdicción y competencia de los juzgados y tribunales de Barcelona – capital, renunciando por tanto en su caso a cualquier otro fuero que pudiera corresponderles.</p>
          </div>

        </div>

      </div>
    </Modal>
  );
}
