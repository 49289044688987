import React, {forwardRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useMutation, useQuery, useQueryClient} from 'react-query';

import {URLS} from '../../../../../lib/api';
import Loader from '../../../../../components/Loader';
import QuantityDiscountModal from '../../../../../components/QuantityDiscountModal';
import {del, get} from '../../../../../lib/restClient';
import EmptyText from '../../../../../components/EmptyText';

export default forwardRef((props, ref) => {
    const queryClient = useQueryClient();
    const {productId, versionId} = useParams();
    const [showQuantityDiscountModal, setShowQuantityDiscountModal] = useState(false);
    const [discountToEdit, setDiscounToEdit] = useState(null);
    const {mutate: deleteQuantityDiscount} = useMutation(
        ({discountId}) => {
            return del(URLS.PRODUCT.DELETE_QUANTITY_DISCOUNT({productId, versionId, discountId}));
        },
        {
            onSuccess: async () => {
                queryClient.refetchQueries(['quantity-status']);
            },
            onError: (error) => {
                console.log('ERROR', error);
            }
        }
    );
    const {error, isLoading, data} = useQuery('quantity-status',
        async () => {
            const quantityDiscountResponse = await get(URLS.PRODUCT.GET_QUANTITY_DISCOUNT({
                productId, versionId,
            }));
            return {
                quantityDiscounts: quantityDiscountResponse.data,
            }
        }, {refetchOnWindowFocus: false});
    if (isLoading) {
        return <Loader/>;
    }
    if (error) {
        return <span>Error</span>;
    }
    return (
        <div className="box form-box">
            <h2 className="title-type6">Descuento por cantidad</h2>

            <div className="btn-absolute">
                <button type="button" id="btnAddVersion" name="btnAddVersion" className="btn-type5"
                        onClick={() => {
                            setDiscounToEdit(null);
                            setShowQuantityDiscountModal(true);
                        }}>
                    Añadir descuento
                </button>
            </div>

            {data?.quantityDiscounts?.length === 0 &&
                <EmptyText>No hay precios definidos</EmptyText>
            }
            {data?.quantityDiscounts?.length > 0 &&
                <div className="responsive-table full-table">
                    <table className="table-type2">
                        <thead>
                        <tr>
                            <th>Países <button type="button" id="btnOrderItem1" name="btnOrderItem1"
                                               className="btn-order up">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th>% 10 - 49 <button type="button" id="btnOrderItem2" name="btnOrderItem2"
                                                  className="btn-order up">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th>% 50 - 99 <button type="button" id="btnOrderItem3" name="btnOrderItem3"
                                                  className="btn-order up">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th>% 100 - 499 <button type="button" id="btnOrderItem4" name="btnOrderItem4"
                                                    className="btn-order">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th>% +500 <button type="button" id="btnOrderItem5" name="btnOrderItem5"
                                               className="btn-order">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th>Fecha inicio <button type="button" id="btnOrderItem6" name="btnOrderItem6"
                                                     className="btn-order">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th>Fecha fin <button type="button" id="btnOrderItem7" name="btnOrderItem7"
                                                  className="btn-order">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th className="centered">Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.quantityDiscounts?.map(qd => (
                            <tr>
                                <td className="wrap">
                                    {qd?.countries?.map(c => c.name).join(', ')}
                                </td>
                                <td>
                                    {qd?.discounts?.find(discount => discount?.volumeCategory?.quantityFrom === 10 && discount?.volumeCategory?.quantityTo === 49) ?
                                        qd?.discounts?.find(discount => discount?.volumeCategory?.quantityFrom === 10 && discount?.volumeCategory?.quantityTo === 49).discount : 0}
                                    %
                                </td>
                                <td>
                                    {qd?.discounts?.find(discount => discount?.volumeCategory?.quantityFrom === 50 && discount?.volumeCategory?.quantityTo === 99) ?
                                        qd?.discounts?.find(discount => discount?.volumeCategory?.quantityFrom === 50 && discount?.volumeCategory?.quantityTo === 99).discount : 0}
                                    %
                                </td>
                                <td>
                                    {qd?.discounts?.find(discount => discount?.volumeCategory?.quantityFrom === 100 && discount?.volumeCategory?.quantityTo === 499) ?
                                        qd?.discounts?.find(discount => discount?.volumeCategory?.quantityFrom === 100 && discount?.volumeCategory?.quantityTo === 499).discount : 0}
                                    %
                                </td>
                                <td>
                                    {qd?.discounts?.find(discount => discount?.volumeCategory?.quantityFrom === 500) ?
                                        qd?.discounts?.find(discount => discount?.volumeCategory?.quantityFrom === 500).discount : 0}
                                    %
                                </td>
                                <td>
                                    {qd.dateFrom}
                                </td>
                                <td>
                                    {qd.dateTo}
                                </td>
                                <td className="centered">
                                    <div className="table-btn-container">
                                        <button type="button" id="btnShowItem1" name="btnShowItem1"
                                                className="btn-icon-border"
                                                onClick={() => {
                                                    setDiscounToEdit(qd);
                                                    setShowQuantityDiscountModal(true);
                                                }}>
                                            <span className="icon-edit"></span>
                                        </button>
                                        <button type="button" id="btnRemoveItem1" name="btnRemoveItem1"
                                                className="btn-icon-border"
                                                onClick={() => {
                                                    deleteQuantityDiscount({productId, versionId, discountId: qd.id});
                                                }}>
                                            <span className="icon-remove"></span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            }
            {showQuantityDiscountModal && (
                <QuantityDiscountModal
                    productId={productId}
                    versionId={versionId}
                    discountToEdit={discountToEdit}
                    isOpen={showQuantityDiscountModal}
                    setIsOpen={setShowQuantityDiscountModal}/>
            )}
        </div>
    );
});
