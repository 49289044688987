import React from 'react'
import Modal from 'react-modal'
import { customStyles } from '../../lib/modals'
import { useDocumentPurifier } from '../../hooks/useHtmlPurifier'

Modal.setAppElement('#root')

export default ({
  isOpen,
  setIsOpen,
  onAccept,
  title,
  subtitle,
  noLogo = false,
}) => {
  const documentPurifier = useDocumentPurifier()

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <Modal isOpen={isOpen} style={customStyles}>
      <div className="modal-container">
        <div className="modal-content action-modal">
          {!noLogo && (
            <header className="modal-header header-icon">
              <span className="icon-key"></span>
            </header>
          )}

          <div className="action-modal-content">
            <h2 className="modal-title-type1">{title}</h2>
            <p
              className="modal-action-desc"
              dangerouslySetInnerHTML={{ __html: documentPurifier(subtitle) }}
            ></p>
            <form action="#" method="post">
              <fieldset>
                <button
                  type="button"
                  id="btnAccept"
                  name="btnAccept"
                  className="btn-type1"
                  onClick={(e) => {
                    e.preventDefault()
                    if (!onAccept) {
                      return closeModal()
                    }
                    onAccept()
                  }}
                >
                  Aceptar
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
}
