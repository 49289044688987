export const getYoutubeId = ({ url }) => {
  var video_id = url.split('v=')[1];
  if (!video_id) {
    return;
  }
  var ampersandPosition = video_id.indexOf('&');
  if (ampersandPosition !== -1) {
    video_id = video_id.substring(0, ampersandPosition);
  }
  return video_id;
};

export const getVimeoId = ({ url }) => {
  var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  var match = url.match(regExp);
  if (match) {
    return match[2];
  }
};

export const getVideoProvider = ({ url }) => {
  return url.indexOf('youtube') !== -1 ? 'youtube' : 'vimeo';
};

export const getVideoId = ({ url }) => {
  if (getVideoProvider({ url }) === 'youtube') {
    return getYoutubeId({ url });
  }
  return getVimeoId({ url });
}