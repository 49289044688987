import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { get } from '../../lib/restClient';
import { URLS } from '../../lib/api';
import { logout } from '../../lib/auth';

export default ({ children }) => {
  const { data = {} } = useQuery('userMe', async () => {
    const response = await get(URLS.AUTH.ME());
    return response.data;
  });
  const history = useHistory();
  const [path, setPath] = useState(window.location.href);
  const isClients = path.indexOf('/admin/clients') !== -1;
  const isDocuments = path.indexOf('/admin/documents') !== -1;
  const isOrders = path.indexOf('/admin/orders') !== -1 || path.indexOf('/admin') === -1;
  const isPartners = path.indexOf('/admin/partners') !== -1;
  const isPricesAndDiscounts = path.indexOf('/admin/prices-and-discounts') !== -1;
  const isProducts = path.indexOf('/admin/products') !== -1;
  const isStock = path.indexOf('/admin/stocks') !== -1;
  return (
    <div>
      <div className="cms-container">
        <nav className="cms-menu">
          <button type="button" id="btnMenu" name="btnMenu" className="btn-menu"
            onClick={() => document.body.classList.toggle('small-menu')}>
            <span className="icon-menu"></span>
          </button>
          <ul className="cms-menu-list">
            <li className={`icon-orders ${isOrders && 'active'}`} onClick={() => {
              history.push('/admin/orders')
              setPath(window.location.href);
            }}>
              <span>Pedidos</span>
            </li>
            <li className={`icon-partners ${isPartners && 'active'}`} onClick={() => {
              history.push('/admin/partners')
              setPath(window.location.href);
            }}>
              <span>Partners</span>
            </li>
            <li className={`icon-clients ${isClients && 'active'}`} onClick={() => {
              history.push('/admin/clients')
              setPath(window.location.href);
            }}>
              <span>Clientes</span>
            </li>
            <li className={`icon-stock ${isStock && 'active'}`} onClick={() => {
              history.push('/admin/stocks')
              setPath(window.location.href);
            }}>
              <span>Stock</span>
            </li>
            <li className={`icon-products ${isProducts && 'active'}`} onClick={() => {
              history.push('/admin/products')
              setPath(window.location.href);
            }}>
              <span>Productos</span>
            </li>
            <li className={`icon-discounts ${isPricesAndDiscounts && 'active'}`} onClick={() => {
              history.push('/admin/prices-and-discounts')
              setPath(window.location.href);
            }}>
              <span>Precios y descuentos</span>
            </li>
            <li className={`icon-documents ${isDocuments && 'active'}`} onClick={() => {
              history.push('/admin/documents')
              setPath(window.location.href);
            }}>
              <span>Documentos</span>
            </li>
          </ul>
        </nav>
        <div className="cms-content">
          <header className="header-cms">
            <img src="/assets/img/logo.svg" alt="Numentech" className="cms-logo" />
            <div className="info-logout">
              <span className="cms-header-user">{data.firstName} {data.lastName}</span>
              <button type="button" id="btnLogout" name="btnLogout" className="btn-logout-cms" onClick={() => logout()}>
                <span className="icon-logout-cms"></span>
              </button>
            </div>
          </header>

          <div role="main" className="main-cms">
            {children}
          </div>
        </div>
      </div>
    </div >
  );
};
