import React, {useRef, useState} from 'react';
import {useQuery} from 'react-query';

import RegularPrice from './components/RegularPrice';
import PartnerPrice from './components/PartnerPrice';
import StatusDiscount from './components/StatusDiscount';
import CategoryDiscount from './components/CategoryDiscount';
import QuantityDiscount from './components/QuantityDiscount';
import {useHistory, useParams} from 'react-router-dom';
import {get} from '../../../lib/restClient';
import {URLS} from '../../../lib/api';
import Loader from '../../../components/Loader';

export default ({edit}) => {
    const history = useHistory();
    const {productId, versionId} = useParams();
    const regularRef = useRef();
    const partnerRef = useRef();
    const statusDiscountRef = useRef();
    const categoryDiscountRef = useRef();
    const quantityDiscountRef = useRef();
    const [selectedTab, setSelectedTab] = useState(0);

    const {isLoading, data} = useQuery('productDetail', async () => {
        const response = await get(URLS.ADMIN.PRODUCT_VERSION_BY_ID({productId, versionId}));
        return response.data;
    });

    if (isLoading) {
        return <Loader/>
    }
    return (
    <main className="main-cms">
            <form action="#" method="post">
                <div className="box main-header-cms">
                    <div className="main-header-cms-top">
                        <div className="main-header-cms-title">
                            <span className="header-cms-icon icon-discounts"></span>
                            <h1 className="header-cms-title">
                                Versión {data?.version} | {data?.product?.name}
                            </h1>
                        </div>

                        <div className="main-header-cms-actions">
                            <div className="header-cms-actions-absolute">
                                <button type="button" id="btnCancel" name="btnCancel" className="btn-type4 small"
                                        onClick={() => history.goBack()}>
                                    Volver
                                </button>
                                <button type="button" id="btnSave" name="btnSave" className="btn-type5"
                                        onClick={() => {
                                            if (selectedTab === 0) {
                                                regularRef.current.update();
                                            } else if (selectedTab === 2) {
                                            }
                                        }}>
                                    Guardar
                                </button>
                            </div>

                        </div>
                    </div>

                    <nav className="cms-tabs">
                        <ul className="tabs-container">
                            <li key="base" className={`item-tab ${selectedTab === 0 ? 'active' : ''}`}
                                onClick={() => setSelectedTab(0)}>
                                Precio base
                            </li>
                            <li  key="partner" className={`item-tab ${selectedTab === 1 ? 'active' : ''}`}
                                onClick={() => setSelectedTab(1)}>
                                Precio partner
                            </li>
                            <li  key="category" className={`item-tab ${selectedTab === 3 ? 'active' : ''}`}
                                onClick={() => setSelectedTab(3)}>
                                Descuento por categoría
                            </li>
                            <li  key="status" className={`item-tab ${selectedTab === 2 ? 'active' : ''}`}
                                onClick={() => setSelectedTab(2)}>
                                Descuento por status
                            </li>
                            <li  key="quantity"className={`item-tab ${selectedTab === 4 ? 'active' : ''}`}
                                onClick={() => setSelectedTab(4)}>
                                Descuento por cantidad
                            </li>
                        </ul>
                    </nav>
                </div>
                {selectedTab === 0 && <RegularPrice ref={regularRef} key={0}/>}
                {selectedTab === 1 && <PartnerPrice ref={partnerRef} key={1}/>}
                {selectedTab === 2 && <StatusDiscount ref={statusDiscountRef} key={2}/>}
                {selectedTab === 3 && <CategoryDiscount ref={categoryDiscountRef} key={3}/>}
                {selectedTab === 4 && <QuantityDiscount ref={quantityDiscountRef} key={4}/>}
            </form>
    </main>
    );
}
