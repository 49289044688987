import React from 'react';
import Modal from 'react-modal';
import useOrderBasket from '../../hooks/useOrderBasket';
import { customStyles } from '../../lib/modals';

Modal.setAppElement('#root')

export default ({ isOpen, setIsOpen }) => {
  const {
    clearBasket,
  } = useOrderBasket();
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
    >
      <div className="modal-container">
        <div className="modal-content cms-modal">

          <form action="#" method="post">
            <h2 className="modal-title-type1">Pedido realizado</h2>
            <p className="cms-modal-desc">Has realizado con éxito el pedido</p>

            <p className="cms-modal-message">Puedes ir a la sección "Gestión de Pedidos" para descargarte el albarán.</p>

            <div className="cms-modal-actions">
              <button type="button" id="btnAccept" name="btnAccept" className="btn-type5 big"
                onClick={() => {
                  clearBasket();
                  setIsOpen();
                }}>
                Aceptar
              </button>
            </div>


          </form>


        </div>
      </div>
    </Modal>
  );
}
