import React from 'react';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { get, post, remove } from '../../../../lib/restClient';
import { URLS } from '../../../../lib/api';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import { groupBy } from '../../../../lib/utils';
import StatusLabel from '../../../../components/StatusLabel';

export default () => {
  const queryClient = useQueryClient();
  const { partnerId } = useParams();
  const { isLoading: isLoadingPartnerProducts, data: partnersProducts, isFetching } = useQuery('partnerProducts', async () => {
    const { data: productSolutions } = await get(URLS.PRODUCT.SOLUTIONS());
    const { data: partnerProductSolutions } = await get(URLS.PARTNER.LIST_PARTNER_PRODUCTS({ userId: partnerId }));

    partnerProductSolutions.forEach(ps => {
      const solutionId = ps.solution.id;
      let selectedIndex = -1;
      for (let i = 0; i < productSolutions.length; i++) {
        if (solutionId === productSolutions[i].id) {
          selectedIndex = i;
        }
      }
      if (selectedIndex !== -1) {
        productSolutions[selectedIndex].partnerSelection = ps;
      }
    })

    return productSolutions;
  });
  const { mutate: toggleProduct } = useMutation(
    ({ product }) => {
      if (product.partnerSelection) {
        return remove(URLS.PARTNER.REMOVE_PRODUCT({ userId: partnerId, productId: product.id }));
      } else {
        return post(URLS.PARTNER.ADD_PRODUCT({ userId: partnerId }), { id: product.id });
      }
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(['partnerProducts']);
      },
      onError: (error) => {
        console.log('ERROR', error);
      }
    }
  );
  if (isLoadingPartnerProducts || isFetching) {
    return <Loader />;
  }
  const products = groupBy(partnersProducts, 'segmentCaption');
  const productNames = Object.keys(products);

  return (
    <div className="box form-box">
      <h2 className="title-type6">Productos</h2>
      <div className="table-col-container">
        {productNames.map(name => (
          <div key={products[name]} className="responsive-table">
            <table className="table-type4">
              <thead>
                <tr>
                  <th>{name} SOLUTIONS</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {products[name].map(function (prd, idx) {
                  const checkboxName = `chItem${name}${idx}`;
                  return (
                    <tr key={prd.id}>
                      <td>
                        <div className="form-item-checkbox">
                          <input
                            type="checkbox"
                            key={`chx-${prd.id}`}
                            id={checkboxName}
                            name={checkboxName}
                            checked={!!prd.partnerSelection}
                            value={prd.id}
                            onChange={function () {
                              toggleProduct({ product: prd })
                            }} />
                          <label htmlFor={checkboxName}>{prd.name}</label>
                        </div>
                      </td>
                      <td>
                        <StatusLabel status={prd?.partnerSelection?.status || ''} />
                      </td>
                    </tr>
                  )
                }
                )}
              </tbody>
            </table>
          </div>
        ))}


      </div>

    </div>
  );
}
