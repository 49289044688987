import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { get } from '../../../lib/restClient';
import { URLS } from '../../../lib/api';
import { getUserId } from '../../../lib/auth';
import TablePager from '../../../components/TablePager';
import { Input } from '../../../components/Inputs';
import Loader from '../../../components/Loader';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { downloadFile } from '../../../lib/downloads';
import { nonStandardDateFormatting } from '../../../lib/dates';
import RightAlignedButton from '../../../components/RightAlignedButton';

const initialValues = {
	firstName: null,
	lastName: null,
	email: null,
	phone: null
};

const Clients = () => {
	const history = useHistory();
	const [currentPage, setCurrentPage] = useState(0);
	const filtersFormik = useFormik({
		initialValues,
		onSubmit: () => {
			refetch();
		},
	});
	const [showFilters, setShowFilters] = useState(false);
	const { isLoading, data, error, refetch, isFetching } = useQuery('clientsFromPartner', async () => {
		const response = await get(URLS.PARTNER.CUSTOMERS({
			userId: getUserId(),
			filters: filtersFormik?.values,
		}));
		return {
			list: response.data,
			pageCount: response.headers['x-page-count'],
			totalCount: response.headers['x-total-count'],
		};
	});
	useEffect(() => {
		refetch();
	}, [refetch, currentPage]);
	if (error) {
		return <span>error</span>;
	}
	if (isLoading) {
		return <Loader />
	}
	return (
		<>
			<div role="main" className="main-cms">
				<form action="#" method="post">
					<div className="box main-header-cms">
						<div className="main-header-cms-top">
							<div className="main-header-cms-title">
								<span className="header-cms-icon icon-clients"></span>
								<h1 className="header-cms-title">
									Clientes
                  <span>Relación de mis clientes</span>
									<span className="cms-title-detail">{data.totalCount} clientes</span>
								</h1>
							</div>

							<div className="main-header-cms-actions">
								<div className="header-cms-actions-buttons">
									<button type="button" id="btnFilters" name="btnFilters" className="btn-cms-actions"
										onClick={() => setShowFilters(!showFilters)}>
										Filtros
                	</button>
									<button type="button" id="btnDownload" name="btnDownload" className="btn-cms-actions"
										onClick={() => downloadFile({
											url: URLS.PARTNER.DOWNLOAD_PARTNERS_CUSTOMERS_CSV({ userId: getUserId() }),
											name: 'Clientes.csv',
											contentType: 'application/csv',
										})}>
										Descargar CSV <span className="icon-download-filters"></span>
									</button>
								</div>
							</div>
						</div>
					</div>

					{(isFetching || isLoading) &&
						<Loader boxed />
					}

					{!isFetching && !isLoading &&
						<div className="box">
							<div className={`cms-filters ${showFilters ? 'show' : false}`}>
								<h2 className="cms-filters-title">Filtros</h2>
								<button type="button" id="btnCloseFilters" name="btnCloseFilters" className="btn-close-filters"
									onClick={() => setShowFilters(!showFilters)}>
									<span className="icon-close-modal"></span>
								</button>
								<div className="form-item-row">
									<div className="form-item filter-size1">
										<label className="form-label">Nombre</label>
										<Input
											type="text"
											id="firstName"
											name="firstName"
											className="form-text"
											onChange={(e) => filtersFormik.setFieldValue('firstName', e.target.value)}
											value={filtersFormik.values.firstName} />
									</div>

									<div className="form-item filter-size2">
										<label className="form-label">Apellidos</label>
										<Input
											type="text"
											id="lastName"
											name="lastName"
											className="form-text"
											onChange={(e) => filtersFormik.setFieldValue('lastName', e.target.value)}
											value={filtersFormik.values.lastName} />
									</div>

									<div className="form-item filter-size2">
										<label className="form-label">Email</label>
										<Input
											type="email"
											id="email"
											name="email"
											className="form-text"
											onChange={(e) => filtersFormik.setFieldValue('email', e.target.value)}
											value={filtersFormik.values.email} />
									</div>

									<div className="form-item filter-size1">
										<label className="form-label">Teléfono</label>
										<Input
											type="phone"
											id="phone"
											name="phone"
											className="form-text"
											onChange={(e) => filtersFormik.setFieldValue('phone', e.target.value)}
											value={filtersFormik.values.phone} />
									</div>
								</div>

								<RightAlignedButton
									onClick={() => {
										filtersFormik.handleSubmit();
										setShowFilters(false);
										setCurrentPage(0);
									}}>
									Filtrar
							</RightAlignedButton>
							</div>

							<div className="responsive-table full-table">
								<table className="table-type2">
									<thead>
										<tr>
											<th>Nombre
											<button type="button" id="btnOrderItem1" name="btnOrderItem1" className="btn-order up">
													<span className="icon-arrow2"></span>
												</button>
											</th>
											<th>Apellidos
                                        <button type="button" id="btnOrderItem2" name="btnOrderItem2" className="btn-order">
													<span className="icon-arrow2"></span>
												</button>
											</th>
											<th>Email</th>
											<th>Teléfono</th>
											<th>Tipología
                                        <button type="button" id="btnOrderItem3" name="btnOrderItem3" className="btn-order">
													<span className="icon-arrow2"></span>
												</button>
											</th>
											<th>fecha de alta
                                        <button type="button" id="btnOrderItem4" name="btnOrderItem4" className="btn-order">
													<span className="icon-arrow2"></span>
												</button>
											</th>
											<th className="centered">Acciones</th>
										</tr>
									</thead>

									<tbody>
										{data?.list.map(customer => (
											<tr>
												<td>{customer.firstName}</td>
												<td>{customer.lastName}</td>
												<td>{customer.email}</td>
												<td>{customer.phone}</td>
												<td>Empresa</td>
												<td>{nonStandardDateFormatting(customer.createdAt.date)}</td>
												<td className="centered">
													<button
														type="button"
														id="btnEditItem1"
														name="btnEditItem1"
														className="btn-icon-border"
														onClick={() => history.push(`/partners/clients/${customer.id}`)}>
														<span className="icon-edit"></span>
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					}
					{!isFetching && !isLoading &&
						<TablePager
							pages={data.pageCount}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							refetch={refetch} />
					}
				</form>
			</div>
		</>
	);
};

export default Clients;
