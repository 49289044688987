import React from 'react';

export default ({ field, sort }) => {
  if (field !== sort.field) {
    return <></>;
  }
  return (
    <button type="button" id="btnOrderItem2" name="btnOrderItem2" className={`btn-order ${sort.order === 'asc' ? 'up' : ''}`}>
      <span className="icon-arrow2"></span>
    </button>
  );
}
