import { get, post } from '../lib/restClient';
import { URLS } from '../lib/api';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import {format } from 'date-fns'

export const useGetUserNotificationsMutation = ({ filters }) => {
  const userFilters = { ...filters }

  if (filters.date_start && !filters.date_end) {
    const today = format((new Date()), 'Y-MM-dd')
    userFilters.date_end = today
  }

  return useQuery('notifications', async () => {
    const response = await get(URLS.CLIENT.NOTIFICATIONS({ filters: userFilters }));
    return {
      notifications: response.data || [],
    };
  });
}

export const useGeNotificationCategoriesMutation = () => {
  return useQuery('categories', async () => {
    const response = await get(URLS.CLIENT.GET_NOTIFICATIONS_CATEGORIES());
    return response.data || []
  });
}

export const useGeNotificationImportancesMutation = () => {
  return useQuery('importances', async () => {
    const response = await get(URLS.CLIENT.GET_NOTIFICATIONS_IMPORTANCES());
    return response.data || []
  });
}

export const useNotificationGroupMutations = () => {
  const queryClient = useQueryClient()

  const { mutate: archiveNotifications } = useMutation(
    ({ notifications_id = [], archived = "1" }) =>
      post(
        URLS.CLIENT.ARCHIVE_NOTIFICATIONS(),
        { notifications_id, archived },
      ),
    {
      onSuccess: () => queryClient.refetchQueries(['notifications']),
      onError: (error) => console.log('ERROR', error),
    }
  );

  const { mutate: featuredNotifications } = useMutation(
    ({ notifications_id = [], featured = true }) =>
      post(
        URLS.CLIENT.FEATURED_NOTIFICATIONS(),
        { notifications_id, featured },
      ),
    {
      onSuccess: () => queryClient.refetchQueries(['notifications']),
      onError: (error) => console.log('ERROR', error),
    }
  );

  const { mutate: openNotifications } = useMutation(
    ({ notifications_id = [], open = true }) =>
      post(
        URLS.CLIENT.OPEN_NOTIFICATIONS(),
        { notifications_id, open },
      ),
    {
      onSuccess: () => queryClient.refetchQueries(['notifications']),
      onError: (error) => console.log('ERROR', error),
    }
  );

  const { mutate: removeNotifications } = useMutation(
    ({ notifications_id = [] }) =>
      post(
        URLS.CLIENT.REMOVE_NOTIFICATIONS(),
        { notifications_id },
      ),
    {
      onSuccess: () => queryClient.refetchQueries(['notifications']),
      onError: (error) => console.log('ERROR', error),
    }
  );

  return {
    archiveNotifications,
    openNotifications,
    featuredNotifications,
    removeNotifications,
  }
}