import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { URLS } from '../../../lib/api';
import { nonStandardDateFormatting, nonStandardDateToDate, simpleDateFormatting } from '../../../lib/dates';
import { Input } from '../../../components/Inputs';
import Loader from '../../../components/Loader';
import ConfirmationModal from '../../../components/ConfirmationModal';
import InfoModal from '../../../components/InfoModal';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { get, put } from '../../../lib/restClient';
import { extractErrorMessageFromResponse, snakeCase } from '../../../lib/utils';
import { downloadFile } from '../../../lib/downloads';
import { formatPrice } from '../../../lib/prices';
import { getUserType } from '../../../lib/auth';

const POSSIBLE_STATES = {
  initializing: [
    { id: 'initializing', caption: 'Inicializando' },
    { id: 'reserved', caption: 'Reservado' },
  ],
  reserved: [
    { id: 'reserved', caption: 'Reservado' },
    { id: 'accepted', caption: 'Aceptado' },
  ],
  accepted: [
    { id: 'accepted', caption: 'Aceptado' },
    { id: 'paid', caption: 'Pagado' },
  ],
}

const Orders = () => {
  const queryClient = useQueryClient();
  const isAdmin = window.location.href.indexOf('admin') !== -1;
  const userType = getUserType();
  const { orderId } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalShown, setModalShown] = useState(false);
  const [isChangedModalShown, setChangedModalShown] = useState(false);
  const [isErrorChangedModalShown, setErrorChangedModalShown] = useState(false);
  const history = useHistory();
  const { isLoading, error, data, isFetching } = useQuery('orderById', async () => {
    const response = await get(URLS.ORDERS.GET_BY_ID({ orderId }));
    return {
      order: response.data,
    };
  }, { refetchOnWindowFocus: false });
  const { mutate: updateState } = useMutation(
    ({ orderId, state }) => put(URLS.ORDERS.EDIT_BY_ID({
      orderId,
    }), { state }),
    {
      onSuccess: (response) => {
        if (response?.data?.state?.id === 'canceled') {
          queryClient.refetchQueries(['orderById']);
          setModalShown(false);
        } else {
          setChangedModalShown(true);
        }
      },
      onError: (error) => {
        console.log('ERROR', error);
        setErrorMessage(extractErrorMessageFromResponse(error));
        setErrorChangedModalShown(true);
      }
    }
  );
  if (isLoading || isFetching) {
    return <Loader />;
  }
  if (error) {
    return <span>Error</span>;
  }
  const order = data?.order;
  const items = order?.items;
  const formattedDate = simpleDateFormatting(nonStandardDateToDate(order?.createdAt?.date));
  const formattedPartnerName = snakeCase(order?.partner?.organizationName);
  const fileName = `${snakeCase(formattedDate)}_${formattedPartnerName}_PEDIDO.pdf`;
  return (
    <div role="main" className="main-cms">
      <form action="#" method="post">
        <div className="box main-header-cms">
          <div className="main-header-cms-top">
            <div className="main-header-cms-title">
              <span className="header-cms-icon icon-orders"></span>
              <h1 className="header-cms-title">
                Consultar pedido
              <span>Consulta los datos de tus pedidos a Numentech</span>
              </h1>
            </div>

            <div className="main-header-cms-actions">

              <button type="button" id="btnBack" name="btnBack" className="btn-type5"
                onClick={() => {
                  history.push(`/${isAdmin ? 'admin' : 'partners'}/orders`)
                }}>
                Volver a Pedidos
              </button>
            </div>
          </div>
        </div>

        <div className="box form-box">

          <h2 className="title-type6">Datos del pedido</h2>
          {((userType === 'PARTNER' && order?.state?.id === 'reserved') ||
            (userType === 'ADMINISTRATOR' && (order?.state?.id === 'reserved' || order?.state?.id === 'accepted'))) &&
            <button type="button" id="btnSaveBlock1" name="btnSaveBlock1" className="btn-type5 btn-absolute"
              onClick={() => setModalShown(true)}>
              Cancelar pedido
          </button>
          }

          <div className="form-item-row small-row margin-bottom">
            <div className="form-item">
              <label className="form-label">ID del pedido</label>

              <div className="form-text-container">
                <Input type="text" id="txtId" name="txtId" className="form-text"
                  value={order?.id}
                  blocked />
              </div>
            </div>

            <div className="form-item">
              <label className="form-label">Fecha</label>
              <div className="form-text-container">
                <Input type="text" id="txtDate" name="txtDate" className="form-text"
                  value={nonStandardDateFormatting(order?.createdAt?.date)}
                  blocked />
              </div>
            </div>

            <div className="form-item">
              <label className="form-label">Estado</label>
              {order?.state?.id !== 'canceled' && order?.state?.id !== 'paid' && userType === 'ADMINISTRATOR' &&
                <div className="form-text-container">
                  <select id="cmbState" name="cmbState" className="form-select"
                    onChange={(e) => updateState({ orderId, state: e?.target?.value })}>
                    {POSSIBLE_STATES[order?.state?.id].map(state => (
                      <option
                        value={state.id}
                        selected={order?.state?.id === state.id}>
                        {state.caption}
                      </option>
                    ))}
                  </select>
                  <span className="icon-arrow select-arrow"></span>
                </div>
              }
              {(order?.state?.id === 'canceled' || order?.state?.id === 'paid' || userType !== 'ADMINISTRATOR') &&
                <Input type="text" id="txtDate" name="txtDate" className="form-text"
                  value={order?.state?.caption}
                  blocked />
              }
            </div>
          </div>

          <h3 className="title-type7">Listado de productos del pedido</h3>

          <div className="responsive-table">
            <table className="table-type3">
              <tbody>
                <tr>
                  <th>Cantidad</th>
                  <th>Nombre producto</th>
                  <th>Solución</th>
                  <th>Precio Unidad</th>
                  <th>Descuentos</th>
                  <th>PRECIO BASE</th>
                </tr>
                {items?.map(item => (
                  <tr>
                    <td>
                      {item?.units}
                    </td>
                    <td>
                      {item?.productVersion?.productName}
                      {/* <span className="trial-tag">Trial version</span> */}
                    </td>
                    <td>
                      {item?.productVersion?.solutionName}
                    </td>
                    <td>
                      {formatPrice({
                        number: item?.unitPrice,
                        currencyIsoCode: order?.currency?.iso_code
                      })}
                    </td>
                    <td>
                      <div className="table-items">
                        <div className="col-item">
                          <span className="col-item-desc">
                            {item?.partnerPlanDiscount?.percent &&
                              `-${item?.partnerPlanDiscount?.percent}%`}
                            {item?.partnerTypeDiscount?.percent &&
                              `-${item?.partnerTypeDiscount?.percent}%`}
                            {item?.volumeDiscount?.percent &&
                              `-${item?.volumeDiscount?.percent}%`}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      {formatPrice({
                        number: item?.totalAmount,
                        currencyIsoCode: order?.currency?.iso_code
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>

              <tbody>
                <tr>
                  <th colSpan="3">PRECIO BASE<span>TOTAL</span></th>
                  <th>Tasas</th>
                  <th>Descuentos</th>
                  <th>Precio final <span>(Tasas incluídas)</span></th>
                </tr>
                <tr>
                  <td colSpan="3">
                    {formatPrice({
                      number: order?.base_amount,
                      currencyIsoCode: order?.currency?.iso_code
                    })}
                  </td>
                  <td>
                    {order?.tax_percent}%
                  </td>
                  <td>
                    <div className="table-items">
                    </div>
                  </td>
                  <td>
                    <span className="total-price">
                      {formatPrice({
                        number: order?.total_amount,
                        currencyIsoCode: order?.currency?.iso_code
                      })}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="responsive-table">
            <table className="table-type1">
              <thead>
                <tr>
                  <th>Albarán</th>
                  <th>Fecha</th>
                  <th className="centered">Descargar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <span className="icon-doc">{fileName}</span>
                  </th>

                  <td>
                    {formattedDate}
                  </td>

                  <td className="centered">
                    <button type="button" id="btnDownloadItem1" name="btnDownloadItem1" className="btn-icon-border"
                      onClick={() => downloadFile({
                        url: URLS.PARTNER.DOWNLOAD_DELIVERY_NOTE({ orderId }),
                        name: `${fileName}.pdf`,
                        contentType: 'application/pdf',
                      })}>
                      <span className="icon-download"></span>
                    </button>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

          <ConfirmationModal
            isOpen={isModalShown}
            setIsOpen={setModalShown}
            title="Cancelar pedido"
            subtitle="¿Estás de seguro?"
            onAccept={() => {
              updateState({
                orderId,
                state: 'canceled',
              });
            }}
          />

          <InfoModal
            isOpen={isChangedModalShown}
            setIsOpen={setChangedModalShown}
            title="Estado cambiado"
            subtitle="El estado ha sido cambiado correctamente"
          />
          <InfoModal
            isOpen={isErrorChangedModalShown}
            setIsOpen={setErrorChangedModalShown}
            title="Error"
            subtitle={errorMessage}
          />
        </div>

      </form>
    </div>
  );
};

export default Orders;