export const TOKEN_STORAGE_KEY = 'numentech::auth_token';
export const USER_TYPE_KEY = 'numentech::user_type';
export const USER_ID = 'numentech::user_id';
export const REQUIRE_CHANGE_PASSWORD = 'numentech::require_change_password';
export const REQUIRE_PARTNER_SIGN = 'numentech::require_partner_sign';

export const getAuthToken = () => window.localStorage.getItem(TOKEN_STORAGE_KEY);
export const setAuthToken = (token) => window.localStorage.setItem(TOKEN_STORAGE_KEY, token);
export const removeAuthToken = () => window.localStorage.removeItem(TOKEN_STORAGE_KEY);
export const getUserId = () => window.localStorage.getItem(USER_ID);
export const setUserId = (userId) => window.localStorage.setItem(USER_ID, userId);
export const removeUserId = () => window.localStorage.removeItem(USER_ID);
export const isLoggedIn = () => !!window.localStorage.getItem(TOKEN_STORAGE_KEY);
export const getUserType = () => window.localStorage.getItem(USER_TYPE_KEY);
export const setUserType = (type) => window.localStorage.setItem(USER_TYPE_KEY, type);
export const getRequireChangePassword = () => window.localStorage.getItem(REQUIRE_CHANGE_PASSWORD);
export const setRequireChangePassword = (type) => window.localStorage.setItem(REQUIRE_CHANGE_PASSWORD, type);
export const getRequirePartnerSigned = () => window.localStorage.getItem(REQUIRE_PARTNER_SIGN);
export const setRequirePartnerSigned = (value) => window.localStorage.setItem(REQUIRE_PARTNER_SIGN, value);
export const removeRequireChangePassword = () => window.localStorage.removeItem(REQUIRE_CHANGE_PASSWORD);
export const logout = () => {
  window.localStorage.clear();
  window.location.href = '/';
}
