import React from 'react';

const Providers = () => {
  return (
    <>
      <span>
        Providers
      </span>
    </>
  );
};

export default Providers;