import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import InfoModal from '../../../../../components/InfoModal';

export default ({ product }) => {
  const history = useHistory();
  const [showOlderVersions, setShowOlderVersions] = useState(false);
  const [showDefineVersionFirst, setShowDefineVersionFirst] = useState(false);
  const hasOlderVersions = (product?.versions?.length > 1);
  return (
    <>
      <tr key={product?.id}>
        <td>{product?.name}</td>
        <td>
          <div className="table-version-bl">
            {!product?.versions[0] &&
              <span className="table-version-txt">No hay versiones definidas</span>
            }
            {product?.versions[0] &&
              <span className="table-version-txt">
                Versión actual {product?.versions[0]?.version}
              </span>
            }
            {hasOlderVersions && (
              <button type="button" id="btnShow1" name="btnShow1"
                className={`btn-type6 btn-show-version ${showOlderVersions ? 'up' : ''}`}
                onClick={() => setShowOlderVersions(!showOlderVersions)}>
                <span className="icon-version"></span> Versiones anteriores <span className="icon-arrow"></span>
              </button>
            )}
          </div>
          <ul className={`version-list ${showOlderVersions ? 'show' : ''}`}>
            {product?.versions?.slice(1).map(version => (
              <li>
                Versión {version?.version}
              </li>
            ))}
          </ul>
        </td>
        <td>{product?.type?.label}</td>
        <td>Particular</td>
        <td className="centered">
          <button type="button" id="btnShowItem1" name="btnShowItem1" className="btn-icon-border"
            onClick={() => {
              if (product?.versions[0]) {
                return history.push(`/admin/prices-and-discounts/${product.id}/versions/${product?.versions[0].id}`)
              } else {
                setShowDefineVersionFirst(true);
              }
            }}>
            <span className="icon-edit"></span>
          </button>
          <ul className={`version-list ${showOlderVersions ? 'show' : ''}`}>
            {hasOlderVersions &&
              product?.versions?.slice(1).map(version => (
                <li>
                  <button type="button" id="btnShowItem1" name="btnShowItem1" className="btn-icon-border"
                    onClick={() => history.push(`/admin/prices-and-discounts/${product.id}/versions/${version.id}`)}>
                    <span className="icon-edit"></span>
                  </button>
                </li>
              ))}
          </ul>
        </td>
      </tr>
      {showDefineVersionFirst &&
        <InfoModal
          isOpen={showDefineVersionFirst}
          setIsOpen={setShowDefineVersionFirst}
          title="No hay versiones definidas"
          subtitle="Tienes que definir una versión de producto primero"
        />
      }
    </>
  );
}
