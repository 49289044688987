import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import { URLS } from '../../../../lib/api';
import { get } from '../../../../lib/restClient';
import SVG from '../../../../components/Svg';
import { isFutureDate, simpleDateFormatting } from '../../../../lib/dates';
import { isProductAboutToExpire, isProductActive, isProductExpired, isProductInactive, showProductTrialTag } from '../../../../lib/models/products';
import EmptyMessage from '../../../../components/EmptyMessage';
import moment from "moment/moment";
import SlideToggle from '../../../../components/SlideToggle';


const Product = ({ product }) => {
  const [isOpen, setIsOpen] = useState(false);
  const DATE_FORMAT = 'MM/DD/YYYY';
  let formattedEndDate = moment().format(DATE_FORMAT);
  let formattedActivation_date = moment().format(DATE_FORMAT);
  if (product.activation_date) {
    formattedActivation_date = moment(product.activation_date.date).format(DATE_FORMAT);
  }
  if (product.end_date) {
    formattedEndDate = moment(product.end_date.date).format(DATE_FORMAT);
  }
  return (
    <SlideToggle
      collapsed={!isOpen}>
      {({ toggle, setCollapsibleElement }) => (
        <div className="accordion-item accordion-item-type2">
          <div className={`accordion-header ${isOpen ? 'active' : ''}`} onClick={(e) => {
            setIsOpen(!isOpen);
            toggle(e);
          }}>
            <SVG
              svg={product?.product_version?.product?.solution?.svgIcon}
              alt={product?.product_version?.product?.name} />

            <div className="accordion-header-info header-info-type2">
              <h2 className="accordion-header-title">
                {product.product_version.product.name}
              </h2>
              <span className="accordion-header-desc">(
                    {product?.product_version?.product?.duration} meses
                    {isFutureDate(new Date(product?.activation_date?.date.split(' ')[0])) &&
                  `. Esta licencia se activará el ${simpleDateFormatting(new Date(product?.activation_date?.date.split(' ')[0]))}`
                }
                  )</span>
            </div>

            {isProductAboutToExpire(product) && <span className="product-tag next-expire">Va a caducar</span>}
            {isProductExpired(product) && <span className="product-tag next-expire">Caducado</span>}
            {isProductInactive(product) && <span className="product-tag inactive">Sin activar</span>}
            {isProductActive(product) && <span className="product-tag active">Activado</span>}
            {showProductTrialTag(product) && <span className="trial-tag">Trial version</span>}

          </div>

          <div className="accordion-content" ref={setCollapsibleElement}>

            <div className="accordion-content-data">
              <div className="accordion-data-item">
                <span className="accordion-content-title">Descripción</span>
                <span className="accordion-content-desc">{product.product_version.product.description}</span>
              </div>
              <div className="accordion-data-item">
                <span className="accordion-content-title">Fecha de activación</span>
                <span className="accordion-content-desc">{formattedActivation_date}</span>
              </div>

            </div>

            <div className="accordion-content-data">
              <div className="accordion-data-item">
                <span className="accordion-content-title">Partner</span>
                <span className="accordion-content-desc">{product.partner.organizationName}</span>
              </div>
              <div className="accordion-data-item">
                <span className="accordion-content-title">Public Key</span>
                <span className="accordion-content-desc">{product.registrationNumber}</span>
              </div>
              <div className="accordion-data-item">
                <span className="accordion-content-title">Fecha renovación</span>
                <span className="accordion-content-desc">{formattedEndDate}</span>
              </div>

            </div>
          </div>
        </div>
      )}
    </SlideToggle>
  );
}

export default () => {
  const params = useParams();
  const { isLoading, error, data = [] } = useQuery('clientProducts', async () => {
    const response = await get(URLS.CLIENT.MY_PRODUCTS({ userId: params.clientId }));
    return response.data;
  }, { refetchOnWindowFocus: false });
  if (error) {
    return <span>error</span>
  }
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="box accordion-box">

      {data.length === 0 &&
        <EmptyMessage>Este cliente no tiene licencias</EmptyMessage>
      }

      {data.map(product => <Product product={product} />)}
    </div>
  );
}
