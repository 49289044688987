import React, { useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { Input } from '../../components/Inputs';
import { Button } from '../../components/Buttons';
import { Checkbox } from '../../components/Checkbox';
import { RadioButton } from '../../components/RadioButton';
import FormError from '../../components/FormError';
import TextModal from '../../components/TextModal';
import { Image } from '../../components/Image';
import TermsAndConditionsModal from '../../components/TermsAndConditionsModal';
import PoliciesModal from '../../components/PoliciesModal';
import { URLS } from '../../lib/api';
import { get } from '../../lib/restClient';
import { extractErrorMessageFromResponse } from '../../lib/utils';

const ClientRegisterSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
  lastName: Yup.string()
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
  email: Yup.string()
    .email('Introduce correo electrónico válido')
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
  phone: Yup.string()
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
  locales: Yup.array(),
  country: Yup.object().nullable(),
  type: Yup.string()
    .required('Requerido')
    .test('length', 'El texto debe tener menos de 255 caracteres', text => text && text.length < 255),
});


const ClientRegister = () => {
  const [TermsAndConditionsModalIsOpen, setTermsAndConditionsModalIsOpen] = useState(false);
  const [showTypeClient] = useState(false);
  const [policiesModalIsOpen, setPoliciesModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRegisterFinished, setIsRegisterFinished] = useState(false);
  const [areTermsAndConditionsChecked, setTermsAndConditionsChecked] = useState(null);
  const { isLoading: areCountriesLoading, data: countriesData } = useQuery('countries', async () => {
    const response = await get(URLS.COUNTRIES.LIST());
    return response.data;
  });

  const { mutate: register } = useMutation(
    registerBody => axios.post(URLS.CLIENT.REGISTER(), registerBody),
    {
      onSuccess: () => {
        setIsRegisterFinished(true);
      },
      onError: (error) => {
        if (error.response.data.message === 'user.email_already_registered_exception') {

        } else if (extractErrorMessageFromResponse(error)) {
          setErrorMessage(extractErrorMessageFromResponse(error));

        } else {
          setErrorMessage(error.response.data.message);
        }
      }
    }
  );
  return (
    <>
      <div className="access-form-bl access-register">
        <Link to="/" className="back-link icon-arrow">Login</Link>
        <Image icon="logo.svg" alt="Numentech" className="access-logo" />
        <h1 className="title">Registro de Cliente</h1>
        <p className="form-desc">Todos los campos son obligatorios</p>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            locales: ['es'],
            country: 'España',
            type: 'company',
            termsAndConditions: false,
          }}
          validationSchema={ClientRegisterSchema}
          onSubmit={(values) => {
            if (!values.country.iso_code) {
              values.country = countriesData.filter(c => c.name === values.country)[0];
            }
            if (areTermsAndConditionsChecked) {
              register(values);
            } else {
              setErrorMessage('Debes marcar los términos y condiciones');
            }
          }}
          render={({
            values,
            errors,
            handleSubmit,
            setFieldValue
          }) => (
              <form action="#" method="post">
                <fieldset className="access-fieldset">
                  <div className="form-item-row">
                    <div className="form-item">
                      <label className="form-label">Nombre</label>
                      <Input
                        type="text"
                        id="firstName"
                        name="firstName"
                        className="form-text"
                        value={values.firstName}
                        onChange={(e) => setFieldValue('firstName', e.target.value)}
                      />
                      {errors.firstName && <FormError>{errors.firstName}</FormError>}
                    </div>

                    <div className="form-item">
                      <label className="form-label">Apellidos</label>
                      <Input
                        type="text"
                        id="lastName"
                        name="lastName"
                        className="form-text"
                        value={values.lastName}
                        onChange={(e) => setFieldValue('lastName', e.target.value)}
                      />
                      {errors.lastName && <FormError>{errors.lastName}</FormError>}
                    </div>
                  </div>

                  <div className="form-item">
                    <label className="form-label">Email</label>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      className="form-text"
                      value={values.email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                    />
                    {errors.email && <FormError>{errors.email}</FormError>}
                  </div>

                  <div className="form-item-row">
                    <div className="form-item">
                      <label className="form-label">Teléfono</label>
                      <Input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-text"
                        value={values.phone}
                        onChange={(e) => setFieldValue('phone', e.target.value)}
                      />
                      {errors.phone && <FormError>{errors.phone}</FormError>}
                    </div>
                    <div className="form-item">
                      <label className="form-label">Pais</label>
                      <div className={`form-text-container ${errors.country && 'form-error'}`}>
                        {!areCountriesLoading &&
                          <select
                            id="cmbCountry"
                            name="cmbCountry"
                            className="form-select"
                            defaultValue="España"
                            onChange={(e) => setFieldValue('country', e.target.value)}>
                            {countriesData.map(country => (
                              <option value={country.name}>{country.name}</option>
                            ))}
                          </select>
                        }
                        <span className="icon-arrow select-arrow"></span>
                      </div>
                      {errors.country && <FormError>{errors.country}</FormError>}
                    </div>
                  </div>
                    {showTypeClient === true && (
                  <div className="form-radio-container">
                    <RadioButton
                      id="rdClientType1"
                      name="clientType"
                      label="Particular"
                      checked={values.type === 'individual'}
                      onClick={() => setFieldValue('type', 'individual')}
                    />
                    <RadioButton
                      id="rdClientType2"
                      name="clientType"
                      label="Empresa"
                      checked={values.type === 'company'}
                      onClick={() => setFieldValue('type', 'company')}
                    />
                  </div>)}

                  <div className="form-item-checkbox">
                    <Checkbox
                      id="chTerms"
                      checked={areTermsAndConditionsChecked}
                      onClick={() => {
                        if (areTermsAndConditionsChecked) {
                          setErrorMessage('Debes marcar los términos y condiciones');
                        } else {
                          setErrorMessage('');
                        }
                        setTermsAndConditionsChecked(!areTermsAndConditionsChecked)
                      }}
                    />
                    <label htmlFor="chTerms">He leído y acepto las condiciones de los
                    <a style={{ fontWeight: 800 }} onClick={() => setTermsAndConditionsModalIsOpen(true)}> términos y condiciones</a>
                    {' '}y la <a style={{ fontWeight: 800 }} onClick={() => setPoliciesModalIsOpen(true)}>política de privacidad</a></label>
                  </div>
                  {errorMessage && <FormError>{errorMessage}</FormError>}

                  <Button
                    type="submit"
                    id="btnRegister"
                    name="btnRegister"
                    onClick={handleSubmit}>
                    Registrarme
                  </Button>

                </fieldset>
              </form>
            )} />

      </div>

      <div className="access-right-col">
        <Image icon="bg-register.jpg" />
      </div>

      <TermsAndConditionsModal isOpen={TermsAndConditionsModalIsOpen} setIsOpen={setTermsAndConditionsModalIsOpen} />
      <PoliciesModal isOpen={policiesModalIsOpen} setIsOpen={setPoliciesModalIsOpen} />
      <TextModal
        isOpen={isRegisterFinished}
        text="Gracias por registrarte en Numentech. Revisa tu email para acceder con la contraseña que te hemos enviado."
        onAccept={() => window.location.href = '/'}
      />
    </>
  );
};

export default ClientRegister;