export const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const snakeCase = string => {
    return string.replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toUpperCase())
        .join('_');
};

// https://melvingeorge.me/blog/remove-duplicate-elements-from-array-of-objects-javascript
export const removeDuplicates = (data = []) => {
    const uniqueValuesSet = new Set();

    return data.filter((obj) => {
        // check if name property value is already in the set
        const isPresentInSet = uniqueValuesSet.has(obj.name);

        // add name property value to Set
        uniqueValuesSet.add(obj.name);

        // return the negated value of
        // isPresentInSet variable
        return !isPresentInSet;
    });
}

export const extractErrorMessageFromResponse = (error, form = null) => {
    const errors = error?.response?.data?.errors;
    const message = error?.response?.data?.message;

    // If there are many messages, join them all
    if (errors) {
        let errorEntries = Object.entries(errors)
        if (errorEntries.length) {
            if (form !== null) {
                console.log(errors)
                errorEntries.map(function ([field,[error]]) {
                     return form.setFieldError(field, error);
                });
               return ;
            }
            const ERROR_JOINER = '. '

            return errorEntries.reduce((message, [errorName, errorMessages]) => {
                return `${errorMessages.join(ERROR_JOINER)}${ERROR_JOINER}${message}`
            }, '')
        }
    }

    if (message) {
        return message;
    }

    if (Object.keys(errors || {}).length === 0) {
        return null;
    }

    const firstError = Object.keys(errors)[0];
    return errors[firstError];
};
