import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../lib/modals';
import { URLS } from '../../lib/api';
import FormError from '../FormError';
import { post } from '../../lib/restClient';
import { useMutation } from 'react-query';
import { useDocumentPurifier } from '../../hooks/useHtmlPurifier';

Modal.setAppElement('#root')

export default ({ isOpen, setIsOpen, documents = [], onFinish }) => {
  const [step, setStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [areTermsAndConditionsChecked, setAreTermsAndConditionsChecked] = useState(0);
  const documentPurifier = useDocumentPurifier();
  const { mutate: signContract } = useMutation(
    ({ documentId, productSolutionsId }) => {
      if (!productSolutionsId) {
        return post(URLS.DOCUMENTS.SIGN({ documentId }));
      }
      return post(URLS.DOCUMENTS.PARTNER_AGREEMENT_SIGN({ productSolutionsId, documentId }));
    },
    {
      onSuccess: () => {
        setErrorMessage('');
        if (step === documents.length - 1) {
          setErrorMessage('');
          closeModal();
          setStep(0);
          onFinish();
          return;
        }
        setStep(step + 1);
      },
      onError: (error) => {
        setErrorMessage('Error al firmar documento');
        console.log('ERROR', error);
      }
    }
  );

  function closeModal() {
    setIsOpen(false);
  }
  if (documents?.length === 0) {
    return <></>;
  }
  const currentDocument = documents[step];
  const isLastDocument = step + 1 === documents.length;

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
    >
      <div className="modal-container">
        <div className="modal-content terms-modal">
          <span>(Documento {step + 1} de {documents.length})</span>
          <h2 className="modal-title-type1">{currentDocument.title}</h2>
          <div className="modal-content-scroll">
            <div dangerouslySetInnerHTML={{__html: documentPurifier(currentDocument.description)}}></div>
          </div>
          <form>
            <fieldset className="terms-modal-actions">
              <div className="form-item-checkbox">
                <input
                  type="checkbox"
                  id="chConfirm"
                  name="chConfirm"
                  checked={areTermsAndConditionsChecked}
                  onClick={() => {
                    if (areTermsAndConditionsChecked) {
                      setErrorMessage('Debes marcar los términos y condiciones');
                    } else {
                      setErrorMessage('');
                    }
                    setAreTermsAndConditionsChecked(!areTermsAndConditionsChecked)
                  }}
                />
                <label htmlFor="chConfirm">He leído y estoy de acuerdo</label>
              </div>

              <FormError>{errorMessage}</FormError>

              <button type="button" id="btnNext" name="btnNext" className="btn-type1"
                onClick={() => {
                  if (!areTermsAndConditionsChecked) {
                    setErrorMessage('Debes marcar los términos y condiciones');
                    return;
                  }
                  if (!isLastDocument) {
                    setAreTermsAndConditionsChecked(false);
                    signContract({
                      documentId: currentDocument.id,
                      productSolutionsId: currentDocument.productSolutionsId,
                    });
                  } else {
                    signContract({
                      documentId: currentDocument.id,
                      productSolutionsId: currentDocument.productSolutionsId,
                    });
                  }
                }}>
                {!isLastDocument && 'Siguiente'}
                {isLastDocument && 'Aceptar'}
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </Modal>
  );
}
