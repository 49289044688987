const { API_URL } = require('../config');

const getAbsolutePath = (path) => {
  return `${API_URL}/${path}`;
};

const buildFilters = (filters) => {
  if (!filters) {
    return '';
  }
  return '&' + Object
    .keys(filters)
    .filter(key => filters[key] !== undefined)
    .filter(key => filters[key] !== 'undefined')
    .filter(key => filters[key] !== null)
    .filter(key => filters[key] !== 'null')
    .filter(key => !!filters[key] || filters[key] !== null)
    .map(filter => {
      if (Array.isArray(filters[filter])) {
        return filters[filter]
          .map(value => {
            return `filters[${filter}][]=${value}`;
          })
          .join('&');
      }
      return `filters[${filter}]=${filters[filter]}`
    }).join('&');
};

const buildQueryString = (params = {}) => {
  if (!Object.entries(params).length) {
    return '';
  }
  return '&' + Object
    .keys(params)
    .filter(key => params[key] !== undefined)
    .filter(key => params[key] !== 'undefined')
    .filter(key => params[key] !== null)
    .filter(key => params[key] !== 'null')
    .filter(key => params[key] !== '')
    .filter(key => !!params[key] || params[key] !== null)
    .map(param => {
      if (Array.isArray(params[param])) {
        return params[param]
          .map(value => {
            return value ? `${param}[]=${value}` : '';
          })
          .filter(p => p)
          .join('&');
      }
      return params[param] ? `${param}=${params[param]}` : ''
    })
    .filter(key => params[key] !== '')
    .join('&');
};

const buildSort = (sort) => {
  if (!sort.field) {
    return '';
  }
  return `&sortby=${sort.field}&sort=${sort.order}`;
};

const AUTH = {
  LOGIN: () => getAbsolutePath('login'),
  PASSWORD_REQUEST: () => getAbsolutePath('password/request'),
  PASSWORD_RESET: ({ id }) => getAbsolutePath(`password/request/${id}`),
  CHANGE_PASSWORD: () => getAbsolutePath('password/change'),
  ME: () => getAbsolutePath('me'),
  NOTIFICATIONS_SETTINGS: () => getAbsolutePath('notificationsSettings'),
};

const CLIENT = {
  REGISTER: () => getAbsolutePath('customers'),
  INFO: () => getAbsolutePath('customer'),
  DETAIL: ({ clientId }) => getAbsolutePath(`customer/${clientId}`),
  UPDATE_INFO: ({ userId }) => getAbsolutePath(`customer/${userId}`),
  MY_PRODUCTS: ({ userId }) => getAbsolutePath(`customer/${userId}/licenses`),
  MARK_NOTIFICATION_AS_READ: ({ notificationId }) => getAbsolutePath(`notification/${notificationId}`),
  GET_NOTIFICATIONS_CATEGORIES: () => getAbsolutePath(`notifications/categories`),
  GET_NOTIFICATIONS_IMPORTANCES: () => getAbsolutePath(`notifications/importance`),
  ACTIVATE_KEY: () => getAbsolutePath('product/activate'),
  PRODUCT_TRIALS: () => getAbsolutePath('products-trial'),
  ARCHIVE_NOTIFICATIONS: () => getAbsolutePath(`notification/archived`),
  REMOVE_NOTIFICATIONS: () => getAbsolutePath(`notification/delete`),
  FEATURED_NOTIFICATIONS: () => getAbsolutePath(`notification/featured`),
  OPEN_NOTIFICATIONS: () => getAbsolutePath(`notification/open`),
  NOTIFICATIONS: ({ filters } = {}) => getAbsolutePath(`user/notifications?${buildQueryString(filters)}`),
};

const PARTNER = {
  REGISTER: () => getAbsolutePath('partners'),
  INFO: () => getAbsolutePath('partner'),
  DETAIL: ({ partnerId }) => getAbsolutePath(`partner/${partnerId}`),
  UPDATE_INFO: ({ userId }) => getAbsolutePath(`partner/${userId}`),
  CUSTOMERS: ({ userId, filters }) => getAbsolutePath(`partner/${userId}/customers?${buildFilters(filters)}`),
  DOWNLOAD_PARTNERS_CUSTOMERS_CSV: ({ userId }) => getAbsolutePath(`partner/${userId}/customers`),
  LIST_TYPES: () => getAbsolutePath('partners/types'),
  LIST_STATUS: () => getAbsolutePath('partners/status'),
  LIST_PLANS: () => getAbsolutePath('partners/plans'),
  LIST_AVERAGE_REVENUE_PLANS: () => getAbsolutePath('partners/average-revenue-types'),
  LIST_PARTNER_PRODUCTS: ({ userId }) => getAbsolutePath(`partner/${userId}/product-solutions`),
  ADD_PRODUCT: ({ userId }) => getAbsolutePath(`partner/${userId}/product-solutions`),
  REMOVE_PRODUCT: ({ userId, productId }) => getAbsolutePath(`partner/${userId}/product-solution/${productId}`),
  REVENUE_TYPES: () => getAbsolutePath('partners/average-revenue-types'),
  PARTNER_PLANS: () => getAbsolutePath('partners/plans'),
  PARTNER_TYPES: () => getAbsolutePath('partners/types'),
  PARTNER_STATUS: () => getAbsolutePath('partners/status'),
  SET_CATEGORIZE: ({ partnerId }) => getAbsolutePath(`partner/${partnerId}/categorize`),
  GET_CATEGORIZE: ({ partnerId }) => getAbsolutePath(`partner/${partnerId}/categorize`),
  LIST_ORDERS: ({ page, partnerId, filters, sort }) =>
    getAbsolutePath(`partner/${partnerId}/orders?${buildFilters(filters)}${buildSort(sort)}${(page || page === 0) ? `&page=${page}` : ''}`),
  GET_ORDER_PRODUCTS: ({ partnerId }) =>
    getAbsolutePath(`partner/${partnerId}/order-products`),
  PLACE_ORDER: () => getAbsolutePath('orders'),
  DOWNLOAD_DELIVERY_NOTE: ({ orderId }) => getAbsolutePath(`order/${orderId}/download`),
};

const CONTRACT = {
  SIGN: () => getAbsolutePath('contract/sign'),
};

const PRODUCT = {
  LIST_PRODUCT_TYPES: () => getAbsolutePath('products/types'),
  LIST_PRODUCT_STATUS: () => getAbsolutePath('products/status'),
  IMPORT_STOCKS: () => getAbsolutePath('stocks/import'),
  EDIT_STOCKS: () => getAbsolutePath('stocks/update'),
  ACTIVATE: () => getAbsolutePath('product/activate'),
  SOLUTIONS: () => getAbsolutePath('product-solutions'),
  ORDER_RENEW: ({ productId }) => getAbsolutePath(`license/${productId}/renew`),
  ASK_FOR_TRIAL: ({ productId }) => getAbsolutePath(`product-solution/${productId}/trial`),
  DOCUMENT_DOWNLOAD: ({ documentId }) => getAbsolutePath(`document/${documentId}/download`),
  DETAIL: ({ productId }) => getAbsolutePath(`product/${productId}`),
  GET_ORDER_PRODUCTS: ({ partnerId }) => getAbsolutePath(`partner/${partnerId}/order-products `),
  GET_PRODUCT_VERSION_BY_ID: ({ productId, versionId }) => getAbsolutePath(`product/${productId}/version/${versionId}`),
  PURCHASE_PRICES: ({ productId, versionId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/purchase-prices`),
  UPDATE_PURCHASE_PRICES: ({ productId, versionId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/purchase-prices`),
  PARTNER_PRICES: ({ productId, versionId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/partner-prices`),
  ADD_PARTNER_PRICES: ({ productId, versionId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/partner-prices`),
  DELETE_PARTNER_PRICES: ({ productId, versionId, priceId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/partner-price/${priceId}`),
  UPDATE_PARTNER_PRICE: ({ productId, versionId, priceId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/partner-price/${priceId}`),
  GET_STATUS_DISCOUNT: ({ productId, versionId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/discounts/partner-plan`),
  ADD_STATUS_DISCOUNT: ({ productId, versionId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/discounts/partner-plan`),
  EDIT_STATUS_DISCOUNT: ({ productId, versionId, discountId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/discount/${discountId}`),
  DELETE_STATUS_DISCOUNT: ({ productId, versionId, discountId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/discount/${discountId}`),
  GET_CATEGORY_DISCOUNT: ({ productId, versionId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/discounts/partner-type`),
  ADD_CATEGORY_DISCOUNT: ({ productId, versionId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/discounts/partner-type`),
  EDIT_CATEGORY_DISCOUNT: ({ productId, versionId, discountId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/discount/${discountId}`),
  DELETE_CATEGORY_DISCOUNT: ({ productId, versionId, discountId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/discount/${discountId}`),
  GET_QUANTITY_DISCOUNT: ({ productId, versionId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/discounts/volume`),
  ADD_QUANTITY_DISCOUNT: ({ productId, versionId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/discounts/volume`),
  EDIT_QUANTITY_DISCOUNT: ({ productId, versionId, discountId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/discount/${discountId}`),
  DELETE_QUANTITY_DISCOUNT: ({ productId, versionId, discountId }) =>
    getAbsolutePath(`product/${productId}/version/${versionId}/discount/${discountId}`),
  LIST_VOLUMES_DISCOUNTS: () =>
    getAbsolutePath(`volume-categories`),
  LIST_STOCKS: ({ page, sort, filters }) =>
    getAbsolutePath(`products/stocks?page=${page}${buildSort(sort)}${buildFilters(filters)}`),
  DOWNLOAD_STOCKS_CSV: () =>
    getAbsolutePath('products/stocks'),
  GET_STOCK: ({ productId, page, sort, filters }) =>
    getAbsolutePath(`product/${productId}/stock?page=${page}${buildSort(sort)}${buildFilters(filters)}`),
};

const DOCUMENTS = {
  LIST_DOCUMENT_TYPES: () => getAbsolutePath('documents/types'),
  LIST_DOCUMENTS_BY_USER: ({ userId }) => getAbsolutePath(`user/${userId}/documents`),
  ADD: () => getAbsolutePath(`documents`),
  LIST: () => getAbsolutePath(`documents`),
  DETAIL: ({ documentId }) => getAbsolutePath(`document/${documentId}`),
  UPDATE: ({ documentId }) => getAbsolutePath(`document/${documentId}`),
  UPLOAD_FILE: ({ documentId }) => getAbsolutePath(`document/${documentId}/file`),
  SIGN: ({ documentId }) => getAbsolutePath(`document/${documentId}/sign`),
  PARTNER_AGREEMENT_SIGN: ({ documentId, productSolutionsId }) =>
    getAbsolutePath(`product-solution/${productSolutionsId}/partner-agreement/${documentId}/sign`),
  DOWNLOAD_DOCUMENT_PDF: ({ documentId }) => getAbsolutePath(`document/${documentId}/download`),
}

const ADMIN = {
  DOWNLOAD_CUSTOMERS_CSV: () => getAbsolutePath('customers'),
  DOWNLOAD_PARTNERS_CSV: () => getAbsolutePath('partners'),
  DOWNLOAD_DOCUMENTS_CSV: () => getAbsolutePath('documents'),
  DOWNLOAD_PRODUCTS_CSV: () => getAbsolutePath('products'),
  DOWNLOAD_ORDERS_CSV: () => getAbsolutePath('orders'),
  LIST_ORDERS: ({ page, sort, filters }) =>
    getAbsolutePath(`orders?page=${page}${buildSort(sort)}${buildFilters(filters)}`),
  LIST_CUSTOMERS: ({ page, sort, filters }) =>
    getAbsolutePath(`customers?page=${page}${buildSort(sort)}${buildFilters(filters)}`),
  LIST_PARTNERS: ({ page, sort = {}, filters }) =>
    getAbsolutePath(`partners?${buildSort(sort)}${buildFilters(filters)}${(page || page === 0) ? `&page=${page}` : ''}`),
  LIST_PRODUCTS_VERSIONS: () => getAbsolutePath('administrator/product/version'),
  LIST_DOCUMENTS: ({ page, filters, sort }) => getAbsolutePath(`documents?page=${page}${buildFilters(filters)}${buildSort(sort)}`),
  LIST_PRODUCTS: ({ page, filters, sort }) => getAbsolutePath(`products?page=${page}${buildSort(sort)}${buildFilters(filters)}`),
  LIST_PRODUCT_VERSIONS_BY_ID: ({ productId }) => getAbsolutePath(`product/${productId}/versions`),
  GET_PRODUCT_VERSIONS_BY_ID: ({ productId, versionId }) => getAbsolutePath(`product/${productId}/versions/${versionId}`),
  CREATE_PRODUCT_VERSIONS_BY_ID: ({ productId }) => getAbsolutePath(`product/${productId}/versions`),
  EDIT_PRODUCT_VERSIONS_BY_ID: ({ productId, versionId }) => getAbsolutePath(`product/${productId}/version/${versionId}`),
  EDIT_PRODUCT_PURCHASE_PRICES_EXCEPTIONS_VERSIONS_BY_ID: ({ productId, versionId }) => getAbsolutePath(`product/${productId}/version/${versionId}/purchase-prices`),
  LIST_PRODUCTS_TYPES: () => getAbsolutePath(`products/types`),
  LIST_PRODUCTS_SEGMENTS: () => getAbsolutePath(`product-solutions/segments`),
  LIST_PROVIDERS: () => getAbsolutePath(`providers`),
  UPDATE_PRODUCT: ({ productId }) => getAbsolutePath(`product/${productId}`),
  PRODUCT_VERSION_BY_ID: ({ productId, versionId }) => getAbsolutePath(`product/${productId}/version/${versionId}`),
  CURRENCIES: () => getAbsolutePath(`currencies`),
  CREATE_PRODUCT: () => getAbsolutePath('products'),
  EDIT_PRODUCT: ({ productId }) => getAbsolutePath(`product/${productId}`),
  DEACTIVATE_USER: ({ userId }) => getAbsolutePath(`user/${userId}`),
};

const COUNTRIES = {
  LIST: () => getAbsolutePath('countries'),
};

const ORDERS = {
  LIST: ({ page, sort, filters }) =>
    getAbsolutePath(`orders?page=${page}${buildSort(sort)}${buildFilters(filters)}`),
  GET_BY_ID: ({ orderId }) => getAbsolutePath(`order/${orderId}`),
  EDIT_BY_ID: ({ orderId }) => getAbsolutePath(`order/${orderId}`),
  AVAILABLE_STATES: ({ orderId }) => getAbsolutePath(`order/${orderId}/available-states`),
};

module.exports = {
  URLS: {
    AUTH,
    CLIENT,
    PARTNER,
    CONTRACT,
    PRODUCT,
    ADMIN,
    COUNTRIES,
    DOCUMENTS,
    ORDERS,
  }
};
