import React from 'react';
import { formatPrice } from '../../../../../../../lib/prices';
import SVG from '../../../../../../../components/Svg';

export default ({
  item,
  currencyIsoCode,
  addProductToBasket,
  removeProductFromBasket,
}) => {
  let nextVolumeDiscount;
  let selectedVolumeDiscount;
  let discountAmount;
  let discountExtra;
  let type = 'VOLUME';
  let totalDiscountInPercentage = 0;
  if (item?.price?.volumeDiscounts?.length > 0) {
    for (let i = 0; i < item?.price?.volumeDiscounts.length; i++) {
      const volDiscount = item?.price?.volumeDiscounts[i];
      const volCategory = volDiscount?.volumeCategory;
      if (
        volCategory.quantityFrom > item?.quantity &&
        (nextVolumeDiscount?.volumeCategory?.quantityFrom > volCategory.quantityFrom || !nextVolumeDiscount)
      ) {
        type = 'VOLUME';
        nextVolumeDiscount = volDiscount
      }
      if (
        volCategory.quantityFrom < item?.quantity &&
        item?.quantity <= volCategory.quantityTo) {
        selectedVolumeDiscount = volDiscount;
        type = 'VOLUME';
      }
      if (item?.quantity > volCategory.quantityFrom) {
        selectedVolumeDiscount = volDiscount;
        type = 'VOLUME';
        totalDiscountInPercentage += volDiscount?.discount;
        console.log({ discountByVolumePercentage: volDiscount?.discount });
      }
    }
  }
  let unitPrice = selectedVolumeDiscount ?
    item?.price?.unitPrice - (item?.price?.unitPrice * selectedVolumeDiscount?.discount) / 100 :
    item?.price?.unitPrice;
  if (item?.price?.planDiscount) {
    unitPrice =
      item?.price?.unitPrice
      - (item?.price?.unitPrice * item?.price?.planDiscount?.discount)
      / 100;
    discountAmount = item?.price?.planDiscount?.discount;
    discountExtra = item?.price?.planDiscount?.partnerPlan?.name;
    console.log({ discountByPlanPercentage: discountAmount })
    totalDiscountInPercentage += discountAmount;
    type = 'PLAN';
  }
  if (item?.price?.typeDiscount) {
    unitPrice =
      item?.price?.unitPrice
      - (item?.price?.unitPrice * item?.price?.typeDiscount?.discount)
      / 100;
    discountAmount = item?.price?.typeDiscount?.discount;
    console.log({ discountByTypePercentage: discountAmount });
    totalDiscountInPercentage += discountAmount;
    type = 'TYPE';
  }

  console.log({ totalDiscountInPercentage })

  unitPrice = totalDiscountInPercentage !== 0 ?
    item?.price?.unitPrice - (item?.price?.unitPrice * totalDiscountInPercentage) / 100 :
    item?.price?.unitPrice;
  return (
    <div className="order-item-bl">
      <div className="order-item">
        <SVG
          svg={item?.solution?.svgIcon}
          alt={item?.solution?.name} />
        <h3 className="order-item-title">
          {item?.productName}
          <span>{item?.solutionName}</span>
        </h3>
      </div>

      <div className="order-item-version-info">

        <span className="order-item-version-txt">Versión {item?.version}</span>

        <button type="button" id="btnRemoveItem3" name="btnRemoveItem3" className="btn-remove"
          onClick={() =>
            removeProductFromBasket({
              productId: item.id,
            })
          }>
          <span className="icon-remove"></span>
        </button>
        <div className="form-item-plus-minus legend-info">
          <button type="button" id="btnMinus3" name="btnMinus3" className="btn-plus-minus"
            onClick={() => 
              addProductToBasket({
                product: item,
                quantity: item?.quantity ? item?.quantity - 1 : 0,
                originalUnitPrice: item?.price?.unitPrice,
                discountedUnitPrice: unitPrice,
                discountType: type,
                discountAmount,
                discountExtra,
                currencyIsoCode,
              })
            }>
            <span className="icon-minus"></span>
          </button>

          <input type="number" id="txtPlusMinus3" name="txtPlusMinus3" className="form-text"
            value={item?.quantity}
            onChange={(e) =>
              addProductToBasket({
                product: item,
                quantity: parseInt(e.target.value, 10),
                originalUnitPrice: item?.price?.unitPrice,
                discountedUnitPrice: unitPrice,
                discountType: type,
                discountAmount,
                discountExtra,
                currencyIsoCode,
              })
            }
          />

          <button type="button" id="btnPlus3" name="btnPlus3" className="btn-plus-minus"
            onClick={() =>
              addProductToBasket({
                product: item,
                quantity: item?.quantity + 1,
                originalUnitPrice: item?.price?.unitPrice,
                discountedUnitPrice: unitPrice,
                discountType: type,
                discountAmount,
                discountExtra,
                currencyIsoCode,
              })
            }>
            <span className="icon-plus"></span>
          </button>


          <div className="legend-plus-minus">
            {item?.price?.planDiscount &&
            <>
              <span>
                Por ser partner {item?.price?.planDiscount?.partnerPlan?.name} el precio baja un
              {` ${item?.price?.planDiscount?.discount}%.`}
              </span>
              <br />
              </>
            }

            {item?.price?.typeDiscount &&
              <>
              <span>
                Por ser partner {item?.price?.typeDiscount?.partnerType?.name} el precio baja un
                {` ${item?.price?.typeDiscount?.discount}%.`}
              </span>
              <br />
              </>
            }
            {item?.price?.volumeDiscounts?.length > 0 &&
              <h5 style={{ marginTop: 20, marginBottom: 10}}>DESCUENTOS POR CANTIDAD</h5>
            }
            {item?.price?.volumeDiscounts
              .sort((a, b) =>
                a?.volumeCategory?.quantityFrom - b?.volumeCategory?.quantityFrom
              )
              .map((volumeDiscount) => (
                <>
                <span>
                  Por cantidades superiores a {volumeDiscount?.volumeCategory?.quantityFrom} unidades el precio baja un {volumeDiscount?.discount}% adicional.
                </span>
                <br />
                </>
              ))}
          </div>

        </div>

        <div className="order-item-price-info">
          <span className="price-info-label">Precio base</span>
          <span className={`price-info-total ${totalDiscountInPercentage === 0 ? '' : 'price-real'}`}>
            {formatPrice({
              number: item?.price?.unitPrice,
              currencyIsoCode,
            })}
          </span>
        </div>

        <div className="order-item-price-info">
          <span className="price-info-label">Unidad</span>
          <span className="price-info-total">
            {formatPrice({
              number: unitPrice,
              currencyIsoCode,
            })}
          </span>
        </div>

        <div className="order-item-price-info">
          <span className="price-info-total price-gold">
            {' ' + formatPrice({
              number: (item.discountedUnitPrice || unitPrice) * item?.quantity,
              currencyIsoCode,
            })}
          </span>
        </div>

      </div>
    </div>
  )
}