import React, { forwardRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { URLS } from '../../../../../lib/api';
import Loader from '../../../../../components/Loader';
import StatusDiscountModal from '../../../../../components/StatusDiscountModal';
import { del, get } from '../../../../../lib/restClient';
import EmptyText from '../../../../../components/EmptyText';

export default forwardRef((props, ref) => {
  const queryClient = useQueryClient();
  const { productId, versionId } = useParams();
  const [showStatusDiscountModal, setShowStatusDiscountModal] = useState(false);
  const [discountToEdit, setDiscounToEdit] = useState(null);
  const { mutate: deleteStatusDiscount } = useMutation(
    ({ discountId }) => {
      return del(URLS.PRODUCT.DELETE_STATUS_DISCOUNT({ productId, versionId, discountId }));
    },
    {
      onSuccess: async () => {
        queryClient.refetchQueries(['discounts-status']);
      },
      onError: (error) => {
        console.log('ERROR', error);
      }
    }
  );
  const { error, isLoading, data } = useQuery('discounts-status',
    async () => {
      const statusDiscountsResponse = await get(URLS.PRODUCT.GET_STATUS_DISCOUNT({
        productId, versionId,
      }));
      return {
        statusDiscounts: statusDiscountsResponse.data,
      }
    });
  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <span>Error</span>;
  }
  return (
    <div className="box form-box">
      <h2 className="title-type6">Descuento por status</h2>

      <div className="btn-absolute">
        <button type="button" id="btnAddVersion" name="btnAddVersion" className="btn-type5"
          onClick={() => {
            setDiscounToEdit(null);
            setShowStatusDiscountModal(true);
          }}>
          Añadir descuento
        </button>
      </div>
      {data?.statusDiscounts?.length === 0 &&
        <EmptyText>No hay precios definidos</EmptyText>
      }
      {data?.statusDiscounts?.length > 0 &&
      <div className="responsive-table full-table">
        <table className="table-type2">
          <thead>
            <tr>
              <th>Países
                <button type="button" id="btnOrderItem1" name="btnOrderItem1" className="btn-order up">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th>% Platinum
                <button type="button" id="btnOrderItem2" name="btnOrderItem2" className="btn-order up">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th>% Gold
                                    <button type="button" id="btnOrderItem3" name="btnOrderItem3" className="btn-order">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th>% Silver
                                    <button type="button" id="btnOrderItem4" name="btnOrderItem4" className="btn-order">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th>% Bronze
                                    <button type="button" id="btnOrderItem5" name="btnOrderItem5" className="btn-order">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th>Fecha inicio
                                    <button type="button" id="btnOrderItem6" name="btnOrderItem6" className="btn-order">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th>Fecha fin
                                    <button type="button" id="btnOrderItem7" name="btnOrderItem7" className="btn-order">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th className="centered">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data.statusDiscounts.map(sd => (
              <tr>
                <td className="wrap">
                  {sd.countries.map(c => c.name).join(',')}
                </td>
                <td>
                  {sd.discounts.filter(d => d?.partnerPlan?.name === 'Platinum')[0] ?
                    sd.discounts.filter(d => d?.partnerPlan?.name === 'Platinum')[0].discount : 0}%
                </td>
                <td>
                  {sd.discounts.filter(d => d?.partnerPlan?.name === 'Gold')[0] ?
                    sd.discounts.filter(d => d?.partnerPlan?.name === 'Gold')[0].discount : 0}%
                </td>
                <td>
                  {sd.discounts.filter(d => d?.partnerPlan?.name === 'Silver')[0] ?
                    sd.discounts.filter(d => d?.partnerPlan?.name === 'Silver')[0].discount : 0}%
                </td>
                <td>
                  {sd.discounts.filter(d => d?.partnerPlan?.name === 'Bronze')[0] ?
                    sd.discounts.filter(d => d?.partnerPlan?.name === 'Bronze')[0].discount : 0}%
                </td>
                <td>
                  {sd.dateFrom}
                </td>
                <td>
                  {sd.dateTo}
                </td>
                <td className="centered">
                  <div className="table-btn-container">
                    <button type="button" id="btnShowItem1" name="btnShowItem1" className="btn-icon-border"
                    onClick={()=> {
                      setDiscounToEdit(sd);
                      setShowStatusDiscountModal(true);
                    }}>
                      <span className="icon-edit"></span>
                    </button>
                    <button type="button" id="btnRemoveItem1" name="btnRemoveItem1" className="btn-icon-border"
                      onClick={()=> {
                        deleteStatusDiscount({ productId, versionId, discountId: sd.id });
                      }}>
                      <span className="icon-remove"></span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
}
      {showStatusDiscountModal && (
        <StatusDiscountModal
          productId={productId}
          versionId={versionId}
          discountToEdit={discountToEdit}
          isOpen={showStatusDiscountModal}
          setIsOpen={setShowStatusDiscountModal} />
      )}
    </div>
  );
});
