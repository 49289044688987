import React from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../lib/modals';

Modal.setAppElement('#root')

export default ({ isOpen, setIsOpen, onAccept, text, hasCancelButton }) => {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
    >
      <div className="modal-container">

        <div className="modal-content terms-modal">

          <h2 className="modal-title-type1">{text}</h2>

          <button type="button" id="btnNext" name="btnNext" className="btn-type1"
            onClick={() => {
              onAccept && onAccept();
              closeModal();
            }}>
            Aceptar
          </button>

          { hasCancelButton &&
            <button type="button" id="btnNext" name="btnNext" className="btn-type1"
              onClick={() => closeModal()}>
              Cancelar
            </button>
          }

        </div>

      </div>
    </Modal>
  );
}
