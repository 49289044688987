import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {URLS} from '../../../lib/api';
import {get} from '../../../lib/restClient';
import {nonStandardDateFormatting} from '../../../lib/dates';
import Loader from '../../../components/Loader';
import TablePager from '../../../components/TablePager';
import {Input} from '../../../components/Inputs';
import {useHistory} from 'react-router-dom';
import {downloadFile} from '../../../lib/downloads';
import RightAlignedButton from '../../../components/RightAlignedButton';
import useDataFilter from '../../../hooks/useDataFilter';
import SortArrow from '../../../components/SortArrow';
import DatePicker from '../../../components/DatePicker';

const initialValues = {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    active: null,
};

const Clients = () => {
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(0);
    const {
        filtersValues,
        setFilterValue,
        submitFilters,
        sort,
        setSortField,
    } = useDataFilter({
        initialFilters: initialValues,
        initialSort: {field: 'createdAt', order: 'desc'},
        onRefetch: () => refetch(),
    });
    const [showFilters, setShowFilters] = useState(false);
    const {isLoading, error, data, refetch, isFetching} = useQuery('adminClients', async () => {
        const response = await get(
            URLS.ADMIN.LIST_CUSTOMERS({
                page: currentPage,
                sort,
                filters: filtersValues,
            }),
        );
        return {
            list: response.data,
            pageCount: response.headers['x-page-count'],
            totalCount: response.headers['x-total-count'],
        };
    }, {refetchOnWindowFocus: false});
    useEffect(() => {
        setTimeout(() => refetch(), 600);
        return () => {
        };
    }, [sort, currentPage, refetch]);
    if (error) {
        return <span>Error</span>;
    }
    const showLoader = isLoading || isFetching;
    return (
        <main>
            <form action="#" method="post">
                <div className="box main-header-cms">
                    <div className="main-header-cms-top">
                        <div className="main-header-cms-title">
                            <span className="header-cms-icon icon-clients"></span>
                            <h1 className="header-cms-title">
                                Clientes <span>Relación de los clientes de Numentech</span>
                                {!showLoader && <span className="cms-title-detail">{data.totalCount} clientes</span>}
                            </h1>
                        </div>

                        <div className="main-header-cms-actions">
                            <div className="header-cms-actions-buttons">
                                <button type="button" id="btnFilters" name="btnFilters" className="btn-cms-actions"
                                        onClick={() => setShowFilters(!showFilters)}>
                                    Filtros
                                </button>
                                <button type="button" id="btnDownload" name="btnDownload" className="btn-cms-actions"
                                        onClick={() => downloadFile({
                                            url: URLS.ADMIN.DOWNLOAD_CUSTOMERS_CSV(),
                                            name: 'Clientes.csv',
                                            contentType: 'application/csv',
                                        })}>
                                    Descargar CSV <span className="icon-download-filters"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {showLoader &&
                    <Loader boxed/>
                }

                {!showLoader &&
                    <div className="box">
                        {!showLoader &&
                            <div className={`cms-filters ${showFilters ? 'show' : false}`}>
                                <h2 className="cms-filters-title">Filtros</h2>

                                <button type="button" id="btnCloseFilters" name="btnCloseFilters"
                                        className="btn-close-filters"
                                        onClick={() => setShowFilters(!showFilters)}>
                                    <span className="icon-close-modal"></span>
                                </button>

                                <div className="form-item-row form-row-special">
                                    <div className="form-item filter-size1">
                                        <label className="form-label">Nombre</label>
                                        <Input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            className="form-text"
                                            onChange={(e) => setFilterValue('firstName', e.target.value)}
                                            value={filtersValues.firstName}/>
                                    </div>
                                    <div className="form-item filter-size2">
                                        <label className="form-label">Apellidos</label>
                                        <Input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            className="form-text"
                                            onChange={(e) => setFilterValue('lastName', e.target.value)}
                                            value={filtersValues.lastName}/>
                                    </div>

                                    <div className="form-item filter-size2">
                                        <label className="form-label">Email</label>
                                        <Input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="form-text"
                                            onChange={(e) => setFilterValue('email', e.target.value)}
                                            value={filtersValues.email}/>
                                    </div>

                                    <div className="form-item filter-size1">
                                        <label className="form-label">Teléfono</label>
                                        <Input
                                            type="text"
                                            id="phone"
                                            name="phone"
                                            className="form-text"
                                            onChange={(e) => setFilterValue('phone', e.target.value)}
                                            value={filtersValues.phone}/>
                                    </div>
                                </div>
                                <div className="form-item-row form-row-special">
                                    <div className="form-item filter-size1">
                                        <label className="form-label">Estado</label>
                                        <div className="form-text-container">
                                            <select id="cmbStatus" name="cmbStatus" className="form-select"
                                                    onChange={(e) => setFilterValue('active', e.target.value)}>
                                                <option value={null}></option>
                                                <option selected={filtersValues.active} value={true}>Activo</option>
                                                <option
                                                    selected={!filtersValues.active && filtersValues.active !== null}
                                                    value={false}>Desactivo
                                                </option>
                                            </select>
                                            <span className="icon-arrow select-arrow"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-item-row form-row-special">
                                    <div className="form-item item-special-size2">
                                        <label className="form-label">Fecha de alta</label>
                                        <div className="form-text-container-two">
                                            <DatePicker
                                                placeholder="Desde"
                                                defaultValue={filtersValues['createdAt|from'] ? new Date(filtersValues['createdAt|from']) : null}
                                                onChange={(date) => setFilterValue('createdAt|from', date)}/>
                                            <DatePicker
                                                placeholder="Hasta"
                                                defaultValue={filtersValues['createdAt|to'] ? new Date(filtersValues['createdAt|to']) : null}
                                                onChange={(date) => setFilterValue('createdAt|to', date)}/>
                                        </div>
                                    </div>
                                </div>
                                <RightAlignedButton
                                    onClick={() => {
                                        submitFilters();
                                        setShowFilters(false);
                                        setCurrentPage(0);
                                    }}>
                                    Filtrar
                                </RightAlignedButton>
                            </div>
                        }

                        <div className="responsive-table full-table">
                            <table className="table-type2">
                                <thead>
                                <tr>
                                    <th onClick={() => setSortField({field: 'firstName'})}>
                                        Nombre
                                        <SortArrow sort={sort} field="firstName"/>
                                    </th>
                                    <th onClick={() => setSortField({field: 'lastName'})}>
                                        Apellidos
                                        <SortArrow sort={sort} field="lastName"/>
                                    </th>
                                    <th onClick={() => setSortField({field: 'email'})}>
                                        Email
                                        <SortArrow sort={sort} field="email"/>
                                    </th>
                                    <th onClick={() => setSortField({field: 'phone'})}>
                                        Teléfono
                                        <SortArrow sort={sort} field="phone"/>
                                    </th>
                                    <th onClick={() => setSortField({field: 'type'})}>
                                        Tipología
                                        <SortArrow sort={sort} field="type"/>
                                    </th>
                                    <th>
                                        Estado
                                    </th>
                                    <th onClick={() => setSortField({field: 'createdAt'})}>
                                        fecha de alta
                                        <SortArrow sort={sort} field="createdAt"/>
                                    </th>
                                    <th className="centered">Acciones</th>
                                </tr>
                                </thead>

                                <tbody>
                                {showLoader && <Loader/>}
                                {!showLoader && data.list.map(customer => (
                                    <tr key={customer.id} id={customer.id}>
                                        <td>{customer.firstName}</td>
                                        <td>{customer.lastName}</td>
                                        <td>{customer.email}</td>
                                        <td>{customer.phone}</td>
                                        <td>{customer.type.caption}</td>
                                        <td>{customer.active ? 'Activo' : 'Desactivo'}</td>
                                        <td>{nonStandardDateFormatting(customer.createdAt.date)}</td>
                                        <td className="centered">
                                            <button
                                                type="button"
                                                id="btnEditItem1"
                                                name="btnEditItem1"
                                                className="btn-icon-border"
                                                onClick={() => history.push(`/admin/clients/${customer.id}`)}>
                                                <span className="icon-edit"></span>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {!showLoader &&
                    <TablePager
                        pages={data.pageCount}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        refetch={refetch}/>
                }

            </form>
        </main>
    );
};

export default Clients;