import React from 'react';

const SvgSource = (props) => {
  const generateSvg = () => {
      return "data:image/svg+xml;base64," + 
          btoa(`${props.svg}`);
  }
  return (
    <img className={props.className} src={generateSvg()} alt="icon" />
  );
}

export default SvgSource;
