import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useMutation, useQuery} from 'react-query';
import * as Yup from 'yup';
import {URLS} from '../../../../lib/api';
import Loader from '../../../../components/Loader';
import InfoModal from '../../../../components/InfoModal';
import DeleteUserModal from '../../../../components/DeleteUserModal';
import FormError from '../../../../components/FormError';
import {Input} from '../../../../components/Inputs';
import {get, put} from '../../../../lib/restClient';
import {nonStandardDateFormatting} from '../../../../lib/dates';
import {useFormik} from 'formik';
import {extractErrorMessageFromResponse} from '../../../../lib/utils';

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('Requerido'),
    lastName: Yup.string()
        .required('Requerido'),
    email: Yup.string()
        .email()
        .required('Requerido'),
    type: Yup.string().required(),
    countryIso: Yup.string().required(),
    active: Yup.bool().required(),
});

export default forwardRef((props, ref) => {
    const [alreadyFetched, setAlreadyFetched] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    let [errorMessage, setErrorMessage] = useState('');
    let [cantDelete, setCantDelete] = useState(false);
    const [showModal, setShowModal] = useState(false);
    let {clientId} = useParams();
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            type: '',
            countryIso: 'ESP',
        },
        validationSchema,
        onSubmit: (values) => {
            values.country = countriesData.filter(c => c.name === values.country)[0];
            updateUser(values)
        },
    });

    const {isLoading, error, data} = useQuery('clientDetail', async () => {
        const response = await get(URLS.CLIENT.DETAIL({clientId}));
        if (!alreadyFetched) {
            formik.setFieldValue('firstName', response?.data?.firstName);
            formik.setFieldValue('lastName', response?.data?.lastName);
            formik.setFieldValue('email', response?.data?.email);
            formik.setFieldValue('phone', response?.data?.phone);
            formik.setFieldValue('type', response?.data?.type.id);
            formik.setFieldValue('active', response?.data?.active);
            setAlreadyFetched(true);
            setCantDelete(!response?.data.hasActiveLicences ?? true);
        }
        return response.data;
    });
    const {isLoading: areCountriesLoading, data: countriesData} = useQuery('countries', async () => {
        const response = await get(URLS.COUNTRIES.LIST());
        return response.data;
    });
    const {mutate: updateUser} = useMutation(
        (values) => {
            return put(URLS.CLIENT.UPDATE_INFO({userId: clientId}), values);
        },
        {
            onSuccess: async () => {
                setShowModal(true);
            },
            onError: (error) => {
                setErrorMessage(extractErrorMessageFromResponse(error));
            }
        }
    );
    useImperativeHandle(ref, () => ({
        update() {
            formik.handleSubmit();
        }
    }));
    if (error) {
        return <span>error</span>;
    }
    if (isLoading || areCountriesLoading) {
        return <Loader/>;
    }
    return (
        <div className="box form-box">
            <h2 className="title-type6">Datos del cliente</h2>
            <FormError>{errorMessage}</FormError>
            <div className="main-header-cms-top">
                <div className="main-header-cms-actions">
                    {cantDelete &&
                        < button type="button" id="btnSave" name="btnSave" className="btn-type5"
                                 onClick={() => setDeleteModalOpen(true)}>
                            Eliminar
                        </button>
                    }
                </div>
            </div>
            <div className="form-item-row">

                <div className="form-item">
                    <label className="form-label">Nombre *</label>
                    <div className="form-text-container">
                        <Input type="text" id="txtFirst" name="txtFirst" className="form-text"
                               value={formik?.values?.firstName}
                               onChange={(e) => formik.setFieldValue('firstName', e.target.value)}/>
                    </div>
                    <FormError>{formik?.touched.firstName && formik?.errors?.firstName}</FormError>

                </div>

                <div className="form-item">
                    <label className="form-label">Apellidos *</label>

                    <Input type="text" id="txtLast" name="txtLast" className="form-text"
                           value={formik?.values?.lastName}
                           onChange={(e) => formik.setFieldValue('lastName', e.target.value)}/>
                    <FormError>{formik?.touched?.lastName && formik?.errors?.lastName}</FormError>

                </div>

                <div className="form-item">
                    <label className="form-label">Email *</label>

                    <Input type="email" id="txtMail" name="txtMail" className="form-text"
                           value={formik?.values?.email}
                           onChange={(e) => formik.setFieldValue('email', e.target.value)}/>
                    <FormError>{formik?.touched?.email && formik?.errors?.email}</FormError>
                </div>

            </div>
            <div className="form-item-row">
                <div className="form-item">
                    <label className="form-label">Teléfono</label>

                    <Input type="text" id="txtPhone" name="txtPhone" className="form-text"
                           value={formik?.values?.phone}
                           onChange={(e) => formik.setFieldValue('phone', e.target.value)}/>
                    <FormError>{formik?.touched?.phone && formik?.errors?.phone}</FormError>
                </div>

                <div className="form-item">
                    <label className="form-label">Tipología</label>

                    <div className="form-text-container">
                        <select id="cmbType" name="cmbType" className="form-select"
                                onChange={(e) => formik.setFieldValue('type', e.target.value)}>
                            <option
                                selected={formik?.values?.type === 'particular'}
                                value="individual">Particular
                            </option>
                            <option
                                selected={formik?.values?.type === 'company'}
                                value="company">Empresa
                            </option>
                        </select>
                        <span className="icon-arrow select-arrow"></span>
                    </div>
                </div>

                <div className="form-item">
                    <label className="form-label">Fecha de alta</label>

                    <div className="form-text-container">
                        <input type="text" id="txtDate" name="txtDate" className="form-text" disabled
                               value={nonStandardDateFormatting(data.createdAt?.date)}/>
                    </div>
                </div>
            </div>
            <div className="form-item-row">
                <div className="form-item">
                    <label className="form-label">País</label>
                    <div className="form-text-container">
                        <select id="cmbCountry" name="cmbCountry" className="form-select"
                                onChange={(e) => formik.setFieldValue('country', e.target.value)}>
                            {countriesData.map(country => (
                                <option selected={data.country.name === country.name}>{country.name}</option>
                            ))}
                        </select>
                        <span className="icon-arrow select-arrow"></span>
                    </div>
                </div>
                <div className="form-item">
                    <label className="form-label">Estado</label>
                    <div className="form-text-container">
                        <select id="cmbStatus" name="cmbStatus" className="form-select"
                                onChange={(e) => formik.setFieldValue('active', e.target.value === 'true')}>
                            <option selected={data.active} value={true}>Activo</option>
                            <option selected={!data.active} value={false}>Desactivo</option>
                        </select>
                        <span className="icon-arrow select-arrow"></span>
                    </div>
                </div>
            </div>
            <InfoModal
                isOpen={showModal}
                setIsOpen={setShowModal}
                title="Datos guardados"
                subtitle="Datos guardados correctamente"
            />
            <DeleteUserModal
                type="Cliente"
                isOpen={isDeleteModalOpen}
                setIsOpen={setDeleteModalOpen}/>
        </div>
    )
        ;
});
