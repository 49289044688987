import styled from 'styled-components';

export const FormError = styled.label`
  color: #A21C22;
  font-size: .875rem;
  line-height: .9375rem;
  display: block;
  margin-top: .5rem;
  margin-bottom: .875rem;
`;
