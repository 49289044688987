import React from 'react';

export const Button = ({ children, ...rest }) => (
  <button {...rest} className="btn-type1">
    {children}
  </button>
);

export const IconButton = ({ children, icon, ...rest }) => (
  <button className="btn-type1 btn-icon" {...rest}>
    {children} <span className={icon}></span>
  </button>
);
