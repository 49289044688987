import React from 'react';

export default ({ children }) => (
  <span style={{
    fontSize: '.875rem',
    color: '#656364',
    lineHeight: '1.25rem',
    marginTop: 20,
    marginBottom: 20,
  }}>{children}</span>
);
