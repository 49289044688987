export const transformPriceExceptions = (priceExceptions) => {
  const pricesExceptionsMapped = [];
  if (priceExceptions) {
    priceExceptions.forEach(pe => {
      let selectedIndex = -1;
      for (let i = 0; i < pricesExceptionsMapped.length; i++) {
        const currentPriceException = pricesExceptionsMapped[i];
        if (currentPriceException?.currency === pe?.country?.currency?.name &&
          currentPriceException?.price === pe.price) {
          selectedIndex = i;
        }
      }
      if (selectedIndex === -1) {
        pricesExceptionsMapped.push({
          country: pe?.country?.name,
          countryIsoCodes: [pe?.country.iso_code],
          currency: pe?.country?.currency?.name,
          price: pe?.price,
        });
      } else {
        pricesExceptionsMapped[selectedIndex].country = `${pricesExceptionsMapped[selectedIndex].country}, ${pe?.country?.name}`
        pricesExceptionsMapped[selectedIndex].countryIsoCodes.push(pe?.country.iso_code);
      }
    });
    return pricesExceptionsMapped;
  }
};

export const formatPrice = ({ number, currencyIsoCode }) => {
  if (!number && number !== 0) {
    return '';
  }
  var formatter = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    minimumFractionDigits: 2,
    useGrouping: true,
    currency: currencyIsoCode,
  });
  return formatter.format(number);
};
