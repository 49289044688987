import React from 'react';
import ReactSelect from 'react-select';
import FormError from '../FormError';
import { URLS } from '../../lib/api';
import { get } from '../../lib/restClient';
import { useQuery } from 'react-query';
import { groupBy } from '../../lib/utils';
import Loader from '../../components/Loader';

const selectContainerStyles = {
  menu: () => ({
    backgroundColor: 'rgba(243, 246, 248, .5)',
  }),
  valueContainer: () => ({
    backgroundColor: 'rgba(243, 246, 248, .5)',
    height: 43,
    paddingLeft: 10,
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'red',
    color: 'red',
  }),
  dropdownIndicator: () => ({
    color: '#A21C22',
    marginRight: '10px',
    marginTop: '3px',
  })
};

export default ({
  onChange,
  touched,
  errorMessage,
  selectedCountries,
  mustHaveCurrency,
  readOnly,
}) => {
  const { isLoading, error, data } = useQuery('getStaticDataForClasification', async () => {
    const response = await get(URLS.COUNTRIES.LIST());
    const filteredCountries = mustHaveCurrency ?
      response.data.filter(country => !!country.currency) :
      response.data;
    let grouped = groupBy(filteredCountries, 'continent');
    const groupedCountries = [];
    Object.keys(grouped).forEach(continent => {
      groupedCountries.push({
        label: continent,
        options: grouped[continent].map(country => ({
          value: country.iso_code,
          label: country.name,
        })),
      })
    });
    return {
      countries: response.data,
      groupedCountries,
    };
  });
  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    console.log('COUNTRIES SELECT', error);
    return;
  }
  return (
    <>
      <div className="form-item">
        {!readOnly &&
          <ReactSelect
            styles={selectContainerStyles}
            placeholder="Selecciona paises"
            onChange={(e) => {
              const selectedCountry = data.countries.find(country => {
                return country.iso_code === e.value
              });
              onChange([...selectedCountries, selectedCountry]);
            }}
            options={data.groupedCountries} />
        }
        {
          touched && errorMessage &&
          <FormError>{errorMessage}</FormError>
        }
      </div>
      <div className="search-tags-container">
        <div className="search-tags-container">
          {selectedCountries.map(country => (
            <span key={country.iso_code} className={`search-tag ${readOnly ? '' : 'icon-close-modal'}`}
              onClick={() => {
                const filteredCountries = selectedCountries
                  .filter((e) => e.iso_code !== country.iso_code);
                onChange(filteredCountries);
              }}>
              {country.name}
            </span>
          ))}
        </div>
      </div>
    </>
  );
};
