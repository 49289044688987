import React, {useState} from 'react'
import Modal from 'react-modal'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import * as Yup from 'yup'
import {customStyles} from '../../lib/modals'
import {get, post, put} from '../../lib/restClient'
import {URLS} from '../../lib/api'
import Loader from '../../components/Loader'
import {Input} from '../Inputs'
import {useFormik} from 'formik'
import {FormError} from '../FormError/styles'
import CountrySearcher from '../CountrySearcher'
import {addDays, format, parseISO} from 'date-fns'
import DatePicker from '../DatePicker'
import {extractErrorMessageFromResponse} from '../../lib/utils'

Modal.setAppElement('#root')

const ValidationSchema = Yup.object().shape({
    partnerPlans: Yup.object().required('Requerido').typeError('Debe '),
    countries: Yup.array().required('Requerido').min(1, 'Debes seleccioanr al menos un país'),
    from: Yup.string().required('Requerido'),
    to: Yup.string().required('Requerido'),
})

export default ({
                    isOpen,
                    setIsOpen,
                    productId,
                    versionId,
                    discountToEdit,
                }) => {
    const queryClient = useQueryClient()

    const [errorMessage, setErrorMessage] = useState('')
    const formik = useFormik({
        initialValues: {
            countries: [],
            from: format(new Date(), 'y-MM-dd'),
            to: format(addDays(new Date(), 1), 'y-MM-dd'),
            partnerPlans: null,
        },
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
            const body = {...values}
            body.countries = values.countries.map((country) => country.iso_code)
            body.from = format(parseISO(values.from), 'y-MM-dd')
            body.to = format(parseISO(values.to), 'y-MM-dd')
            body.partnerPlans = []
            Object.keys(values.partnerPlans).forEach((pp) => {
                if (values.partnerPlans[pp]) {
                    body.partnerPlans.push({
                        id: pp,
                        discount: values.partnerPlans[pp],
                    })
                }
            })
            if (discountToEdit) {
                updateStatusDiscount({discountId: discountToEdit.id, body})
            } else {
                addStatusDiscount(body)
            }
        },
    })

    const {error, isLoading, data} = useQuery(
        'getPartners-statusdiscount',
        async () => {
            const partnerPlansResponse = await get(URLS.PARTNER.LIST_PLANS())
            const plans = partnerPlansResponse.data
            if (discountToEdit && !formik?.values?.countries.length) {
                formik.setFieldValue('countries', discountToEdit.countries)
                formik.setFieldValue(
                    'from',
                    format(new Date(discountToEdit.dateFrom), 'y-MM-dd')
                )
                formik.setFieldValue(
                    'to',
                    format(new Date(discountToEdit.dateTo), 'y-MM-dd')
                )
                const newDiscounts = {}
                discountToEdit.discounts.forEach((dte) => {
                    newDiscounts[dte.partnerPlan.id] = dte.discount
                })
                formik.setFieldValue('partnerPlans', newDiscounts)
            } else {
                plans.forEach((plan) => {
                    formik.setFieldValue(`partnerPlans.${plan.id}`, null)
                })
            }
            return {
                plans,
            }
        }
    )
    const {mutate: addStatusDiscount} = useMutation(
        (body) =>
            post(
                URLS.PRODUCT.ADD_STATUS_DISCOUNT({
                    productId,
                    versionId,
                }),
                body
            ),
        {
            onSuccess: () => {
                setIsOpen(false)
                queryClient.refetchQueries(['discounts-status'])
            },
            onError: (error) => {
                setErrorMessage(extractErrorMessageFromResponse(error, formik))
            },
        }
    )
    const {mutate: updateStatusDiscount} = useMutation(
        ({discountId, body}) =>
            put(
                URLS.PRODUCT.EDIT_STATUS_DISCOUNT({
                    productId,
                    versionId,
                    discountId,
                }),
                body
            ),
        {
            onSuccess: () => {
                setIsOpen(false)
                queryClient.refetchQueries(['discounts-status'])
            },
            onError: (error) => {
                setErrorMessage(extractErrorMessageFromResponse(error, formik))
            },
        }
    )
    if (error) {
        return <span>Error</span>
    }
    return (
        <Modal isOpen={isOpen} style={customStyles}>
            <div className="modal-container">
                <div className="modal-content cms-modal">
                    <form action="#" method="post">
                        <h2 className="modal-title-type1">Añadir descuento</h2>
                        <p className="cms-modal-desc">
                            Selecciona los países y añade los descuentos
                        </p>

                        <div className="form-item">
                            <label className="form-label">País</label>
                            <CountrySearcher
                                onChange={(selectedCountries) => {
                                    formik.setFieldValue('countries', selectedCountries)
                                }}
                                mustHaveCurrency
                                selectedCountries={formik?.values?.countries}
                                touched={formik?.touched?.countries}
                                errorMessage={formik?.errors?.countries}
                            />
                        </div>
                        <label className="form-label">Duración</label>
                        <div className="form-item-row">
                            <div className="form-item-col  modal-col-size1">
                                <div className="form-item">

                                    <DatePicker
                                        placeholder="Desde"
                                        defaultValue={new Date(formik?.values?.from)}
                                        onChange={(date) => formik?.setFieldValue('from', date)}
                                    />
                                    {formik?.touched?.from && formik?.errors?.from && (
                                        <FormError>{formik?.errors?.from}</FormError>
                                    )}   </div>
                            </div>
                            <div className="form-item-col  modal-col-size1">
                                <div className="form-item">
                                    <DatePicker
                                        placeholder="Hasta"
                                        defaultValue={new Date(formik?.values?.to)}
                                        onChange={(date) => formik?.setFieldValue('to', date)}
                                    />
                                    {formik?.touched?.to && formik?.errors?.to && (
                                        <FormError>{formik?.errors?.to}</FormError>
                                    )}
                                </div>
                            </div>

                        </div>
                        <div className="form-item-row">
                            {isLoading && <Loader/>}
                            {!isLoading &&
                                data.plans.map((pp) => (
                                    <div key={pp.id} className="form-item-col modal-col-size5">
                                        <div className="form-item">
                                            <label className="form-label">% {pp.name}</label>
                                            <Input
                                                type="text"
                                                id="txtPlatinum"
                                                name="txtPlatinum"
                                                className="form-text"
                                                onChange={(e) =>
                                                    formik.setFieldValue(
                                                        `partnerPlans.${pp.id}`,
                                                        e.target.value
                                                    )
                                                }
                                                value={
                                                    formik?.values?.partnerPlans
                                                        ? formik?.values?.partnerPlans[pp.id]
                                                        : 0
                                                }
                                            />
                                            {formik?.touched?.partnerPlans &&
                                                formik?.errors?.partnerPlans && (
                                                    <FormError>{formik?.errors?.partnerPlans}</FormError>
                                                )}
                                        </div>

                                    </div>

                                ))}

                        </div>


                        {errorMessage && <FormError>{errorMessage}</FormError>}

                        <div className="cms-modal-actions">
                            <button
                                type="button"
                                id="btnCancelModal"
                                name="btnCancelModal"
                                className="btn-type4 small"
                                onClick={() => setIsOpen(false)}
                            >
                                Cancelar
                            </button>

                            <button
                                type="button"
                                id="btnAddModal"
                                name="btnAddModal"
                                className="btn-type5 big"
                                onClick={() => formik.handleSubmit()}
                            >
                                {discountToEdit ? 'Guardar' : 'Añadir'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}
