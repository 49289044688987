import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import * as Yup from 'yup';
import {useFormik} from 'formik';

import {URLS} from '../../../../../lib/api';
import Loader from '../../../../../components/Loader';
import PartnerPriceModal from '../../../../../components/PartnerPriceModal';
import { get, put, del } from '../../../../../lib/restClient';
import EmptyText from '../../../../../components/EmptyText';

const ValidationSchema = Yup.object().shape({
    partnerPrices: Yup.object(),
});

export default forwardRef((props, ref) => {
    const queryClient = useQueryClient();
    const {productId, versionId} = useParams();
    const [showPartnerPriceModal, setShowPartnerPriceModal] = useState(false);
    const [partnerPriceModalToEdit, setPartnerPriceModalToEdit] = useState(null);
    const formik = useFormik({
        initialValues: {
            purchasePrices: null,
            purchasePriceExceptions: null,
        },
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
            updatePurchasePrices(values);
        },
    });
    useImperativeHandle(ref, () => ({
        update() {
            formik.handleSubmit();
        }
    }));
    const {mutate: updatePurchasePrices} = useMutation(
        (values) => {
            const data = Object
                .keys(values.purchasePrices)
                .map(currency => ({
                    currency,
                    price: values.purchasePrices[currency],
                }))
            return put(URLS.PRODUCT.UPDATE_PURCHASE_PRICES({productId, versionId}),
                {purchasePrices: data});
        },
        {
            onSuccess: async () => {

            },
            onError: (error) => {
                console.log('ERROR', error);
            }
        }
    );
    const {mutate: deletePartnerPrice} = useMutation(
        ({priceId}) => {
            return del(URLS.PRODUCT.DELETE_PARTNER_PRICES({productId, versionId, priceId}),
                {purchasePrices: data});
        },
        {
            onSuccess: async () => {
                queryClient.refetchQueries(['discounts-and-prices-partnerPrice']);
            },
            onError: (error) => {
                console.log('ERROR', error);
            }
        }
    );
    const {error, isLoading, data} = useQuery('discounts-and-prices-partnerPrice',
        async () => {
            const currenciesResponse = await get(URLS.ADMIN.CURRENCIES());
            const partnerPriceResponse = await get(URLS.PRODUCT.PARTNER_PRICES({productId, versionId}));
            return {
                currencies: currenciesResponse.data,
                partnerPrices: partnerPriceResponse.data,
            }
        });
    if (isLoading) {
        return <Loader/>;
    }
    if (error) {
        return <span>Error</span>;
    }
    return (
        <div className="box form-box">
            <h2 className="title-type6">Precio partner</h2>
            <div className="btn-absolute">
                <button type="button" id="btnAddVersion" name="btnAddVersion" className="btn-type5"
                        onClick={() => {
                            setPartnerPriceModalToEdit(null);
                            setShowPartnerPriceModal(true);
                        }}>
                    Añadir precio
                </button>
            </div>
            {data?.partnerPrices?.length === 0 &&
                <EmptyText>No hay precios definidos</EmptyText>
            }
            {data?.partnerPrices?.length > 0 &&
                <div className="responsive-table full-table">
                    <table className="table-type2 rowspan-body">
                        <thead>
                        <tr>
                            <th>Nombre partner <button type="button" id="btnOrderItem1" name="btnOrderItem1"
                                                       className="btn-order up">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th>Países <button type="button" id="btnOrderItem2" name="btnOrderItem2"
                                               className="btn-order up">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th>Precio <button type="button" id="btnOrderItem3" name="btnOrderItem3"
                                               className="btn-order">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th>Moneda <button type="button" id="btnOrderItem4" name="btnOrderItem4"
                                               className="btn-order">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th className="centered">Acciones</th>
                        </tr>
                        </thead>
                        {data.partnerPrices.map(pp => (
                            <tbody key={pp?.id}>
                            <tr>
                                <td rowSpan="2">{pp?.partner?.organizationName}</td>
                                <td className="wrap">
                                    {pp?.country?.name}
                                </td>
                                <td>
                                    {pp?.buyPrice}
                                </td>
                                <td>
                                    {pp?.country?.currency?.name}
                                </td>
                                <td className="centered">
                                    <div className="table-btn-container">
                                        <button type="button" id="btnShowItem1" name="btnShowItem1"
                                                className="btn-icon-border"
                                                onClick={() => {
                                                    setShowPartnerPriceModal(true);
                                                    setPartnerPriceModalToEdit(pp);
                                                }}>
                                            <span className="icon-edit"></span>
                                        </button>
                                        <button type="button" id="btnRemoveItem1" name="btnRemoveItem1"
                                                className="btn-icon-border"
                                                onClick={() => {
                                                    deletePartnerPrice({productId, versionId, priceId: pp.id});
                                                }}>
                                            <span className="icon-remove"></span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            }
            {showPartnerPriceModal && (
                <PartnerPriceModal
                    productId={productId}
                    versionId={versionId}
                    partnerPriceModalToEdit={partnerPriceModalToEdit}
                    isOpen={showPartnerPriceModal}
                    setIsOpen={setShowPartnerPriceModal}/>
            )}
        </div>
    );
});
