import React, {useEffect, useState} from 'react';

export default ({pages, currentPage, setCurrentPage}) => {
    const [window, setWindow] = useState([]);
    useEffect(() => {
        const arr = Array.from({length: pages}, (_, i) => i);
        if (currentPage < 5) {
            const w = arr.slice(0, 5);
            setWindow(w);
        } else if (currentPage > pages - 4) {
            const w = arr.slice(-5);
            setWindow(w);
        } else {
            const w = arr.slice(currentPage - 2, currentPage + 1);
            setWindow(w);
        }
        setCurrentPage(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage,pages,setCurrentPage]);


    return (
        <div className="pager">
            <ul>
                {currentPage !== 0 &&
                    <li
                        className="pager-prev"
                        onClick={() => {
                            setCurrentPage(currentPage - 1);
                        }}>
                        Anterior
                    </li>
                }
                {!window.includes(0) && <>
                    <li
                        key={0}
                        onClick={() => setCurrentPage(0)}
                        className={0 === currentPage ? 'pager-active' : ''}>
                        {1}
                    </li>
                    {pages > 5 && <span className="dots">...</span>}</>}

                {window.map((ele) =>
                    (ele < pages - 1 &&
                        <li
                            key={ele}
                            onClick={() => setCurrentPage(ele)}
                            className={ele === currentPage ? 'pager-active' : ''}>
                            {ele + 1}
                        </li>))}

                {!window.includes(pages - 1) && <>
                    {pages > 5 && <span className="dots">...</span>}</>}
                <li
                    key={pages - 1}
                    onClick={() => setCurrentPage(pages - 1)}
                    className={pages - 1 === currentPage ? 'pager-active' : ''}>
                    {pages}
                </li>
                {(currentPage < (pages - 1)) &&
                    <li
                        className="pager-next"
                        onClick={() => {
                            setCurrentPage(currentPage + 1);
                        }}>
                        Siguiente
                    </li>
                }
            </ul>

        </div>
    );
};
