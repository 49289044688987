import React from 'react';

export default ({ status = 'active' }) => (
  <>
    {status === 'active' &&
      <span className="status-tag active">Activo</span>
    }
    {status === 'approved' &&
      <span className="status-tag active">Aprobado</span>
    }
    {status === 'about_to_expire' &&
      <span className="status-tag next-expire">Va a caducar</span>
    }
    {status === 'refused' &&
      <span className="status-tag next-expire">Rechazado</span>
    }
    { status === 'inactive' &&
      <span className="status-tag inactive">Inactivo</span>
    }
    { status === 'pending' &&
      <span className="status-tag next-expire">Pendiente</span>
    }
  </>
)