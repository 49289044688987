import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {useFormik} from 'formik';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {URLS} from '../../../../../lib/api';
import {transformPriceExceptions} from '../../../../../lib/prices';
import Loader from '../../../../../components/Loader';
import {Input} from '../../../../../components/Inputs';
import InfoModal from '../../../../../components/InfoModal';
import {get, put} from '../../../../../lib/restClient';
import PriceExceptionsModal from '../../../../../components/PriceExceptionsModal';
import {useParams} from 'react-router-dom';
import EmptyText from '../../../../../components/EmptyText';
import {extractErrorMessageFromResponse} from '../../../../../lib/utils';

const ValidationSchema = Yup.object().shape({
    purchasePrices: Yup.object(),
});

export default forwardRef((props, ref) => {
    const queryClient = useQueryClient();
    const {productId, versionId} = useParams();
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showPriceExceptionsModal, setShowPriceExceptionsModal] = useState(false);
    const [editablePriceExceptionModal, setEditablePriceExceptionModal] = useState(null);
    const formik = useFormik({
        initialValues: {
            purchasePrices: null,
            purchasePriceExceptions: null,
        },
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
            updatePurchasePrices(values);
        },
    });
    useImperativeHandle(ref, () => ({
        update() {
            formik.handleSubmit();
        }
    }));
    const {mutate: updatePurchasePrices} = useMutation(
        (values) => {
            const data = Object
                .keys(values.purchasePrices)
                .map(currency => ({
                    currency,
                    price: values.purchasePrices[currency],
                }))
            return put(URLS.PRODUCT.UPDATE_PURCHASE_PRICES({productId, versionId}),
                {purchasePrices: data});
        },
        {
            onSuccess: async () => {
                setShowModal(true);
            },
            onError: (error) => {
                console.log('ERROR', error);
                setErrorMessage(extractErrorMessageFromResponse(error));
            }
        }
    );
    const {mutate: updatePriceExceptions} = useMutation(
        ({purchasePriceExceptions}) => put(URLS.ADMIN.EDIT_PRODUCT_PURCHASE_PRICES_EXCEPTIONS_VERSIONS_BY_ID({
            productId,
            versionId,
        }), {
            purchasePriceExceptions: purchasePriceExceptions.map(pe => (
                pe.country.iso_code ?
                    {country: pe.country.iso_code, price: pe.price} :
                    pe
            ))
        }),
        {
            onSuccess: () => {
                setShowPriceExceptionsModal(false);
                setEditablePriceExceptionModal(null);
                queryClient.refetchQueries(['discounts-and-prices-regularPrice']);
            },
            onError: (error) => {
                setErrorMessage(extractErrorMessageFromResponse(error));
                console.log('ERROR', error);
            }
        }
    );
    const {error, isLoading, data} = useQuery('discounts-and-prices-regularPrice',
        async () => {
            const currenciesResponse = await get(URLS.ADMIN.CURRENCIES());
            const regularPriceResponse = await get(URLS.PRODUCT.PURCHASE_PRICES({productId, versionId}));

            // regularPrice
            const purchasePrices = {};
            if (regularPriceResponse.data.purchasePrices && !formik?.values?.purchasePrices) {
                regularPriceResponse.data.purchasePrices.forEach(price => {
                    if (price?.price) {
                        purchasePrices[price.currency] = price.price;
                    }
                });
                formik.setFieldValue('purchasePrices', purchasePrices);
                formik.setFieldValue('purchasePriceExceptions', regularPriceResponse.data.purchasePriceExceptions);
            }
            return {
                currencies: currenciesResponse.data,
                regularPrices: regularPriceResponse.data,
            }
        });
    if (isLoading) {
        return <Loader/>;
    }
    if (error) {
        return <span>Error</span>;
    }
    const priceExceptionsTransformed = transformPriceExceptions(formik?.values?.purchasePriceExceptions);
    return (
        <div className="box form-box">
            <h2 className="title-type6">Precio base</h2>

            <div className="form-item-row small-row">
                {formik?.values?.purchasePrices && data?.currencies?.map(currency => (
                    <div key={currency.id} className="form-item">
                        <label className="form-label">{currency.name}</label>
                        <Input type="number" id="txtEuro" name="txtEuro" className="form-text"
                               onChange={(e) => {
                                   if (e.target.value === '') {
                                       formik.setFieldValue(`purchasePrices.${currency.iso_code}`, null);
                                   } else {
                                       formik.setFieldValue(`purchasePrices.${currency.iso_code}`, parseFloat(e.target.value));
                                   }
                               }}
                               value={formik?.values?.purchasePrices[currency.iso_code] || 0}/>
                    </div>
                ))}

                <button type="button" id="btnAdd" name="btnAdd" className="btn-type5 al-right"
                        onClick={() => {
                            setEditablePriceExceptionModal(null);
                            setShowPriceExceptionsModal(true);
                        }}>
                    Añadir excepción
                </button>

            </div>

            {(!priceExceptionsTransformed || priceExceptionsTransformed.length === 0) &&
                <EmptyText>No hay excepciones definidas</EmptyText>
            }
            {priceExceptionsTransformed?.length > 0 &&
                <div className="responsive-table full-table">
                    <table className="table-type2">
                        <thead>
                        <tr>
                            <th>Países <button type="button" id="btnOrderItem1" name="btnOrderItem1"
                                               className="btn-order up">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th>Precio <button type="button" id="btnOrderItem2" name="btnOrderItem2"
                                               className="btn-order">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th>Moneda <button type="button" id="btnOrderItem3" name="btnOrderItem3"
                                               className="btn-order">
                                <span className="icon-arrow2"></span>
                            </button>
                            </th>
                            <th className="centered">Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {priceExceptionsTransformed?.map(pe => (
                            <tr key={pe?.country.id}>
                                <td className="wrap">
                                    {pe?.country}
                                </td>
                                <td>
                                    {pe?.price}
                                </td>
                                <td>
                                    {pe?.currency}
                                </td>
                                <td className="centered">
                                    <div className="table-btn-container">
                                        <button type="button" id="btnShowItem1" name="btnShowItem1"
                                                className="btn-icon-border"
                                                onClick={() => {
                                                    setShowPriceExceptionsModal(true);
                                                    setEditablePriceExceptionModal(pe);
                                                }}>
                                            <span className="icon-edit"></span>
                                        </button>
                                        <button type="button" id="btnRemoveItem1" name="btnRemoveItem1"
                                                className="btn-icon-border"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const countryName = pe?.country.split(',').map(c => c.trim());
                                                    const newPurchasePriceExceptions = formik?.values?.purchasePriceExceptions
                                                        .filter(exception => countryName.indexOf(exception?.country?.name) === -1);
                                                    formik.setFieldValue('purchasePriceExceptions', newPurchasePriceExceptions);
                                                    updatePriceExceptions({purchasePriceExceptions: newPurchasePriceExceptions});
                                                }}>
                                            <span className="icon-remove"></span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            }
            <InfoModal
                isOpen={showModal}
                setIsOpen={setShowModal}
                title="Datos guardados"
                subtitle="Datos guardados correctamente"
            />
            {showPriceExceptionsModal &&
                <PriceExceptionsModal
                    isOpen={showPriceExceptionsModal}
                    errorMessage={errorMessage}
                    setIsOpen={setShowPriceExceptionsModal}
                    priceException={editablePriceExceptionModal}
                    onFinish={(values) => {
                        console.log('values');
                        console.log(values);
                        const priceExceptions = values.countries.map(country => ({
                            country: country.iso_code,
                            price: values.price,
                        }));
                        formik.setFieldValue('purchasePriceExceptions', (formik?.values?.purchasePriceExceptions ? [
                            ...formik?.values?.purchasePriceExceptions,
                            ...values.countries.map(country => ({
                                country,
                                price: values.price,
                            }))
                        ] : values.countries.map(country => ({
                            country,
                            price: values.price,
                        }))));
                        const priceExceptionsValues = formik?.values?.purchasePriceExceptions ? [...formik?.values?.purchasePriceExceptions, ...priceExceptions] : priceExceptions;
                        updatePriceExceptions({purchasePriceExceptions: priceExceptionsValues});
                    }}/>
            }
        </div>
    );
});
