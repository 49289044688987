import React, { useState } from 'react';
import SlideToggle from '../../../../../components/SlideToggle';

import Product from '../Product';

export default ({ productGroup, currencyIsoCode }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <SlideToggle
        collapsed={!isOpen}>
        {({ toggle }) => (
          <div className="accordion-item accordion-item-type2">
            <div className={`accordion-header ${isOpen ? 'active' : ''}`} onClick={(e) => {
              setIsOpen(!isOpen);
              toggle(e);
            }}>
              <div className="accordion-header-info">
                <span className="accordion-header-pre">{productGroup?.segment}</span>
                <h2 className="accordion-header-title">{productGroup?.type}</h2>
                {/* <span className="accordion-header-desc">{product?.solution?.description}</span> */}
              </div>
            </div>

            <div className="accordion-content">
              {productGroup?.products?.map((product, index) => (
                <Product
                  key={index}
                  product={product}
                  currencyIsoCode={currencyIsoCode} />
              ))}
            </div>
          </div>
        )}
      </SlideToggle>
    </>
  );
}
