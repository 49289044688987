import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../lib/modals';

Modal.setAppElement('#root')

export default ({ isOpen, setIsOpen, stepable }) => {
  const [step] = useState(0);
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="modal-container">

        <div className="modal-content terms-modal">

          <button type="button" id="btnCloseModal" name="btnCloseModal" className="btn-close-modal"
            onClick={closeModal}>
            <span className="icon-close-modal"></span>
          </button>

          <h2 className="modal-title-type1">Política de Privacidad {stepable && <span>({step + 1} de 3)</span>}</h2>

          <div className="modal-content-scroll">
            <h3>1.IDENTIFICACIÓN Y DATOS DE CONTACTO DEL RESPONSABLE</h3>

            <p>Numen Technologies S.L., sociedad domiciliada en la Avenida Diagonal 401, 2o, 08008 Barcelona con CIF B65405466, (en adelante, “Numetech” o la “Sociedad”) es la responsable del tratamiento de los datos personales facilitados en el formulario de registro.</p>
            <p>La presente Política de Privacidad tiene por objeto facilitar información sobre los derechos que le asisten en virtud del Reglamento General de Protección de Datos (“RGPD”).</p>
            <p>Si tiene cualquier duda relativa al tratamiento de sus datos personales, contacte con la Sociedad en la siguiente dirección: <b>privacy@numentech.es</b>. Asimismo, le informamos que Numentech cuenta con un Delegado de Protección de Datos, con quien se podrá poner en contacto a través de la siguiente dirección: <b>dpo@numentech.es</b>.</p>
            <p>Al hacer click en el check box declara conocer y entender la siguiente política de privacidad de Numen Technologies.</p>

            <h3>2. INFORMACIÓN NECESARIA Y ACTUALIZADA</h3>
            <p>La información solicitada será de obligado cumplimiento, de tal modo que su omisión podría comportar la imposibilidad de que se pueda otorgar la licencia.</p>
            <p>La legitimación para el tratamiento es el interés legítimo de Numentech en mantener la relación existente con el Usuario así como la relación entre Licenciatario y Licenciante.</p>
            <p>Debe proporcionar información verídica. Para que la información facilitada esté siempre actualizada y no contenga errores, puede comunicar con la mayor brevedad posible, las modificaciones y rectificaciones de su situación a través de un correo electrónico a la dirección: <b>privacy@numentech.es</b>.</p>

            <h3>3. FINALIDAD DEL TRATAMIENTO</h3>
            <p>Gestionar la suscripción a los servicios y licencias de productos y servicios de Numentech y gestionar las comunicaciones promocionales de la Sociedad.</p>
            <p>Los datos personales de los usuarios se recogerán y tratarán con la finalidad de gestionar la relación con Numentech y como punto de contacto para poder enviar información relativa a eventos de divulgación y enseñanza relacionados con los productos y servicios de la Sociedad.</p>
            <p>Asimismo sus datos personales se recogerán y tratarán para responder a las consultas o cuestiones que pueda remitir a través de cualquier medio electrónico.</p>

            <h3>4. ESPECIFICACIÓN DE LOS DATOS Y OBTENCIÓN DE LOS MISMOS</h3>
            <p>Se tratarán los datos que se hayan incluido en el formulario de registro. Asimismo se tratará información vinculada a los accesos y gestión de los servicios Numentech.</p>
            <p>En el caso de que los Usuarios nos proporcionen datos de carácter personal de terceras personas, el Usuario garantiza que les ha informado con carácter previo a su inclusión, de los extremos contenidos en la presente Política de Privacidad, respondiendo personalmente frente a Numentech de cuantos daños y perjuicios puedan derivarse.</p>

            <p>En cualquier caso, sus datos serán tratados con un nivel de protección de acuerdo a las normas aplicables sobre Protección de Datos y no se someterán a decisiones automatizadas.</p>

            <h3>5. ALMACENAMIENTO DE LOS DATOS</h3>
            <p>Numentech recogerá y tratará sus datos mientras la relación comercial siga vigente.</p>
            <p>En el caso de las comunicaciones promocionales, el almacenamiento durará mientras el Usuario no revoque el consentimiento que ha prestado al tratamiento de sus datos para esta actividad.</p>
            <p>En el supuesto que los datos personales no sean necesarios para el fin para el que fueron recabados y mientras no se proceda a la cancelación definitiva de los mismos, se conservarán de forma bloqueada.</p>
            <p>Sus datos personales serán almacenados en países del Espacio Económico Europeo (EEE).</p>

            <h3>6. DESTINATARIOS</h3>
            <p>Sus datos podrán ser comunicados a terceras entidades comercializadoras de los servicios y productos Numentech. También a los proveedores de servicios que actuarán como encargados del tratamiento.</p>
            <p>Le informamos que puede solicitar mayor información respecto a los destinatarios de sus datos enviando un correo electrónico a la dirección <b>privacy@numentech.es</b>, indicando el tratamiento concreto sobre cuyo destinatarios querría información.</p>

            <h3>7. EJERCICIO DE SUS DERECHOS</h3>
            <p>Le informamos que podrás ejercer los siguientes derechos:</p>

            <ul>
              <li>Derecho de acceso a sus datos personales para saber cuáles están siendo objeto de tratamiento y las operaciones de tratamiento llevadas a cabo con ellos.</li>
              <li>Derecho de rectificación de cualquier dato personal inexacto.</li>
              <li>Derecho de supresión de sus datos personales, cuando esto sea posible</li>
              <li>Derecho a solicitar la limitación del tratamiento de sus datos personales cuando la exactitud, la legalidad o la necesidad del tratamiento de los datos resulte dudosa, en cuyo caso, podremos conservarlos para el ejercicio o la defensa de reclamaciones.</li>
              <li>Derecho a la portabilidad de sus datos personales, cuando la base legal que nos habilite para su tratamiento de las indicadas sea la relación contractual o el consentimiento.</li>
              <li>Derecho de oposición al tratamiento de sus datos personales, cuando la base legal que nos habilite para su tratamiento de las indicadas arriba incluida sea el interés legítimo. A estos efectos, dejaremos de tratar sus datos salvo que tengamos un interés legítimo imperioso o para la formulación, el ejercicio o la defensa de reclamaciones.</li>
              <li>Derecho a revocar su consentimiento en cualquier momento en el caso que la base del tratamiento sea el consentimiento.</li>
            </ul>

            <p>Podrá ejercitar sus derechos en cualquier momento y de forma gratuita dirigiendo un correo electrónico a <b>dpo@numentech.es</b> indicando el derecho que desea ejercitar y sus datos identificativos.</p>
            <p>Si consideras que no hemos tratado tus datos personales adecuadamente puedes contactar con el Delegado de Protección de Datos en la dirección <b>dpo@numentech.es</b>. No obstante, le informamos de que tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos si considera que se ha cometido una infracción de la legislación en materia de protección de datos respecto al tratamiento de sus datos personales.</p>

            <h3>8. MEDIDAS DE SEGURIDAD</h3>
            <p>Numentech adopta los niveles de seguridad requeridos por el RGPD adecuados a la naturaleza de los datos que son objeto de tratamiento en cada momento. No obstante lo anterior, la seguridad técnica en un medio como las comunicaciones electrónicas e Internet no es inexpugnable y pueden existir actuaciones dolosas de terceros, si bien Numentech pone todos los medios a su alcance para evitar dichas actuaciones.</p>
          </div>

        </div>

      </div>
    </Modal>
  );
}
