const moment = require('moment');

export const getProductStatus = (product) => {
  if (
    product.status === 'inactive' ||
    product.status === 'expired'
  ) {
    return product.status;
  }
  if (!product.end_date) {
    return 'active';
  }
  const expirationDate = moment(product.end_date.date);
  const now = moment();
  const daysToExpire = expirationDate.diff(now, 'days');
  if (daysToExpire >= 0 && daysToExpire <= 30) {
    return 'about_to_expire';
  }
  if (
    product.status === 'active'
  ) {
    return product.status;
  }
};

export const isProductActive = (product) => getProductStatus(product) === 'active';
export const isProductInactive = (product) => getProductStatus(product) === 'inactive';
export const isProductExpired = (product) => getProductStatus(product) === 'expired';
export const isProductAboutToExpire = (product) => getProductStatus(product) === 'about_to_expire';
export const isProductTrial = (product) => product?.product_version?.product?.trial;
export const showProductTrialTag = (product) => isProductTrial(product) && !isProductInactive(product);
export const isProductRenewable = (product) =>
  !isProductTrial(product) &&
  (
    isProductAboutToExpire(product) ||
    isProductExpired(product)
  );
export const canActivateTrial = (product) =>
  isProductTrial(product) &&
  isProductInactive(product);
export const canActivateProduct = (product) =>
  !isProductTrial(product) &&
  isProductInactive(product);

export const canActivateTrialInMyProducts = (product) =>
  isProductInactive(product) && isProductTrial(product);
