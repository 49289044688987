import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Info from './components/Info';
import Products from './components/Products';

const ClientDetail = () => {
  const history = useHistory();
  const infoRef = useRef();
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <div role="main" className="main-cms">
      <form action="#" method="post">
        <div className="box main-header-cms">
          <div className="main-header-cms-top">
            <div className="main-header-cms-title">
              <span className="header-cms-icon icon-clients"></span>
              <h1 className="header-cms-title">
                Consulta de datos del cliente
              </h1>
            </div>
            <div className="main-header-cms-actions">
              <button
                style={{ marginRight: 20 }}
                type="button" id="btnBack" name="btnBack" className="btn-type4 small" onClick={() => history.goBack()}>
                Volver
              </button>
              <button type="button" id="btnSave" name="btnSave" className="btn-type5"
                onClick={() => {
                  if (selectedTab === 1) {
                    infoRef.current.update();
                  }
                }}>
                Guardar
              </button>
            </div>
          </div>

          <nav className="cms-tabs">
            <ul className="tabs-container">
              <li
                className={`item-tab ${selectedTab === 0 ? 'active' : ''}`}
                onClick={() => setSelectedTab(0)}>
                Productos
              </li>
              <li
                className={`item-tab ${selectedTab === 1 ? 'active' : ''}`}
                onClick={() => setSelectedTab(1)}>
                Datos del cliente
              </li>
            </ul>
          </nav>
        </div>

        {selectedTab === 0 && <Products />}
        {selectedTab === 1 && <Info ref={infoRef} />}
      </form>
    </div>
  );
};

export default ClientDetail;