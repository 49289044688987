import React, {useState} from 'react';
import Modal from 'react-modal';
import {useMutation, useQueryClient} from 'react-query';
import * as Yup from 'yup';
import {customStyles} from '../../lib/modals';
import {post, put} from '../../lib/restClient';
import {URLS} from '../../lib/api';
import {Input} from '../Inputs';
import { useFormik} from 'formik';
import {FormError} from '../FormError/styles';
import PartnerSearcher from '../PartnerSearcher';
import {extractErrorMessageFromResponse} from "../../lib/utils";

Modal.setAppElement('#root');

const ValidationSchema = Yup.object().shape({
    partner: Yup.string()
        .required('Requerido').typeError('Debe seleccionar un Partner'),
    buyPrice: Yup.number().typeError('El precio de compra tiene que ser un número mayor que 0, separando los decimales con (.) ejemplo 4.50')
        .min(1,'El precio de compra tiene que ser un número mayor que 0')
        .required('Requerido'),
});

export default ({isOpen, setIsOpen, productId, versionId, partnerPriceModalToEdit}) => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState('')
    const formik = useFormik({
        initialValues: {
            partner: null,
            buyPrice: null,
        },
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
            if (partnerPriceModalToEdit) {
                updatePartnerPrice({priceId: partnerPriceModalToEdit.id, buyPrice: values.buyPrice});
            } else {
                addPartnerPrice(values);
            }
        },
    });
    if (partnerPriceModalToEdit && !formik?.values?.partner) {
        formik.setFieldValue('partner', partnerPriceModalToEdit.id);
        formik.setFieldValue('buyPrice', partnerPriceModalToEdit.buyPrice);
    }
    const {mutate: addPartnerPrice} = useMutation(
        (body) => post(URLS.PRODUCT.ADD_PARTNER_PRICES({
            productId,
            versionId,
        }), body),
        {
            onSuccess: () => {
                setIsOpen(false);
                queryClient.refetchQueries(['discounts-and-prices-partnerPrice']);
            },
            onError: (error) => {
                setErrorMessage(extractErrorMessageFromResponse(error,formik))
            }
        }
    );
    const {mutate: updatePartnerPrice} = useMutation(
        ({priceId, buyPrice}) => put(URLS.PRODUCT.UPDATE_PARTNER_PRICE({
            productId,
            versionId,
            priceId,
        }), {buyPrice}),
        {
            onSuccess: () => {
                setIsOpen(false);
                queryClient.refetchQueries(['discounts-and-prices-partnerPrice']);
            },
            onError: (error) => {
                console.log('ERROR', error);
                setErrorMessage(extractErrorMessageFromResponse(error,formik))
            }
        }
    );
    return (
        <Modal
            isOpen={isOpen}
            style={customStyles}>
            <div className="modal-container">
                <div className="modal-content cms-modal price-modal">


                    <form action="#" method="post">
                        <h2 className="modal-title-type1">Añadir precio</h2>
                        <p className="cms-modal-desc">Selecciona el partner y añade los países y el precio</p>

                        <div className="form-item">
                            <label className="form-label">Partner</label>

                            {!partnerPriceModalToEdit &&
                                <PartnerSearcher
                                    onChange={(value) => formik?.setFieldValue('partner', value.id)}
                                />
                            }
                            {partnerPriceModalToEdit &&
                                <span>{partnerPriceModalToEdit.partner.organizationName}</span>}
                            {formik?.errors?.partner && formik?.touched?.partner &&
                                <FormError>{formik?.errors?.partner}</FormError>}
                        </div>

                        <div className="form-item-row">

                            <div className="form-item-col modal-col-size4">
                                <div className="form-item">
                                    <label className="form-label">Precio</label>
                                    <Input
                                        type="text"
                                        id="txtPrice2"
                                        name="txtPrice2"
                                        className="form-text"
                                        value={formik?.values?.buyPrice}
                                        onChange={(e) => formik.setFieldValue('buyPrice', e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        {formik?.errors?.buyPrice && formik?.touched?.buyPrice &&
                            <FormError
                                className="form-item-col modal-col-size12">{formik?.errors?.buyPrice}</FormError>}
                        {errorMessage && <FormError>{errorMessage}</FormError>}
                        <div className="cms-modal-actions">
                            <button type="button" id="btnCancelModal" name="btnCancelModal" className="btn-type4 small"
                                    onClick={() => setIsOpen(false)}>
                                Cancelar
                            </button>

                            <button type="button" id="btnAddModal" name="btnAddModal" className="btn-type5 big"
                                    onClick={() => {
                                        formik.handleSubmit()
                                    }}>
                                {partnerPriceModalToEdit ? 'Guardar' : 'Añadir'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </Modal>
    );
}
