import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { get, put } from '../../../../../lib/restClient';
import { useFormik } from 'formik';

import { URLS } from '../../../../../lib/api';
import Loader from '../../../../../components/Loader';
import InfoModal from '../../../../../components/InfoModal';
import { useParams } from 'react-router-dom';

export default forwardRef((props, ref) => {
  const { productId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const formik = useFormik({
    initialValues: {
      dependencies: [],
    },
    onSubmit: (values) => {
      updateDependencies({
        dependencies: values?.dependencies?.map(dependency => dependency.id),
      });
    }
  });
  useImperativeHandle(ref, () => ({
    update() {
      formik.handleSubmit();
    }
  }));
  const toggleDependency = (product) => {
    const dependencies = formik?.values?.dependencies;
    let newDependencies = [...dependencies];
    const found = dependencies.find(dependency => dependency.id === product.id);
    if (found) {
      newDependencies = newDependencies.filter(p => p.id !== found.id);
    } else {
      newDependencies = [...newDependencies, product];
    }
    formik.setFieldValue('dependencies', newDependencies);
    updateDependencies({ dependencies: newDependencies.map(dependency => dependency.id) });
  }
  const { isLoading, data } = useQuery('productVersionById-create',
    async () => {
      const productResponse = await get(URLS.PRODUCT.DETAIL({ productId }));
      const productsResponse = await get(URLS.ADMIN.LIST_PRODUCTS({
        page: 0,
        filters: {},
        sort: { field: 'createdAt', ordr: 'asc' },
      }));
      if (formik?.values?.dependencies.length === 0) {
        formik.setFieldValue('dependencies', productResponse.data.dependencies);
      }
      return {
        products: productsResponse.data || [],
        dependencies: productResponse.data.dependencies || [],
      };
    }, { refetchOnWindowFocus: false });
  const { mutate: updateDependencies } = useMutation(
    ({ dependencies }) => {
      return put(URLS.ADMIN.EDIT_PRODUCT({ productId }), { dependencies });
    },
    {
      onSuccess: async () => {
        setShowModal(true);
      },
      onError: (error) => {
        console.log('ERROR', error);
      }
    }
  );
  if (isLoading) {
    return <Loader />;
  }
  const firstLevelGroup = {};
  const secondLevelGroup = {};
  if (data?.products) {
    data.products.forEach(p => {
      const segment = p?.solution?.segmentCaption;
      if (firstLevelGroup[segment]) {
        firstLevelGroup[segment].push(p);
      } else {
        firstLevelGroup[segment] = [p];
      }
    });
    Object.keys(firstLevelGroup).forEach(key => secondLevelGroup[key] = {});
    Object.keys(firstLevelGroup).forEach(key => {
      firstLevelGroup[key].forEach(product => {
        const typeCaption = product.solution.typeCaption;
        if (secondLevelGroup[key][typeCaption]) {
          secondLevelGroup[key][typeCaption].push(product);
        } else {
          secondLevelGroup[key][typeCaption] = [product];
        }
      })
    });
  }
  return (
    <div className="box form-box">
      <h2 className="title-type6">Dependencia de otros productos</h2>
      <div className="cols-container">

        {Object.keys(firstLevelGroup).map(segment => (
          <div key={segment.id} className="col-item">
            <h3 className="title-type9">{segment} Products</h3>

            <div className="accordion-item accordion-item-type3">

              {Object.keys(secondLevelGroup[segment]).map(type => (
                <div key={type.id}>
                  <div className="accordion-header active">
                    <div className="accordion-header-info">
                      <h4 className="accordion-header-title">{type}</h4>
                      <span className="accordion-header-items">({secondLevelGroup[segment][type].length})</span>
                    </div>
                  </div>

                  <div className="accordion-content">
                    {secondLevelGroup[segment][type].map(product => (
                      <div key={product.id}  className="form-item-checkbox">
                        <input type="checkbox" id={product.id} name={product.id}
                          onClick={() => {
                            toggleDependency(product)
                          }}
                          checked={formik?.values?.dependencies.find(dependency => dependency.id === product.id)} />
                        <label htmlFor={product.id}>{product.name}</label>
                      </div>
                    ))
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <InfoModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        title="Datos guardados"
        subtitle="Datos guardados correctamente"
      />
    </div>
  );
});
