import React, { useRef, useState } from 'react';
import Info from './components/Info';
import Products from './components/Products';
import CalificationAndAnalysis from './components/CalificationAndAnalysis';
import Contracts from './components/Contracts';

import { useHistory } from 'react-router-dom';

const PartnerDetail = () => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);
  const infoRef = useRef();
  const productsRef = useRef();
  const contractsRef = useRef();
  const calificationRef = useRef();

  return (
    <div role="main" className="main-cms">
      <form action="#" method="post">
        <div className="box main-header-cms">
          <div className="main-header-cms-top">
            <div className="main-header-cms-title">
              <span className="header-cms-icon icon-partners"></span>
              <h1 className="header-cms-title">
                Editar partner
              </h1>
            </div>

            <div className="main-header-cms-actions">
              <div className="header-cms-actions-absolute">
                <button type="button" id="btnCancel" name="btnCancel" className="btn-type4 small"
                  onClick={() => {
                    history.goBack();
                  }}>
                  Volver
                </button>
                {selectedTab !== 1 && selectedTab !== 3 &&
                  <>
                    <button type="button" id="btnSave" name="btnSave" className="btn-type5"
                      onClick={() => {
                        if (selectedTab === 0) {
                          infoRef.current.update();
                        } else if (selectedTab === 2) {
                          calificationRef.current.update();
                        }
                      }}>
                      Guardar
                    </button>
                  </>
                }
              </div>
            </div>
          </div>

          <nav className="cms-tabs">
            <ul className="tabs-container">
              <li
                className={`item-tab ${selectedTab === 0 ? 'active' : ''}`}
                onClick={() => setSelectedTab(0)}>
                Datos básicos
              </li>
              <li
                className={`item-tab ${selectedTab === 1 ? 'active' : ''}`}
                onClick={() => setSelectedTab(1)}>
                Productos
              </li>
              <li
                className={`item-tab ${selectedTab === 2 ? 'active' : ''}`}
                onClick={() => setSelectedTab(2)}>
                Calificación y análisis
              </li>
              <li
                className={`item-tab ${selectedTab === 3 ? 'active' : ''}`}
                onClick={() => setSelectedTab(3)}>
                Contratos
              </li>
            </ul>
          </nav>
        </div>

        {selectedTab === 0 && <Info ref={infoRef} />}
        {selectedTab === 1 && <Products ref={productsRef} />}
        {selectedTab === 2 && <CalificationAndAnalysis ref={calificationRef} />}
        {selectedTab === 3 && <Contracts ref={contractsRef} />}
      </form>

    </div>
  );
};

export default PartnerDetail;