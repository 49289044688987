import React from 'react';

export default ({ onClick, children }) => (
  <div style={{
    flex: 1,
    alignItems: 'right',
    justifyContent: 'right',
    marginBottom: 20,
  }}>
    <button style={{ marginLeft: 'auto' }} type="button" id="btnAdd" name="btnAdd" className="btn-type5" onClick={onClick}>
      {children}
    </button>
  </div>
)