import React, {useState} from 'react';
import {useQuery} from 'react-query';
import Modal from 'react-modal';
import * as Yup from 'yup';
import {customStyles} from '../../lib/modals';
import {Input} from '../Inputs';
import Loader from '../Loader';
import {URLS} from '../../lib/api';
import {get} from '../../lib/restClient';
import {useFormik} from 'formik';
import CountrySearcher from '../CountrySearcher';
import {FormError} from '../FormError/styles';

Modal.setAppElement('#root');

const ValidationSchema = Yup.object().shape({
    countries: Yup.array()
        .required('Requerido'),
    currency: Yup.object({})
        .required('Requerido'),
    price: Yup.number()
        .required('Requerido'),
});

export default ({isOpen, setIsOpen, onFinish, priceException, errorMessage}) => {
    const [editionWasStarted, setEditionWasStarted] = useState(false);
    const formik = useFormik({
        initialValues: {
            countries: [],
            price: 0,
            currency: null,
        },
        validationSchema: ValidationSchema,
        onSubmit: (values) => onFinish(values),
    });
    const {isLoading, data} = useQuery('currenciesInPriceException', async () => {
        const response = await get(URLS.ADMIN.CURRENCIES());
        const countryResponse = await get(URLS.COUNTRIES.LIST());
        if (!formik?.values?.currency) {
            formik.setFieldValue('currency', response.data[0]);
            formik.setFieldValue('price', priceException?.price);
        }
        return {
            currencies: response.data,
            countries: countryResponse.data,
        };
    });
    if (isLoading) {
        return <Loader/>;
    }
    if (isOpen && priceException && editionWasStarted) {
        const countries = priceException.countryIsoCodes.map(isoCode => {
            return data.countries.find(c => c.iso_code === isoCode);
        })
        formik.setFieldValue('countries', countries);
        formik.setFieldValue('currency', priceException.country[0].currency);
        formik.setFieldValue('price', priceException.price);
        setEditionWasStarted(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
    return (
        <Modal
            isOpen={isOpen}
            style={customStyles}
        >
            <div className="modal-container">
                <div className="modal-content cms-modal">
                    <form action="#" method="post">
                        <h2 className="modal-title-type1">{`${priceException ? 'Editar' : 'Añadir'}`} excepción</h2>
                        <p className="cms-modal-desc">{`${priceException ?
                            'Modifique los precios para estos países' :
                            'Selecciona los países y añade un precio'}`
                        }</p>

                        <div className="form-item-row">
                            <div className="form-item-col modal-col-size1">
                                <div className="form-item">
                                    <label className="form-label">País</label>
                                    {!priceException &&
                                        <CountrySearcher
                                            readOnly={!!priceException}
                                            onChange={(selectedCountries) => {
                                                formik.setFieldValue('countries', selectedCountries)
                                                formik.setFieldValue('currency', selectedCountries[0]?.currency)
                                            }}
                                            mustHaveCurrency
                                            selectedCountries={formik?.values?.countries}
                                            touched={formik?.touched?.countries}
                                            errorMessage={formik?.errors?.countries}
                                        />
                                    }
                                    {priceException && <span>{priceException?.country}</span>}
                                </div>
                            </div>

                            <div className="form-item-col modal-col-size2">
                                <div className="form-item">
                                    <label
                                        className="form-label">Precio {formik?.values?.currency ? `(${formik?.values?.currency?.name})` : ''}</label>
                                    <Input type="number" id="txtPrice" name="txtPrice" className="form-text"
                                           onChange={(e) => formik.setFieldValue('price', e.target.value === '' ? 0 : parseFloat(e.target.value))}
                                           value={formik?.values?.price}/>
                                    {formik?.errors?.price &&
                                        <FormError>{formik?.errors?.price && formik?.touched?.price}</FormError>
                                    }
                                </div>
                            </div>
                        </div>

                        {errorMessage && <FormError>{errorMessage}</FormError>}

                        <div className="cms-modal-actions">
                            <button type="button" id="btnCancelModal" name="btnCancelModal" className="btn-type4 small"
                                    onClick={closeModal}>
                                Cancelar
                            </button>
                            <button type="button" id="btnAddModal" name="btnAddModal" className="btn-type5 big"
                                    onClick={() => formik.handleSubmit()}>
                                {priceException ? 'Guardar' : 'Añadir'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
}
