import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import NotificationStatusLabel from '../../../components/NotificationStatusLabel';
import EmptyText from '../../../components/EmptyText';
import TablePager from '../../../components/TablePager';
import Loader from '../../../components/Loader';
import ConfirmationModal from '../../../components/ConfirmationModal';
import DatePicker from '../../../components/DatePicker';
import Dropdown from '../../../components/Dropdown';
import RightAlignedButton from '../../../components/RightAlignedButton';

import {nonStandardDateFormatting} from '../../../lib/dates';
import useDataFilter from '../../../hooks/useDataFilter';

import {MAX_ELEMENTS_IN_PAGE, VIEW_TEXTS} from './constants'
import {
    useGeNotificationCategoriesMutation,
    useGeNotificationImportancesMutation,
    useGetUserNotificationsMutation,
    useNotificationGroupMutations
} from '../../../hooks/useClientMutations'

const Notifications = () => {
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    const [showDescription, setShowDescription] = useState(null);
    const [removeNotificationModal, setRemoveNotificationModal] = useState(false);
    const [removeNotificationGroupModal, setRemoveNotificationGroupModal] = useState(false);
    const [showFilters, setShowFilters] = useState(false);

    const {
        archiveNotifications,
        removeNotifications,
        featuredNotifications,
        openNotifications,
    } = useNotificationGroupMutations()

    const initialValues = {
        archived: '0',
        categories: [],
        importance: [],
        featured: null,
        opened: null,
    };

    const {
        filtersValues,
        setFilterValue,
        submitFilters,
    } = useDataFilter({
        initialFilters: initialValues,
        initialSort: {field: 'createdAt', order: 'desc'},
        onRefetch: () => refetch(),
    });

    const {isLoading, error, data, refetch} = useGetUserNotificationsMutation({filters: filtersValues})

    const categoriesResponse = useGeNotificationCategoriesMutation()
    const categories = categoriesResponse?.data || []

    const importancesResponse = useGeNotificationImportancesMutation()
    const importances = importancesResponse?.data || []

    if (error) {
        return <span>Error</span>;
    }

    const showLoader = isLoading;
    const pageCount = Math.ceil(data?.notifications?.length / MAX_ELEMENTS_IN_PAGE);

    const notificationWindowInitialIndex = currentPage * MAX_ELEMENTS_IN_PAGE;
    let notificationWindowFinalIndex = notificationWindowInitialIndex + MAX_ELEMENTS_IN_PAGE;

    if (notificationWindowFinalIndex > (data?.notifications?.length - 1)) {
        notificationWindowFinalIndex = data?.notifications?.length - 1;
    }

    const notificationsWindow = data?.notifications?.slice(notificationWindowInitialIndex, notificationWindowFinalIndex + 1);


    const dropdownActions = [
        {text: 'Borrar', onClick: () => setRemoveNotificationGroupModal(true)},
        {
            text: filtersValues.archived === "0" ? 'Archivar' : 'Desarchivar', onClick: () => {
                archiveNotifications({
                    notifications_id: selectedNotifications,
                    archived: filtersValues.archived === "0" ? "1" : "0"
                })
                setSelectedNotifications([])
            }
        },
        {text: 'Leído', onClick: () => openNotifications({notifications_id: selectedNotifications})},
        {text: 'No leído', onClick: () => openNotifications({notifications_id: selectedNotifications, open: false})},
        {text: 'Destacado', onClick: () => featuredNotifications({notifications_id: selectedNotifications})},
        {
            text: 'No destacado',
            onClick: () => featuredNotifications({notifications_id: selectedNotifications, featured: false})
        },
    ];

    return (
        <>
            <div role="main" className="main-clients">
                <Header/>
                <div className="row">
                    <div
                        className="main-header-cms-actions"
                        style={{
                            float: 'right',
                            marginTop: 40,
                            marginBottom: 20,
                        }}>

                        <button type="button" id="btnAdd" name="btnAdd"
                                className={`btn-cms-actions ${showFilters ? "active" : ""}`}
                                onClick={() => setShowFilters(!showFilters)}>
                            Filtros
                        </button>
                        <button type="button" id="btnAdd" name="btnAdd"
                                className={`btn-cms-actions ${filtersValues.archived === "1" ? "active" : ""}`}
                                onClick={() => {
                                    const newVal = filtersValues.archived === "0" ? "1" : "0"
                                    setFilterValue("archived", newVal);
                                    submitFilters();
                                    setCurrentPage(0);
                                }}>
                            Notificaciones archivadas
                            <span className="icon-archive"></span>
                        </button>

                        <button type="button" id="btnAdd" name="btnAdd" className="btn-cms-actions"
                                onClick={() => history.push('/clients/settings')}>
                            <span className="icon-settings ml-0"></span>
                        </button>

                    </div>
                </div>

                {/* Filters */}
                {!showLoader &&
                    <div className="row">
                        <div className="box">
                            {!showLoader &&
                                <div className={`cms-filters ${showFilters ? 'show' : false}`}>
                                    <h2 className="cms-filters-title">Filtros</h2>

                                    <button type="button" id="btnCloseFilters" name="btnCloseFilters"
                                            className="btn-close-filters"
                                            onClick={() => setShowFilters(!showFilters)}>
                                        <span className="icon-close-modal"></span>
                                    </button>

                                    <div className="form-item-row form-row-special">

                                        <div className="form-item filter-size1">
                                            <label className="form-label">Categoría</label>
                                            <div className="form-text-container">
                                                <select id="cmbCategory" name="cmbCategory" className="form-select"
                                                        onChange={(e) => setFilterValue("categories", [e.target.value])}>
                                                    <option value={""}>Seleccionar</option>
                                                    {categories.map((category) =>
                                                        <option key={category.id}
                                                                value={category.id}>{category.name}</option>
                                                    )}
                                                </select>
                                                <span className="icon-arrow select-arrow"></span>
                                            </div>
                                        </div>

                                        <div className="form-item item-special-size2-dates">
                                            <label className="form-label">Fecha</label>
                                            <div className="form-text-container-two">
                                                <DatePicker
                                                    selectsStart
                                                    placeholder="Desde"
                                                    startDate={filtersValues['date_start'] ? new Date(filtersValues['date_start']) : null}
                                                    endDate={filtersValues['date_end'] ? new Date(filtersValues['date_end']) : null}
                                                    onChange={(date) => setFilterValue("date_start", date)}/>
                                                <DatePicker
                                                    selectsEnd
                                                    placeholder="Hasta"
                                                    startDate={filtersValues['date_start'] ? new Date(filtersValues['date_start']) : null}
                                                    endDate={filtersValues['date_end'] ? new Date(filtersValues['date_end']) : null}
                                                    onChange={(date) => setFilterValue("date_end", date)}/>
                                            </div>
                                        </div>

                                        <div className="form-item filter-size1">
                                            <label className="form-label">Importancia</label>
                                            <div className="form-text-container">
                                                <select id="cmbImportance" name="cmbImportance" className="form-select"
                                                        onChange={(e) => setFilterValue("importance", [e.target.value])}>
                                                    <option value={""}>Todas</option>
                                                    {importances.map((importance) =>
                                                        <option key={importance.id}
                                                                value={importance.id}>{importance.name}</option>
                                                    )}
                                                </select>
                                                <span className="icon-arrow select-arrow"></span>
                                            </div>
                                        </div>

                                        <div className="form-item filter-size1">
                                            <label className="form-label">Estado</label>
                                            <div className="form-text-container">
                                                <select id="cmbStatus" name="cmbStatus" className="form-select"
                                                        onChange={(e) => {
                                                            if (e.target.value === '2') {
                                                                setFilterValue('featured', '1')
                                                                setFilterValue('opened', null)
                                                                return
                                                            }
                                                            setFilterValue('featured', null)
                                                            setFilterValue('opened', e.target.value)
                                                        }}>
                                                    <option value={""}>Todas</option>
                                                    <option value={"1"}>Leídas</option>
                                                    <option value={"0"}>No Leídas</option>
                                                    <option value={"2"}>Destacadas</option>
                                                </select>
                                                <span className="icon-arrow select-arrow"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-item item-special-size2">
                                        <RightAlignedButton
                                            onClick={() => {
                                                submitFilters();
                                                setCurrentPage(0);
                                                setShowFilters(false);
                                            }}>
                                            Aplicar filtros
                                        </RightAlignedButton>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }

                <div className="row">
                    {showLoader && <Loader/>}
                    {!showLoader &&
                        <>
                            {data?.notifications?.length === 0 &&
                                <EmptyText>No tienes notificaciones actualmente</EmptyText>}
                            {data?.notifications?.length !== 0 &&
                                <div className="responsive-table full-table">
                                    <table className="table-type2">
                                        <thead>
                                        <tr>
                                            <th className="pr-0">
                                                <input
                                                    className="checkbox-gray"
                                                    type="checkbox"
                                                    checked={selectedNotifications.length === data?.notifications.length}
                                                    onChange={() => {
                                                        setSelectedNotifications(!selectedNotifications?.length ?
                                                            data?.notifications.map(n => n.id) :
                                                            []
                                                        );
                                                    }}
                                                />
                                                <Dropdown actions={dropdownActions}
                                                          visibility={!!selectedNotifications.length}/>
                                            </th>
                                            <th className="pl-0 pr-0"></th>
                                            <th>Título</th>
                                            <th>Fecha y hora</th>
                                            <th>Importancia</th>
                                            <th>Categoría</th>
                                            <th>Acciones</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {notificationsWindow.map(notification => (
                                            <tr key={notification?.id}
                                                className="va-t clickable"
                                                style={{
                                                    backgroundColor: notification?.open ? 'white' : '#F3F3F3',
                                                }}
                                                onClick={() => {
                                                    if (showDescription) return setShowDescription(null)
                                                    setShowDescription(notification)
                                                    openNotifications({notifications_id: [notification?.id]})
                                                }}>

                                                <td className="pr-0">
                                                    <input
                                                        className="checkbox-gray"
                                                        type="checkbox"
                                                        checked={selectedNotifications?.indexOf(notification?.id) !== -1}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={() => {
                                                            if (selectedNotifications?.indexOf(notification?.id) !== -1) {
                                                                const index = selectedNotifications?.indexOf(notification?.id)
                                                                selectedNotifications.splice(index, 1);
                                                                setSelectedNotifications([...selectedNotifications]);
                                                            } else {
                                                                setSelectedNotifications([...selectedNotifications, notification?.id]);
                                                            }
                                                        }}
                                                    />
                                                </td>

                                                <td className="pl-0 pr-0">
                                                    <button
                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            notification.featured = !notification.featured
                                                            featuredNotifications({
                                                                notifications_id: [notification?.id],
                                                                featured: notification.featured
                                                            })
                                                        }}
                                                        type="button"
                                                        id="btnShowItem1"
                                                        name="btnShowItem1"
                                                        className="btn-icon-simple">
                                                        <span
                                                            className={"featured " + (notification.featured ? "icon-star active" : "icon-star-o")}></span>
                                                    </button>
                                                </td>

                                                <td className="wrap main-col">
                                                    <div>{notification?.title}</div>

                                                    {notification?.id === showDescription?.id &&
                                                        <div className="small">{notification?.description}</div>}
                                                </td>

                                                <td>{nonStandardDateFormatting(notification?.createdAt.date)}</td>
                                                <td><NotificationStatusLabel status={notification?.importance?.name}/>
                                                </td>
                                                <td>{notification?.category?.name}</td>

                                                <td>
                                                    <div className="btn-group-spaced">

                                                        {/* BUTTON :: ARCHIVE */}
                                                        {filtersValues.archived === '0' &&
                                                            <button
                                                                onClick={(event) => {
                                                                    event.stopPropagation()
                                                                    archiveNotifications({notifications_id: [notification?.id]})
                                                                }}
                                                                type="button"
                                                                id="btnArchiveItem"
                                                                name="btnArchiveItem"
                                                                className="btn-icon-border">
                                                                <span className="icon-archive"></span>
                                                            </button>}

                                                        {/* BUTTON :: UNARCHIVE */}
                                                        {filtersValues.archived === '1' &&
                                                            <button
                                                                onClick={(event) => {
                                                                    event.stopPropagation()
                                                                    archiveNotifications({
                                                                        notifications_id: [notification?.id],
                                                                        archived: "0"
                                                                    })
                                                                }}
                                                                type="button"
                                                                id="btnUnarchiveItem"
                                                                name="btnUnarchiveItem"
                                                                className="btn-icon-border">
                                                                <span className="icon-back"></span>
                                                            </button>}

                                                        {/* BUTTON :: REMOVE */}
                                                        <button
                                                            onClick={(event) => {
                                                                event.stopPropagation()
                                                                setRemoveNotificationModal(notification)
                                                            }}
                                                            type="button"
                                                            id="btnRemoveItem"
                                                            name="btnRemoveItem"
                                                            className="btn-icon-border">
                                                            <span className="icon-remove"></span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </>
                    }
                </div>
                <div className="row">
                    {!showLoader &&
                        <TablePager
                            pages={pageCount}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            refetch={() => {
                            }}/>
                    }
                </div>
            </div>

            {/* Modal: Remove one notification */}
            <ConfirmationModal
                title={VIEW_TEXTS.MODAL_REMOVE_NOTIFICATION_TITLE}
                subtitle={VIEW_TEXTS.MODAL_REMOVE_NOTIFICATION_MESSAGE(removeNotificationModal?.title)}
                isOpen={!!removeNotificationModal}
                setIsOpen={setRemoveNotificationModal}
                onAccept={() => {
                    setRemoveNotificationModal(false)
                    removeNotifications({notifications_id: [removeNotificationModal?.id]})
                }}
            />

            {/* Modal: Remove several notifications */}
            <ConfirmationModal
                title={VIEW_TEXTS.MODAL_REMOVE_NOTIFICATIONGROUP_TITLE}
                subtitle={VIEW_TEXTS.MODAL_REMOVE_NOTIFICATIONGROUP_MESSAGE}
                isOpen={removeNotificationGroupModal}
                setIsOpen={setRemoveNotificationGroupModal}
                onAccept={() => {
                    setRemoveNotificationGroupModal(false)
                    removeNotifications({notifications_id: selectedNotifications})
                    setSelectedNotifications([])
                }}
            />

            <Footer/>
        </>
    );
};

export default Notifications;