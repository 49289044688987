import { useFormik } from 'formik';
import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import * as Yup from 'yup';
import { URLS } from '../../../../../../../lib/api';
import { get, post, put } from '../../../../../../../lib/restClient';
import { FormError } from '../../../../../../../components/FormError/styles';
import { Input } from '../../../../../../../components/Inputs';
import InfoModal from '../../../../../../../components/InfoModal';
import Loader from '../../../../../../../components/Loader';
import { nonStandardDateFormatting } from '../../../../../../../lib/dates';
import PriceExceptionsModal from '../../../../../../../components/PriceExceptionsModal';
import { transformPriceExceptions } from '../../../../../../../lib/prices';
import { extractErrorMessageFromResponse } from '../../../../../../../lib/utils';

const ValidationSchema = Yup.object().shape({
  version: Yup
    .string()
    .required('Requerido'),
  available: Yup
    .boolean()
    .required('Requerido'),
  description: Yup
    .string()
    .max(500)
    .required('Requerido'),
});

const selectContainerStyles = {
  menu: () => ({
    backgroundColor: 'rgba(243, 246, 248, .5)',
  }),
  valueContainer: () => ({
    backgroundColor: 'rgba(243, 246, 248, .5)',
    height: 43,
    paddingLeft: 10,
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'red',
    color: 'red',
  }),
  dropdownIndicator: () => ({
    color: '#A21C22',
    marginRight: '10px',
    marginTop: '3px',
  })
};

export default ({
  createMode,
  productId,
  versionId,
  isLatest,
  documentTypes,
}) => {
  const queryClient = useQueryClient();
  const actualVersionFormik = useFormik({
    validationSchema: ValidationSchema,
    initialValues: {
      version: '',
      description: '',
      available: false,
      prices: [],
      priceExceptions: [],
      documents: [],
    },
    onSubmit: (values) => {
      setIsCreating(true);
      createOrUpdateVersion(values);
    },
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [addNewVersionShown, setAddNewVersionShown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPreviousVersionDetails, setShowPreviousVersionDetails] = useState(false);
  const [alreadyFetched, setAlreadyFetched] = useState(false);
  const [showPriceExceptionsModal, setShowPriceExceptionsModal] = useState(false);
  const [editablePriceExceptionModal, setEditablePriceExceptionModal] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const { error, isLoading, data, refetch } = useQuery(
    createMode ? 'productVersionById-create' : `productVersionById-${productId}-${versionId}`,
    async () => {
      const currenciesResponse = await get(URLS.ADMIN.CURRENCIES());
      if (createMode) {
        return {
          currencies: currenciesResponse.data,
        };
      }
      const versionByIdResponse = await get(URLS.ADMIN.PRODUCT_VERSION_BY_ID({
        productId,
        versionId,
      }));
      const versionById = versionByIdResponse.data;
      if (!alreadyFetched) {
        actualVersionFormik.setFieldValue('version', versionById?.version);
        actualVersionFormik.setFieldValue('description', versionById?.description);
        actualVersionFormik.setFieldValue('available', versionById?.available || false);
        actualVersionFormik.setFieldValue('createdAt', versionById?.createdAt);
        actualVersionFormik.setFieldValue('documents', versionById?.documents);
        actualVersionFormik.setFieldValue('priceExceptions', versionById?.priceExceptions);
        actualVersionFormik.setFieldValue('id', versionById?.id);

        const prices = {};
        if (versionById.prices) {
          versionById.prices.forEach(price => {
            prices[price.currency] = price.price;
          });
          actualVersionFormik.setFieldValue('prices', prices);
        }
        setAlreadyFetched(true);
      }
      return {
        versionById: versionById,
        currencies: currenciesResponse.data,
      };
    });
  const { mutate: createOrUpdateVersion } = useMutation(
    (values) => {
      const { dependencies, priceExceptions, documents, available, ...rest } = values;
      const transformedPrices = [];
      Object.keys(values.prices).forEach(key => {
        transformedPrices.push({ currency: key, price: values.prices[key] });
      })
      delete rest.id;
      const body = {
        ...rest,
        documents: documents.map(d => d.id),
        prices: transformedPrices,
        available: available === 'true',
      };
      if (createMode) {
        return post(URLS.ADMIN.CREATE_PRODUCT_VERSIONS_BY_ID({
          productId,
        }), body);
      }
      return put(URLS.ADMIN.EDIT_PRODUCT_VERSIONS_BY_ID({
        productId,
        versionId: versionId,
      }), body);
    },
    {
      onSuccess: () => {
        refetch();
        setIsCreating(false);
        setShowModal(true);
        if (createMode) {
          actualVersionFormik.resetForm();
        }
        queryClient.refetchQueries(['productsVersion']);
      },
      onError: (error) => {
        console.log('ERROR', error);
      }
    }
  );
  const { mutate: updatePriceExceptions } = useMutation(
    ({ priceExceptions }) => put(URLS.ADMIN.EDIT_PRODUCT_VERSIONS_BY_ID({
      productId,
      versionId,
    }), {
      priceExceptions: priceExceptions.map(pe => (
        !!pe.country.iso_code ?
          { country: pe.country.iso_code, price: pe.price } :
          pe
      ))
    }),
    {
      onSuccess: () => {
        setShowPriceExceptionsModal(false);
        setEditablePriceExceptionModal(null);
        refetch();
        queryClient.refetchQueries([`productVersionById-${productId}-${versionId}`]);
      },
      onError: (error) => {
        setErrorMessage(extractErrorMessageFromResponse(error));
        console.log('ERROR', error);
      }
    }
  );
  if (error) {
    return <span>Error</span>;
  }
  if (isLoading) {
    return (
      <div className="box form-box">
        <div className="main-header-cms-top">
          <Loader />
        </div>
      </div>
    );
  }
  const documents = actualVersionFormik?.values?.documents || [];
  const pricesExceptionsMapped = transformPriceExceptions(actualVersionFormik?.values?.priceExceptions);
  const groupedDocumentTypes = [];
  if (documentTypes) {
    groupedDocumentTypes.push({
      label: 'Producto',
      options: documentTypes['product'].map(document => ({
        value: document.id,
        label: document.title,
      })),
    });
    groupedDocumentTypes.push({
      label: 'Activación Producto',
      options: documentTypes['product_activation'].map(document => ({
        value: document.id,
        label: document.title,
      })),
    });
  }
  return (
    <>
      {createMode && !addNewVersionShown &&
        <div
          className="main-header-cms-top"
          style={{ marginBottom: 15 }}>
          <div className="main-header-cms-actions">
            <div className="header-cms-actions-absolute">
              <button
                type="button"
                id="btnAddVersion"
                name="btnAddVersion"
                className="btn-type5"
                onClick={() => {
                  setAddNewVersionShown(true);
                }}>
                Añadir nueva versión
            </button>
            </div>
          </div>
        </div>
      }
      {((createMode && addNewVersionShown) || !createMode) &&
        <div className="box form-box">
          <div className="main-header-cms-top">
            {createMode && <h2 className="title-type6">Nueva versión</h2>}
            {!createMode && isLatest && <h2 className="title-type6">Última versión</h2>}
            {!createMode && !isLatest && <h2 className="title-type6">Versión {actualVersionFormik?.values?.version}</h2>}
            {!createMode &&
              <div className="main-header-cms-actions">
                <div className="header-cms-actions-absolute">
                  {
                    !createMode && !isLatest &&
                    <button type="button" id="btnViewDetail" name="btnViewDetail" className="btn-type7"
                      onClick={() => setShowPreviousVersionDetails(!showPreviousVersionDetails)}>
                      {showPreviousVersionDetails ? 'Ocultar' : 'Ver'} detalle
                </button>
                  }
                  {((isLatest || addNewVersionShown || showPreviousVersionDetails) && !isCreating) &&
                    <button type="button" id="btnSave" name="btnSave" className="btn-type5"
                      onClick={() => actualVersionFormik.handleSubmit()}>
                      Guardar
                  </button>
                  }
                  {isCreating && <div style={{marginLeft: 20}}><Loader /></div>}
                </div>
              </div>
            }
            {createMode && addNewVersionShown && isCreating &&
              <>
                <label className="form-label">Creando versión...</label>
                <Loader />
              </>
            }
            {createMode && addNewVersionShown && !isCreating &&
              <div className="main-header-cms-actions">
                <div className="header-cms-actions-absolute">
                  {addNewVersionShown &&
                    <button type="button" id="btnCancel" name="btnCancel" className="btn-type4 small"
                      onClick={() => setAddNewVersionShown(false)}>
                      Cancelar
                </button>
                  }
                  <button type="button" id="btnAddVersion" name="btnAddVersion" className="btn-type5"
                    onClick={() => {
                      if (createMode && addNewVersionShown) {
                        actualVersionFormik.handleSubmit();
                        // setAddNewVersionShown(false);
                      } else {
                        setAddNewVersionShown(true);
                      }
                    }}>
                    Guardar nueva versión
              </button>
                </div>
              </div>
            }
          </div>
          <div className={(createMode || (!createMode && !isLatest)) ?
            `new-version-bl ${(addNewVersionShown || showPreviousVersionDetails) ? 'show' : ''}` : ''
          } id="newVersion">
            {!createMode &&
              <div className="form-item-row">
                <div className="form-item-col">
                  <div className="form-item">
                    <label className="form-label">ID VERSIÓN</label>
                    <div className="form-text-container">
                      <Input type="text" id="txtName" name="txtName" className="form-text"
                        blocked
                        value={actualVersionFormik?.values?.id} />
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="form-item-row">
              <div className="form-item-col">
                <div className="form-item">
                  <label className="form-label">Número/Nombre de la versión</label>
                  <div className="form-text-container">
                    <Input type="text" id="txtName" name="txtName" className="form-text"
                      onChange={(e) => actualVersionFormik?.setFieldValue('version', e.target.value)}
                      value={actualVersionFormik?.values?.version} />
                    <FormError>{actualVersionFormik?.touched?.version && actualVersionFormik?.errors?.version}</FormError>
                  </div>
                </div>
                <div className="form-item form-item-two">
                  <div className="form-item">
                    <label className="form-label">Estado</label>
                    <div className="form-text-container">
                      <select id="cmbStatus" name="cmbStatus" className="form-select"
                        onChange={(e) => actualVersionFormik?.setFieldValue('available', e.target.value)}>
                        <option
                          selected={actualVersionFormik?.values?.available}
                          value={true}>
                          Disponible
                      </option>
                        <option
                          selected={!actualVersionFormik?.values?.available}
                          value={false}>
                          No disponible
                      </option>
                      </select>
                      <span className="icon-arrow select-arrow"></span>
                    </div>
                  </div>
                  {!createMode &&
                    <div className="form-item">
                      <label className="form-label">Fecha de alta</label>
                      <div className="form-text-container">
                        <Input
                          type="text"
                          id="txtDate"
                          name="txtDate"
                          className="form-text"
                          blocked
                          value={nonStandardDateFormatting(actualVersionFormik?.values?.createdAt?.date)} />
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="form-item-col">
                <div className="form-item">
                  <label className="form-label">Descripción de las mejoras</label>
                  <div className="form-text-container form-textarea">
                    <textarea id="txtDescription" name="txtDrescription" className="form-text"
                      onChange={(e) => actualVersionFormik?.setFieldValue('description', e.target.value)}
                      value={actualVersionFormik?.values?.description} />
                  </div>
                  <span className="text-container-legend">{actualVersionFormik?.values?.description?.length || 0}/500</span>
                </div>
                <FormError>{actualVersionFormik?.touched?.description && actualVersionFormik?.errors?.description}</FormError>
              </div>
              <div className="form-item-col">
                <div className="form-item">
                  <label className="form-label">Documentación obligatoria</label>

                  <ReactSelect
                    styles={selectContainerStyles}
                    placeholder="Selecciona documentos"
                    onChange={(e) => {
                      const documentId = e.value;
                      let found = documentTypes['product'].find(doc => doc.id === documentId);
                      if (!found) {
                        found = documentTypes['product_activation'].find(doc => doc.id === documentId);
                      }
                      actualVersionFormik.setFieldValue(
                        'documents',
                        [...actualVersionFormik?.values?.documents, found],
                      );
                    }}
                    options={groupedDocumentTypes} />
                  <div style={{ height: 20 }} />
                  {
                    actualVersionFormik?.touched?.documents && actualVersionFormik?.errors?.documents &&
                    <FormError>{actualVersionFormik?.errors?.documents}</FormError>
                  }
                  <div className="search-tags-container">
                    {documents.map((doc) => (
                      <span key={doc.id} className="search-tag icon-close-modal"
                        onClick={() => {
                          const newDocuments = actualVersionFormik?.values?.documents
                            .filter(document => document.id !== doc.id);
                          actualVersionFormik.setFieldValue('documents', newDocuments);
                        }}>
                        {doc.title}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <h3 className="title-type8">Precio de venta recomendado</h3>
            <div className="form-item-row small-row">
              {data?.currencies?.map(currency => (
                <div key={currency.name} className="form-item">
                  <label className="form-label">{currency.name}</label>
                  <Input type="number" id="txtPricePVP" name="txtPricePVP" className="form-text"
                    onChange={(e) => {
                      if (e.target.value === '') {
                        actualVersionFormik.setFieldValue(`prices.${currency.iso_code}`, []);
                      } else {
                        actualVersionFormik.setFieldValue(`prices.${currency.iso_code}`, parseFloat(e.target.value));
                      }
                    }}
                    value={actualVersionFormik?.values?.prices[currency.iso_code]} />
                </div>
              ))}

              <button type="button" id="btnAdd" name="btnAdd" className="btn-type5 al-right"
                onClick={() => {
                  setEditablePriceExceptionModal(null);
                  setShowPriceExceptionsModal(true);
                }}>
                Añadir excepción
          </button>

            </div>
            {pricesExceptionsMapped?.length > 0 &&
              <div className="responsive-table full-table">
                <table className="table-type2">
                  <thead>
                    <tr>
                      <th>Países
                  <button type="button" id="btnOrderItem1" name="btnOrderItem1" className="btn-order up">
                          <span className="icon-arrow2"></span>
                        </button>
                      </th>
                      <th>Precio
                  <button type="button" id="btnOrderItem2" name="btnOrderItem2" className="btn-order">
                          <span className="icon-arrow2"></span>
                        </button>
                      </th>
                      <th>Moneda
                  <button type="button" id="btnOrderItem3" name="btnOrderItem3" className="btn-order">
                          <span className="icon-arrow2"></span>
                        </button>
                      </th>
                      <th className="centered">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pricesExceptionsMapped?.map((pe, idx) => (
                      <tr key={pe.id}>
                        <td className="wrap">
                          {pe?.country}
                        </td>
                        <td>
                          {pe?.price}
                        </td>
                        <td>
                          {pe?.currency}
                        </td>
                        <td className="centered">
                          <div className="table-btn-container">
                            <button type="button" id="btnShowItem2" name="btnShowItem2" className="btn-icon-border"
                              onClick={() => {
                                setShowPriceExceptionsModal(true);
                                setEditablePriceExceptionModal(pe);
                              }}>
                              <span className="icon-edit"></span>
                            </button>
                            <button type="button" id="btnRemoveItem2" name="btnRemoveItem2" className="btn-icon-border"
                              onClick={(e) => {
                                e.preventDefault();
                                const countryName = pe?.country.split(',').map(c => c.trim());
                                const newPriceExceptions = actualVersionFormik?.values?.priceExceptions
                                  .filter(exception => countryName.indexOf(exception?.country?.name) === -1);
                                actualVersionFormik.setFieldValue('priceExceptions', newPriceExceptions);
                                updatePriceExceptions({ priceExceptions: newPriceExceptions });
                              }}>
                              <span className="icon-remove"></span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            }
          </div>
        </div>
      }
      <InfoModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        title="Datos guardados"
        subtitle="Datos guardados correctamente"
        onAccept={async () => {
          queryClient.refetchQueries(['productsVersion']);
          setShowModal(false);
        }}
      />
      {showPriceExceptionsModal &&
        <PriceExceptionsModal
          isOpen={showPriceExceptionsModal}
          setIsOpen={setShowPriceExceptionsModal}
          errorMessage={errorMessage}
          priceException={editablePriceExceptionModal}
          onFinish={(values) => {
            const priceExceptions = values.countries.map(country => ({
              country: country.iso_code,
              price: values.price,
            }));
            actualVersionFormik.setFieldValue('priceExceptions', [
              ...actualVersionFormik?.values?.priceExceptions,
              ...values.countries.map(country => ({
                country,
                price: values.price,
              }))
            ]);
            const priceExceptionsValues = [...actualVersionFormik?.values?.priceExceptions, ...priceExceptions];
            updatePriceExceptions({ priceExceptions: priceExceptionsValues });
          }} />
      }
    </>
  )

};
