import React, { useContext } from 'react';
import Modal from 'react-modal';

import { customStyles } from '../../lib/modals';
import FormError from '../FormError';
import Loader from '../Loader';
import LoaderContext from '../../contexts/LoaderContext';

Modal.setAppElement('#root')

export default ({
  isOpen,
  setIsOpen,
  onAccept,
  title,
  subtitle,
  errorMessage,
  onlyOneButton,
}) => {
  const { isRequestLoading } = useContext(LoaderContext);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
    >
      <div className="modal-container">
        <div className="modal-content cms-modal">

          <form action="#" method="post">
            <h2 className="modal-title-type1">{title}</h2>
            <p className="cms-modal-desc">{subtitle}</p>

            {errorMessage &&
              <FormError>{errorMessage}</FormError>
            }

            {isRequestLoading && <Loader />}

            <div className="cms-modal-actions">
              {!onlyOneButton &&
                <button
                  type="button"
                  id="btnCancel"
                  name="btnCancel"
                  className="btn-type4 small"
                  onClick={() => closeModal()}>
                  Cancelar
              </button>
              } 

              <button
                type="button"
                id="btnAccept"
                name="btnAccept"
                className="btn-type5 big"
                onClick={() => onAccept()}>
                Aceptar
              </button>
            </div>


          </form>


        </div>
      </div>
    </Modal>
  );
}
