import React, {useEffect} from 'react';
import {useQuery} from 'react-query';
import {useHistory} from 'react-router-dom';
import _ from 'underscore';
import Loader from '../../../components/Loader';
import useOrderBasket from '../../../hooks/useOrderBasket';
import {URLS} from '../../../lib/api';
import {getUserId} from '../../../lib/auth';
import {get} from '../../../lib/restClient';
import ProductGroup from './components/ProductGroup';

const Products = () => {
    const history = useHistory();
    const {
        orderBasket,
    } = useOrderBasket();
    const {isLoading, error, data, refetch} = useQuery('partnerProducts', async () => {
        const response = await get(URLS.PRODUCT.GET_ORDER_PRODUCTS({partnerId: getUserId()}));
        const documentsResponse = await get(URLS.DOCUMENTS.LIST_DOCUMENTS_BY_USER({userId: getUserId()}));
        const needsToSign = documentsResponse?.data?.filter(d => !d.signedAt).length !== 0;
        if (needsToSign) {
            history.push('/partners/my-account');
        }
        const onlyProductsWithVersions = response?.data?.products?.filter(p =>
            (p?.versions?.length > 0));
        return {
            list: onlyProductsWithVersions,
            totalCount: onlyProductsWithVersions?.length,
            currencyIsoCode: response?.data?.currency?.iso_code,
        };
    }, {refetchOnWindowFocus: false});
    useEffect(() => {
        setTimeout(() => refetch(), 600);
        return () => {
        };
    }, [refetch]);
    if (error) {
        return <span>Error</span>;
    }
    if (isLoading) {
        return (
            <main  className="main-cms">
                <Loader boxed/>
            </main>
        );
    }
    const bySegment = _.groupBy(
        data?.list,
        (currentObject) => currentObject?.solution?.segmentCaption
    );
    const productsToBeDisplayed = {};
    Object.keys(bySegment).forEach((key) => {
        productsToBeDisplayed[key] = _.groupBy(
            bySegment[key],
            (currentObject) => currentObject?.solution?.typeCaption,
        );
    });
    let normalizedProducts = [];
    Object.keys(productsToBeDisplayed).forEach(segment => {
        Object.keys(productsToBeDisplayed[segment]).forEach(type => {
            normalizedProducts = [
                ...normalizedProducts,
                {
                    segment,
                    type,
                    products: productsToBeDisplayed[segment][type],
                }
            ];
        })
    });

    const productsNumber = orderBasket?.length || 0;
    return (
        <main  className="main-cms">
            <form action="#" method="post">
                <div className="box main-header-cms">
                    <div className="main-header-cms-top">
                        <div className="main-header-cms-title">
                            <span className="header-cms-icon icon-products"></span>
                            <h1 className="header-cms-title">
                                Productos <span> Relación de los productos de Numentech </span>
                                <span className="cms-title-detail">{data.totalCount} productos</span>
                            </h1>
                        </div>
                        <div className="main-header-cms-actions">
                            <div className="header-cms-actions-absolute">
                                {!!productsNumber &&
                                    <button type="button" id="btnAdd" name="btnAdd" className="btn-type5"
                                            onClick={() => history.push('/partners/orders')}>
                                        Ver pedido en curso ({productsNumber})
                                    </button>
                                }
                            </div>
                        </div>

                    </div>
                </div>

                {normalizedProducts?.map(productGroup => (
                    <ProductGroup key={data?.currencyIsoCode} productGroup={productGroup} currencyIsoCode={data?.currencyIsoCode}/>
                ))}
            </form>
        </main>
    );
};

export default Products;