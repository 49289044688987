import axios from 'axios';
import {StatusCodes} from 'http-status-codes';
import {getAuthToken, logout} from './auth';
import {URLS} from './api';

let api = null;

const getHeaders = async () => {
    let headers = {
        'Content-Type': 'application/json',
        accept: 'application/json',
    };
    const token = await getAuthToken();
    if (token) {
        headers = {
            ...headers,
            Authorization: `Bearer ${token}`,
        };
    }
    return headers;
};

const getInitializedApi = () => {
    if (api) {
        return api;
    }
    api = axios.create({
        responseType: 'json',
    });
    api.interceptors.response.use((config) => config, (error) => {
        console.error(error);
        const statusCode = error.response.status;
        if (statusCode === StatusCodes.UNAUTHORIZED) {
            get(URLS.AUTH.ME())
                .then(() => {
                })
                .catch(() => logout());
        }
        return Promise.reject(error);
    });
    return api;
};

export const get = async (url, data) => {
    const headers = await getHeaders();
    return getInitializedApi().get(url, {headers, params: data});
};

export const post = async (url, data, options) => {
    const headers = await getHeaders();
    return getInitializedApi().post(url, data, {headers, ...options});
};

export const remove = async (url, data = null, options = []) => {
    const headers = await getHeaders();
    return getInitializedApi().delete(url, {
        ...options,
        data,
        headers,
    });
};

export const del = async (url, data = null, options = []) => {
    const headers = await getHeaders();
    return getInitializedApi().delete(url, {
        ...options,
        headers,
        data,
    });
};

export const postMultiPart = async (url, data) => {
    const headers = await getHeaders();
    headers['Content-Type'] = 'multipart/form-data';
    console.log('headers', headers);
    return getInitializedApi().post(url, data, {headers});
};

export const put = async (url, data) => {
    const headers = await getHeaders();
    return getInitializedApi().put(url, data, {headers});
};

export const uploadMedia = async (url, file) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    return postMultiPart(url, bodyFormData);
};

export const download = async (url, contentType) => {
    let headers = await getHeaders();
    if (contentType) {
        headers = {
            ...headers,
            'Content-Type': contentType,
            Accept: contentType,
        };
    }
    return getInitializedApi().get(
        url,
        {
            headers,
            responseType: 'blob',
            data: {},
        });
};
