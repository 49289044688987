import React, { useState } from 'react';
import { usePopper } from 'react-popper';

const Dropdown = ({ visibility = true, actions = [] }) => {
  const [expanded, setExpanded] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  return (
    <div className='dropdown-container' style={{ visibility: visibility ? 'visible' : 'hidden' }}>
      <button
        type="button"
        className="btn-icon-simple"
        ref={setReferenceElement}
        onClick={() => setExpanded(true)}
      >
        <span className="icon-arrow dropdown"></span>
      </button>

      {expanded && <div className='dropdown-overlay' onClick={() => setExpanded(false)} />}

      {expanded && (
        <div ref={setPopperElement} style={{...styles.popper, zIndex: 3}} {...attributes.popper}>
          <div className='dropdown-popper'>
            {actions.length && actions.map(action => (
              <div className='dropdown-action' key={action.text} >
                <button
                  type='button'
                  onClick={() => {
                    action.onClick();
                    setExpanded(false);
                  }}
                >
                  {action.text}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;