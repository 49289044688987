import React from 'react';

export const Input = ({
  hasError,
  type,
  id,
  name,
  resetId,
  resetName,
  value,
  onChange,
  blocked,
  alternateIcon,
}) => (
    <div className={`form-text-container ${hasError ? 'form-error' : ''}`}>
      <input
        type={type}
        id={id}
        name={name}
        className="form-text"
        value={value}
        readOnly={blocked}
        onChange={onChange} />
      {!blocked && !alternateIcon &&
        <button
          type="button"
          id={resetId}
          name={resetName}
          className="btn-reset"
          onClick={() => onChange && onChange({ target: { value: '' } })}
        >
          <span className="icon-close" />
        </button>
      }
      {alternateIcon &&
        <button
          type="button"
          id={resetId}
          name={resetName}
          className="btn-reset"
          onClick={() => onChange && onChange({ target: { value: '' } })}
        >
          <span className={alternateIcon}></span>
        </button>
      }
    </div>
  );
