import { useFormik } from 'formik';
import { useEffect, useState } from 'react'

const DEFAULT_TIMEOUT_FOR_EXPENSIVE_OPERATIONS = 700;

function useDataFilter({
  initialFilters,
  initialSort,
  onRefetch,
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [sort, setSort] = useState(initialSort);
  const filters = useFormik({
    initialValues: initialFilters,
    onSubmit: () => {
      onRefetch();
    },
  });
  useEffect(() => {
    onRefetch && onRefetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  useEffect(() => {
    onRefetch && onRefetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  function next() {
    setCurrentPage(currentPage + 1);
  }

  function previous() {
    setCurrentPage(currentPage + 1);
  }

  function setFilterValue(field, value) {
    filters.setFieldValue(field, value);
  }

  function submitFilters() {
    filters.handleSubmit();
  }

  function setSortField({ field }) {
    let newOrder = 'asc';
    if (field === sort.field && sort.order === 'asc') {
      newOrder = 'desc';
    }
    if (field === sort.field && sort.order === 'desc') {
      newOrder = 'asc';
    }
    console.log({ field })
    console.log({ newOrder })
    setSort({ field, order: newOrder });
    setTimeout(() => onRefetch(), DEFAULT_TIMEOUT_FOR_EXPENSIVE_OPERATIONS);
  }
  
  function resetFilters() {
    filters?.setValues('initialFilters');
    filters?.setValues(initialFilters);
  }

  return {
    currentPage,
    setCurrentPage,
    next,
    previous,
    filtersValues: filters?.values,
    setFilterValue,
    submitFilters,
    sort,
    setSortField,
    resetFilters,
  };
}

export default useDataFilter;
