import React, {useState} from 'react';
import ActiveOrders from './components/ActiveOrders';
import ManageOrders from './components/ManageOrders';

const Orders = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    return (
        <main>
            <main className="main-cms">
                <form action="#" method="post">
                    <div className="box main-header-cms">
                        <div className="main-header-cms-top">
                            <div className="main-header-cms-title">
                                <span className="header-cms-icon icon-orders"></span>
                                <h1 className="header-cms-title">
                                    Pedidos <span>Gestión de tus pedidos a Numentech</span>
                                </h1>
                            </div>
                        </div>

                        <nav className="cms-tabs">
                            <ul className="tabs-container">
                                <li className={`item-tab ${selectedTab === 0 ? 'active' : ''}`}
                                    onClick={() => setSelectedTab(0)}>
                                    Pedido en curso
                                </li>
                                <li className={`item-tab ${selectedTab === 1 ? 'active' : ''}`}
                                    onClick={() => setSelectedTab(1)}>
                                    Gestión de pedidos
                                </li>
                            </ul>
                        </nav>
                    </div>
                    {selectedTab === 0 && <ActiveOrders/>}
                    {selectedTab === 1 && <ManageOrders/>}
                </form>
            </main>
        </main>
    );
};

export default Orders;