import { format } from 'date-fns'
import React, { forwardRef, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const MyInput = forwardRef((props, ref) => (
  <input ref={ref} {...props} />
))

const CustomInput = ({ value, onClick, attrs, onChange, notResetable, ...rest }) => {

  const inputRef = useRef(null)

  const handleChange = () => {}

  return (
    <div
      className="form-text-container"
      style={{
        width: '90%',
        minWidth: '90%',
        maxWidth: '90%',
        marginRight: 20,
      }}
    >
      <MyInput
        ref={inputRef}
        style={{ width: '100%' }}
        type="text"
        id="txtFrom"
        name="txtFrom"
        className="form-text"
        placeholder={attrs.placeholder}
        value={value}
        onChange={handleChange}
        onClick={onClick}
      />

      {!notResetable && value && value !== '' && (
        <button
          type="button"
          className="btn-reset"
          onClick={() => onChange && onChange({ target: { value: '' } })}
        >
          <span className="icon-close" />
        </button>
      )}

      {(!value || value === '' || notResetable) && (
        <button
          type="button"
          id="btnCalendarFrom"
          name="btnCalendarFrom"
          className="btn-calendar"
          onClick={onClick}
        >
          <span className="icon-calendar"></span>
        </button>
      )}
    </div>
  )
}


export default ({
  onChange,
  placeholder,
  defaultValue,
  notResetable,
  startDate,
  endDate,
  selectsStart = false,
  selectsEnd = false
}) => {
  const [selectedDate, setSelectedDate] = useState(null)
  return (
    <DatePicker
      selected={selectedDate || defaultValue}
      startDate={startDate}
      endDate={endDate}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      onChange={(date) => {
        setSelectedDate(date)
        if (date) {
          const formattedDate = format(date, 'Y-MM-dd')
          return onChange(formattedDate || '')
        }
        onChange('')
      }}
      customInput={
        <CustomInput onChange={onChange} notResetable={notResetable} attrs={{ placeholder }} value={null} />
      }
      popperPlacement="top-end"
    />
  )
}
