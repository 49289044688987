import React from 'react';
import Modal from 'react-modal';

import { customStyles } from '../../lib/modals';
import { URLS } from '../../lib/api';
import { del } from '../../lib/restClient';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

Modal.setAppElement('#root')

export default ({
  type,
  isOpen,
  setIsOpen,
}) => {
  function closeModal() {
    setIsOpen(false);
  }
  const { clientId, partnerId } = useParams();
  const history = useHistory();
  const [completed, setCompleted] = useState(false);

  const { mutate: deactivateUser } = useMutation(
    ({ userId }) =>
      del(
        URLS.ADMIN.DEACTIVATE_USER({ userId }),
      ),
    {
      onSuccess: () => {
        setCompleted(true);
      },
      onError: (error) => {
        console.log('ERROR', error);
      }
    }
  );

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
    >
      <div className="modal-container">
        <div className="modal-content cms-modal">

          <h2 className="modal-title-type1">Eliminar</h2>
          <p className="cms-modal-desc">
            {completed ?
              `${type} eliminado correctamente` :
              '¿Estas seguro de borrar todos los datos? No podrás acceder al detalle si confirmas'
            }
          </p>

          {!completed && <div className="cms-modal-actions">
            <button
              type="button"
              id="btnCancel"
              name="btnCancel"
              className="btn-type4 small"
              onClick={() => closeModal()}>
              No, cancelar
              </button>

            <button
              type="button"
              id="btnAccept"
              name="btnAccept"
              className="btn-type5 big"
              onClick={() => {
                deactivateUser({ userId: clientId || partnerId });
              }}>
              Si, borrar
              </button>
          </div>}


          {completed &&
          <div className="action-modal-content">
            <form action="#" method="post">
              <fieldset>

                <button
                  style={{ marginTop: -50 }}
                  type="button"
                  id="btnAccept"
                  name="btnAccept"
                  className="btn-type1"
                  onClick={() => {
                    closeModal();
                    history.goBack();
                  }}>
                  Aceptar
              </button>
              </fieldset>
            </form>
            </div>
          }
        </div>
      </div>
    </Modal>
  );
}
