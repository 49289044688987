import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { URLS } from '../../../../lib/api';
import { nonStandardDateFormatting, nonStandardDateToDate } from '../../../../lib/dates';
import Loader from '../../../../components/Loader';
import InfoModal from '../../../../components/InfoModal';
import DeleteUserModal from '../../../../components/DeleteUserModal';
import { Input } from '../../../../components/Inputs';
import { get, put } from '../../../../lib/restClient';
import FormError from '../../../../components/FormError';
import DatePicker from '../../../../components/DatePicker';
import { format } from 'date-fns';


const validationSchema = Yup.object().shape({
  active: Yup.bool(),
  email: Yup.string()
    .email('Tiene que ser un email')
    .required('Requerido'),
  firstName: Yup.string()
    .required('Requerido'),
  lastName: Yup.string()
    .required('Requerido'),
  organizationName: Yup.string()
    .required('Requerido'),
  phone: Yup.string()
    .required('Requerido'),
  status: Yup.object()
    .required('Requerido'),
  website: Yup.string()
    .required('Requerido'),
  address: Yup.object({
    address: Yup.string()
      .required('Requerido'),
    address2: Yup.string()
      .optional()
      .nullable(),
    city: Yup.string()
      .required('Requerido'),
    region: Yup.string()
      .required('Requerido'),
    zip: Yup.string()
      .required('Requerido'),
  }),
});

export default forwardRef((props, ref) => {
  let { partnerId } = useParams();
  let [showModal, setShowModal] = useState();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  let [errorMessage, setErrorMessage] = useState('');
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {},
    onSubmit: values => {
      update(values);
    },
  });
  const { isLoading, error, data } = useQuery('partnerDetail', async () => {
    const response = await get(URLS.PARTNER.DETAIL({ partnerId }));
    response.data.address.country = response.data?.address?.country?.name;
    const userInfo = response?.data;
    if (!formik?.values?.createdAt) {
      formik.setFieldValue('createdAt', userInfo?.createdAt);
      formik.setFieldValue('active', userInfo?.active);
      formik.setFieldValue('address', userInfo?.address);
      formik.setFieldValue('administrativeContact', userInfo?.administrativeContact);
      formik.setFieldValue('assistantService', userInfo?.assistantService);
      formik.setFieldValue('contractStatus', userInfo?.contractStatus);
      formik.setFieldValue('email', userInfo?.email);
      formik.setFieldValue('firstName', userInfo?.firstName);
      formik.setFieldValue('id', userInfo?.id);
      formik.setFieldValue('lastName', userInfo?.lastName);
      formik.setFieldValue('notifications', userInfo?.notifications);
      formik.setFieldValue('notificationsSettings', userInfo?.notificationsSettings);
      formik.setFieldValue('organizationName', userInfo?.organizationName);
      formik.setFieldValue('partnerAgreement', userInfo?.partnerAgreement);
      formik.setFieldValue('phone', userInfo?.phone);
      formik.setFieldValue('status', userInfo?.status);
      formik.setFieldValue('website', userInfo?.website);
      formik.setFieldValue('vat', userInfo?.vat);
    }
    return response.data;
  });
  const { isLoading: areCountriesLoading, data: countriesData } = useQuery('countries', async () => {
    const response = await get(URLS.COUNTRIES.LIST());
    return response.data;
  });
  const { mutate: update } = useMutation(
    async (values) => {
      if (values.address.country) {
        values.address.country = countriesData.filter(c => c.name === values.address.country)[0];
      }
      if (values.address.country) {
        values.address.country = countriesData.filter(c => c.name === values.address.country)[0];
      }
      if (values?.partnerAgreement?.expiresAt) {
        values.partnerAgreement.expiresAt =
          `${format(nonStandardDateFormatting(values?.partnerAgreement?.expiresAt?.date), 'y-MM-dd')} 18:00:00.000000`;
      }
      if (values?.phone) {
        values.phone = values?.phone?.split(' ').join('');
      }
      const response = await put(URLS.PARTNER.UPDATE_INFO({ userId: data.id }), values);
      return response;
    },
    {
      onSuccess: async () => setShowModal(true),
      onError: (error) => {
        console.log('ERROR', error);
        const errors = error?.response?.data?.errors
        const firstError = Object.keys(errors)[0];
        setErrorMessage(errors[firstError]);
      }
    }
  );

  useImperativeHandle(ref, () => ({
    update() {
      formik.handleSubmit();
    }
  }));
  if (error) {
    return <span>error</span>;
  }
  if (isLoading || areCountriesLoading) {
    return <Loader />;
  }

  return (
    <div className="box form-box">
      <h2 className="title-type6">Datos básicos</h2>
      <FormError>{errorMessage}</FormError>
      <div className="main-header-cms-top">
        <div className="main-header-cms-actions">
          <button type="button" id="btnSave" name="btnSave" className="btn-type5"
            onClick={() => setDeleteModalOpen(true)}>
            Eliminar
        </button>
        </div>
      </div>
      <div className="form-item-row">
        <div className="form-item-col">
          <div className="form-item form-item-two">
            <div className="form-item">
              <label className="form-label">Nombre *</label>
              <Input type="text" id="txtName" name="txtName" className="form-text"
                onChange={(e) => formik.setFieldValue('firstName', e.target.value)}
                value={formik.values?.firstName} />
              {formik?.touched?.firstName && formik?.errors?.firstName &&
                <FormError>{formik?.errors?.firstName}</FormError>
              }
            </div>

            <div className="form-item">
              <label className="form-label">Apellido *</label>
              <Input type="text" id="txtSurname" name="txtSurname" className="form-text"
                onChange={(e) => formik.setFieldValue('lastName', e.target.value)}
                value={formik.values?.lastName} />
              {formik?.touched?.lastName && formik?.errors?.lastName &&
                <FormError>{formik?.errors?.lastName}</FormError>
              }
            </div>
          </div>

          <div className="form-item">
            <label className="form-label">Email *</label>
            <Input type="text" id="txtEmail" name="txtEmail" className="form-text"
              onChange={(e) => formik.setFieldValue('email', e.target.value)}
              value={formik.values?.email} />
            {formik?.touched?.email && formik?.errors?.email &&
              <FormError>{formik?.errors?.email}</FormError>
            }
          </div>

          <div className="form-item form-item-two">

            <div className="form-item">
              <label className="form-label">Teléfono</label>
              <Input type="text" id="txtPhone" name="txtPhone" className="form-text"
                onChange={(e) => formik.setFieldValue('phone', e.target.value)}
                value={formik.values?.phone} />
              {formik?.touched?.phone && formik?.errors?.phone &&
                <FormError>{formik?.errors?.phone}</FormError>
              }
            </div>

          </div>

          <div className="form-item">
            <label className="form-label">Nombre empresa *</label>
            <Input type="text" id="txtEnterprise" name="txtEnterprise" className="form-text"
              onChange={(e) => formik.setFieldValue('organizationName', e.target.value)}
              value={formik.values?.organizationName} />
            {formik?.touched?.organizationName && formik?.errors?.organizationName &&
              <FormError>{formik?.errors?.organizationName}</FormError>
            }
          </div>

          <div className="form-item">
            <label className="form-label">Página web</label>
            <Input type="text" id="txtWeb" name="txtWeb" className="form-text"
              onChange={(e) => formik.setFieldValue('website', e.target.value)}
              value={formik.values?.website} />
            {formik?.touched?.website && formik?.errors?.website &&
              <FormError>{formik?.errors?.website}</FormError>
            }
          </div>

        </div>
        <div className="form-item-col">
          <div className="form-item">
            <label className="form-label">Dirección *</label>
            <Input type="text" id="txtAddress" name="txtAddress" className="form-text"
              onChange={(e) => formik.setFieldValue('address.address', e.target.value)}
              value={formik.values?.address?.address} />
            {formik?.touched?.address?.address && formik?.errors?.address?.address &&
              <FormError>{formik?.errors?.address?.address}</FormError>
            }
          </div>
          <div className="form-item">
            <label className="form-label">Dirección 2 (opcional)</label>
            <Input type="text" id="txtAddress2" name="txtAddress2" className="form-text"
              onChange={(e) => formik.setFieldValue('address.address2', e.target.value)}
              value={formik.values?.address?.address2} />
            {formik?.touched?.address?.address2 && formik?.errors?.address?.address2 &&
              <FormError>{formik?.errors?.address?.address2}</FormError>
            }
          </div>

          <div className="form-item form-item-two">
            <div className="form-item">
              <label className="form-label">País *</label>
              <Input
                id="cmbCountry"
                name="cmbCountry"
                className="form-select"
                blocked
                value={formik?.values?.address?.country} />
              {formik?.touched?.address?.country && formik?.errors?.address?.country &&
                <FormError>{formik?.errors?.address?.country}</FormError>
              }
            </div>

            <div className="form-item">
              <label className="form-label">Región *</label>
              <Input type="text" id="txtRegion" name="txtRegion" className="form-text"
                onChange={(e) => formik.setFieldValue('address.region', e.target.value)}
                value={formik.values?.address?.region} />
              {formik?.touched?.address?.region && formik?.errors?.address?.region &&
                <FormError>{formik?.errors?.address?.region}</FormError>
              }
            </div>
          </div>

          <div className="form-item form-item-two">
            <div className="form-item">
              <label className="form-label">Ciudad *</label>
              <Input type="text" id="txtCity" name="txtCity" className="form-text"
                onChange={(e) => formik.setFieldValue('address.city', e.target.value)}
                value={formik.values?.address?.city} />
              {formik?.touched?.address?.city && formik?.errors?.address?.city &&
                <FormError>{formik?.errors?.address?.city}</FormError>
              }
            </div>

            <div className="form-item">
              <label className="form-label">Código postal *</label>
              <Input type="text" id="txtPostalCode" name="txtPostalCode" className="form-text"
                onChange={(e) => formik.setFieldValue('address.zip', e.target.value)}
                value={formik.values?.address?.zip} />
              {formik?.touched?.address?.zip && formik?.errors?.address?.zip &&
                <FormError>{formik?.errors?.address?.zip}</FormError>
              }
            </div>
          </div>

          <div className="form-item form-item-two">
            <div className="form-item">
              <label className="form-label">fecha de alta</label>
              <div className="form-text-container">
                <Input type="text" id="txtDate1" name="txtDate1" className="form-text" disabled value={data.createdAt.date} blocked />
              </div>
            </div>
            <div className="form-item">
              <label className="form-label">Fecha de expiración</label>
              <DatePicker
                placeholder="Fecha de expiración"
                defaultValue={formik?.values?.partnerAgreement?.expiresAt?.date ?
                  nonStandardDateToDate(formik?.values?.partnerAgreement?.expiresAt?.date) :
                  null
                }
                onChange={(value) => {
                  const formattedDate = `${format(new Date(value), 'y-MM-dd')} 18:00:00.000000`;
                  formik.setFieldValue('partnerAgreement.expiresAt.date', formattedDate);
                }} />
            </div>
          </div>
        </div>
        <div className="form-item-col">
          <div className="form-item">
            <label className="form-label">Estado *</label>
            <div className="form-text-container">
              <select id="cmbStatus2" name="cmbStatus2" className="form-select"
                onChange={(e) => formik.setFieldValue('active', e.target.value === 'true')}>
                <option
                  selected={formik?.values?.active}
                  value={true}>Activado</option>
                <option
                  selected={!formik?.values?.active}
                  value={false}>Desactivado</option>
              </select>
              <span className="icon-arrow select-arrow"></span>
            </div>
            {formik?.touched?.active && formik?.errors?.active &&
              <FormError>{formik?.errors?.active}</FormError>
            }
          </div>
        </div>
      </div>
      <InfoModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        title="Datos guardados"
        subtitle="Datos guardados correctamente"
      />
      <DeleteUserModal
        type="Partner"
        isOpen={isDeleteModalOpen}
        setIsOpen={setDeleteModalOpen} />
    </div>
  );
});
