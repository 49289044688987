import React, { useState } from 'react';

import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import { useMutation } from 'react-query';

import { Input } from '../../components/Inputs';
import { Button } from '../../components/Buttons';
import { Image } from '../../components/Image';
import FormError from '../../components/FormError';
import TextModal from '../../components/TextModal';
import { URLS } from '../../lib/api';

const RememberSchema = Yup.object().shape({
  email: Yup.string()
    .email('Introduce correo electrónico válido')
    .required('Requerido'),
});

const RememberPassword = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const { mutate: rememberPass } = useMutation(
    rememberPassBody => axios.post(URLS.AUTH.PASSWORD_REQUEST(), rememberPassBody),
    {
      onSuccess: () => {
        setModalIsOpen(true);
      },
      onError: (error) => {
        console.log('ERROR', error);
        const { response: { data: { message } }} = error;
        if (message) {
          setMessage(message);
        }
      }
    }
  )
  return (
    <>
      <div className="access-form-bl access-pass">
        <Link to="/"><Image icon="logo.svg" alt="Numentech" className="access-logo" /></Link>
        <h1 className="title">Recuperar contraseña</h1>
        <p className="form-desc">Puedes cambiar tu contraseña por motivos de seguridad o recuperarla si la olvidas.</p>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={RememberSchema}
          onSubmit={(values) => {
            rememberPass(values);
          }}
          render={({
            values,
            errors,
            handleSubmit,
            setFieldValue
          }) => (
              <form action="#" method="post">
                <fieldset className="access-fieldset">
                  <div className="form-item">
                    <label className="form-label">Correo electrónico para cambio</label>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                    />
                    {errors.email && <FormError>{errors.email}</FormError>}
                  </div>

                  {message && <FormError>{message}</FormError>}

                  <label className="form-label">Introduce el correo electrónico y revisa tu bandeja de entrada.</label>
                  <Button type="submit" id="btnChangePass" name="btnChangePass" onClick={handleSubmit}>
                    Siguiente
                  </Button>
                </fieldset>
              </form>
            )}
        />
      </div>
      <div className="access-right-col">
        <Image icon="loginnumen.jpg" />
      </div>
      <TextModal
        isOpen={modalIsOpen}
        text="Hemos enviado las instrucciones para cambiar tu contraseña a tu correo"
        onAccept={() => window.location.href = '/'}
      />
    </>
  );
};

export default RememberPassword;