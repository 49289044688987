import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useHistory} from 'react-router-dom';
import Loader from '../../../components/Loader';
import {Input} from '../../../components/Inputs';
import RightAlignedButton from '../../../components/RightAlignedButton';
import SortArrow from '../../../components/SortArrow';
import TablePager from '../../../components/TablePager';
import useDataFilter from '../../../hooks/useDataFilter';
import {URLS} from '../../../lib/api';
import {downloadFile} from '../../../lib/downloads';
import {get} from '../../../lib/restClient';

const initialValues = {
    name: null,
};

const Products = () => {
    const history = useHistory();
    const {
        currentPage,
        setCurrentPage,
        filtersValues,
        setFilterValue,
        submitFilters,
        sort,
        setSortField,
    } = useDataFilter({
        initialFilters: initialValues,
        initialSort: {field: '', order: ''},
        onRefetch: () => refetch(),
    });
    const [showFilters, setShowFilters] = useState(false);
    const {isLoading, isFetching, error, data, refetch} = useQuery('adminProducts', async () => {
        const response = await get(
            URLS.ADMIN.LIST_PRODUCTS({
                page: currentPage,
                sort,
                filters: filtersValues,
            }),
        );
        const typesResponse = await get(
            URLS.ADMIN.LIST_PRODUCTS_TYPES(),
        );
        const segmentsResponse = await get(
            URLS.ADMIN.LIST_PRODUCTS_SEGMENTS(),
        );
        return {
            list: response.data,
            pageCount: response.headers['x-page-count'],
            totalCount: response.headers['x-total-count'],
            types: typesResponse.data,
            segments: segmentsResponse.data,
        };
    }, {refetchOnWindowFocus: false});
    useEffect(() => {
        setTimeout(() => refetch(), 600);
    return () => { };
    }, [sort, currentPage, refetch]);
    if (error) {
        return <span>Error</span>;
    }
    if (isLoading) {
        return <Loader/>;
    }
    return (
        <main>
            <form action="#" method="post">
                <div className="box main-header-cms">
                    <div className="main-header-cms-top">
                        <div className="main-header-cms-title">
                            <span className="header-cms-icon icon-products"></span>
                            <h1 className="header-cms-title">
                                Productos <span>Relación de los productos de Numentech</span>
                                <span className="cms-title-detail">{data.totalCount} productos</span>
                            </h1>
                        </div>

                        <div className="main-header-cms-actions">
                            <div className="header-cms-actions-absolute">
                                <button type="button" id="btnAdd" name="btnAdd" className="btn-type5"
                                        onClick={() => history.push('/admin/products/add')}>
                                    Añadir producto
                                </button>
                            </div>

                            <div className="header-cms-actions-buttons">
                                <button type="button" id="btnFilters" name="btnFilters" className="btn-cms-actions"
                                        onClick={() => setShowFilters(!showFilters)}>
                                    Filtros
                                </button>
                                <button type="button" id="btnDownload" name="btnDownload" className="btn-cms-actions"
                                        onClick={() => downloadFile({
                                            url: URLS.ADMIN.DOWNLOAD_PRODUCTS_CSV(),
                                            name: 'Products.csv',
                                            contentType: 'application/csv',
                                        })}>
                                    Descargar CSV <span className="icon-download-filters"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="box">
                    <div className={`cms-filters ${showFilters ? 'show' : false}`}>
                        <h2 className="cms-filters-title">Filtros</h2>
                        <button type="button" id="btnCloseFilters" name="btnCloseFilters" className="btn-close-filters"
                                onClick={() => setShowFilters(!showFilters)}>
                            <span className="icon-close-modal"></span>
                        </button>

                        <div className="form-item-row form-row-special">
                            <div className="form-item item-special-size2">
                                <label className="form-label">Nombre producto</label>

                                <div className="form-text-container">
                                    <Input
                                        type="text"
                                        id="name"
                                        name="name"
                                        className="form-text"
                                        value={filtersValues?.name}
                                        onChange={(e) => setFilterValue('name', e.target.value)}/>
                                </div>

                            </div>

                            <div className="form-item item-special-size3">
                                <label className="form-label">Tipo</label>

                                <div className="form-text-container">
                                    <select id="cmbType" name="cmbType" className="form-select"
                                            onChange={(e) => setFilterValue('type', e.target.value === 'Todos' ? null : e.target.value)}>
                                        <option value={null}>Todos</option>
                                        {data?.types.map(type => (
                                            <option key={type.id} value={type.id}>{type.label}</option>
                                        ))}
                                    </select>
                                    <span className="icon-arrow select-arrow"></span>
                                </div>
                            </div>

                            <div className="form-item item-special-size3">
                                <label className="form-label">Segmento</label>

                                <div className="form-text-container">
                                    <select id="cmbCategory" name="cmbCategory" className="form-select"
                                            onChange={(e) => {
                                                setFilterValue('segment', e.target.value === 'Todos' ? null : e.target.value)
                                            }}>
                                        <option value={null}>Todos</option>
                                        {data?.segments.map(segment => (
                                            <option key={segment.id} value={segment.id}>{segment.caption}</option>
                                        ))}
                                    </select>
                                    <span className="icon-arrow select-arrow"></span>
                                </div>
                            </div>

                            <RightAlignedButton
                                onClick={() => {
                                    submitFilters();
                                    setShowFilters(false);
                                    setCurrentPage(0);
                                }}>
                                Filtrar
                            </RightAlignedButton>
                        </div>
                    </div>

                    {(isFetching || isLoading) &&
                        <Loader boxed/>
                    }
                    {!isFetching && !isLoading &&
                        <div className="responsive-table full-table">
                            <table className="table-type2">
                                <thead>
                                <tr>
                                    <th onClick={() => setSortField({field: 'name'})}>
                                        Nombre producto
                                        <SortArrow sort={sort} field="name"/>
                                    </th>
                                    <th onClick={() => setSortField({field: 'createdAt'})}>
                                        fecha de alta
                                        <SortArrow sort={sort} field="createdAt"/>
                                    </th>
                                    <th>Última versión
                                    </th>
                                    <th onClick={() => setSortField({field: 'type'})}>
                                        Tipo
                                        <SortArrow sort={sort} field="type"/>
                                    </th>
                                    <th onClick={() => setSortField({field: 'segment'})}>
                                        Segmento
                                        <SortArrow sort={sort} field="segment"/>
                                    </th>
                                    <th>Und. disponibles</th>
                                    <th className="centered">Acciones</th>
                                </tr>
                                </thead>

                                <tbody>
                                {data.list.map(product => (
                                    <tr key={product?.id}>
                                        <td>{product.name}</td>
                                        <td>20/06/20</td>
                                        <td>{product?.latestVersion?.version}</td>
                                        <td>{product?.type?.label}</td>
                                        <td>{product?.solution?.segmentCaption}</td>
                                        <td>{product?.stockAvailable}</td>
                                        <td className="centered">
                                            <button type="button" id="btnShowItem1" name="btnShowItem1"
                                                    className="btn-icon-border"
                                                    onClick={() => history.push(`/admin/products/${product.id}/edit`)}>
                                                <span className="icon-edit"></span>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>

                {!isFetching && !isLoading &&
                    <TablePager
                        pages={data.pageCount}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        refetch={refetch}/>
                }

            </form>
        </main>
    );
};

export default Products;