import React from 'react';

export default ({ status }) => (
  <>
    {status.toLowerCase() === 'baja' &&
      <span className="status-tag inactive">Baja</span>
    }
    {status.toLowerCase() === 'media' &&
      <span className="status-tag active">Media</span>
    }
    {status.toLowerCase() === 'alta' &&
      <span className="status-tag next-expire">Alta</span>
    }
  </>
)