import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import OrderItem from './components/OrderItem';
import useOrderBasket from '../../../../../hooks/useOrderBasket';
import OrderOKModal from '../../../../../components/OrderOKModal';
import { basketIsEmpty } from '../../../../../lib/order-basket';
import Loader from '../../../../../components/Loader';
import { URLS } from '../../../../../lib/api';
import { useMutation, useQuery } from 'react-query';
import { get, post } from '../../../../../lib/restClient';
import { formatPrice } from '../../../../../lib/prices';
import { getUserId } from '../../../../../lib/auth';
import { FormError } from '../../../../../components/FormError/styles';

export default () => {
  const history = useHistory();
  const [showOKModal, setShowOKModal] = useState(false);
  const [orderErrorMessage, setOrderErrorMessage] = useState(null);
  const {
    orderBasket,
    addProductToBasket,
    addOneMoreProductToBasket,
    removeOneProductFromBasket,
    removeProductFromBasket,
  } = useOrderBasket();
  const { isLoading, error, data } = useQuery('partnerActiveOrders', async () => {
    const response = await get(URLS.PRODUCT.GET_ORDER_PRODUCTS({ partnerId: getUserId() }));
    return {
      currencyIsoCode: response?.data?.currency?.iso_code,
    };
  });
  const { mutate: placeOrder } = useMutation(
    () => {
      const products = orderBasket.map(item => ({
        id: item.id,
        quantity: item.quantity,
      }));
      return post(URLS.PARTNER.PLACE_ORDER(),
        { products });
    },
    {
      onSuccess: async () => {
        setShowOKModal(true);
      },
      onError: (error) => {
        console.log('ERROR', error);
        setOrderErrorMessage(error?.response?.data?.message);
      }
    }
  );
  if (basketIsEmpty()) {
    return (
      <div className="box">
        <p className="no-items-desc icon-orders">En este momento no tienes ningún pedido en curso</p>
      </div>
    );
  }
  if (error) {
    return <span>Error</span>;
  }
  if (isLoading) {
    return <Loader boxed />;
  }
  const totalPrice = orderBasket?.reduce((acc, item) => {
    let nextVolumeDiscount;
    let selectedVolumeDiscount;
    let discountAmount;
    let totalDiscountInPercentage = 0;
    if (item?.price?.volumeDiscounts?.length > 0) {
      for (let i = 0; i < item?.price?.volumeDiscounts.length; i++) {
        const volDiscount = item?.price?.volumeDiscounts[i];
        const volCategory = volDiscount?.volumeCategory;
        if (
          volCategory.quantityFrom > item?.quantity &&
          (nextVolumeDiscount?.volumeCategory?.quantityFrom > volCategory.quantityFrom || !nextVolumeDiscount)
        ) {
          nextVolumeDiscount = volDiscount
        }
        if (
          volCategory.quantityFrom < item?.quantity &&
          item?.quantity <= volCategory.quantityTo) {
          selectedVolumeDiscount = volDiscount;
        }
        if (item?.quantity > volCategory.quantityFrom) {
          selectedVolumeDiscount = volDiscount;
          totalDiscountInPercentage += volDiscount?.discount;
        }
      }
    }
    let unitPrice = selectedVolumeDiscount ?
      item?.price?.unitPrice - (item?.price?.unitPrice * selectedVolumeDiscount?.discount) / 100 :
      item?.price?.unitPrice;
    if (item?.price?.planDiscount) {
      unitPrice =
        item?.price?.unitPrice
        - (item?.price?.unitPrice * item?.price?.planDiscount?.discount)
        / 100;
      discountAmount = item?.price?.planDiscount?.discount;
      totalDiscountInPercentage += discountAmount;
    }
    if (item?.price?.typeDiscount) {
      unitPrice =
        item?.price?.unitPrice
        - (item?.price?.unitPrice * item?.price?.typeDiscount?.discount)
        / 100;
      discountAmount = item?.price?.typeDiscount?.discount;
      totalDiscountInPercentage += discountAmount;
    }

    unitPrice = totalDiscountInPercentage !== 0 ?
      item?.price?.unitPrice - (item?.price?.unitPrice * totalDiscountInPercentage) / 100 :
      item?.price?.unitPrice;
    return acc + ((unitPrice * item?.quantity) || 0);
  }, 0);
  return (
    <div className="box form-box">
      <h2 className="title-type6">Datos del Pedido en curso</h2>

      {orderBasket?.map(item =>
        <OrderItem
          key={item?.id}
          item={item}
          currencyIsoCode={data?.currencyIsoCode}
          addProductToBasket={addProductToBasket}
          addOneMoreProductToBasket={addOneMoreProductToBasket}
          removeProductFromBasket={removeProductFromBasket}
          removeOneProductFromBasket={removeOneProductFromBasket}
        />
      )}

      <div className="order-item-total-bl">
        <span className="order-item-total-label">Precio Total</span>
        <div className="order-item-price-info">
        </div>
        <span className="order-item-total-txt">
          {formatPrice({
            number: totalPrice,
            currencyIsoCode: data?.currencyIsoCode,
          })}
        </span>
      </div>

      <FormError>{orderErrorMessage}</FormError>

      <div className="order-actions">
        <button type="button" id="btnCancel" name="btnCancel" className="btn-type4 small"
          onClick={() => history.push('/partners/products')}>
          Añadir más productos
        </button>
        <button type="button" id="btnAdd" name="btnAdd" className="btn-type5 big"
          onClick={() => placeOrder()}>
          Finalizar pedido
        </button>
      </div>

      <OrderOKModal
        isOpen={showOKModal}
        setIsOpen={setShowOKModal}
      />
    </div>
  );
};
