import { format } from 'date-fns';
import isFuture from 'date-fns/isFuture';

export const simpleDateFormatting = (date) => (
  format(date, 'MM/dd/yyyy')
);

export const nonStandardDateFormatting = (date) => {
  // 2020-09-19 16:18:23.000000
  if (!date) {
    return '';
  }
  const splitted = date.split(' ');
  const parsedDate = new Date();
  parsedDate.setFullYear(splitted[0].split('-')[0]);
  parsedDate.setMonth(splitted[0].split('-')[1] - 1);
  parsedDate.setDate(splitted[0].split('-')[2]);
  parsedDate.setHours(splitted[1].split(':')[0]);
  parsedDate.setMinutes(splitted[1].split(':')[1]);
  return format(parsedDate, 'dd/MM/yyyy HH:mm');
};


export const nonStandardDateToDate = (date) => {
  // 2020-09-19 16:18:23.000000
  if (!date) {
    return '';
  }
  const splitted = date.split(' ');
  const parsedDate = new Date();
  parsedDate.setFullYear(splitted[0].split('-')[0]);
  parsedDate.setMonth(splitted[0].split('-')[1] - 1);
  parsedDate.setDate(splitted[0].split('-')[2]);
  parsedDate.setHours(splitted[1].split(':')[0]);
  parsedDate.setMinutes(splitted[1].split(':')[1]);
  return parsedDate;
};

export const isFutureDate = (date) => isFuture(date);
