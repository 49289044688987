import React, { forwardRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { URLS } from '../../../../../lib/api';
import Loader from '../../../../../components/Loader';
import CategoryDiscountModal from '../../../../../components/CategoryDiscountModal';
import { del, get } from '../../../../../lib/restClient';
import EmptyText from '../../../../../components/EmptyText';

export default forwardRef((props, ref) => {
  const queryClient = useQueryClient();
  const { productId, versionId } = useParams();
  const [showCategoryDiscountModal, setShowCategoryDiscountModal] = useState(false);
  const [discountToEdit, setDiscounToEdit] = useState(null);
  const { mutate: deleteCategoryDiscount } = useMutation(
    ({ discountId }) => {
      return del(URLS.PRODUCT.DELETE_CATEGORY_DISCOUNT({ productId, versionId, discountId }));
    },
    {
      onSuccess: async () => {
        queryClient.refetchQueries(['category-status']);
      },
      onError: (error) => {
        console.log('ERROR', error);
      }
    }
  );
  const { error, isLoading, data } = useQuery('category-status',
    async () => {
      const categoryDiscountResponse = await get(URLS.PRODUCT.GET_CATEGORY_DISCOUNT({
        productId, versionId,
      }));
      return {
        categoryDiscounts: categoryDiscountResponse.data,
      }
    });
  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <span>Error</span>;
  }
  return (
    <div className="box form-box">
      <h2 className="title-type6">Descuento por categoría</h2>

      <div className="btn-absolute">
        <button type="button" id="btnAddVersion" name="btnAddVersion" className="btn-type5"
          onClick={() => {
            setDiscounToEdit(null);
            setShowCategoryDiscountModal(true)
          }}>
          Añadir descuento
        </button>
      </div>

      {data?.categoryDiscounts?.length === 0 &&
        <EmptyText>No hay precios definidos</EmptyText>
      }
      {data?.categoryDiscounts?.length > 0 &&
      <div className="responsive-table full-table">
        <table className="table-type2">
          <thead>
            <tr>
              <th>Países
                    <button type="button" id="btnOrderItem1" name="btnOrderItem1" className="btn-order up">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th>% Assistance
                    <button type="button" id="btnOrderItem2" name="btnOrderItem2" className="btn-order up">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th>% Bank / Credit
                    <button type="button" id="btnOrderItem3" name="btnOrderItem3" className="btn-order">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th>% Insurance
                    <button type="button" id="btnOrderItem4" name="btnOrderItem4" className="btn-order">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th>% IT Reseller
                    <button type="button" id="btnOrderItem5" name="btnOrderItem5" className="btn-order">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th>% Security
                    <button type="button" id="btnOrderItem6" name="btnOrderItem6" className="btn-order">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th>% Telco
                    <button type="button" id="btnOrderItem7" name="btnOrderItem7" className="btn-order">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th>% Other
                    <button type="button" id="btnOrderItem7" name="btnOrderItem7" className="btn-order">
                  <span className="icon-arrow2"></span>
                </button>
              </th>
              <th className="centered">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data?.categoryDiscounts?.map(cd => (
              <tr>
                <td className="wrap">
                  {cd.countries.map(c => c.name).join(', ')}
                </td>
                <td>
                  {cd.discounts.filter(d => d?.partnerType?.name === 'Assistance Company')[0] ?
                    cd.discounts.filter(d => d?.partnerType?.name === 'Assistance Company')[0].discount : 0}%
                </td>
                <td>
                  {cd.discounts.filter(d => d?.partnerType?.name === 'Bank/Credit Company')[0] ?
                    cd.discounts.filter(d => d?.partnerType?.name === 'Bank/Credit Company')[0].discount : 0}%
                </td>
                <td>
                  {cd.discounts.filter(d => d?.partnerType?.name === 'Insurance Company')[0] ?
                    cd.discounts.filter(d => d?.partnerType?.name === 'Insurance Company')[0].discount : 0}%
                </td>
                <td>
                  {cd.discounts.filter(d => d?.partnerType?.name === 'IT Reseller')[0] ?
                    cd.discounts.filter(d => d?.partnerType?.name === 'IT Reseller')[0].discount : 0}%
                </td>
                <td>
                  {cd.discounts.filter(d => d?.partnerType?.name === 'Security Company')[0] ?
                    cd.discounts.filter(d => d?.partnerType?.name === 'Security Company')[0].discount : 0}%
                </td>
                <td>
                  {cd.discounts.filter(d => d?.partnerType?.name === 'Telco')[0] ?
                    cd.discounts.filter(d => d?.partnerType?.name === 'Telco')[0].discount : 0}%
                </td>
                <td>
                  {cd.discounts.filter(d => d?.partnerType?.name === 'Others')[0] ?
                    cd.discounts.filter(d => d?.partnerType?.name === 'Others')[0].discount : 0}%
                </td>
                <td className="centered">
                  <div className="table-btn-container">
                    <button type="button" id="btnShowItem1" name="btnShowItem1" className="btn-icon-border"
                      onClick={() => {
                        setDiscounToEdit(cd);
                        setShowCategoryDiscountModal(true);
                      }}>
                      <span className="icon-edit"></span>
                    </button>
                    <button type="button" id="btnRemoveItem1" name="btnRemoveItem1" className="btn-icon-border"
                      onClick={() => {
                        deleteCategoryDiscount({ productId, versionId, discountId: cd.id });
                      }}>
                      <span className="icon-remove"></span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>

        </table>
      </div>
      }
      {showCategoryDiscountModal && (
        <CategoryDiscountModal
          productId={productId}
          versionId={versionId}
          discountToEdit={discountToEdit}
          isOpen={showCategoryDiscountModal}
          setIsOpen={setShowCategoryDiscountModal} />
      )}
    </div>
  );
});
