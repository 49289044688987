import React from 'react';
import { removeAuthToken } from '../../lib/auth';

const Dashboard = () => {
  return (
    <>
      <div>Logged user</div>
      <a style={{ cursor: 'pointer' }} href="/" onClick={(e) => {
        e.preventDefault();
        removeAuthToken();
        window.location.href = '/';
      }}>LOGOUT</a>
    </>
  );
};

export default Dashboard;