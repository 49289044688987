import React, {useState} from 'react';
import Modal from 'react-modal';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import * as Yup from 'yup';
import {customStyles} from '../../lib/modals';
import {get, post, put} from '../../lib/restClient';
import {URLS} from '../../lib/api';
import {Input} from '../Inputs';
import {useFormik} from 'formik';
import {FormError} from '../FormError/styles';
import CountrySearcher from '../CountrySearcher';
import Loader from '../Loader';
import {addDays, format, parseISO} from 'date-fns';
import DatePicker from '../DatePicker';
import {extractErrorMessageFromResponse} from "../../lib/utils";

Modal.setAppElement('#root');

const ValidationSchema = Yup.object().shape({
    volumes: Yup.object().required('Requerido'),
    countries: Yup.array().required('Requerido').min(1, 'Debes seleccioanr al menos un país'),
    from: Yup.string().required('Requerido'),
    to: Yup.string().required('Requerido'),
});

export default ({isOpen, setIsOpen, productId, versionId, discountToEdit}) => {
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState('')
    const formik = useFormik({
        initialValues: {
            countries: [],
            from: format(new Date(), 'y-MM-dd'),
            to: format(addDays(new Date(), 1), 'y-MM-dd'),
            volumes: null,
        },
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
            const body = {...values};
            body.countries = values.countries.map(country => country.iso_code);
            body.from = format(parseISO(values.from), 'y-MM-dd');
            body.to = format(parseISO(values.to), 'y-MM-dd');
            body.volumes = []
            Object.keys(values.volumes).forEach((pp) => {
                if (values.volumes[pp]) {
                    body.volumes.push({
                        id: pp,
                        discount: values.volumes[pp],
                    })
                }
            })
            if (discountToEdit) {
                updateStatusDiscount({discountId: discountToEdit.id, body});
            } else {
                addStatusDiscount(body);
            }
        },
    });
    const {error, isLoading, data} = useQuery(
        'getPartners-quantitydiscount',
        async () => {
            const volumesDiscountResponse = await get(URLS.PRODUCT.LIST_VOLUMES_DISCOUNTS());
            const volumes = volumesDiscountResponse.data;
            if (discountToEdit && !formik?.values?.countries?.length) {
                discountToEdit.discounts.forEach((dte) => {
                    formik.setFieldValue(`volumes.${dte?.volumeCategory?.id}`, dte.discount);
                });
                await formik.setFieldValue('countries', discountToEdit?.countries);
                await formik.setFieldValue('from', format(new Date(discountToEdit?.dateFrom), 'y-MM-dd'));
                await formik.setFieldValue('to', format(new Date(discountToEdit?.dateTo), 'y-MM-dd'));

            } else {
                volumes.forEach((v) => {
                    formik.setFieldValue(`volumes.${v.id}`, 0)
                })
            }
            return {
                volumes,
            }
        });
    const {mutate: addStatusDiscount} = useMutation(
        (body) => post(URLS.PRODUCT.ADD_QUANTITY_DISCOUNT({
            productId,
            versionId,
        }), body),
        {
            onSuccess: () => {
                setIsOpen(false);
                queryClient.refetchQueries(['quantity-status']);
            },
            onError: (error) => {
                console.log('ERROR', error);
                setErrorMessage(extractErrorMessageFromResponse(error, formik))
            }
        }
    );
    const {mutate: updateStatusDiscount} = useMutation(
        ({discountId, body}) => put(URLS.PRODUCT.EDIT_QUANTITY_DISCOUNT({
            productId,
            versionId,
            discountId,
        }), body),
        {
            onSuccess: () => {
                setIsOpen(false);
                queryClient.refetchQueries(['quantity-status']);
            },
            onError: (error) => {
                console.log('ERROR', error);
                setErrorMessage(extractErrorMessageFromResponse(error, formik))
            }
        }
    );
    if (isLoading) {
        return <Loader/>;
    }
    if (error) {
        return <span>Error</span>;
    }
    return (
        <Modal
            isOpen={isOpen}
            style={customStyles}>
            <div className="modal-container">
                <div className="modal-content cms-modal">

                    <form action="#" method="post">
                        <h2 className="modal-title-type1">Añadir descuento</h2>
                        <p className="cms-modal-desc">Selecciona los países y añade los descuentos</p>

                        <div className="form-item">
                            <label className="form-label">País</label>
                            <CountrySearcher
                                onChange={(selectedCountries) => {
                                    formik.setFieldValue('countries', selectedCountries)
                                }}
                                mustHaveCurrency
                                selectedCountries={formik?.values?.countries}
                                touched={formik?.touched?.countries}
                                errorMessage={formik?.errors?.countries}/>
                        </div>
                        <label className="form-label">Duración</label>
                        <div className="form-item-row">
                            <div className="form-item-col modal-col-size1">
                                <div className="form-item">
                                    <DatePicker
                                        placeholder="Desde"
                                        defaultValue={new Date(formik?.values?.from)}
                                        onChange={(date) => formik?.setFieldValue('from', date)}/>
                                    {formik?.touched?.from && formik?.errors?.from &&
                                        <FormError>{formik?.errors?.from}</FormError>
                                    }  </div>
                            </div>
                            <div className="form-item-col modal-col-size1">
                                <div className="form-item">
                                    <DatePicker
                                        placeholder="Hasta"
                                        defaultValue={new Date(formik?.values?.to)}
                                        onChange={(date) => formik?.setFieldValue('to', date)}/>
                                    {formik?.touched?.to && formik?.errors?.to &&
                                        <FormError>{formik?.errors?.to}</FormError>
                                    }
                                </div>
                            </div>

                        </div>

                        <div className="form-item-row">
                            {isLoading && <Loader/>}
                            {!isLoading &&
                                data?.volumes?.map((v) => (
                                    <div key={v.id} className="form-item-col modal-col-size5">
                                        <div className="form-item">
                                            <label className="form-label">% {v.quantityFrom} - {v.quantityTo}</label>
                                            <Input
                                                type="text"
                                                id={`txt${v.quantityFrom}-${v.quantityTo}`}
                                                name={`txt${v.quantityFrom}-${v.quantityTo}`}
                                                className="form-text"
                                                onChange={(e) => {
                                                    formik.setFieldValue(`volumes.${v.id}`, e.target.value)
                                                }
                                                }
                                                value={
                                                    formik?.values?.volumes
                                                        ? formik?.values?.volumes[v.id]
                                                        : 0
                                                }/>
                                            {formik?.touched?.values &&
                                                formik?.errors?.values && (
                                                    <FormError>{formik?.errors?.values}</FormError>
                                                )}
                                        </div>
                                    </div>
                                ))}
                        </div>

                        {errorMessage && <FormError>{errorMessage}</FormError>}
                        <div className="cms-modal-actions">
                            <button type="button" id="btnCancelModal" name="btnCancelModal" className="btn-type4 small"
                                    onClick={() => setIsOpen(false)}>
                                Cancelar
                            </button>
                            <button type="button" id="btnAddModal" name="btnAddModal" className="btn-type5 big"
                                    onClick={() => formik.handleSubmit()}>
                                {discountToEdit ? 'Guardar' : 'Añadir'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
        ;
}
