import React from 'react';
import BarLoader from 'react-spinners/BarLoader';

export default ({ boxed }) => {
  if (boxed) {
    return (
      <div className="box" style={{
        display: 'flex',
        padding: '20px 20px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <div className="sweet-loading">
          <BarLoader
            css={{
              display: 'block',
              margin: '0 auto',
            }}
            size={150}
            color="#4BC4C4"
            loading={true}
          />
        </div>
      </div>
    )
  }
  return (
    <div className="sweet-loading">
      <BarLoader
        css={{
          display: 'block',
          margin: '0 auto',
        }}
        size={150}
        color="#4BC4C4"
        loading={true}
      />
    </div>
  );
};
