import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {URLS} from '../../../lib/api';
import {get} from '../../../lib/restClient';
import TablePager from '../../../components/TablePager';
import {Input} from '../../../components/Inputs';
import {useHistory} from 'react-router-dom';
import useDataFilter from '../../../hooks/useDataFilter';
import SortArrow from '../../../components/SortArrow';
import {downloadFile} from '../../../lib/downloads';
import DatePicker from '../../../components/DatePicker';
import RightAlignedButton from '../../../components/RightAlignedButton';
import AddProductModal from '../../../components/AddProductModal';
import Loader from '../../../components/Loader';

const initialValues = {};

const Stock = () => {
    const history = useHistory();
    const {
        currentPage,
        setCurrentPage,
        filtersValues,
        setFilterValue,
        submitFilters,
        sort,
        setSortField,
    } = useDataFilter({
        initialFilters: initialValues,
        initialSort: {field: 'name', order: 'asc'},
        onRefetch: () => refetch(),
    });
    const [showFilters, setShowFilters] = useState(false);
    const [addProductIsOpen, setAddProductIsOpen] = useState(false);
    const [editProductIsOpen, setEditProductIsOpen] = useState(false);
    const {isLoading, error, data, refetch, isFetching} = useQuery('adminStocks', async () => {
        const response = await get(URLS.PRODUCT.LIST_STOCKS({
            page: currentPage,
            sort,
            filters: {
                ...filtersValues,
                registrationNumber: encodeURIComponent(filtersValues?.registrationNumber),
            },
        }));
        const partnersResponse = await get(URLS.ADMIN.LIST_PARTNERS({}));
        return {
            list: response.data,
            partners: partnersResponse.data,
            pageCount: response.headers['x-page-count'],
            totalCount: response.headers['x-total-count'],
        };
    }, {refetchOnWindowFocus: false});
    useEffect(() => {
        setTimeout(() => refetch(), 600);
        return () => {
        };
    }, [sort, currentPage, refetch]);
    if (error) {
        return <span>Error</span>;
    }
    const showLoader = isLoading || isFetching;
    return (
        <main>
            <form action="#" method="post">
                <div className="box main-header-cms">
                    <div className="main-header-cms-top">
                        <div className="main-header-cms-title">
                            <span className="header-cms-icon icon-stock"></span>
                            <h1 className="header-cms-title">
                                Stock de productos <span>Relación de los productos en stock de Numentech</span>
                                <span className="cms-title-detail">{data?.totalCount} productos</span>
                            </h1>
                        </div>

                        <div className="main-header-cms-actions">
                            <div className="header-cms-actions-absolute">
                                <button type="button" id="btnAdd" name="btnAdd" className="btn-type5"
                                        onClick={() => setAddProductIsOpen(true)}>
                                    Añadir stock
                                </button>
                                <button type="button" id="btnAdd" name="btnAdd" className="btn-type5"
                                        onClick={() => setEditProductIsOpen(true)}>
                                    Editar stock
                                </button>
                            </div>

                            <div className="header-cms-actions-buttons">
                                <button type="button" id="btnFilters" name="btnFilters" className="btn-cms-actions"
                                        onClick={() => setShowFilters(!showFilters)}>
                                    Filtros
                                </button>
                                <button type="button" id="btnDownload" name="btnDownload" className="btn-cms-actions"
                                        onClick={() => downloadFile({
                                            url: URLS.PRODUCT.DOWNLOAD_STOCKS_CSV(),
                                            name: 'Stocks.csv',
                                            contentType: 'application/csv',
                                        })}>
                                    Descargar CSV <span className="icon-download-filters"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {(isFetching || isLoading) &&
                    <Loader boxed/>
                }

                <div className="box">
                    {!showLoader &&
                        <div className={`cms-filters ${showFilters ? 'show' : ''}`}>
                            <h2 className="cms-filters-title">Filtros</h2>

                            <button type="button" id="btnCloseFilters" name="btnCloseFilters"
                                    className="btn-close-filters"
                                    onClick={() => setShowFilters(false)}>
                                <span className="icon-close-modal"></span>
                            </button>

                            <div className="form-item-row form-row-special">
                                <div className="form-item filter-size1">
                                    <label className="form-label">ID Registration Number</label>
                                    <Input
                                        type="text"
                                        id="registrationNumber"
                                        name="registrationNumber"
                                        onChange={(e) => setFilterValue('registrationNumber', e.target.value)}
                                        value={filtersValues.registrationNumber}/>
                                </div>

                                <div className="form-item item-special-size1">
                                    <label className="form-label">Nombre del producto</label>
                                    <Input
                                        type="text"
                                        id="name"
                                        name="name"
                                        onChange={(e) => setFilterValue('name', e.target.value)}
                                        value={filtersValues.name}/>
                                </div>


                                <div className="form-item filter-size1">
                                    <label className="form-label">Estado del producto</label>

                                    <div className="form-text-container">
                                        <select id="cmbStatus" name="cmbStatus" className="form-select"
                                                defaultValue={filtersValues.status}
                                                onChange={(e) => setFilterValue('status', e.target.value || undefined)}>
                                            <option value="">Todos</option>
                                            <option value="active">Activo
                                            </option>
                                            <option value="inactive">Inactivo
                                            </option>
                                        </select>
                                        <span className="icon-arrow select-arrow"></span>
                                    </div>
                                </div>
                            </div>

                            <div className="form-item-row form-row-special">
                                <div className="form-item item-special-size1">
                                    <label className="form-label">Partners</label>

                                    <div className="form-text-container">
                                        <select id="cmbPartner" name="cmbPartner" className="form-select"
                                                defaultValue={filtersValues.partners ?? ""}
                                                onChange={(e) => setFilterValue('partners', e.target.value)}>
                                            <option value="">Todos</option>
                                            {data?.partners?.map((partner) => (
                                                <option key={partner.id}
                                                        value={partner.id}>{partner.organizationName}</option>
                                            ))}
                                        </select>
                                        <span className="icon-arrow select-arrow"></span>
                                    </div>
                                </div>

                                <div className="form-item item-special-size2">
                                    <label className="form-label">Fecha de alta</label>
                                    <div className="form-text-container-two">
                                        <DatePicker
                                            placeholder="Desde"
                                            defaultValue={filtersValues['createdAt|from'] ? new Date(filtersValues['createdAt|from']) : null}
                                            onChange={(date) => setFilterValue('createdAt|from', date)}/>
                                        <DatePicker
                                            placeholder="Hasta"
                                            defaultValue={filtersValues['createdAt|to'] ? new Date(filtersValues['createdAt|from']) : null}
                                            onChange={(date) => setFilterValue('createdAt|to', date)}/>
                                    </div>
                                </div>

                            </div>

                            <RightAlignedButton
                                onClick={() => {
                                    submitFilters();
                                    setShowFilters(false);
                                    setCurrentPage(0);
                                }}>
                                Filtrar
                            </RightAlignedButton>
                        </div>
                    }
                    {!showLoader &&
                        <div className="responsive-table full-table">
                            <table className="table-type2">
                                <thead>
                                <tr>
                                    <th>Id producto</th>
                                    <th onClick={() => setSortField({field: 'name'})}>
                                        Nombre producto
                                        <SortArrow sort={sort} field="name"/>
                                    </th>
                                    <th>
                                        Und.<br/> disponibles
                                    </th>
                                    <th>
                                        Und. activadas<br/>
                                    </th>
                                    <th>
                                        Und. asignadas<br/>
                                    </th>
                                    <th className="centered">Acciones</th>
                                </tr>
                                </thead>

                                <tbody>
                                {data?.list?.map(stock => (
                                    <tr key={stock.id}>
                                        <td>
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                history.push(`/admin/products/${stock.id}/edit`);
                                            }}>
                                                {stock?.id}
                                            </a>
                                        </td>
                                        <td>{stock?.name}</td>
                                        <td>{stock?.unitsAvailable}</td>
                                        <td>{stock?.customerActivatedUnits}</td>
                                        <td>{stock?.unitsAssignedPartner}</td>
                                        <td className="centered">
                                            <button type="button" id="btnShowItem1" name="btnShowItem1"
                                                    className="btn-icon-border"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        history.push(`/admin/stocks/${stock.id}`);
                                                    }}>
                                                <span className="icon-edit"></span>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    }

                </div>
                {!showLoader &&
                    <TablePager
                        pages={data?.pageCount}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}/>
                }
                {addProductIsOpen &&
                    <AddProductModal
                        success={()=>refetch()}
                        isOpen={addProductIsOpen}
                        setIsOpen={setAddProductIsOpen}
                        mode="ADD"
                    />
                }
                {editProductIsOpen &&
                    <AddProductModal
                        success={()=>refetch()}
                        isOpen={editProductIsOpen}
                        setIsOpen={setEditProductIsOpen}
                        mode="EDIT"
                    />
                }

            </form>
        </main>
    );
};

export default Stock;